/**
 * Helper for showing a flash message on failure of a form submission. This is
 * used in addition to highlighting individual fields to increase a11y.
 *
 * @name flashInvalidFormSubmitMessage
 * @param {Object} errors - Errors object containing form-wide and individual field errors
 * @param {Function} dispatch - Redux dispatch function (passed from redux-form)
 * @param {Object} [submitError] - External error responsible for failing submission (usually from API call)
 * @returns undefined
 */

import { flashErrorMessage } from 'redux-flash'
import { get } from 'lodash'

function flashInvalidFormSubmitMessage(errors, dispatch, submitError) {
  if (!submitError)
    return dispatch(
      flashErrorMessage(
        'Invalid form entry. Please correct the highlighted fields'
      )
    )
  const message =
    get(errors, 'message') ||
    get(errors, '_error') ||
    'Oops! Something went wrong. Please try again later or contact customer support'

  dispatch(flashErrorMessage(message))
}

export default flashInvalidFormSubmitMessage
