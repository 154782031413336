import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'

const propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
}

const defaultProps = {}

function ExternalLink({ children, href, ...rest }) {
  return (
    <a target="_blank" rel="noopener noreferrer" href={href} {...rest}>
      {children}
    </a>
  )
}

ExternalLink.propTypes = propTypes
ExternalLink.defaultProps = defaultProps

export default pure(ExternalLink)
