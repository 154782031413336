import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose, withProps } from 'recompose'
import { lpForm } from 'lp-form'
import { Field, FormSection } from 'redux-form'
import { Expandable, HorizontalCard, QuantityInput } from 'components'
import { get } from 'lodash/fp'
import { noop } from 'lodash'

const propTypes = {
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  addOns: PropTypes.arrayOf(Types.addOn).isRequired,
  cart: PropTypes.object,
  getCurrentTransactionQuantity: PropTypes.func,
  warnTransactionCapExceeded: PropTypes.func,
}

const defaultProps = {
  currentPriceQuantities: {},
  warnTransactionCapExceeded: noop,
}

const hasAddOnPriceSelected = (initialValues, addOn) => {
  if (!addOn.prices) return false
  const priceIds = addOn.prices.map(({ centamanId }) => centamanId)
  return priceIds.some((id) => get(`selectedAddOns.addOn-${id}`, initialValues))
}

function SelectAddOnsForm({
  change,
  handleSubmit,
  initialValues,
  addOns,
  cart,
  getCurrentTransactionQuantity,
  warnTransactionCapExceeded,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <FormSection name="selectedAddOns">
        {addOns.map((addOn) => (
          <Expandable
            key={addOn.id}
            startExpanded={hasAddOnPriceSelected(initialValues, addOn)}
          >
            {(expanded, setExpanded) => (
              <div className="horizontal-item">
                <HorizontalCard
                  active={expanded}
                  descriptionHtml={addOn.descriptionHtml}
                  displayName={addOn.displayName}
                  onClick={() => {
                    setExpanded(!expanded)

                    // Remove any added items specific to this add-on from cart on close
                    if (expanded) {
                      addOn.prices.forEach((price) => {
                        change(
                          `selectedAddOns.addOn-${price.centamanId}.quantity`,
                          0
                        )
                      })

                      // Skip a tick to ensure that form values are updated
                      setTimeout(handleSubmit, 0)
                    }
                  }}
                  imageUrl={addOn.image}
                  buttonText={addOn.selectButtonText}
                />
                {expanded && (
                  <div className="horizontal-item-expand">
                    <div className="select-item-group-grid">
                      {addOn.prices.map((ticket) => {
                        const currentTransactionQuantity = getCurrentTransactionQuantity(
                          addOn.id,
                          cart
                        )
                        return (
                          <Field
                            key={ticket.id}
                            aria-label={`Select ${ticket.displayname} amount`}
                            name={`addOn-${ticket.centamanId}.quantity`}
                            component={QuantityInput}
                            itemName={ticket.displayName}
                            itemDescription={ticket.details}
                            price={ticket.price}
                            tooltip={ticket.infoText}
                            min={ticket.minQuantity}
                            max={ticket.maxQuantity}
                            format={(val) => {
                              if (!val) return 0
                              return val
                            }}
                            currentTransactionQuantity={
                              currentTransactionQuantity
                            }
                            transactionQuantityCap={addOn.maxQuantity}
                            warnTransactionCapExceeded={
                              warnTransactionCapExceeded
                            }
                          />
                        )
                      })}
                    </div>
                  </div>
                )}
              </div>
            )}
          </Expandable>
        ))}
      </FormSection>
    </form>
  )
}

function modifyInitialValues({ initialValues }) {
  // initial values come in as price selections.
  // We need to map them to an object to render them in the form.
  const selectedAddOns = {}
  initialValues.forEach((priceSelection) => {
    selectedAddOns[`addOn-${priceSelection.centamanId}`] = {
      quantity: priceSelection.quantity,
    }
  })
  return {
    initialValues: {
      selectedAddOns,
    },
  }
}

function beforeSubmit({ selectedAddOns }) {
  // Create "quantity" object from form fields
  return Object.keys(selectedAddOns).map((key) => ({
    centamanId: Number(key.replace('addOn-', '')),
    quantity: selectedAddOns[key].quantity,
  }))
}

SelectAddOnsForm.propTypes = propTypes
SelectAddOnsForm.defaultProps = defaultProps

export default compose(
  withProps(modifyInitialValues),
  lpForm({
    name: 'select-add-on-form',
    beforeSubmit,
    submitOnChange: true,
  })
)(SelectAddOnsForm)
