import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import { noop } from 'lodash'
import classnames from 'classnames'

const propTypes = {
  time: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  vacancy: PropTypes.number,
  displayVacancy: PropTypes.bool,
}

const defaultProps = {
  onClick: noop,
  selected: false,
  disabled: false,
  loading: false,
  vacancy: null,
  displayVacancy: true,
}

function getVacancyLabel({ disabled, loading, vacancy, displayVacancy }) {
  if (loading) return 'Loading'
  if (disabled) return 'Sold Out'
  if (vacancy && displayVacancy) return vacancy + ' Remaining'
  return ''
}

function TimeSelectButton({
  time,
  onClick,
  selected,
  disabled,
  loading,
  vacancy,
  displayVacancy,
}) {
  return (
    <li
      className={classnames({
        selected: selected,
        'not-available': disabled,
      })}
      onClick={(...args) => {
        if (!disabled && !loading) return onClick(...args)
      }}
    >
      <a disabled={disabled}>{time}</a>
      <p className="ticket-count">
        {getVacancyLabel({ loading, disabled, vacancy, displayVacancy })}
      </p>
    </li>
  )
}

TimeSelectButton.propTypes = propTypes

TimeSelectButton.defaultProps = defaultProps

export default pure(TimeSelectButton)
