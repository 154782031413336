import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import MainHeader from './MainHeader'

const propTypes = {
  status: PropTypes.string,
}

const defaultProps = {
  status:
    'We expect to be back online in a few hours. Thanks for your patience.',
}

function MaintenanceState({ status }) {
  return (
    <div className="step-container">
      <MainHeader title="Checkout currently unavailable" disablePrevStep>
        <p>{status}</p>
      </MainHeader>
    </div>
  )
}

MaintenanceState.propTypes = exact(propTypes)
MaintenanceState.defaultProps = defaultProps

export default pure(MaintenanceState)
