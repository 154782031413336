import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { Field, FormSection, formValueSelector } from 'redux-form'
import { modifyProps } from 'lp-hoc'
import { RenderedHTML, QuantityInput, SectionHeader } from 'components'
import { first, get, sum } from 'lodash'
import * as flashActions from 'redux-flash'
import { getMaxQuantity, serializeSelectedTickets } from 'utils'

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  ticketsByCategory: PropTypes.arrayOf(PropTypes.object),
  selectedTickets: PropTypes.object,
  vacancy: PropTypes.number,
  showVacancy: PropTypes.bool,
  transactionQuantityCap: PropTypes.number,
  flashErrorMessage: PropTypes.func.isRequired,
}

const defaultProps = {
  vacancy: null,
  showVacancy: true,
  transactionQuantityCap: null,
}

// The generic section header should only show if one category exists and it doesn't have a name
const showGenericHeader = (categories) =>
  categories.length === 1 && !get(first(categories), 'categoryName')

function SelectTicketsForm({
  handleSubmit,
  ticketsByCategory,
  selectedTickets,
  vacancy,
  showVacancy,
  transactionQuantityCap,
  flashErrorMessage,
}) {
  const getCurrentTransactionQuantity = () => {
    if (!selectedTickets) return 0
    const tickets = Object.keys(selectedTickets).map(
      (ticket) => selectedTickets[ticket].quantity
    )
    return sum(tickets)
  }

  const currentTransactionQuantity = getCurrentTransactionQuantity()

  const warnTransactionCapExceeded = () => {
    flashErrorMessage(
      `Only ${transactionQuantityCap} tickets can be purchased at a time.`
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormSection name="selectedTickets">
        {showGenericHeader(ticketsByCategory) && (
          <SectionHeader title={'Select Ticket Amount'} />
        )}
        {ticketsByCategory.map(
          ({ categoryName, categorySubtitle, prices }, i) => (
            <div className="ticket-category-group" key={i}>
              {categoryName && <SectionHeader title={categoryName} />}
              {categorySubtitle && (
                <RenderedHTML className="confirmation-info">
                  {categorySubtitle}
                </RenderedHTML>
              )}
              <div className="select-item-group-grid">
                {prices.map((ticket) => (
                  <Field
                    key={ticket.id}
                    name={`ticket-${ticket.centamanId}.quantity`}
                    label={`${ticket.displayName} Ticket Amount`}
                    component={QuantityInput}
                    itemName={ticket.displayName}
                    itemDescription={ticket.details}
                    price={ticket.price}
                    min={ticket.minQuantity || 0}
                    max={getMaxQuantity({
                      ticket,
                      vacancy,
                    })}
                    showVacancy={showVacancy}
                    tooltip={ticket.infoText}
                    transactionQuantityCap={transactionQuantityCap}
                    currentTransactionQuantity={currentTransactionQuantity}
                    warnTransactionCapExceeded={warnTransactionCapExceeded}
                  />
                ))}
              </div>
            </div>
          )
        )}
      </FormSection>
    </form>
  )
}

// This is required to create a custom object that matches the 'ticket-' nomenclature for Redux form
const setInitialFormValues = (tickets, initialValues) => {
  let initialTicketForm = {}
  // Set everything to zero by default
  tickets.forEach((ticket) => {
    initialTicketForm[`ticket-${ticket.centamanId}`] = { quantity: 0 }
  })

  // Update quantities based on what exists in the cart
  initialValues.forEach((value) => {
    initialTicketForm[`ticket-${value.centamanId}`] = {
      quantity: value.quantity,
    }
  })

  return { selectedTickets: initialTicketForm }
}

const modify = ({ onSubmit, tickets, initialValues }) => {
  return {
    onSubmit: ({ selectedTickets }) => {
      return onSubmit(serializeSelectedTickets(selectedTickets, tickets))
    },
    initialValues: setInitialFormValues(tickets, initialValues),
  }
}

SelectTicketsForm.propTypes = propTypes
SelectTicketsForm.defaultProps = defaultProps

const selectValue = formValueSelector('ticketingForm')

function mapStateToProps(state) {
  return {
    selectedTickets: selectValue(state, 'selectedTickets'),
  }
}

const mapDispatchToProps = {
  flashErrorMessage: flashActions.flashErrorMessage,
}

export default compose(
  modifyProps(modify),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  lpForm({
    name: 'ticketingForm',
    submitOnChange: true,
  })
)(SelectTicketsForm)
