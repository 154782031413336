import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import { selectors as globalSelectors } from 'global-reducer'
import { Spinner } from 'lp-components'
import { waitFor, modifyProps, onMount } from 'lp-hoc'
import * as routerActions from 'react-router-redux'
import {
  PaymentConfirmation,
  MainHeader,
  Receipt,
  RenderedHTML,
  UpsellCard,
} from 'components'
import { createDisplayOrgText } from 'utils'
import { displayKeys } from 'config'

const propTypes = {
  ticketConfirmation: PropTypes.object.isRequired,
  selectedTicket: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  displayOrgText: PropTypes.func.isRequired,
}

const defaultProps = {}

function TicketConfirmation({
  selectedTicket,
  ticketConfirmation,
  displayOrgText,
  config,
}) {
  return (
    <div className="step-container">
      <div>
        <MainHeader title="Thank You!" disablePrevStep>
          <RenderedHTML>
            {displayOrgText(displayKeys.TICKET_PAYMENT_CONFIRMATION_SUBTITLE)}
          </RenderedHTML>
          {config.CONFIRMATION_UPSELL_LINK && (
            <UpsellCard
              content={displayOrgText(displayKeys.CONFIRMATION_UPSELL_TEXT)}
              path={config.CONFIRMATION_UPSELL_LINK}
            />
          )}
        </MainHeader>
        <div className="row confirmation-page">
          <div className="col-6">
            <Receipt
              title={selectedTicket.displayName}
              image={selectedTicket.image}
              cart={ticketConfirmation.cart}
              displayTotal
            />
          </div>
          <div className="col-6">
            <PaymentConfirmation
              confirmation={ticketConfirmation}
              info={displayOrgText(
                displayKeys.TICKET_PAYMENT_CONFIRMATION_INFO
              )}
              downloadText="Download Tickets"
              displayValidPeriod
            />
          </div>
        </div>
      </div>
    </div>
  )
}

TicketConfirmation.propTypes = propTypes
TicketConfirmation.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    webstore: globalSelectors.webstore(state),
    config: globalSelectors.config(state),
    selectedTicket: selectors.selectedTicket(state),
    ticketConfirmation: selectors.ticketConfirmation(state),
  }
}

const mapDispatchToProps = {
  push: routerActions.push,
}

function onComponentDidMount({ ticketConfirmation, push, webstore }) {
  if (!ticketConfirmation) return push('/' + webstore + '/tickets')
}

function initializeOrgHelpers({ config }) {
  return {
    displayOrgText: createDisplayOrgText(config),
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  onMount(onComponentDidMount),
  waitFor('ticketConfirmation', Spinner),
  modifyProps(initializeOrgHelpers)
)(TicketConfirmation)
