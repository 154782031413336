/**
 * @name stripStepName
 * @param {String} path - Url path
 * @param {Boolean} [options.includesWebstore=false] - Specifies if the path will contain a reference to the resource's webstore
 * @returns {String} - Strips out details specific to the specific step
 * @example
 *   stripStepDetails('/member/forgot-password')
 *   // '/member
 *
 *   stripStepDetails('/sydney/tickets/details/834', { includesWebstore: true })
 *   // '/sydney/tickets'
 */

export default function stripStepDetails(
  path = '',
  { includesWebstore = false } = {}
) {
  const pathComponents = path.split('/')
  if (pathComponents.length <= 2) return path
  const sliceIndex = includesWebstore ? 3 : 2

  return pathComponents.slice(0, sliceIndex).join('/')
}
