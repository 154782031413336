// Organization configuration file
import { displayText as baseDisplayText, displayKeys } from 'config'
import favicon from 'images/favicons/big-rivers.png'
import logoImage from 'images/logos/big-rivers/logo.png'
import * as Types from 'types'

const config = {}

// ----- CONSTANTS -----

config.DEFAULT_COUNTRY = 'UNITED STATES'
config.PROMPT_MEMBERSHIP_UPGRADE = false
config.SHOW_TICKET_DETAILS_INFO_BOX = false
config.SHOW_MEMBER_LOGIN_INFO_BOX = false
config.USE_MEMBERSHIP_QUANTITY_SELECTORS = true
config.ENABLE_BACK_BUTTON_ON_MEMBERSHIP_DETAILS = true
config.ENABLE_RECAPTCHA = false
config.MEMBERSHIP_SHOW_COSTS = false
config.MEMBERSHIP_CONFIRMATION_SHOW_VALID_PERIOD = true
config.DISPLAY_CARD_LOGOS = false
config.MEMBER_LOGIN_METHOD = Types.loginFormMethods.email
config.ENABLE_MEMBER_REGISTRATION = true

// Right now, this points to a concert.
// config.CONFIRMATION_UPSELL_LINK = '/all/tickets/details/1045'

// ----- BRANDING -----

// This is the fallback used if a webstore cannot be located. The paths should always be prepended with 'all'
config.defaultBranding = {
  className: 'big-rivers',
  title: 'Big Rivers',
  navLinks: [
    { name: 'Tickets', path: '/all/tickets' },
    { name: 'Season Passes', path: '/all/memberships' },
  ],
  showLogin: false,
  logo: logoImage,
  homeUrl: 'http://bigriverswaterpark.com/',
  pageTitle: 'Big Rivers | Streamlined Checkout',
  favicon,
}

config.webstoreBranding = {
  all: config.defaultBranding,
}

// ----- FORM FIELDS -----

/**
 * This object controls the fields that are visible for a particular form. The fields must
 * be nested within a key that is specific to a form name. Only the fields that the organization
 * wants to render need to be specified.
 */

config.fields = {
  'ticketing-billing-details': {
    paymentDetails: {
      zip: false,
    },
    customerDetails: {
      firstAndLastName: true,
      email: true,
      phone: true,
      address: true,
      zip: true,
    },
  },
  'membership-billing-details': {
    paymentDetails: {
      zip: false,
    },
    customerDetails: false,
  },
  'member-contact': {
    primaryMember: {
      firstName: true,
      lastName: true,
      phone: true,
      dateOfBirth: true,
      address: {
        streetAddress1: true,
        city: true,
        state: true,
        zip: true,
        country: true,
      },
    },
  },
}

// ----- DISPLAY TEXT -----

/**
 * This object overrides the display text that is defined globally with string values that are
 * specific to the organization. Mustache-esque templating is available for dynamic strings using
 * {{ insertFieldName }} for interpolation.
 */

config.displayText = {
  ...baseDisplayText,
  [displayKeys.MEMBERSHIP_LABEL]: 'season pass',
  // [displayKeys.CONFIRMATION_UPSELL_TEXT]:
  //   '<p><strong>Book your own cabana!</strong> Your reserved cabana provides you with a shady, comfortable and secure place to stay. Each cabana is suitable for up to six guests.</p>',
  [displayKeys.MEMBER_EMAIL_LOGIN_INSTRUCTIONS]:
    'Log in to renew your season pass.',
  [displayKeys.MEMBER_ID_LOGIN_INSTRUCTIONS]:
    'Log in to renew your season pass.',
  [displayKeys.MEMBER_LOGIN_TITLE]: 'Already have a Season Pass?',
  [displayKeys.MEMBER_REGISTRATION_TITLE]: 'Become a Passholder',
  [displayKeys.MEMBER_REGISTRATION_INSTRUCTIONS]:
    'Not a season passholder yet? Become one today!',
  [displayKeys.MEMBERSHIP_DETAILS_EXISTING_RENEWAL_NOTICE]: `
    <p>If you want to <strong>renew</strong> your season pass or <strong>add additional season passes</strong> to your account, please email us at <a href="mailto:info@bigriverswaterpark.com">info@bigriverswaterpark.com</a>.</p>
    <br />
    <p>Please include your name and phone number in your email, and we will get right back to you.</p>
  `,
  [displayKeys.MEMBERSHIP_DETAILS_RENEWAL_NOTICE]:
    '<p>Your season pass has expired. Please proceed below to <strong>renew</strong> your season pass.</p>',
  [displayKeys.MEMBERSHIP_DETAILS_INSTRUCTIONS]:
    '<p>You have chosen <strong>{{ displayName }}</strong>. Please enter your passholder details below.',
  [displayKeys.PRIMARY_MEMBER_FORM_TITLE]: 'Primary Passholder Details',
  [displayKeys.PRIMARY_MEMBER_FORM_DETAILS]: `
    <p>Enter the details for the Primary Passholder. This is the person who will control the account, receive communications about your season pass(es), and purchase renewals.</p>
    <br />
    <p>A season pass for this passholder is already included in your cart. Additional passes can be added below.</p>
  `,
  [displayKeys.SECONDARY_MEMBER_FORM_DETAILS]:
    '<p>Here you can purchase additional season passes. These will be tied to the Primary Passholder and their account.</p>',
  [displayKeys.SECONDARY_MEMBER_FORM_TITLE]: 'Add Additional Passholders',
  [displayKeys.SECONDARY_MEMBER_LIMIT_MODAL_ADD_MORE_INSTRUCTIONS]: '',
  [displayKeys.SECONDARY_MEMBER_LIMIT_MODAL_BASE_MESSAGE]:
    '{{ membershipType }} can only be purchased with up to {{ limitQuantity }} passholder(s).',
  [displayKeys.SECONDARY_MEMBER_LIMIT_MODAL_TITLE]: 'Passholder Limit Reached',
  [displayKeys.SELECT_MEMBERSHIP_CURRENT_MEMBERSHIP_OVERLAY]:
    'Current Season Pass',
  [displayKeys.SELECT_MEMBERSHIP_INSTRUCTIONS]:
    'Please select the season pass you would like to purchase below.',
  [displayKeys.SELECT_MEMBERSHIP_NO_MEMBERSHIPS_FOUND]:
    'Sorry, there are no season passes available at this time.',
  [displayKeys.SELECT_MEMBERSHIP_TITLE]: 'Select Season Pass',
  [displayKeys.SELECT_MEMBERSHIP_TYPE]: 'season pass',
}

// ----- ANALYTICS -----

/**
 * This object provides certain analytics packages to be enabled if an identifier is provided.
 */

config.analytics = {
  gtm: process.env.GT_GTM_CONTAINER_ID,
}

// ----- STEPS -----

/**
 * These objects control the existence and ordering of steps for various flows.
 */
config.membershipSteps = [
  {
    name: 'Passholder Details',
    route: 'details',
  },
  {
    name: 'Select Add-Ons',
    route: 'add-ons',
  },
  {
    name: 'Payment Info',
    route: 'payment',
  },
]

export default config
