/**
 * @name generateMemberOptionGroups
 * @param currentMember {Object} - The object with data specific to the current member
 * @param availableMembers {Array} - Array of members from the account that have not already been added
 * @returns {Array} - Returns an array of objects with `name` and `options` properties, where each object in `options` has a `key` and `value` property. This is setup to work with the prop shape expected by `lp-components` for the `Select` input.
 */

import { NEW_MEMBERSHIP_ID } from '../config'
import { unionBy } from 'lodash'

function generateMemberOptionGroups(currentMember, availableMembers = []) {
  const availableMemberOptions = availableMembers.map((member) => ({
    key: member.firstName + ' ' + member.lastName,
    value: member.id,
  }))

  const existingMembersOptionGroup = {
    name: 'Existing Members',
    options: createExistingMemberOptions(currentMember, availableMemberOptions),
  }

  const defaultOptionGroup = {
    name: '----------',
    options: [{ key: 'New Pass Holder', value: NEW_MEMBERSHIP_ID }],
  }

  return [existingMembersOptionGroup, defaultOptionGroup]
}

// ----- PRIVATE -----
function createExistingMemberOptions(currentMember, availableMemberOptions) {
  // If the member does not exist, then do not include them in the list
  if (!currentMember.id || currentMember.id === NEW_MEMBERSHIP_ID)
    return availableMemberOptions

  return unionBy(
    [
      {
        key: currentMember.firstName + ' ' + currentMember.lastName,
        value: currentMember.id,
      },
    ],
    availableMemberOptions,
    'value'
  )
}

export default generateMemberOptionGroups
