/**
 * @name getBrandingConfig
 * @param {Object} orgConfig - An object representing configuration details specific to the current Org
 * @param {String} webstore - String representing the specific webstore, defaulted to 'all'
 * @returns {Object} - Returns an object with org specific details that should be displayed in the navigation bar of the application
 */
import { get } from 'lodash'

/* eslint-disable no-console */

function getBrandingConfig(orgConfig, webstore) {
  if (!validConfiguration(orgConfig)) return

  return (
    get(orgConfig, `webstoreBranding.${webstore}`) || orgConfig.defaultBranding
  )
}

// ------- HELPERS -------

function validConfiguration({ defaultBranding, webstoreBranding } = {}) {
  const valid = defaultBranding || webstoreBranding
  if (!valid) {
    console.warn(
      "WARNING: No branding has been defined for this organization. The application will appear unstyled with the default navigational elements. Please add the appropriate customer branding information in the org's config file."
    )
  }

  return valid
}

export default getBrandingConfig
