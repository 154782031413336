/**
 * @name validateDateOfBirth
 * @param {String} date - A date of birth
 * @param {Object} [options] - Options object to configure validations
 * @param {Boolean} [options.isRequired=true] - If presence of the date is required
 * @param {Date} [options.startDate=new Date()] - Date to reference for age calculations
 * @param {Number} [options.minimumAge=0] - The mininimum age that is required
 * @param {Number} [options.maximumAge] - The maximum age that is required
 * @returns {String|undefined} - An Error message (if applicable)
 */

import { differenceInYears } from 'date-fns'

function validateDateOfBirth(
  date,
  { isRequired = true, startDate = new Date(), minimumAge = 0, maximumAge } = {}
) {
  if (isRequired && !date)
    return 'Must be provided in a valid format (e.g., January 1, 1980)'
  if (!minimumAge && !maximumAge) return

  const age = differenceInYears(startDate, date)
  if (age < minimumAge && !maximumAge)
    return `Must be at least ${minimumAge} years old to qualify (based on local time)`
  if (age < minimumAge || age > maximumAge)
    return `Must be between ${minimumAge} - ${maximumAge} years old to qualify (based on local time)`
}

export default validateDateOfBirth
