import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import * as Types from 'types'
import { TimeSelectButton } from 'components'

const propTypes = {
  timedTicketOffers: PropTypes.arrayOf(Types.offer).isRequired,
  input: PropTypes.object.isRequired,
  loading: PropTypes.bool,
}

const defaultProps = {}

function TimeSelectButtonInput({
  timedTicketOffers,
  loading,
  input: { value, onChange },
}) {
  return (
    <ul className="select-time">
      {/* Map over the times with a select button */}
      {timedTicketOffers.map((offer, i) => {
        return (
          <TimeSelectButton
            key={i}
            time={
              offer.showEventTimeRange
                ? offer.displayTime
                : offer.displayStartTime
            }
            loading={loading}
            disabled={offer.soldOut}
            vacancy={offer.vacancy}
            displayVacancy={offer.showRemainingQuantity}
            selected={!!value && offer.centamanId === value.centamanId}
            onClick={() => onChange(offer)}
          />
        )
      })}
    </ul>
  )
}

TimeSelectButtonInput.propTypes = propTypes
TimeSelectButtonInput.defaultProps = defaultProps

export default pure(TimeSelectButtonInput)
