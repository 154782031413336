/**
 * @name getAvailableMembersForMembershipType
 * @param {Array} availableMembers - Array of all members available for a selected membership
 * @param {Object} membershipType - Specific membership type
 * @param {Date} membershipStartDate - Start date of the membership
 * @returns {Array} - Returns a filtered array of the members that match the restrictions specified by the membership type (e.g., age)
 */

import { isNil, inRange } from 'lodash'
import { differenceInYears } from 'date-fns'

function getAvailableMembersForMembershipType(
  availableMembers,
  membershipType,
  membershipStartDate
) {
  const { minimumAge, maximumAge } = membershipType

  // This can be composed with multiple private filters as and when membership type restrictions change
  return filterMembersByAge(availableMembers, {
    minimumAge,
    maximumAge,
    startDate: membershipStartDate,
  })
}

// ----- PRIVATE -----

function filterMembersByAge(members, { minimumAge, maximumAge, startDate }) {
  // If no age restrictions are defined, then all members can have the membership type
  if (isNil(minimumAge) && isNil(maximumAge)) return members

  // Set max age to infinity for date comparisons if there is no restriction for a membership type
  // Operates under the assumption that a membership would never have a maximum age of 0
  const maxAgeCompareValue =
    isNil(maximumAge) || maximumAge === 0 ? Infinity : maximumAge

  return members.filter((member) => {
    const age = differenceInYears(startDate, member.dateOfBirth)
    return inRange(age, minimumAge, maxAgeCompareValue)
  })
}

export default getAvailableMembersForMembershipType
