import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import { ExternalHorizontalCard, MainHeader } from 'components'
import { CustomProductCard, ProductCard } from '../components'
import { onMount, waitFor } from 'lp-hoc'
import * as apiActions from 'api-actions'
import parties from 'images/background/GrandTexas_Parties.jpg'
import seasonPass from 'images/background/GrandTexas_SeasonPass.jpg'
import concerts from 'images/background/GrandTexas_Concerts.jpg'
import { selectors } from '../../../base/ticketing/reducer'

const propTypes = {
  products: PropTypes.arrayOf(Types.product),
}

const defaultProps = {}

function Home({ products }) {
  return (
    <div className="step-container">
      <MainHeader title="Big Rivers Passes" disablePrevStep />
      <div className="row">
        <div className="col-12">
          <CustomProductCard
            name="Season Pass"
            descriptionHtml={`
              <p>All-day, season-long access to all waterpark slides and activities; all-day season-long access to all aerial activities, including zip lines and ropes courses; free admission to Wild Isle, Mystic Forest, Big Al’s Farm and more; discounts on merchandise, food and beverages; and discounts on select kart races at Speedsportz Racing Park.</p>
            `}
            image={seasonPass}
            path="/all/memberships"
          />
          {products.map((product) => (
            <ProductCard key={product.id} product={product} />
          ))}
          <CustomProductCard
            name="Concerts"
            descriptionHtml={`
              <p>Great rides, great food, and great <strong>music</strong> can be found year round at Big Rivers.</p>
            `}
            image={concerts}
            path="/all/tickets#concerts"
          />
          <ExternalHorizontalCard
            displayName="Parties & Private Events"
            buttonText="Contact Us"
            imageUrl={parties}
            descriptionHtml={`
              <p>
                Birthday parties, corporate events, and special occasions can be held year-round. Contact us to plan your next event.
              </p>
            `}
            url="https://bigriverswaterpark.com/about/#contact"
          />
        </div>
      </div>
    </div>
  )
}

Home.propTypes = propTypes
Home.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    products: selectors.products(state),
  }
}

const mapDispatchToProps = {
  fetchProducts: apiActions.fetchProducts,
}

// Don't show concerts, since we have a custom product for that.
const FILTERED_PRODUCT_CATEGORIES = ['Concerts']

const filterProductsByCategory = ({ products = [] }) => {
  return {
    products: products.filter(
      (product) => !FILTERED_PRODUCT_CATEGORIES.includes(product.categoryName)
    ),
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withPropsOnChange(['products'], filterProductsByCategory),
  onMount('fetchProducts'),
  waitFor('products')
)(Home)
