import { countBy, get } from 'lodash/fp'

// Return all membership types that have been selected
function getSelectedMembershipTypeIds(members) {
  return countBy('membershipTypeId', members)
}

// If overrides aren't passed, default to vart values
function calculatePrimaryMember({
  cart,
  passedPrimaryMember,
  passedPrimaryMembershipType,
}) {
  const primaryMember = passedPrimaryMember || cart.primaryMember
  if (!passedPrimaryMembershipType) return primaryMember
  return { ...primaryMember, membershipTypeId: passedPrimaryMembershipType.id }
}

// Pull membership types from contacts and update the corresponding price quantities in the cart
export function mapUpdateMembershipQuantities(
  cart,
  membershipTypes,
  {
    primaryMember: passedPrimaryMember,
    primaryMembershipType: passedPrimaryMembershipType,
    secondaryMembers,
    addOnMembers,
    productId,
  }
) {
  // Calculate primary member, using overrride values if provided
  const primaryMember = calculatePrimaryMember({
    cart,
    passedPrimaryMember,
    passedPrimaryMembershipType,
  })
  // Collect all members, defaulting to what's already in the cart if they are not specified
  const allMembers = [
    primaryMember,
    ...(secondaryMembers || cart.secondaryMembers),
    ...(addOnMembers || cart.addOnMembers),
  ]

  const selectedMembershipTypeIds = getSelectedMembershipTypeIds(allMembers)
  const selectedMembershipTypes = membershipTypes.filter(
    (membershipType) => selectedMembershipTypeIds[membershipType.id]
  )

  return {
    productId: productId || get('productId', selectedMembershipTypes[0]), // productId is the same for all prices
    priceSelections: selectedMembershipTypes.map((membershipType) => ({
      centamanId: membershipType.centamanId,
      productId: membershipType.productId,
      membershipTypeId: membershipType.membershipTypeId,
      collectCustomerName: membershipType.collectCustomerName,
      collectCustomerDateOfBirth: membershipType.collectCustomerDateOfBirth,
      displayName: membershipType.displayName,
      quantity: selectedMembershipTypeIds[membershipType.id],
      type: 'MembershipPrice',
      assignToPrimary: membershipType.assignToPrimary,
    })),
  }
}

export default mapUpdateMembershipQuantities
