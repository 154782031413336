import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as apiSelectors } from 'lp-redux-api'
import {
  MainHeader,
  StickyContainer,
  Receipt,
  MembershipPerksBox,
  ProductEmptyState,
} from 'components'
import { selectors as giftMembershipSelectors } from 'base/giftMemberships/reducer'
import { selectors as globalSelectors } from 'global-reducer'
import * as apiActions from 'api-actions'
import { onMount, waitFor, modifyProps } from 'lp-hoc'
import { GiftMembershipForm } from 'base/giftMemberships/forms'
import { flashInvalidFormSubmitMessage, getBrandingConfig } from 'utils'
import * as routerActions from 'react-router-redux'
import { get, find, isEmpty } from 'lodash'
import { clearMessages } from 'redux-flash'

const propTypes = {
  giftMemberships: PropTypes.arrayOf(Types.giftMembership),
  cart: Types.cart.isRequired,
  updateGiftMembershipPriceSelections: PropTypes.func.isRequired,
  updateGiftMembershipCart: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  isCartLoading: PropTypes.bool.isRequired,
  webstore: PropTypes.string.isRequired,
  nextStepPath: PropTypes.string.isRequired,
  receiptImagePath: PropTypes.string.isRequired,
  clearMessages: PropTypes.func.isRequired,
}

const defaultProps = {}

// Helper for grabbing the first recipient name since it is saved on all price selections if they are being collected
function getRecipientDetails(priceSelections, collectRecipientDetails = true) {
  if (!collectRecipientDetails) return null
  const firstPriceSelection = priceSelections[0]
  const recipientName = get(firstPriceSelection, 'attendeeDetails', [])
  // for now there is always only one recipient name, so we grab the first item in the array
  return recipientName[0]
}

// Helper for adding recipient details to price selections if they are being collected
function getPriceSelectionsWithAttendeeDetails(
  priceSelections,
  params,
  collectRecipientDetails = true
) {
  if (!collectRecipientDetails) return priceSelections
  // add the recipient details to all price selections to be included in each gift certificate
  const recipientDetails = { ...params.recipientDetails }
  return priceSelections.map((priceSelection) => {
    return {
      ...priceSelection,
      attendeeDetails: [recipientDetails],
    }
  })
}

const perks = [
  'Unlimited year-round admission',
  'Double Discount days in the Museum Stores every December',
  'FREE entry to annual festivals, exhibits, and events',
  'Subscription to <em>Mystic Seaport Museum Magazine</em>',
  'Exclusive Members-Only programming',
  '10-20% discounts on classes, programs, performances, and summer camps',
  'Access to the Members’ Lounge and Patio with complimentary beverages',
  '10% discount in the Museum shops and restaurants',
]

// Adds perks box and custom form

function SelectGiftMembership({
  giftMemberships,
  cart,
  updateGiftMembershipPriceSelections,
  isCartLoading,
  updateGiftMembershipCart,
  webstore,
  nextStepPath,
  push,
  receiptImagePath,
  clearMessages,
}) {
  if (isEmpty(giftMemberships))
    return (
      <ProductEmptyState title="Gift Membership">
        <p>Sorry, there are no gift memberships available at this time.</p>
      </ProductEmptyState>
    )

  // check if any of the gift memberships return true for collecting recipient details
  const collectRecipientDetails = !!find(
    giftMemberships,
    'collectAttendeeDetails'
  )
  const recipientDetails = getRecipientDetails(
    cart.priceSelections,
    collectRecipientDetails
  )
  return (
    <div className="step-container">
      <MainHeader title="Gift of Membership">
        <p>
          Give the gift that lasts all year long by purchasing a gift
          membership. Please select the gift membership level below.
        </p>
      </MainHeader>
      <MembershipPerksBox
        perks={perks}
        title="Membership Benefits At All Levels Include:"
      />
      <div className="row">
        <div className="col-8">
          <h2>Gift Membership Levels</h2>
          <GiftMembershipForm
            giftMemberships={giftMemberships}
            collectRecipientDetails={collectRecipientDetails}
            initialValues={{
              priceSelections: cart.priceSelections,
              recipientDetails,
            }}
            onChangePriceSelections={(priceSelections) =>
              updateGiftMembershipPriceSelections(cart, priceSelections)
            }
            onSubmit={({ priceSelections, ...params }) => {
              clearMessages()
              const priceSelectionsWithAttendeeDetails = getPriceSelectionsWithAttendeeDetails(
                priceSelections,
                params,
                collectRecipientDetails
              )
              return updateGiftMembershipCart(cart, {
                priceSelectionsWithAttendeeDetails,
                ...params,
              })
            }}
            onSubmitSuccess={() =>
              push(`/${webstore}/gift-memberships/${nextStepPath}`)
            }
            onSubmitFail={flashInvalidFormSubmitMessage}
          />
        </div>
        <StickyContainer className="col-4">
          <Receipt
            title="Gift Memberships"
            cart={cart}
            isCartLoading={isCartLoading}
            displayTotal
            image={receiptImagePath}
          />
        </StickyContainer>
      </div>
    </div>
  )
}

SelectGiftMembership.propTypes = propTypes
SelectGiftMembership.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    cart: giftMembershipSelectors.cart(state),
    isCartLoading: apiSelectors.isLoading(
      state,
      apiActions.updateGiftMembershipPriceSelections
    ),
    giftMemberships: giftMembershipSelectors.giftMemberships(state),
    nextStepPath: giftMembershipSelectors.nextStepPath(state),
    webstore: globalSelectors.webstore(state),
    orgConfig: globalSelectors.config(state),
  }
}

const mapDispatchToProps = {
  fetchGiftMemberships: apiActions.fetchGiftMemberships,
  updateGiftMembershipPriceSelections:
    apiActions.updateGiftMembershipPriceSelections,
  updateGiftMembershipCart: apiActions.updateGiftMembershipCart,
  push: routerActions.push,
  clearMessages,
}

const modify = ({ webstore, orgConfig }) => {
  const branding = getBrandingConfig(orgConfig, webstore)
  const receiptImagePath = get(branding, 'giftMembershipsImage', '')
  return {
    receiptImagePath,
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  modifyProps(modify),
  onMount('fetchGiftMemberships'),
  waitFor('giftMemberships')
)(SelectGiftMembership)
