import React from 'react'
import PropTypes from 'prop-types'
import { CouponCodeForm } from 'forms'
import { compose } from 'recompose'
import { onUpdate } from 'lp-hoc'
import { clearSubmitErrors as reduxFormClearSubmitErrors } from 'redux-form/es'
import { connect } from 'react-redux'
import { isEqual } from 'lodash'
import { selectors as globalSelectors } from 'global-reducer'

const propTypes = {
  coupon: PropTypes.string,
  hasPromoCodes: PropTypes.bool.isRequired,
  removeCoupon: PropTypes.func.isRequired,
  replaceCart: PropTypes.func.isRequired,
  validateCoupon: PropTypes.func.isRequired,
}

const defaultProps = {
  coupon: '',
}

function CouponCode({
  coupon,
  hasPromoCodes,
  removeCoupon,
  replaceCart,
  validateCoupon,
}) {
  // Do not show the input if an organization does not have promo codes enabled
  if (!hasPromoCodes) return null

  return (
    <div className="coupon-code">
      {coupon ? (
        <div className="promo-application">
          <label>Promo Code</label>
          <p className="promo-copy">
            Your promo code <b>{coupon}</b> was successfully applied.
          </p>
          <button className="remove-code" onClick={removeCoupon}>
            Remove Code
          </button>
        </div>
      ) : (
        <CouponCodeForm
          onSubmit={validateCoupon}
          onSubmitFail={({ cart }) => {
            if (!cart) return
            return replaceCart(cart)
          }}
        />
      )}
    </div>
  )
}

CouponCode.propTypes = propTypes
CouponCode.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    hasPromoCodes: globalSelectors.hasPromoCodes(state),
  }
}

const mapDispatchToProps = {
  clearSubmitErrors: reduxFormClearSubmitErrors,
}

// Coupon codes can be specific to items added to the cart, so error message needs to be removed on change
const watchCartItemsChange = (
  { cartItems, clearSubmitErrors },
  { cartItems: prevCartItems }
) => {
  if (!isEqual(cartItems, prevCartItems)) clearSubmitErrors('coupon-code')
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  onUpdate(watchCartItemsChange)
)(CouponCode)
