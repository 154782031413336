import React from 'react'
import PropTypes from 'prop-types'
import { TimeSelectButtonInput } from 'components'
import * as Types from 'types'
import { Field } from 'redux-form'

const propTypes = {
  availableTimes: PropTypes.arrayOf(Types.offer).isRequired,
}

const defaultProps = {}

function getOfferForId(offers, offerId) {
  return offers.find((offer) => offer.centamanId === offerId)
}

// This is necessary because we want to treat time values just as IDs.
// The formatter and normalizer map back and forth between ids (numbers) and offers (objects).

function AddOnTimeField({ availableTimes, ...rest }) {
  return (
    <Field
      component={TimeSelectButtonInput}
      timedTicketOffers={availableTimes}
      format={(offerId) => getOfferForId(availableTimes, offerId)}
      normalize={(offer) => offer.centamanId}
      {...rest}
    />
  )
}

AddOnTimeField.propTypes = propTypes
AddOnTimeField.defaultProps = defaultProps

export default AddOnTimeField
