import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'

const propTypes = {
  links: PropTypes.arrayOf(PropTypes.object),
}

const defaultProps = {
  links: [],
}

function Footer({ links }) {
  const numLinks = links.length
  return (
    <footer>
      {links.map((link, i) => (
        <React.Fragment key={i}>
          <a href={link.url} rel="noopener noreferrer" target="_blank">
            {link.displayName}
          </a>
          {i < numLinks - 1 && <span> |&nbsp;</span>}
        </React.Fragment>
      ))}
    </footer>
  )
}

Footer.propTypes = exact(propTypes)
Footer.defaultProps = defaultProps

export default pure(Footer)
