// Organization configuration file
import {
  // displayKeys,
  displayText as baseDisplayText,
  ticketingSteps as baseTicketingSteps,
  membershipSteps as baseMembershipSteps,
} from 'config'
import * as Types from 'types'

const config = {}

// ----- CONSTANTS -----

config.DEFAULT_COUNTRY = 'UNITED STATES'
config.PROMPT_MEMBERSHIP_UPGRADE = false
config.SHOW_TICKET_DETAILS_INFO_BOX = false
config.SHOW_MEMBER_LOGIN_INFO_BOX = false
config.USE_MEMBERSHIP_QUANTITY_SELECTORS = false
config.ENABLE_BACK_BUTTON_ON_MEMBERSHIP_DETAILS = true
config.ENABLE_RECAPTCHA = true
config.MEMBERSHIP_SHOW_COSTS = false
config.MEMBERSHIP_CONFIRMATION_SHOW_VALID_PERIOD = true
config.DISPLAY_CARD_LOGOS = false
config.MEMBER_LOGIN_METHOD = Types.loginFormMethods.email
config.ENABLE_MEMBER_REGISTRATION = true

// ----- BRANDING -----

// This is the fallback used if a webstore cannot be located. The paths should always be prepended with 'all'
config.defaultBranding = {
  title: 'LPL Template Org',
  className: 'lpl-template',
  logo: null,
  navLinks: [
    { name: 'Tickets', path: `/all/tickets` },
    { name: 'Memberships', path: `/all/memberships` },
  ],
  homeUrl: '/',
  showLogin: true,
  pageTitle: 'LPL Template | Streamlined Checkout',
}

config.webstoreBranding = {
  all: config.defaultBranding,
}

// ----- FORM FIELDS -----

/**
 * This object controls the fields that are visible for a particular form. The fields must
 * be nested within a key that is specific to a form name. Only the fields that the organization
 * wants to render need to be specified.
 */

config.fields = {
  'ticketing-billing-details': {
    paymentDetails: {
      zip: true,
    },
    customerDetails: {
      firstAndLastName: true,
      email: true,
      zip: true,
      phone: true,
    },
  },
  'membership-billing-details': {
    paymentDetails: {
      zip: true,
    },
    customerDetails: false,
  },
  'member-contact': {
    primaryMember: {
      firstName: true,
      lastName: true,
      dateOfBirth: true, // might be overridden by the collect_customer_date_of_birth field on the membership product
      address: {
        country: true,
        streetAddress1: true,
        city: true,
        state: true,
        zip: true,
      },
    },
  },
  'donation-billing-details': {
    paymentDetails: {
      zip: false,
    },
    customerDetails: {
      firstAndLastName: true,
      email: true,
      phone: true,
      country: true,
      streetAddress1: true,
      city: true,
      state: true,
      zip: true,
    },
  },
}

// ----- DISPLAY TEXT -----

/**
 * This object overrides the display text that is defined globally with string values that are
 * specific to the organization. Mustache-esque templating is available for dynamic strings using
 * {{ insertFieldName }} for interpolation.
 *
 * Import displayKeys from 'config' when overriding values, e.g.,
 * {
 *   ...baseDisplayText
 *   [displayKeys.EMAIL]: 'Email Address'
 * }
 */

config.displayText = {
  ...baseDisplayText,
}

// ----- ANALYTICS -----

/**
 * This object provides certain analytics packages to be enabled if an identifier is provided. Use ENV variables to allow customers to test analytics without diluting their production data.
 */

config.analytics = {
  ga: '',
  gtm: '',
}

// ----- STEPS -----

/**
 * These objects control the existence and ordering of steps for various flows.
 */

config.ticketingSteps = baseTicketingSteps
config.membershipSteps = baseMembershipSteps

export default config
