/**
 * @name redirectUnless
 * @param {String|Array(String)|Function} condition - A string (or array of strings) or a function that
 * indicates the condition that must be true for a redirect to NOT be triggerd
 * @param {String|Function} redirect- The url (or function that returns a url) that the user should be redirect to if the condition returns false
 * @param {Object} [options] - An optional options object
 * @param {Boolean} [options.saveUrl=false] - Set to true if the target url should be saved (useful for redirecting after auth)
 * @param {Boolean} [options.preserveQuery=false] - Set to true if the target query params should be saved
 */

import React from 'react'
import { waitFor } from 'lp-hoc'
import { isFunction } from 'lodash'
import Redirect from './Redirect'

function redirectUnless(
  condition,
  redirect,
  { saveUrl = false, preserveQuery = false } = {}
) {
  return waitFor(condition, (props) => (
    <Redirect
      to={getRedirectUrl(redirect, props)}
      saveUrl={saveUrl}
      preserveQuery={preserveQuery}
    />
  ))
}

function getRedirectUrl(redirect, props) {
  return isFunction(redirect) ? redirect(props) : redirect
}

export default redirectUnless
