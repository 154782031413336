// Returns an errors object mapped to the appropriate form fields corresponding to the errors received from the Spreedly payment provider

export default function mapSpreedlyFormErrors(errors) {
  const fieldMappings = {
    year: 'expirationDate',
    month: 'expirationDate',
    number: 'number',
    cvv: 'cvv',
    name: 'fullName',
  }
  const FORM_SECTION = 'paymentDetails'
  // Since Credit Card form is deeply nested, the mappings cannot be set similar to constraints, but must be properly nested under the field set (https://github.com/erikras/redux-form/issues/400)
  const mappedErrors = {
    [FORM_SECTION]: {},
  }

  for (let i = 0; i < errors.length; i++) {
    const error = errors[i]
    // Immediately break out if the environment key is invalid
    if (error.key.includes('environment_key'))
      return {
        errors: {
          _error:
            'There was an issue connecting to the payment provider. Please contact customer support.',
        },
      }

    // Handles an edge case where the month is out of date but year is valid
    if (error.attribute === 'year' && error.key === 'errors.expired') {
      mappedErrors[FORM_SECTION][fieldMappings.month] = 'Card is expired'
    } else {
      mappedErrors[FORM_SECTION][fieldMappings[error.attribute]] = error.message
    }
  }

  return {
    errors: {
      _error:
        'We cannot process your card. Please review your payment details.',
      ...mappedErrors,
    },
  }
}
