import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { BillingDetailsForm } from 'forms'
import { selectors } from '../reducer'
import * as routerActions from 'react-router-redux'
import { modifyProps, onMount } from 'lp-hoc'
import { Modal } from 'lp-components'
import { getPaymentProcessor } from 'payment'
import { selectors as globalSelectors } from 'global-reducer'
import * as apiActions from 'api-actions'
import * as LS from 'local-storage'
import * as actions from '../actions'
import {
  flashInvalidFormSubmitMessage,
  mapAdditionalCartFields,
  redirectUnless,
  getBrandingConfig,
} from 'utils'
import { StickyContainer, MainHeader, Receipt, AddOnProduct } from 'components'
import { get, includes } from 'lodash'
import { clearMessages } from 'redux-flash'

const propTypes = {
  cart: Types.cart.isRequired,
  giftMemberships: PropTypes.arrayOf(Types.giftMembership).isRequired,
  redirectToNextStep: PropTypes.func.isRequired,
  stepName: PropTypes.string.isRequired,
  submitOrder: PropTypes.func.isRequired,
  paymentProcessor: PropTypes.object.isRequired,
  receiptImagePath: PropTypes.string.isRequired,
  clearMessages: PropTypes.func.isRequired,
  addOrRemoveModalProduct: PropTypes.func.isRequired,
}

const defaultProps = {}

function GiftMembershipPaymentInfo({
  cart,
  giftMemberships,
  paymentProcessor,
  redirectToNextStep,
  stepName,
  submitOrder,
  receiptImagePath,
  clearMessages,
  addOrRemoveModalProduct,
}) {
  const selectedGiftMembershipProduct = giftMemberships.find(
    (gm) => gm.id === cart.productId
  )

  const modals = selectedGiftMembershipProduct.modals
  const productModalData =
    modals &&
    modals.find(
      (m) =>
        m.location === Types.addOnProductModalLocations.PAYMENT_PAGE &&
        includes(Types.addOnProductModalTypes, m.modalType)
    )

  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (
      productModalData &&
      !sessionStorage.getItem('giftMembershipModalViewed')
    ) {
      setTimeout(() => {
        sessionStorage.setItem('giftMembershipModalViewed', true)
        setShowModal(true)
      }, Types.modalSettings.TIMEOUT)
    }
  }, [productModalData])

  return (
    <div className="step-container">
      <MainHeader title={stepName} />
      <div className="row">
        <div className="col-8">
          <BillingDetailsForm
            name={Types.billingFormTypes.GIFT_MEMBERSHIP}
            initialValues={{ giftcardUdfs: cart.giftcardUdfs }}
            paymentProcessor={paymentProcessor}
            onSubmit={(params) => {
              clearMessages()
              return submitOrder(params)
            }}
            onSubmitSuccess={redirectToNextStep}
            onSubmitFail={flashInvalidFormSubmitMessage}
          />
        </div>
        {productModalData && showModal && (
          <Modal onClose={() => setShowModal(false)}>
            <AddOnProduct
              productModalData={productModalData}
              cart={cart}
              addOrRemoveModalProduct={addOrRemoveModalProduct}
              isInModal={true}
              hideModal={() => setShowModal(false)}
            />
          </Modal>
        )}
        <StickyContainer className="col-4">
          <Receipt
            title="Gift Memberships"
            cart={cart}
            displayTotal
            image={receiptImagePath}
          />
          {productModalData && (
            <AddOnProduct
              productModalData={productModalData}
              addOrRemoveModalProduct={addOrRemoveModalProduct}
              cart={cart}
            />
          )}
        </StickyContainer>
      </div>
    </div>
  )
}

GiftMembershipPaymentInfo.propTypes = propTypes
GiftMembershipPaymentInfo.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    cart: selectors.cart(state),
    config: globalSelectors.config(state),
    organization: globalSelectors.organization(state),
    webstore: globalSelectors.webstore(state),
    nextStepPath: selectors.nextStepPath(state),
    stepName: selectors.currentStepName(state),
    giftMemberships: selectors.giftMemberships(state),
  }
}

const mapDispatchToProps = {
  validateGiftcardOrder: apiActions.validateGiftcardOrder,
  createGiftMembershipOrder: apiActions.createGiftMembershipOrder,
  emptyCart: actions.emptyCart,
  push: routerActions.push,
  updateGiftMembershipCartPayment: apiActions.updateGiftMembershipCartPayment,
  clearMessages,
  addOrRemoveModalProduct: apiActions.addOrRemoveModalProduct,
}

const initializeOrgHelpers = ({ organization }) => {
  return {
    paymentProcessor: getPaymentProcessor(organization.paymentConfig.service),
  }
}

const initializePaymentProcessor = ({ organization, paymentProcessor }) => {
  return paymentProcessor.init(
    organization.paymentConfig.envVariables.environmentKey
  )
}

const modify = ({
  cart,
  validateGiftcardOrder,
  createGiftMembershipOrder,
  emptyCart,
  nextStepPath,
  paymentProcessor,
  push,
  updateGiftMembershipCartPayment,
  webstore,
  config,
}) => {
  const branding = getBrandingConfig(config, webstore)
  const receiptImagePath = get(branding, 'giftMembershipsImage', '')
  return {
    redirectToNextStep: () =>
      push(`/${webstore}/gift-memberships/${nextStepPath}`),
    submitOrder: (params) => {
      return paymentProcessor
        .submitPaymentDetails(cart.total, params.paymentDetails)
        .then(({ cardReference, cardType, lastFourDigits }) =>
          updateGiftMembershipCartPayment({
            customerDetails: {
              ...params.customerDetails,
              cardType,
              lastFourDigits,
            },
            cardReference,
            cart: mapAdditionalCartFields(cart, params),
          })
        )
        .then(validateGiftcardOrder)
        .then(createGiftMembershipOrder)
        .then(() => {
          LS.clearCartToken()
          emptyCart()
        })
    },
    receiptImagePath,
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  redirectUnless(
    ({ cart }) => cart.priceSelections.length,
    ({ webstore }) => `/${webstore}/gift-memberships`
  ),
  modifyProps(initializeOrgHelpers),
  onMount(initializePaymentProcessor),
  modifyProps(modify)
)(GiftMembershipPaymentInfo)
