import { selectorForSlice } from 'lp-redux-utils'
import { createSelector } from 'reselect'

// react-router-redux doesn't provide these by default

const select = selectorForSlice('routing')

const routerSelectors = {
  pathname: select('locationBeforeTransitions.pathname'),
  redirectUrl: select('locationBeforeTransitions.state.redirectUrl'),
  search: select('locationBeforeTransitions.search'),
}

routerSelectors.currentPath = createSelector(
  [routerSelectors.pathname, routerSelectors.search],
  (currentPath, search) => {
    return currentPath + search
  }
)

export default routerSelectors
