// Organization configuration file
import {
  displayText as baseDisplayText,
  displayKeys,
  ticketingSteps as baseTicketingSteps,
  membershipSteps as baseMembershipSteps,
} from 'config'
import favicon from 'images/favicons/discovery-park.png'
import discoveryParkLogo from 'images/logos/discovery-park/dp-logo.png'
import giftMembershipsImage from 'images/background/DPA_gift_memberships.jpg'
import * as Types from 'types'

const config = {}

// ----- CONSTANTS -----

config.DEFAULT_COUNTRY = 'UNITED STATES'
config.PROMPT_MEMBERSHIP_UPGRADE = false
config.SHOW_TICKET_DETAILS_INFO_BOX = false
config.SHOW_MEMBER_LOGIN_INFO_BOX = false
config.USE_MEMBERSHIP_QUANTITY_SELECTORS = true
config.ENABLE_BACK_BUTTON_ON_MEMBERSHIP_DETAILS = true
config.ENABLE_RECAPTCHA = false
config.MEMBERSHIP_SHOW_COSTS = false
config.MEMBERSHIP_CONFIRMATION_SHOW_VALID_PERIOD = true
config.DISPLAY_CARD_LOGOS = false
config.HAS_MEMBER_DISCOUNTS = true
config.MEMBER_LOGIN_METHOD = Types.loginFormMethods.email
config.ENABLE_MEMBER_REGISTRATION = true

// ----- BRANDING -----

// This is the fallback used if a webstore cannot be located. The paths should always be prepended with 'all'
config.defaultBranding = {
  title: 'Discovery Park of America',
  className: 'discovery-park',
  logo: discoveryParkLogo,
  navLinks: [
    { name: 'Tickets', path: '/all/tickets' },
    { name: 'Memberships', path: `/all/memberships` },
    { name: 'Gift Membership', path: `/all/gift-memberships` },
    { name: 'Donate', path: `/all/donate` },
  ],
  homeUrl: '/',
  showLogin: false,
  pageTitle: 'Discovery Park of America | Streamlined Checkout',
  giftMembershipsImage,
  favicon,
}

config.webstoreBranding = {
  all: config.defaultBranding,
}

// ----- FORM FIELDS -----

/**
 * This object controls the fields that are visible for a particular form. The fields must
 * be nested within a key that is specific to a form name. Only the fields that the organization
 * wants to render need to be specified.
 */

config.fields = {
  'ticketing-billing-details': {
    paymentDetails: {
      zip: false,
    },
    customerDetails: {
      firstAndLastName: true,
      email: true,
      zip: true,
      phone: true,
    },
  },
  'membership-billing-details': {
    paymentDetails: {
      zip: false,
    },
    customerDetails: false,
  },
  'member-contact': {
    primaryMember: {
      firstName: true,
      lastName: true,
      dateOfBirth: true,
      address: {
        country: true,
        streetAddress1: true,
        city: true,
        state: true,
        zip: true,
      },
    },
  },
  'donation-billing-details': {
    paymentDetails: {
      zip: false,
    },
    customerDetails: {
      firstAndLastName: true,
      email: true,
      phone: true,
      country: true,
      streetAddress1: true,
      city: true,
      state: true,
      zip: true,
    },
  },
  'gift-membership-billing-details': {
    paymentDetails: {
      zip: false,
    },
    customerDetails: {
      firstAndLastName: true,
      email: true,
      phone: true,
      country: true,
      streetAddress1: true,
      city: true,
      state: true,
      zip: true,
    },
  },
}

// ----- DISPLAY TEXT -----

/**
 * This object overrides the display text that is defined globally with string values that are
 * specific to the organization. Mustache-esque templating is available for dynamic strings using
 * {{ insertFieldName }} for interpolation.
 */

config.displayText = {
  ...baseDisplayText,
  [displayKeys.PRIMARY_MEMBER_FORM_DETAILS]:
    '<p>Enter the details for the Primary Member in your group. This is the person who will receive renewal notifications and communications about your membership.</p>',
  [displayKeys.SECONDARY_MEMBER_FORM_DETAILS]:
    '<p>This plan includes free additional members (up to the amount specified). Additional paid members can be added at the individual member rates on the next step.</p>',
  [displayKeys.SELECT_MEMBERSHIP_TYPE]: 'charter or regular membership',
  [displayKeys.TICKET_PAYMENT_CONFIRMATION_SUBTITLE]: `
    <p>You have successfully placed your order.</p>
    <p>A copy of your purchase has been sent to the email provided.</p>
    <p class="extra-confirmation-subtitle">To keep up with what’s happening at the park, visit <a href="https://discoveryparkofamerica.com/" target="_blank" rel="noopener noreferrer">discoveryparkofamerica.com</a> and follow us on <a href="https://www.facebook.com/discoveryparkofamerica/" target="_blank" rel="noopener noreferrer">Facebook</a>, <a href="https://twitter.com/DiscoveryParkUC" target="_blank" rel="noopener noreferrer">Twitter</a>, and <a href="https://www.instagram.com/discoveryparkuc/" target="_blank" rel="noopener noreferrer">Instagram</a>!</p>
  `,
  [displayKeys.GIFT_MEMBERSHIP_ADDITIONAL_INSTRUCTIONS]: `
    <p>
      After selecting CONTINUE below, you will be able to review your order
      and submit your contact and payment information. Then, you will receive
      an individual recognition of each gift membership via email to present
      to the recipient(s) in person or by email. They will take the
      recognition of their membership to the Discovery Park ticket counter for
      activation. Memberships are valid for 12 months after activation.
    </p>
    <p>
      If we can be of any assistance at all, please call
      <a href="tel:+17318855455">731-885-5455</a> or email
      <a href="mailto:memberships@discoveryparkofamerica.com">memberships@discoveryparkofamerica.com</a>.
    </p>
  `,
  [displayKeys.TICKET_SELECTION_FOOTER_INSTRUCTIONS]: `
    <p>
      <strong>
        For more information, please visit our
        <a target="_blank" rel="noopener noreferrer" href="https://discoveryparkofamerica.com/visit/tickets-information/">website</a>.
      </strong>
    </p>
  `,
}

// ----- ANALYTICS -----

/**
 * This object provides certain analytics packages to be enabled if an identifier is provided.
 */

config.analytics = {
  gtm: process.env.DPA_GTM_CONTAINER_ID,
}

// ----- STEPS -----

/**
 * These objects control the existence and ordering of steps for various flows.
 */

config.ticketingSteps = baseTicketingSteps
config.membershipSteps = baseMembershipSteps
config.donationSteps = [
  {
    name: 'Support',
    route: 'details',
  },
  {
    name: 'Payment Info',
    route: 'payment',
  },
]

export default config
