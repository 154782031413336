import React from 'react'
import PropTypes from 'prop-types'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { compose } from 'redux'
import { lpForm } from 'lp-form'
import { Select } from 'lp-components'

const propTypes = {
  ...formPropTypes,
  offers: PropTypes.arrayOf(PropTypes.object),
}

const defaultProps = {}

const offerOptions = (offers) => {
  return offers && offers.map((offer) => ({ key: offer.name, value: offer.id }))
}

function TicketOffersForm({ handleSubmit, offers }) {
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="offers"
        label="Offers"
        component={Select}
        placeholder="Select Offer"
        options={offerOptions(offers)}
      />
    </form>
  )
}

TicketOffersForm.propTypes = propTypes
TicketOffersForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'offers',
  })
)(TicketOffersForm)
