import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
}

const defaultProps = {
  children: null,
  title: null,
}

function SectionHeader({ children, title, ...rest }) {
  return (
    <div className="section-header" {...rest}>
      <h2>{title}</h2>
      {children}
    </div>
  )
}

SectionHeader.propTypes = propTypes
SectionHeader.defaultProps = defaultProps

export default SectionHeader
