import { withProps, wrapDisplayName } from 'recompose'
import { noop, mapValues, isFunction, pick } from 'lodash'
import { get } from 'lodash/fp'
import coerce from './coerce'

// Pull params from props and coerce values to primitives
function grabParams(props) {
  const params = get('params', props)
  return params ? mapValues(params, coerce) : {}
}

// Mapping config be a function, string or array of strings.
// String arguments are interpreted as the names of props to pull from params.
const createMapperFunc = (mappingConfig) => {
  if (isFunction(mappingConfig)) return mappingConfig
  return function map(values) {
    return pick(values, mappingConfig)
  }
}

// HOC to pull props from params
function connectParams(mappingConfig = noop) {
  function modify(props) {
    const params = grabParams(props)
    const mapperFunc = createMapperFunc(mappingConfig)
    return mapperFunc(params, props)
  }
  return (Wrapped) => {
    const Wrapper = withProps(modify)(Wrapped)
    Wrapper.displayName = wrapDisplayName('connectParams', Wrapper)
    return Wrapper
  }
}

export default connectParams
