import { handleActions } from 'redux-actions'
import { selectorForSlice, unsetState } from 'lp-redux-utils'
import { handleResponse, handleSuccess } from 'lp-redux-api'
import { compose } from 'recompose'
import { createSelector } from 'reselect'
import * as actions from './actions'
import * as apiActions from 'api-actions'
import * as LS from 'local-storage'
import { set, unset } from 'lodash/fp'

const reducerKey = 'member'
const slice = 'root.member'

const initialState = {
  loggedIn: LS.isLoggedIn(),
}

const select = selectorForSlice(slice)

const setMemberDetails = (state, { payload: { data } }) => {
  LS.setMemberToken(data.token)
  return compose(
    set('currentMember', data),
    set('loggedIn', true)
  )(state)
}

const clearMemberDetails = (state) => {
  LS.clearMemberToken()
  return compose(
    unset('currentMember'),
    set('loggedIn', false)
  )(state)
}

const reducer = handleActions(
  {
    // Handle the case where there is a token in storage but the user does not exist (returns 404)
    [apiActions.fetchMembershipAccount]: handleResponse(
      setMemberDetails,
      clearMemberDetails
    ),
    [apiActions.registerMember]: handleSuccess(setMemberDetails),
    [apiActions.requestMemberLogin]: handleSuccess(setMemberDetails),
    [actions.clearMemberSession]: clearMemberDetails,
    [actions.unsetMember]: unsetState('currentMember'),
  },
  initialState
)

const selectors = {
  loggedIn: select('loggedIn'),
  currentMember: select('currentMember'),
  costRateId: select('currentMember.costRateId'),
  primaryMember: select('currentMember.primaryMember'),
}

selectors.canEditPrimaryMember = createSelector(
  [selectors.primaryMember],
  (primaryMember) => {
    if (!primaryMember) return true
    return !primaryMember.firstName && !primaryMember.lastName
  }
)

export { reducer, selectors, reducerKey }
