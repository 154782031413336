/* Formats prices across the application based on currency type
 * Rules:
 *  - If the input is not a number, returns "" (to avoid re-rendering on UI Receipt)
 *  - Else if the client does not support regional string formatting, returns basic formatting (e.g., $1042.51)
 *  - Else returns locale specific currency formatting (e.g., $1,042.51 or 1.042,51 €)
 */

import coerce from './coerce'
import { isNumber } from 'lodash'

function displayCurrency(
  number,
  { locale = 'en-US', displayFree = false, appendStr = '' } = {}
) {
  // Allow string representations of numbers to be passed by coercing them
  const primitiveNumber = coerce(number)

  if (!isNumber(primitiveNumber)) return ''
  if (primitiveNumber === 0 && displayFree) return 'FREE'

  const strToAppend = appendStr ? ' ' + appendStr : ''
  if (!toLocaleStringSupportsOptions())
    return `${primitiveNumber.toFixed(2)}` + strToAppend

  const formatOptions = getLocaleFormatOptions(locale)

  return new Intl.NumberFormat(locale, formatOptions)
    .format(primitiveNumber)
    .concat(strToAppend)
}

// ----- PRIVATE -----

// Checks that locale is supported (provided by MDN - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString)
function toLocaleStringSupportsOptions() {
  return !!(
    typeof Intl == 'object' &&
    Intl &&
    typeof Intl.NumberFormat == 'function'
  )
}

function getLocaleFormatOptions(locale) {
  switch (locale) {
    case 'en-US':
      return { style: 'currency', currency: 'USD' }
    case 'en-AU':
      return { style: 'currency', currency: 'AUD' }
    case 'de-DE':
      return { style: 'currency', currency: 'EUR' }
    default:
      return { minimumFractionDigits: 2, maximumFractionDigits: 2 }
  }
}

export default displayCurrency
