import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { ExternalLink, PriceDisplay, RenderedHTML } from 'components'

const propTypes = {
  buttonText: PropTypes.string.isRequired,
  descriptionHtml: PropTypes.string,
  displayName: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  price: PropTypes.number,
  pricePhrase: PropTypes.string,
  url: PropTypes.string.isRequired,
}

const defaultProps = {
  descriptionHtml: '',
  price: null,
  pricePhrase: '',
}

function ExternalHorizontalCard({
  buttonText,
  imageUrl,
  displayName,
  descriptionHtml,
  price,
  pricePhrase,
  url,
}) {
  return (
    <div className="horizontal-item">
      <div className="left-content">
        <ExternalLink href={url}>
          <div className="image">
            <img src={imageUrl} alt="" />
          </div>
          <span className="button-primary button-selected">{buttonText}</span>
        </ExternalLink>
      </div>
      <div className="right-content">
        <div className="right-content-overflow">
          <h3>{displayName}</h3>
          <PriceDisplay price={price} phrase={pricePhrase} />
          {descriptionHtml && (
            <RenderedHTML className="item-copy">{descriptionHtml}</RenderedHTML>
          )}
        </div>
      </div>
    </div>
  )
}

ExternalHorizontalCard.propTypes = exact(propTypes)
ExternalHorizontalCard.defaultProps = defaultProps

export default pure(ExternalHorizontalCard)
