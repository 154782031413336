import React from 'react'
import { compose } from 'redux'
import { Field, FormSection, propTypes as formPropTypes } from 'redux-form'
import { Input, InputError, SubmitButton } from 'lp-components'
import { lpForm } from 'lp-form'
import { displayKeys } from 'config'
import { createFieldValidator } from 'utils'
import { modifyProps } from 'lp-hoc'

const propTypes = {
  ...formPropTypes,
}

const defaultProps = {}

const validateEmail = createFieldValidator('newMember.email', {
  presence: true,
  email: {
    message: 'Must be a valid email address',
  },
  length: { maximum: 60 },
})
const validatePassword = (password) => {
  if (!password) return "Can't be blank"
  if (password.length > 50) return 'Password is too long (max 50 characters)'
  if (password.length < 8 || !/[A-Z]/.test(password))
    return 'Password must have at least 8 characters and one uppercase letter'
}
const validateConfirmPassword = createFieldValidator(
  'newMember.confirmPassword',
  {
    presence: true,
    equality: {
      attribute: 'newMember.password',
      message: 'Passwords must match',
    },
  }
)

function MemberRegistrationForm({
  handleSubmit,
  submitting,
  displayOrgText,
  error,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <FormSection name="newMember">
        <Field
          name="email"
          component={Input}
          label={displayOrgText(displayKeys.EMAIL)}
          validate={validateEmail}
          autoComplete="username"
        />
        <Field
          name="password"
          component={Input}
          type="password"
          label={displayOrgText(displayKeys.PASSWORD)}
          validate={validatePassword}
          autoComplete="new-password"
        />
        <Field
          name="confirmPassword"
          component={Input}
          type="password"
          label={displayOrgText(displayKeys.CONFIRM_PASSWORD)}
          validate={validateConfirmPassword}
          autoComplete="new-password"
        />
      </FormSection>
      <InputError className="formwide-error" error={error} invalid touched />
      <SubmitButton submitting={submitting}>Register</SubmitButton>
    </form>
  )
}

MemberRegistrationForm.propTypes = propTypes
MemberRegistrationForm.defaultProps = defaultProps

const modify = ({ onSubmitFail }) => {
  return {
    onSubmitFail: (errors, dispatch, submitError, props) => {
      // If an error is returned by the API, reset the form
      if (
        submitError &&
        submitError.errors &&
        submitError.errors.status === 409
      ) {
        props.reset()
      }

      return onSubmitFail(errors, dispatch, submitError, props)
    },
  }
}

export default compose(
  modifyProps(modify),
  lpForm({
    name: 'member-registration',
  })
)(MemberRegistrationForm)
