/**
 * @name setCategoryProducts
 * @param {Object} category - The category to set
 * @param {Array} products - All products available
 * @param {String} [path='products'] - The path to store the category's products at
 * @returns {Object} - Returns a category object updated with the appropriately filtered products
 */

import { set } from 'lodash/fp'

function setCategoryProducts(category, products, path = 'products') {
  return set(
    path,
    products.filter((product) => product.categoryName === category.name),
    category
  )
}

export default setCategoryProducts
