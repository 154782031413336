import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import {
  DisplayMemberships,
  MembershipPerksBox,
  MainHeader,
  ProductEmptyState,
} from 'components'
import { Spinner } from 'lp-components'
import { selectors } from 'base/membership/reducer'
import * as apiActions from 'api-actions'
import { onMount, waitFor } from 'lp-hoc'
import { selectors as memberSelectors } from 'base/member/reducer'
import { selectors as globalSelectors } from 'global-reducer'
import { routerSelectors } from 'utils'
import { Link } from 'react-router'
import { displayKeys } from 'config'

const propTypes = {
  currentPath: PropTypes.string.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  productsByCategory: PropTypes.arrayOf(Types.productCategory),
  primaryMember: Types.primaryMember,
  webstore: PropTypes.string.isRequired,
  displayOrgText: PropTypes.func.isRequired,
}

const defaultProps = {
  primaryMember: null,
}

const perks = [
  'Unlimited year-round admission',
  'Double Discount days in the Museum Stores every December',
  'FREE entry to annual festivals, exhibits, and events held during regular Museum hours',
  'Subscription to <em>Mystic Seaport Museum Magazine</em>',
  'Exclusive Members-Only programming',
  '10-20% discounts on classes, programs, performances, summer camps, and holiday evening events',
  'Access to the Members’ Lounge and Patio with complimentary beverages',
  '10% discount in the Museum shops and restaurants',
]

function RenewalCopy({ currentPath, displayOrgText, loggedIn }) {
  const currentMembershipNote =
    'note your current membership level is highlighted below.'
  const logInRequest = (
    <span>
      <Link
        to={{
          pathname: '/member/login',
          state: { redirectUrl: currentPath },
        }}
      >
        log in
      </Link>{' '}
      to proceed.
    </span>
  )
  return (
    <p>
      If you are renewing your{' '}
      {displayOrgText(displayKeys.SELECT_MEMBERSHIP_TYPE)}, please{' '}
      {loggedIn ? currentMembershipNote : logInRequest}
    </p>
  )
}

function SelectMembership({
  currentPath,
  loggedIn,
  productsByCategory,
  primaryMember,
  webstore,
  displayOrgText,
}) {
  const contactEmail = 'membership@mysticseaport.org'
  return (
    <div className="step-container">
      {!productsByCategory.length ? (
        <ProductEmptyState title={displayKeys.SELECT_MEMBERSHIP_TYPE}>
          <p>
            {displayOrgText(displayKeys.SELECT_MEMBERSHIP_NO_MEMBERSHIPS_FOUND)}
          </p>
        </ProductEmptyState>
      ) : (
        <div className="row">
          <MainHeader
            title={displayOrgText(displayKeys.SELECT_MEMBERSHIP_TITLE)}
            disablePrevStep
          >
            <p>{displayOrgText(displayKeys.SELECT_MEMBERSHIP_INSTRUCTIONS)}</p>
            <RenewalCopy
              currentPath={currentPath}
              displayOrgText={displayOrgText}
              loggedIn={loggedIn}
            />
            <br />
            <p className="smaller-subtitle">
              If you would like to apply your admission tickets towards a{' '}
              {displayOrgText(displayKeys.SELECT_MEMBERSHIP_TYPE)}, please
              contact the{' '}
              <a href={`mailto:${contactEmail}`}>Membership Department</a> at{' '}
              <a href="tel:+1-860-572-5339">(860) 572-5339</a>.
            </p>
            <p className="smaller-subtitle">
              If you would like to update your current contact information or
              upgrade to a different level during your current active{' '}
              {displayOrgText(displayKeys.SELECT_MEMBERSHIP_TYPE)}, please
              contact the{' '}
              <a href={`mailto:${contactEmail}`}>Membership Department</a> at{' '}
              <a href="tel:+1-860-572-5339">(860) 572-5339</a>.
            </p>
          </MainHeader>
          <MembershipPerksBox
            perks={perks}
            title="Membership Benefits At All Levels Include:"
          />
          <div className="clearfix membership-options-block">
            <DisplayMemberships
              currentPath={currentPath}
              productsByCategory={productsByCategory}
              loggedIn={loggedIn}
              primaryMember={primaryMember}
              webstore={webstore}
            />
          </div>
          <div className="org-info">
            <h2>Gift of Membership</h2>
            <p className="gift-membership-link-text">
              Give the gift that lasts all year long by purchasing a{' '}
              <Link to={`/${webstore}/gift-memberships`}>
                <strong>Gift Membership</strong>
              </Link>
              .
            </p>
          </div>
          <div className="org-info">
            <h2>Corporate Memberships</h2>
            <p className="gift-membership-link-text">
              Businesses and organizations across the country enjoy a wide
              variety of corporate benefits for their clients and employees. For
              more information, contact the{' '}
              <a href={`mailto:${contactEmail}`}>
                <strong>Membership Department</strong>
              </a>{' '}
              at{' '}
              <a href="tel:+1-860-572-5339">
                <strong>(860) 572-5339</strong>
              </a>
              .
            </p>
          </div>
        </div>
      )}
    </div>
  )
}

SelectMembership.propTypes = propTypes
SelectMembership.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentPath: routerSelectors.currentPath(state),
    loggedIn: memberSelectors.loggedIn(state),
    productsByCategory: selectors.productsByCategory(state),
    primaryMember: selectors.primaryMember(state),
    webstore: globalSelectors.webstore(state),
    displayOrgText: globalSelectors.displayOrgText(state),
  }
}

const mapDispatchToProps = {
  fetchMemberships: apiActions.fetchMemberships,
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  onMount('fetchMemberships'),
  waitFor('productsByCategory', Spinner)
)(SelectMembership)
