import React from 'react'
// import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Link } from 'react-router'

const propTypes = {}

const defaultProps = {}

function ForgotPasswordConfirmation() {
  return (
    <div className="login-container reset-password">
      <div className="col-12">
        <h2>Reset Password</h2>
        <p>
          Email has been submitted. If there is an account associated with this
          email, you will receive password reset instructions.
        </p>
        <Link to="/member/login">Back to Login</Link>
      </div>
    </div>
  )
}

ForgotPasswordConfirmation.propTypes = propTypes
ForgotPasswordConfirmation.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ForgotPasswordConfirmation)
