import React from 'react'
// import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'

const propTypes = {}

const defaultProps = {}

function Home() {
  return (
    <div className="content">
      This is an example homepage. To use this, create an IndexRoute component
      in the routes.js file.
    </div>
  )
}

Home.propTypes = propTypes
Home.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Home)
