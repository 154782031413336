import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import MainHeader from './MainHeader'

const propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
}

const defaultProps = {
  children: null,
}

function ProductEmptyState({ children, title }) {
  return (
    <div className="step-container">
      <MainHeader title={title} disablePrevStep>
        {children}
      </MainHeader>
    </div>
  )
}

ProductEmptyState.propTypes = exact(propTypes)
ProductEmptyState.defaultProps = defaultProps

export default pure(ProductEmptyState)
