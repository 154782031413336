/**
 * @name splitDate
 * @param {String} dateString - Value of a formatted / masked date
 * @param {Object} [options] - Specify different options
 * @param {String} [options.separatorChar='/'] - Separator character to split the string by. Defaults to '/' (e.g., 12/20/2019)
 * @returns {Array} Returns array of date values (depending on the format of the passed in date)
 *
 * @example
 *
 * splitDate('12/20/2019') // [12, 20, 2019]
 * splitDate('12-20-2019', { separatorChar: '-' }) // [12, 20, 2019]
 */

function splitDate(dateString, { separatorChar = '/' } = {}) {
  if (!dateString) return []
  return dateString.split(separatorChar)
}

export default splitDate
