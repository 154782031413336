import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { BackgroundImage, RenderedHTML } from 'components'

const propTypes = {
  membership: PropTypes.object.isRequired,
  webstore: PropTypes.string.isRequired,
}

const defaultProps = {}

function MembershipVerticalCard({ membership, webstore }) {
  return (
    <div className="vertical-item">
      <BackgroundImage imageUrl={membership.image}>
        {membership.featured && <p className="banner">Best Deal</p>}
      </BackgroundImage>
      <div className="bottom-content">
        <h3>{membership.displayName}</h3>
        <div className="price">
          <p className="starting-from">starting from</p>
          <h3>${membership.startingPrice}</h3>
        </div>
        <RenderedHTML className="item-copy">
          {membership.descriptionHtml}
        </RenderedHTML>
      </div>
      <Link to={`/${webstore}/memberships/details/${membership.id}`}>
        <button className="button-primary">Select Item</button>
      </Link>
    </div>
  )
}

MembershipVerticalCard.propTypes = propTypes
MembershipVerticalCard.defaultProps = defaultProps

export default MembershipVerticalCard
