// Maps Spreedly Response data to format our API expects

export default function mapSpreedlyResponse({
  cardType = '',
  lastFourDigits = '',
  token = '',
}) {
  return {
    cardReference: token,
    cardType,
    lastFourDigits,
  }
}
