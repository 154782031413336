import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { Spinner } from 'lp-components'
import { waitFor } from 'lp-hoc'
import { displayCurrency } from 'utils'

const propTypes = {
  confirmation: PropTypes.object,
  displayValidPeriod: PropTypes.bool,
  downloadText: PropTypes.string,
  info: PropTypes.string,
}

const defaultProps = {
  displayValidPeriod: false,
  downloadText: 'Download',
  info: '',
}

function PaymentConfirmation({
  confirmation,
  displayValidPeriod,
  downloadText,
  info,
}) {
  return (
    <div>
      <div className="confirmation-info confirmation-info-right">
        {info && <p>{info}</p>}
        <ul>
          <li>
            Receipt Number <br />
            <strong>{confirmation.receipt}</strong>
          </li>
          <li>
            Purchase Date <br />
            <strong>{confirmation.purchaseDate}</strong>
          </li>
          {displayValidPeriod && confirmation.validPeriod && (
            <li>
              {' '}
              Valid Through <br />
              <strong>{confirmation.validPeriod}</strong>
            </li>
          )}
          <li>
            Purchase Total <br />
            <strong>{displayCurrency(confirmation.total)}</strong>
          </li>

          {/* Purchases that are free will not have a payment method */}
          {confirmation.paymentMethod && (
            <li>
              Payment Method <br />
              <strong>{confirmation.paymentMethod}</strong>
            </li>
          )}
          <li>
            Email <br />
            <strong>{confirmation.email}</strong>
          </li>
        </ul>
      </div>
      {!!confirmation.downloadLink && (
        <a
          className="confirmation-page-button button-primary"
          href={confirmation.downloadLink}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          {downloadText}
        </a>
      )}
    </div>
  )
}

PaymentConfirmation.propTypes = propTypes
PaymentConfirmation.defaultProps = defaultProps

export default compose(waitFor('confirmation', Spinner))(PaymentConfirmation)
