import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { ResetPasswordForm } from '../forms'
import { selectors as globalSelectors } from 'global-reducer'
import { modifyProps } from 'lp-hoc'
import { connectQuery, createDisplayOrgText } from 'utils'
import * as apiActions from 'api-actions'
import * as routerActions from 'react-router-redux'
import { displayKeys } from 'config'
import { clearMessages } from 'redux-flash'

const propTypes = {
  displayOrgText: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  token: PropTypes.string,
  contactEmail: PropTypes.string.isRequired,
  clearMessages: PropTypes.func.isRequired,
}

const defaultProps = {
  token: '',
}

function ResetPassword({
  displayOrgText,
  push,
  resetPassword,
  token,
  contactEmail,
  clearMessages,
}) {
  return (
    <div>
      <div className="login-container reset-password">
        <div className="col-12">
          <h2> {displayOrgText(displayKeys.RESET_PASSWORD)}</h2>
          <p>{displayOrgText(displayKeys.RESET_PASSWORD_INSTRUCTIONS)}</p>
          <ResetPasswordForm
            initialValues={{ member: { token } }}
            onSubmit={(params) => {
              clearMessages()
              return resetPassword(params).catch(() => {
                throw new Error(
                  `We are unable to reset your password at this time. Please try again later. If you are still having problems, contact customer service at ${contactEmail}.`
                )
              })
            }}
            onSubmitSuccess={() => push(`/member/reset-password-confirmation`)}
            displayOrgText={displayOrgText}
          />
          <Link to="/member/login">Back to Login</Link>
        </div>
      </div>
    </div>
  )
}

ResetPassword.propTypes = propTypes
ResetPassword.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    config: globalSelectors.config(state),
    contactEmail: globalSelectors.contactEmail(state),
  }
}

const mapDispatchToProps = {
  push: routerActions.push,
  resetPassword: apiActions.resetPassword,
  clearMessages,
}

const initializeOrgHelpers = ({ config }) => {
  return {
    displayOrgText: createDisplayOrgText(config),
  }
}

export default compose(
  connectQuery('token'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  modifyProps(initializeOrgHelpers)
)(ResetPassword)
