import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { modal } from 'lp-hoc'
import RenderedHTML from './RenderedHTML'

const propTypes = {
  handleHide: PropTypes.func.isRequired,
  htmlContent: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  className: PropTypes.string,
}

const defaultProps = {
  className: '',
}

function ReadMoreModal({ htmlContent, handleHide, heading, className }) {
  return (
    <div className={className}>
      <h2>{heading}</h2>
      <RenderedHTML>{htmlContent}</RenderedHTML>
      <button onClick={handleHide} className="modal-close">
        ×
      </button>
    </div>
  )
}

ReadMoreModal.propTypes = propTypes
ReadMoreModal.defaultProps = defaultProps

export default compose(modal({ name: 'read-more-modal' }))(ReadMoreModal)
