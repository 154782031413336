import React from 'react'
import { routes as BaseRoutes } from 'base'
import Layout from './Layout'
import * as Views from './views'
import { Route, Redirect } from 'react-router'
import WebstoreLayout from 'base/WebstoreLayout'
import GiftMembershipsStepLayout from 'base/giftMemberships/StepLayout'

function Routes() {
  return (
    <BaseRoutes orgSlug="mystic-seaport" layout={Layout}>
      <Route path=":webstore" component={WebstoreLayout}>
        <Route path="tickets" component={Views.SelectTicket} />
        <Route path="donate" component={Views.SelectDonationType} />
        <Route path="memberships" component={Views.SelectMembership} />
        <Route component={GiftMembershipsStepLayout}>
          <Route
            path="gift-memberships/select"
            component={Views.SelectGiftMembership}
          />
          <Redirect path="gift-memberships" to="gift-memberships/select" />
        </Route>
      </Route>
    </BaseRoutes>
  )
}

export default Routes
