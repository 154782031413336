import React from 'react'
import PropTypes from 'prop-types'
import { compose, withProps, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import {
  Field,
  FieldArray,
  FormSection,
  propTypes as formPropTypes,
  formValueSelector,
  stopSubmit,
} from 'redux-form/es'
import {
  ButtonArea,
  Input,
  InputError,
  SubmitButton,
  Select,
  Checkbox,
} from 'lp-components'
import { CalendarInput, RenderedHTML, UdfField } from 'components'
import { modifyProps, onUpdate } from 'lp-hoc'
import { selectors as globalSelectors } from 'global-reducer'
import { selectors } from '../reducer'
import { lpForm } from 'lp-form'
import SecondaryMembershipContactFields from './SecondaryMembershipContactFields'
import MembershipTypeField from './MembershipTypeField'
import { get, noop, isEmpty, isEqual, startCase, values, set } from 'lodash'
import {
  validateDateOfBirth,
  validateNested,
  regionData,
  findStates,
  createShowFormField,
  createDisplayOrgText,
  createFieldValidator,
  getPostcodePattern,
  getMembershipTypeQuantities,
  stripTimezone,
} from 'utils'
import { displayKeys } from 'config'
import * as Types from 'types'
import moment from 'moment'
import { format } from 'date-fns'

const propTypes = {
  ...formPropTypes,
  canEditPrimaryMember: PropTypes.bool.isRequired,
  startDate: PropTypes.object.isRequired,
  showFormField: PropTypes.func.isRequired,
  selectedPrimaryMembershipType: Types.membershipType,
  secondaryMembershipTypes: PropTypes.arrayOf(Types.membershipType).isRequired,
  secondaryMembers: PropTypes.arrayOf(PropTypes.object).isRequired,
  useQuantitySelectors: PropTypes.bool,
  hasAddOnMemberships: PropTypes.bool.isRequired,
  isRenewing: PropTypes.bool,
  primaryMember: PropTypes.object,
  availableMembers: PropTypes.array.isRequired,
  showSecondaryMembershipLimitModal: PropTypes.func.isRequired,
  membership: Types.membershipProduct.isRequired,
}

const defaultProps = {
  selectedPrimaryMembershipType: null,
  countryOptions: regionData,
  useQuantitySelectors: false,
  isRenewing: false,
  primaryMember: null,
  onUpgradeProduct: noop,
}

/*
 Field level validations (can't be in-line or they won't trigger onSubmit for redux-form)
*/
const validatePrimaryMembershipTypeId = createFieldValidator(
  'primaryMember.membershipTypeId',
  {
    presence: {
      message: 'Must select a membership type',
    },
  }
)
const validateTitle = createFieldValidator('primaryMember.title', {
  length: { maximum: 30 },
})
const validateFirstName = createFieldValidator('primaryMember.firstName', {
  presence: true,
  length: { maximum: 30 },
})
const validateLastName = createFieldValidator('primaryMember.lastName', {
  presence: true,
  length: { maximum: 30 },
})
const validateEmail = createFieldValidator('primaryMember.email', {
  presence: true,
  email: true,
  length: { maximum: 60 },
})
const validatePhone = createFieldValidator('primaryMember.phone', {
  presence: true,
  length: { maximum: 20 },
})
const validateAddress = createFieldValidator(
  'primaryMember.address.streetAddress1',
  {
    presence: true,
    length: { maximum: 50 },
  }
)
const validateCity = createFieldValidator('primaryMember.address.city', {
  presence: true,
  length: { maximum: 30 },
})
const validateSuburb = createFieldValidator('primaryMember.address.city', {
  presence: true,
  length: { maximum: 30 },
})
const validateZip = createFieldValidator(
  'primaryMember.address.zip',
  ({ country }) => ({
    presence: true,
    format: {
      pattern: getPostcodePattern(country),
      message: 'Please enter a valid format',
    },
    length: { maximum: 10 },
  })
)
const validateState = createFieldValidator('primaryMember.address.state', {
  presence: true,
  length: { maximum: 30 },
})
const validateCountry = createFieldValidator('primaryMember.address.country', {
  presence: true,
})

function MembershipContactForm({
  availableMembers,
  canEditPrimaryMember,
  resetSubmitFlags,
  startDate,
  primaryMember,
  primaryMembershipTypes,
  handleSubmit,
  submitting,
  hasAddOnMemberships,
  secondaryMembershipTypes,
  secondaryMembers,
  countryOptions,
  showFormField,
  displayOrgText,
  useQuantitySelectors,
  isRenewing,
  stateOptions,
  invalid,
  submitFailed,
  showSecondaryMembershipLimitModal,
  validateDob,
  selectedPrimaryMembershipType,
  membership,
  onUpgradeProduct,
  change,
}) {
  const attendeeUdfs = get(selectedPrimaryMembershipType, 'attendeeUdfs') || []
  const renderPrimaryMemberDOBField = selectedPrimaryMembershipType
    ? selectedPrimaryMembershipType.collectCustomerDateOfBirth
    : showFormField('primaryMember.dateOfBirth')
  const requireCustomerDateOfBirth = selectedPrimaryMembershipType
    ? selectedPrimaryMembershipType.requireCustomerDateOfBirth
    : showFormField('primaryMember.dateOfBirth')
  return (
    <form onSubmit={handleSubmit} noValidate>
      <div>
        <FormSection name="primaryMember" className="form-container">
          {primaryMembershipTypes.length > 1 && (
            <MembershipTypeField
              name="membershipTypeId"
              label="Select Membership Type"
              membershipTypes={primaryMembershipTypes}
              validate={validatePrimaryMembershipTypeId}
            />
          )}
          {showFormField('primaryMember.title') && (
            <div className="short-inputs">
              <Field
                name="title"
                hint=" (optional)"
                label={displayOrgText(displayKeys.TITLE)}
                component={Input}
                type="text"
                validate={validateTitle}
                disabled={!canEditPrimaryMember}
              />
            </div>
          )}
          <div className="short-inputs">
            {showFormField('primaryMember.firstName') && (
              <Field
                name="firstName"
                label={displayOrgText(displayKeys.FIRST_NAME)}
                component={Input}
                type="text"
                validate={validateFirstName}
                disabled={!canEditPrimaryMember}
              />
            )}
            {showFormField('primaryMember.lastName') && (
              <Field
                name="lastName"
                label={displayOrgText(displayKeys.LAST_NAME)}
                component={Input}
                type="text"
                validate={validateLastName}
                disabled={!canEditPrimaryMember}
              />
            )}
          </div>
          {renderPrimaryMemberDOBField && (
            <Field
              name="dateOfBirth"
              label={displayOrgText(displayKeys.DATE_OF_BIRTH)}
              hint={requireCustomerDateOfBirth ? '' : ' (optional)'}
              component={CalendarInput}
              type="text"
              validate={validateDob}
              dateFormat="MMMM D, YYYY"
              autoComplete="off"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              maxDate={moment()}
              format={stripTimezone}
              normalize={(date) => {
                if (!date) return date
                return format(date, 'YYYY-MM-DD')
              }}
            />
          )}
          {showFormField('primaryMember.email') && (
            <Field
              name="email"
              label={displayOrgText(displayKeys.EMAIL)}
              component={Input}
              type="email"
              validate={validateEmail}
              disabled={!canEditPrimaryMember}
            />
          )}
          {showFormField('primaryMember.phone') && (
            <Field
              name="phone"
              label={displayOrgText(displayKeys.PHONE)}
              component={Input}
              type="tel"
              validate={validatePhone}
            />
          )}
          <FormSection name="address">
            {showFormField('primaryMember.address.country') && (
              <Field
                name="country"
                label={displayOrgText(displayKeys.COUNTRY)}
                component={Select}
                placeholder={'Select'}
                options={countryOptions}
                validate={validateCountry}
                normalize={(value) => startCase(value.toLowerCase())}
                format={(value) => (value ? value.toUpperCase() : '')}
              />
            )}
            {showFormField('primaryMember.address.streetAddress1') && (
              <Field
                name="streetAddress1"
                label={displayOrgText(displayKeys.MEMBER_STREET_ADDRESS)}
                component={Input}
                type="text"
                validate={validateAddress}
              />
            )}
            <div className="short-inputs shorter">
              {showFormField('primaryMember.address.suburb') && (
                <Field
                  name="city" // api only accepts city
                  label={displayOrgText(displayKeys.SUBURB)}
                  component={Input}
                  type="text"
                  validate={validateSuburb}
                />
              )}
              {showFormField('primaryMember.address.city') && (
                <Field
                  name="city"
                  label={displayOrgText(displayKeys.CITY)}
                  component={Input}
                  type="text"
                  validate={validateCity}
                />
              )}
              {// Only show a drop down if there are states available for the selected country
              showFormField('primaryMember.address.state') &&
                !isEmpty(stateOptions) && (
                  <Field
                    name="state"
                    label={displayOrgText(displayKeys.STATE)}
                    component={Select}
                    placeholder={'Select'}
                    options={stateOptions}
                    validate={validateState}
                    normalize={(value) => startCase(value.toLowerCase())}
                    format={(value) => (value ? value.toUpperCase() : '')}
                  />
                )}
              {showFormField('primaryMember.address.state') &&
                isEmpty(stateOptions) && (
                  <Field
                    name="state"
                    label={displayOrgText(displayKeys.STATE)}
                    component={Input}
                    validate={validateState}
                  />
                )}
              {showFormField('primaryMember.address.zip') && (
                <Field
                  name="zip"
                  label={displayOrgText(displayKeys.ZIP)}
                  component={Input}
                  validate={validateZip}
                />
              )}
            </div>
          </FormSection>
        </FormSection>
      </div>

      {/* Attendee Udfs */}
      {attendeeUdfs.length > 0 && (
        <div>
          <div className="section-header">
            <h2>
              {displayOrgText(displayKeys.MEMBER_ATTENDEE_DETAILS_FORM_TITLE)}
            </h2>
          </div>
          <FormSection name="attendeeUdfs" className="form-container">
            {selectedPrimaryMembershipType.attendeeUdfs.map((udf) => (
              <UdfField key={udf.id} udf={udf} name={`id-${udf.id}`} />
            ))}
          </FormSection>
        </div>
      )}

      {!!secondaryMembershipTypes.length && (
        <div>
          <div className="section-header">
            <h2>{displayOrgText(displayKeys.SECONDARY_MEMBER_FORM_TITLE)}</h2>
          </div>
          <RenderedHTML className="confirmation-info subheading-info">
            {displayOrgText(displayKeys.SECONDARY_MEMBER_FORM_DETAILS)}
          </RenderedHTML>
          <FieldArray
            name="secondaryMembers"
            productId={membership.centamanId}
            startDate={startDate}
            component={SecondaryMembershipContactFields}
            membershipTypes={secondaryMembershipTypes}
            members={secondaryMembers}
            allowTypeSelection={!useQuantitySelectors}
            primaryMember={primaryMember}
            isRenewing={isRenewing}
            displayOrgText={displayOrgText}
            availableMembers={availableMembers}
            onExceedMax={(membershipType) =>
              showSecondaryMembershipLimitModal({
                hasAddOnMemberships,
                title: displayOrgText(
                  displayKeys.SECONDARY_MEMBER_LIMIT_MODAL_TITLE,
                  { membershipType: membershipType.displayName }
                ),
                baseMessage: displayOrgText(
                  displayKeys.SECONDARY_MEMBER_LIMIT_MODAL_BASE_MESSAGE,
                  {
                    limitQuantity: membershipType.maxQuantity,
                    membershipType: membershipType.displayName,
                  }
                ),
                addMoreInstructions: displayOrgText(
                  displayKeys.SECONDARY_MEMBER_LIMIT_MODAL_ADD_MORE_INSTRUCTIONS
                ),
              })
            }
            resetSubmitFlags={resetSubmitFlags}
          />
        </div>
      )}

      {membership.centamanUpgradeProductRef && (
        <div className="membership-upgrade-container">
          <Field
            name="isUpgrading"
            label="Upgrade your membership to a PLUS!"
            onChange={(_, value, __, fieldName) => {
              onUpgradeProduct(membership.centamanUpgradeProductRef)
              change(fieldName, value)
            }}
            component={Checkbox}
          />
          <p>
            Upgrade to the PLUS version of this membership to allow you to bring
            a complimentary guest with you each time you visit.
          </p>
        </div>
      )}

      {invalid && submitFailed && (
        <InputError
          error={
            'There are errors in your submission. Please resolve the issues highlighted above to continue.'
          }
          invalid
          touched
        />
      )}
      <ButtonArea className="full-width-button">
        <SubmitButton type="primary" {...{ submitting }}>
          Continue
        </SubmitButton>
      </ButtonArea>
    </form>
  )
}

MembershipContactForm.defaultProps = defaultProps
MembershipContactForm.propTypes = propTypes

const selectFormValues = formValueSelector('member-contact')

function mapStateToProps(state) {
  return {
    config: globalSelectors.config(state),
    country: selectFormValues(state, 'primaryMember.address.country'),
    primaryMembershipTypeId: selectFormValues(
      state,
      'primaryMember.membershipTypeId'
    ),
    startDate: selectors.startDate(state),
    primaryMember: selectFormValues(state, 'primaryMember'),
    secondaryMembers: selectFormValues(state, 'secondaryMembers') || [],
  }
}

const mapDispatchToProps = {
  resetSubmitFlags: () => stopSubmit('member-contact'), // there is no action creator for modifying submitFailed directly (https://github.com/erikras/redux-form/issues/2868)
}

function convertUdfsObjToArray(udfsObj) {
  if (!udfsObj) return []
  return values(udfsObj)
}

function convertUdfsArrayToObj(udfsArray) {
  if (!udfsArray) return {}
  const obj = {}
  udfsArray.forEach((udf) => {
    obj[`id-${udf.id}`] = udf
  })
  return obj
}

function beforeSubmit({ attendeeUdfs, ...params }) {
  if (!attendeeUdfs) return params
  // Convert attendeeUdfs to array and add to primary member.
  const attendeeUdfsArray = convertUdfsObjToArray(attendeeUdfs)
  return set(
    params,
    'primaryMember.attendeeDetails.attendeeUdfs',
    attendeeUdfsArray
  )
}

function modifyInitialValues({ initialValues, membership }) {
  const attendeeUdfs = get(
    initialValues,
    'primaryMember.attendeeDetails.attendeeUdfs'
  )
  return {
    initialValues: {
      ...initialValues,
      attendeeUdfs: convertUdfsArrayToObj(attendeeUdfs),
      isUpgrading: membership.isUpgrade,
    },
  }
}

function calculateMembershipTypeQuantities({
  primaryMember,
  secondaryMembers,
}) {
  if (!primaryMember)
    return {
      membershipTypeQuantities: null,
    }
  const allMembers = [primaryMember, ...secondaryMembers]
  return {
    membershipTypeQuantities: getMembershipTypeQuantities(allMembers),
  }
}

// When membership type quantitities change, call provided event handler with form data
function watchMembershipTypeQuantityChange(
  {
    membershipTypeQuantities,
    onChangeQuantity = noop,
    primaryMember,
    secondaryMembers,
  },
  prevProps
) {
  // Don't call when first initializing quantities
  if (!prevProps.membershipTypeQuantities) return
  if (!isEqual(membershipTypeQuantities, prevProps.membershipTypeQuantities))
    return onChangeQuantity({ primaryMember, secondaryMembers })
}

const initializeOrgHelpers = ({ config, form }) => {
  return {
    showFormField: createShowFormField(config, form),
    displayOrgText: createDisplayOrgText(config),
  }
}

const getStateOptions = ({ country }) => {
  return {
    stateOptions: findStates(regionData, country),
  }
}

function getMembershipTypeWithId(id, membershipTypes) {
  if (!id) return null
  return membershipTypes.find((membershipType) => {
    return membershipType.id === id
  })
}

function calculateSelectedPrimaryMembershipType({
  primaryMembershipTypeId,
  primaryMembershipTypes,
}) {
  return {
    selectedPrimaryMembershipType: getMembershipTypeWithId(
      primaryMembershipTypeId,
      primaryMembershipTypes
    ),
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withProps(modifyInitialValues),
  withProps(calculateMembershipTypeQuantities),
  withProps(calculateSelectedPrimaryMembershipType),
  onUpdate(watchMembershipTypeQuantityChange),
  modifyProps(getStateOptions),
  lpForm({
    name: 'member-contact',
    enableReinitialize: true,
    validate: validateNested({
      'secondaryMembers[].membershipTypeId': { presence: true },
    }),
    beforeSubmit,
  }),
  modifyProps(initializeOrgHelpers),
  withHandlers({
    validateDob: ({ primaryMembershipTypes, startDate }) => (
      value,
      allValues
    ) => {
      const primaryMembershipTypeId = get(
        allValues,
        'primaryMember.membershipTypeId'
      )
      const primaryMembershipType = getMembershipTypeWithId(
        primaryMembershipTypeId,
        primaryMembershipTypes
      )
      const { minimumAge, maximumAge, requireCustomerDateOfBirth } =
        primaryMembershipType || {}
      return validateDateOfBirth(value, {
        isRequired: requireCustomerDateOfBirth,
        startDate,
        minimumAge,
        maximumAge,
      })
    },
  })
)(MembershipContactForm)
