import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { Link } from 'react-router'
import RenderedHTML from './RenderedHTML'

const propTypes = {
  content: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
}

const defaultProps = {}

function UpsellCard({ content, path }) {
  return (
    <div className="upsell-card">
      <RenderedHTML>{content}</RenderedHTML>
      <Link to={path} className="button-primary">
        View
      </Link>
    </div>
  )
}

UpsellCard.propTypes = exact(propTypes)
UpsellCard.defaultProps = defaultProps

export default pure(UpsellCard)
