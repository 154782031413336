import React from 'react'
import PropTypes from 'prop-types'
import { Router, browserHistory, Route, IndexRedirect } from 'react-router'
import BaseLayout from './Layout'
import { routes as memberRoutes } from './member'
import { routes as ticketingRoutes } from './ticketing'
import { routes as membershipRoutes } from './membership'
import { routes as donationRoutes } from './donation'
import { routes as giftMembershipsRoutes } from './giftMemberships'
import GA from 'google-analytics'
import initializeStore from './store'
import { isDevelopment } from 'utils'
import { isEqual } from 'lodash'
import { Provider } from 'react-redux'
import { syncHistoryWithStore } from 'react-router-redux'
import GlobalLayout from './GlobalLayout'
import { routes as styleguideRoutes } from '../styleguide'
import WebstoreLayout from './WebstoreLayout'

const propTypes = {
  children: PropTypes.node,
  indexPath: PropTypes.string,
  layout: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  orgConfig: PropTypes.object,
  orgSlug: PropTypes.string,
}

const defaultProps = {
  children: null,
  indexPath: '/all/tickets',
  layout: BaseLayout,
  orgConfig: null,
  orgSlug: 'demo',
}

// Scroll to top of page on route change
const onRouteChange = (previousState, nextState, replace, callback) => {
  // Note: using route object equality to keep scroll position when navigating to the "same" route
  if (!isEqual(previousState.routes, nextState.routes)) {
    window.scrollTo(0, 0)
    if (!isDevelopment()) GA.sendPageview()
  }
  return callback()
}

const Routes = ({ layout, children, indexPath, orgConfig, orgSlug }) => {
  const store = initializeStore({ orgSlug, orgConfig })
  const history = syncHistoryWithStore(browserHistory, store)
  return (
    <Provider store={store}>
      <Router history={history}>
        <Route path="/" component={GlobalLayout}>
          <Route onChange={onRouteChange} component={layout}>
            {isDevelopment() ||
              (process.env.DISPLAY_STYLEGUIDE && styleguideRoutes)}
            {indexPath !== '/' && <IndexRedirect to={indexPath} />}
            {children}
            {memberRoutes}
            <Route path=":webstore" component={WebstoreLayout}>
              {ticketingRoutes}
              {membershipRoutes}
              {donationRoutes}
              {giftMembershipsRoutes}
              {/* Include member routes here to take advantage of styling on a per webstore basis */}
              {memberRoutes}
            </Route>
          </Route>
        </Route>
      </Router>
    </Provider>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
