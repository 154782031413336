import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import {
  MainHeader,
  HorizontalCard,
  ProductEmptyState,
  RenderedHTML,
  SectionHeader,
  ReadMoreModal,
} from 'components'
import { selectors } from '../reducer'
import { onMount, waitFor } from 'lp-hoc'
import * as apiActions from 'api-actions'
import { Spinner } from 'lp-components'
import { selectors as globalSelectors } from 'global-reducer'

const propTypes = {
  children: PropTypes.node,
  productsByCategory: PropTypes.arrayOf(Types.productCategory).isRequired,
  webstore: PropTypes.string.isRequired,
  showReadMoreModal: PropTypes.func.isRequired,
}

const defaultProps = {
  children: null,
  products: [],
}

const getGenericHeader = (categories) => {
  if (categories.length === 1) return null
  return 'Donation Products'
}

function SelectDonationType({
  children,
  productsByCategory,
  webstore,
  showReadMoreModal,
}) {
  if (!productsByCategory.length)
    return (
      <ProductEmptyState title="Donations">
        <p>Sorry, there are no donation products available at this time.</p>
      </ProductEmptyState>
    )

  return (
    <div className="step-container">
      <MainHeader title="Thank you for your support" disablePrevStep>
        <p>Please select a donation fund below.</p>
      </MainHeader>
      <div className="row">
        <div className="col-12">
          {productsByCategory.map((category, idx) => {
            const categoryTitle =
              category.name || getGenericHeader(productsByCategory)
            return (
              <div key={category.name || idx}>
                {!!categoryTitle && (
                  <SectionHeader title={categoryTitle}>
                    {!!category.subtitle && (
                      <RenderedHTML>{category.subtitle}</RenderedHTML>
                    )}
                  </SectionHeader>
                )}
                {category.products.map((type) => (
                  <div className="horizontal-item" key={type.id}>
                    <HorizontalCard
                      descriptionHtml={type.descriptionHtml}
                      displayName={type.displayName}
                      imageUrl={type.image}
                      path={`/${webstore}/donate/details/${type.id}`}
                      learnMoreLink={type.learnMoreLink}
                      showReadMoreModal={showReadMoreModal}
                    />
                  </div>
                ))}
              </div>
            )
          })}
          {children}
        </div>
      </div>
    </div>
  )
}

SelectDonationType.propTypes = propTypes
SelectDonationType.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    productsByCategory: selectors.productsByCategory(state),
    webstore: globalSelectors.webstore(state),
  }
}

const mapDispatchToProps = {
  fetchProducts: apiActions.fetchDonationProducts,
  showReadMoreModal: ReadMoreModal.show,
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  onMount('fetchProducts'),
  waitFor('productsByCategory', Spinner)
)(SelectDonationType)
