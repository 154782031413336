import React from 'react'
import { pure } from 'recompose'
import { Link } from 'react-router'

const propTypes = {}

const defaultProps = {}

function Home() {
  return (
    <div className="step-container text-center">
      <div className="main-header">
        <h1>Which Location Would You Like to Visit?</h1>
      </div>
      <div className="select-location-container">
        <Link to="/zoo/tickets" className="select-location">
          <img
            src="https://streamlined-checkout-client.s3.amazonaws.com/images/zoo.jpg"
            alt=""
          />
          <div className="button-primary"> Buy Zoo Tickets! </div>
        </Link>
        <Link to="/amusement/tickets" className="select-location">
          <img
            src="https://streamlined-checkout-client.s3.amazonaws.com/images/amusmentpark.jpg"
            alt=""
          />
          <div className="button-primary"> Buy Amusement Tickets! </div>
        </Link>
      </div>
    </div>
  )
}

Home.propTypes = propTypes
Home.defaultProps = defaultProps

export default pure(Home)
