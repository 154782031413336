import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'

const propTypes = {
  children: PropTypes.node,
  imageUrl: PropTypes.string,
}

const defaultProps = {
  children: null,
  imageUrl: '',
}

const backgroundImageStyle = (url) => {
  return url ? { backgroundImage: `url(${url})` } : {}
}

function BackgroundImage({ children, imageUrl }) {
  return (
    <div className="image" style={backgroundImageStyle(imageUrl)}>
      {children}
    </div>
  )
}

BackgroundImage.propTypes = propTypes
BackgroundImage.defaultProps = defaultProps

export default pure(BackgroundImage)
