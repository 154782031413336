import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { modal } from 'lp-hoc'

const propTypes = {
  children: PropTypes.node,
  handleHide: PropTypes.func.isRequired,
}

const defaultProps = {}

function ForgotMemberNumModal({ children, handleHide }) {
  return (
    <div className="small-modal">
      <button onClick={handleHide} className="modal-close">
        ×
      </button>
      {children}
      <button type="button" className="button-primary" onClick={handleHide}>
        Okay
      </button>
    </div>
  )
}

ForgotMemberNumModal.propTypes = propTypes
ForgotMemberNumModal.defaultProps = defaultProps

export default compose(modal({ name: 'forgot-member-num' }))(
  ForgotMemberNumModal
)
