import { mapValues, isPlainObject } from 'lodash'

// Like mapValues, but traverses nested objects.
// Only transforms the leaves.
function deepMapValues(obj, func) {
  return mapValues(obj, (value) => {
    // Recurse if the value is an object
    if (isPlainObject(value)) return deepMapValues(value, func)
    return func(value)
  })
}

export default deepMapValues
