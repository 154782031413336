import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import { QuantityInput } from 'components'
import { Field } from 'redux-form'
import * as Types from 'types'
import { setIf, splitBy, trimArray, getMembershipTypeQuantity } from 'utils'
import { NEW_MEMBERSHIP_ID } from 'config'

const propTypes = {
  hasAvailableMembers: PropTypes.bool.isRequired,
  membershipTypes: PropTypes.arrayOf(Types.membershipType),
  members: PropTypes.arrayOf(PropTypes.object),
}

const defaultProps = {}

// Return a new members array with members added or removed based on the new quantity of the given membership type
function updateMembersArray(
  membersArray,
  membershipType,
  quantity,
  hasAvailableMembers
) {
  const [start, matchingMembers, end] = splitBy(
    membersArray,
    (member) => member.membershipTypeId === membershipType.id
  )
  const newMember = setIf(!hasAvailableMembers, 'id', NEW_MEMBERSHIP_ID)({
    membershipTypeId: membershipType.id,
  })

  // this member will be added to the array if the new quantity of that type is larger than the number that currently exist
  const newMembers = trimArray(matchingMembers, quantity, { pad: newMember })
  return [...start, ...newMembers, ...end]
}

// Fields for modifying an array of members with membership type quantities
function MembershipTypeQuantityFields({
  hasAvailableMembers,
  membershipTypes,
  members,
  ...rest
}) {
  return (
    <div className="select-item-group-grid">
      {membershipTypes.map((membershipType) => (
        <Field
          key={membershipType.id}
          component={QuantityInput}
          min={membershipType.minQuantity}
          max={membershipType.maxQuantity}
          itemName={membershipType.displayName}
          itemDescription={membershipType.details}
          normalize={(quantity) =>
            updateMembersArray(
              members,
              membershipType,
              quantity,
              hasAvailableMembers
            )
          }
          format={(members) =>
            getMembershipTypeQuantity(members, membershipType)
          }
          {...rest}
        />
      ))}
    </div>
  )
}

MembershipTypeQuantityFields.propTypes = propTypes
MembershipTypeQuantityFields.defaultProps = defaultProps

export default pure(MembershipTypeQuantityFields)
