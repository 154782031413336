import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import { RadioGroup } from 'lp-components'
import { Field } from 'redux-form'
import { getMembershipTypeQuantity } from 'utils'
import * as Types from 'types'

const propTypes = {
  membershipTypes: PropTypes.arrayOf(Types.membershipType),
  validate: PropTypes.func,
}

const defaultProps = {
  membershipTypes: [],
  validate: null,
}

// Validate whether the max number of memberships of this type have been selected
function validateMaxed(membershipTypes, membershipTypeId, allValues) {
  const membershipType = membershipTypes.find(
    ({ id }) => id === membershipTypeId
  )
  if (!membershipType || !membershipType.maxQuantity) return

  const allMembers = [allValues.primaryMember, ...allValues.secondaryMembers]
  const numSelectedOfType = getMembershipTypeQuantity(
    allMembers,
    membershipType
  )
  if (membershipType.maxQuantity < numSelectedOfType)
    return `There are too many selected memberships of this type (max ${membershipType.maxQuantity})`
}

// A radio group for selecting among membership types
function MembershipTypeField({ membershipTypes, validate, ...rest }) {
  return (
    <Field
      component={RadioGroup}
      options={membershipTypes.map(({ id, displayName }) => ({
        key: displayName,
        value: id,
      }))}
      validate={
        validate
          ? validate
          : (value, allValues) =>
              validateMaxed(membershipTypes, value, allValues)
      }
      {...rest}
    />
  )
}

MembershipTypeField.propTypes = propTypes
MembershipTypeField.defaultProps = defaultProps

export default pure(MembershipTypeField)
