import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { logException } from 'sentry'
import { onError, onMount, onUpdate, waitFor } from 'lp-hoc'
import { getFlashMessages, flashMessageType, removeMessage } from 'redux-flash'
import { FlashMessageContainer, Spinner } from 'lp-components'
import * as apiActions from 'api-actions'
import * as globalActions from 'global-actions'
import { selectors as globalSelectors } from 'global-reducer'
import { selectors as memberSelectors } from './member/reducer'
import { isEqual, uniqBy } from 'lodash'

const propTypes = {
  flashMessages: PropTypes.arrayOf(flashMessageType).isRequired,
  removeMessage: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

const defaultProps = {}

// This layout is rendered for every app. It takes care of loading global data into the store.
// Right now, it loads the org and org config for every page.

function GlobalLayout({ flashMessages, children, removeMessage }) {
  // don't display duplicates
  const uniqueFlashMessages = uniqBy(flashMessages, 'message')
  return (
    <div>
      <FlashMessageContainer
        messages={uniqueFlashMessages}
        onDismiss={(message) => {
          flashMessages.forEach((flashMessage) => {
            // remove any duplicates
            if (flashMessage.message === message.message) {
              removeMessage(flashMessage.id)
            }
          })
        }}
      />
      {children}
    </div>
  )
}

GlobalLayout.propTypes = propTypes
GlobalLayout.defaultProps = defaultProps

function onComponentDidCatch(props, error, errorInfo) {
  return logException(error, errorInfo)
}

function mapStateToProps(state) {
  return {
    flashMessages: getFlashMessages(state),
    orgConfig: globalSelectors.config(state),
    currentMember: memberSelectors.currentMember(state),
    loggedIn: memberSelectors.loggedIn(state),
  }
}

const mapDispatchToProps = {
  fetchOrganization: apiActions.fetchOrganization,
  fetchOrgConfig: apiActions.fetchOrgConfig,
  setOrgConfig: globalActions.setOrgConfig,
  fetchMembershipAccount: apiActions.fetchMembershipAccount,
  removeMessage,
}

// This implementation can eventually be swapped for receiving the JSON config from the API
function loadOrganizationDetails({
  fetchOrganization,
  fetchOrgConfig,
  orgConfig,
  setOrgConfig,
}) {
  return fetchOrganization().then((org) => {
    // If initial orgConfig is not present, fetch it
    if (!orgConfig) return fetchOrgConfig(org.slug).then(setOrgConfig)
  })
}

function loadMemberDetails({ fetchMembershipAccount, loggedIn }) {
  if (loggedIn) return fetchMembershipAccount().catch(() => null)
}

function resourcesLoaded({ currentMember, loggedIn, orgConfig }) {
  const authInfoFetched = loggedIn ? currentMember : true

  return orgConfig && authInfoFetched
}

// Reload details whenever the current member is unset from state
function reloadMemberDetails(
  { fetchMembershipAccount, loggedIn, currentMember },
  prevProps
) {
  if (currentMember || isEqual(currentMember, prevProps.currentMember)) return

  loadMemberDetails({ fetchMembershipAccount, loggedIn })
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  onError(onComponentDidCatch),
  onMount(loadOrganizationDetails),
  onMount(loadMemberDetails),
  onUpdate(reloadMemberDetails),
  waitFor(resourcesLoaded, Spinner)
)(GlobalLayout)
