import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import * as actions from '../actions'
import * as apiActions from 'api-actions'
import { connectParams, onMount, modifyProps } from 'lp-hoc'
import { SelectTicketsForm, TicketOffersForm } from '../forms'
import { isEmpty, noop, get, first, size } from 'lodash'
import { Spinner, SubmitButton } from 'lp-components'
import * as routerActions from 'react-router-redux'
import * as flashActions from 'redux-flash'
import classnames from 'classnames'
import { MembershipUpgradeModal } from '../components/'
import { selectors as apiSelectors } from 'lp-redux-api'
import { selectors as globalSelectors } from 'global-reducer'
import {
  CouponCode,
  InfoBox,
  RenderedHTML,
  StickyContainer,
  MainHeader,
  Receipt,
} from 'components'
import { createDisplayOrgText } from 'utils'
import { displayKeys } from 'config'
import * as Types from 'types'
import * as LS from 'local-storage'

const propTypes = {
  cart: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  displayOrgText: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  clearMessages: PropTypes.func.isRequired,
  isCartLoading: PropTypes.bool.isRequired,
  isFetchingTickets: PropTypes.bool.isRequired,
  webstore: PropTypes.string,
  nextStepPath: PropTypes.string.isRequired,
  prices: PropTypes.array,
  pricesByCategory: PropTypes.array,
  push: PropTypes.func.isRequired,
  selectedTicket: PropTypes.object,
  updateCart: PropTypes.func.isRequired,
  replaceCart: PropTypes.func.isRequired,
  validateCoupon: PropTypes.func.isRequired,
  removeCoupon: PropTypes.func.isRequired,
  addProductToCart: PropTypes.func.isRequired,
  currentStepName: PropTypes.string.isRequired,
  selectedOffer: Types.offer,
  offers: PropTypes.array.isRequired,
  contactEmail: PropTypes.string.isRequired,
}

const defaultProps = {
  selectedTicket: {},
  displayOrgText: noop,
  selectedOffer: null,
}

function GeneralAdmissionTicketDetails({
  cart,
  config,
  displayOrgText,
  flashErrorMessage,
  clearMessages,
  isCartLoading,
  isFetchingTickets,
  webstore,
  nextStepPath,
  prices,
  pricesByCategory,
  push,
  selectedTicket,
  updateCart,
  replaceCart,
  validateCoupon,
  removeCoupon,
  currentStepName,
  selectedOffer,
  offers,
  addProductToCart,
  contactEmail,
}) {
  const hasSelectedPrices =
    !!cart.priceSelections && cart.priceSelections.length > 0
  const transactionQuantityCap = selectedTicket.maxQuantity
  return (
    <div className="step-container">
      <div>
        <MainHeader title={currentStepName} />
        <div className="row">
          <div className="col-8">
            <div className="confirmation-info">
              <RenderedHTML>
                {displayOrgText(displayKeys.TICKET_DETAILS_INSTRUCTIONS, {
                  displayName: selectedTicket.displayName,
                })}
              </RenderedHTML>
              {!!selectedTicket.details && <p>{selectedTicket.details}</p>}
            </div>
            <div>
              <div>
                {offers && offers.length > 1 && (
                  <TicketOffersForm
                    offers={offers}
                    initialValues={{ offers: cart.offerId }}
                    onChange={({ offers: offerId }) =>
                      addProductToCart(selectedTicket, offerId)
                    }
                  />
                )}
              </div>
            </div>

            <React.Fragment>
              {isFetchingTickets || !prices ? (
                <Spinner className="spinner-bottom-space" />
              ) : (
                <React.Fragment>
                  <div>
                    <SelectTicketsForm
                      vacancy={get(selectedOffer, 'vacancy')}
                      showVacancy={get(selectedOffer, 'showRemainingQuantity')}
                      tickets={prices || []}
                      ticketsByCategory={pricesByCategory}
                      onSubmit={updateCart}
                      debounceSubmit={500}
                      initialValues={cart.priceSelections}
                      transactionQuantityCap={transactionQuantityCap}
                    />
                  </div>
                  {config.SHOW_TICKET_DETAILS_INFO_BOX && !isFetchingTickets && (
                    <InfoBox>
                      <RenderedHTML>
                        {displayOrgText(displayKeys.TICKET_DETAILS_INFO_BOX, {
                          contactEmail,
                        })}
                      </RenderedHTML>
                    </InfoBox>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>

            <SubmitButton
              className={classnames('full-width-button', {
                'is-disabled': isEmpty(cart.priceSelections),
              })}
              form="ticketingForm"
              onClick={() => {
                clearMessages()
                if (isEmpty(cart.priceSelections))
                  return flashErrorMessage('Please select at least one ticket')

                return push(`/${webstore}/tickets/${nextStepPath}`)
              }}
              aria-disabled={isEmpty(cart.priceSelections)}
            >
              Continue
            </SubmitButton>
          </div>
          <StickyContainer className="col-4">
            <Receipt
              title={selectedTicket.displayName}
              image={selectedTicket.image}
              cart={cart}
              displayTotal
              isCartLoading={isCartLoading}
            />
            {hasSelectedPrices && (
              <CouponCode
                coupon={cart.coupon}
                cartItems={cart.listItems}
                replaceCart={replaceCart}
                removeCoupon={removeCoupon}
                validateCoupon={validateCoupon}
              />
            )}
          </StickyContainer>
        </div>
      </div>
      <MembershipUpgradeModal upgradeLink="/all/memberships/main">
        <RenderedHTML>
          {displayOrgText(displayKeys.UPGRADE_MEMBERSHIP_MODAL_CONTENT)}
        </RenderedHTML>
      </MembershipUpgradeModal>
    </div>
  )
}

GeneralAdmissionTicketDetails.propTypes = propTypes
GeneralAdmissionTicketDetails.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    offers: selectors.offers(state),
    selectedTicket: selectors.selectedTicket(state),
    prices: selectors.prices(state),
    pricesByCategory: selectors.pricesByCategory(state),
    nextStepPath: selectors.nextStepPath(state),
    cart: selectors.cart(state),
    isFetchingTickets:
      apiSelectors.isLoading(state, apiActions.fetchTicketTypes) ||
      apiSelectors.isLoading(state, apiActions.addTicketToCart),
    isCartLoading:
      apiSelectors.isLoading(
        state,
        apiActions.updateTicketCartPriceSelections
      ) || apiSelectors.isLoading(state, apiActions.addTicketToCart),
    webstore: globalSelectors.webstore(state),
    config: globalSelectors.config(state),
    membershipUpgradeModalShown: selectors.membershipUpgradeModalShown(state),
    currentStepName: selectors.currentStepName(state),
    selectedOffer: selectors.selectedOffer(state),
    contactEmail: globalSelectors.contactEmail(state),
  }
}

const mapDispatchToProps = {
  selectTicket: actions.selectTicket,
  fetchTicketTypes: apiActions.fetchTicketTypes,
  addTicketToCart: apiActions.addTicketToCart,
  updateCart: apiActions.updateTicketCartPriceSelections,
  fetchTicket: apiActions.fetchTicket,
  replaceCart: actions.replaceTicketingCart,
  flashErrorMessage: flashActions.flashErrorMessage,
  clearMessages: flashActions.clearMessages,
  validateCoupon: apiActions.validateTicketCoupon,
  removeCoupon: apiActions.removeTicketCoupon,
  push: routerActions.push,
  setMembershipUpgradeModalShown: actions.setMembershipUpgradeModalShown,
  showMembershipUpgradeModal: () => MembershipUpgradeModal.show(),
}

function modify({
  cart,
  config,
  updateCart,
  addTicketToCart,
  fetchTicketTypes,
  replaceCart,
}) {
  return {
    initialOfferValues: {
      offers: cart.offerId,
    },
    updateCart: (tickets) => updateCart(cart, tickets),
    displayOrgText: createDisplayOrgText(config),
    addProductToCart: (product, offerId) => {
      // If an offer is not specified and there are multiple available, then do nothing
      if (!offerId && size(product.offers) !== 1) return

      return addTicketToCart(
        product.id,
        offerId || first(product.offers).id
      ).then(({ attributes, additionalData }) => {
        replaceCart(attributes)
        LS.setCartToken(additionalData.cartToken)

        return fetchTicketTypes(attributes.productId, attributes.offerId)
      })
    },
  }
}

const maybeShowUpgradeModal = ({
  config,
  membershipUpgradeModalShown,
  setMembershipUpgradeModalShown,
  showMembershipUpgradeModal,
}) => {
  // Only show upsell prompt if requested by the org and if the user has not already seen it for their current session
  if (config.PROMPT_MEMBERSHIP_UPGRADE && !membershipUpgradeModalShown) {
    setTimeout(showMembershipUpgradeModal, 1000) // delay slightly for a smoother transition
    setMembershipUpgradeModalShown(true)
  }
}

const initializeTicketTypes = ({
  cart,
  selectedTicket,
  addProductToCart,
  fetchTicketTypes,
}) => {
  if (cart && cart.productId === selectedTicket.id) {
    // If an offer has already been selected or there is only one option, then fetch ticket types
    if (!cart.offerId && size(selectedTicket.offers) !== 1) return

    return fetchTicketTypes(
      selectedTicket.id,
      cart.offerId || first(selectedTicket.offers).id
    )
  }

  return addProductToCart(selectedTicket)
}

export default compose(
  connectParams('ticketId'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  modifyProps(modify),
  onMount(maybeShowUpgradeModal),
  onMount(initializeTicketTypes)
)(GeneralAdmissionTicketDetails)
