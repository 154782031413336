import { min } from 'lodash'

// Calculates the input max based on preset max or how many are available (if timed capacity driven event)
function getMaxQuantity({ ticket, vacancy }) {
  const maxQuantity = ticket.maxQuantity || Infinity
  // Determine the smaller of the two limits (e.g., max = 10, remaining = 5 -> 5)
  return min([maxQuantity, vacancy])
}

export default getMaxQuantity
