/**
 * @name toFullYear
 * @param {Number|String} yearPostfix - Single or double digit year abbreviation (e.g., 09 or 19)
 * @param {Object} [base=new Date()] - Base date to use for calculating the year. Defaults to today
 * @returns {String} - Returns a 4 digit string representing the year
 */

function toFullYear(yearPostfix, base = new Date()) {
  // convert to a string
  const year = '' + yearPostfix
  const millenium = getMillennium(base)

  return year.padStart(4, millenium)
}

// ----- PRIVATE -----
function getMillennium(date) {
  return Math.trunc(date.getFullYear() / 1000) * 1000
}

export default toFullYear
