/**
 * @name mapAdditionalCartFields
 * @param {Object} cart
 * @param {Object} params
 * @returns {Object} - Returns an object representing the updated cart based on fields captured on
 * the Payment Info step that are NOT directly related to payment information
 */

import { pick } from 'lodash'

const fieldsToAdd = ['emailOptIn', 'donationUdfs']

export default function mapAdditionalCartFields(cart, params = {}) {
  return {
    ...cart,
    ...pick(params, fieldsToAdd),
  }
}
