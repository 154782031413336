import PropTypes from 'prop-types'

/**
 * Constant Types
 */

export const GENERAL_ADMISSION_PRODUCT = 'GeneralAdmissionProduct'
export const TIMED_PRODUCT = 'TimedProduct'

export const TOURS_NAV_CATEGORY = 'tours'
export const EVENTS_NAV_CATEGORY = 'events'
export const MERCHANDISE_NAV_CATEGORY = 'merchandise'

export const statusCodes = {
  GATEWAY_TIMEOUT: 504,
}

export const addOnTypes = {
  MEMBERSHIP: 'MembershipPrice',
}

export const billingFormTypes = {
  DONATION: 'donation-billing-details',
  MEMBERSHIP: 'membership-billing-details',
  GIFT_MEMBERSHIP: 'gift-membership-billing-details',
  TICKETING: 'ticketing-billing-details',
}

export const cartTypes = {
  TICKETING: 'ticketing',
  GIFT_CARDS: 'giftcards',
  DONATIONS: 'donations',
  ORDERS: 'orders',
  MEMBERSHIP: 'membership',
}

export const resourceTypeNamespace = {
  GIFTCARD: 'giftcards',
  TICKETING: 'ticketing',
  MEMBERSHIP: 'membership',
  DONATION: 'donations',
}

export const loginFormMethods = {
  email: 'email',
  memberNumber: 'memberNumber',
}

export const addOnProductModalLocations = {
  PAYMENT_PAGE: 'payment',
}

export const addOnProductModalTypes = ['donation']

export const modalSettings = {
  TIMEOUT: 1500,
}

export const modalProductOptions = {
  DONATION: 'donationLevels',
}

/**
 * Prop Types
 */

// ----- GENERAL -----

export const udf = PropTypes.shape({
  id: PropTypes.number.isRequired,
  fieldName: PropTypes.string.isRequired,
  fieldType: PropTypes.string.isRequired,
  fieldLength: PropTypes.string, // note: different from AZ
  tabName: PropTypes.string,
  isRequired: PropTypes.bool.isRequired,
  isAttendeeUdf: PropTypes.bool,
  isEmail: PropTypes.bool,
  position: PropTypes.number,
})

export const step = PropTypes.shape({
  name: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
})

export const footerLink = PropTypes.shape({
  displayName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
})

export const productCategory = PropTypes.shape({
  name: PropTypes.string,
  subtitle: PropTypes.string,
  position: PropTypes.number,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
})

export const price = PropTypes.shape({
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  ticketType: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  productId: PropTypes.number.isRequired,
  offerId: PropTypes.number,
  centamanId: PropTypes.number.isRequired,
  minQuantity: PropTypes.number,
  maxQuantity: PropTypes.number,
  'timed?': PropTypes.bool.isRequired,
  'extra?': PropTypes.bool.isRequired,
  'generalAdmission?': PropTypes.bool.isRequired,
})

export const addOn = PropTypes.shape({
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  details: PropTypes.string,
  infoText: PropTypes.string,
  selectButtonText: PropTypes.string,
  'timed?': PropTypes.bool.isRequired,
  'extra?': PropTypes.bool.isRequired,
  'generalAdmission?': PropTypes.bool.isRequired,
  prices: PropTypes.arrayOf(price).isRequired,
})

export const priceSelection = PropTypes.shape({
  type: PropTypes.string.isRequired,
  price: PropTypes.number,
  quantity: PropTypes.number,
  offerId: PropTypes.number,
  productId: PropTypes.number.isRequired,
  centamanId: PropTypes.number.isRequired,
})

export const listItem = PropTypes.shape({
  centamanId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  displayName: PropTypes.string.isRequired,
  resourceType: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  priceAfterDiscounts: PropTypes.number.isRequired,
  subtotalBeforeDiscounts: PropTypes.number.isRequired,
  subtotal: PropTypes.number.isRequired,
  savings: PropTypes.number.isRequired,
  totalSavings: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
})

const cartBase = {
  productId: PropTypes.number,
  priceSelections: PropTypes.arrayOf(priceSelection).isRequired,
  addOnSelections: PropTypes.arrayOf(priceSelection).isRequired,
  listItems: PropTypes.arrayOf(listItem).isRequired,
  subtotalBeforeDiscounts: PropTypes.number.isRequired,
  subtotal: PropTypes.number.isRequired,
  taxes: PropTypes.number.isRequired,
  totalSavings: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
}

export const cart = PropTypes.shape(cartBase)

// ----- TICKETING -----

export const ticketProduct = PropTypes.shape({
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  descriptionHtml: PropTypes.string,
  details: PropTypes.string,
  infoText: PropTypes.string,
  featured: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  selectButtonText: PropTypes.string.isRequired,
  webstore: PropTypes.string,
})

export const offer = PropTypes.shape({
  centamanId: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  displayTime: PropTypes.string.isRequired,
  displayStartTime: PropTypes.string.isRequired,
  productId: PropTypes.number.isRequired,
  soldOut: PropTypes.bool.isRequired,
})

export const ticket = PropTypes.shape({
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  details: PropTypes.string,
  infoText: PropTypes.string,
  selectButtonText: PropTypes.string.isRequired,
  'timed?': PropTypes.bool.isRequired,
  'extra?': PropTypes.bool.isRequired,
  'generalAdmission?': PropTypes.bool.isRequired,
  offers: PropTypes.arrayOf(offer).isRequired,
})

// ----- GIFT CARDS -----

export const giftCardProduct = PropTypes.shape({
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  centamanId: PropTypes.number.isRequired,
  displayName: PropTypes.string.isRequired,
  details: PropTypes.string,
  categoryName: PropTypes.string,
  startingPrice: PropTypes.number,
  image: PropTypes.string,
  descriptionHtml: PropTypes.string,
  infoText: PropTypes.string,
  learnMoreLink: PropTypes.string,
  featured: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  position: PropTypes.number,
  selectButtonText: PropTypes.string,
  selectButtonLink: PropTypes.string,
  selectButtonInternalLink: PropTypes.string,
  primaryPrices: PropTypes.arrayOf(membershipType),
  secondaryPrices: PropTypes.arrayOf(membershipType),
  addOns: PropTypes.arrayOf(membershipType),
})

// These are the same.
export const giftMembership = giftCardProduct

// ----- MEMBERSHIP -----

export const membershipType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  productId: PropTypes.number.isRequired,
  centamanId: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  addOn: PropTypes.bool.isRequired,
  collectCustomerName: PropTypes.bool.isRequired,
  collectCustomerDateOfBirth: PropTypes.bool.isRequired,
  maxQuantity: PropTypes.number,
  minQuantity: PropTypes.number,
  minimumAge: PropTypes.number,
  maximumAge: PropTypes.number,
  image: PropTypes.string,
})

export const membershipProduct = PropTypes.shape({
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  centamanId: PropTypes.number.isRequired,
  displayName: PropTypes.string.isRequired,
  details: PropTypes.string,
  categoryName: PropTypes.string,
  startingPrice: PropTypes.number,
  image: PropTypes.string,
  descriptionHtml: PropTypes.string,
  infoText: PropTypes.string,
  learnMoreLink: PropTypes.string,
  featured: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  main: PropTypes.bool.isRequired,
  position: PropTypes.number,
  selectButtonText: PropTypes.string,
  selectButtonLink: PropTypes.string,
  selectButtonInternalLink: PropTypes.string,
  primaryPrices: PropTypes.arrayOf(membershipType),
  secondaryPrices: PropTypes.arrayOf(membershipType),
  addOns: PropTypes.arrayOf(membershipType),
})

export const existingMembership = PropTypes.shape({
  packageId: PropTypes.number.isRequired,
  membershipTypeId: PropTypes.number.isRequired,
  expirationDate: PropTypes.string.isRequired,
  joinDate: PropTypes.string,
})

export const primaryMember = PropTypes.shape({
  id: PropTypes.number,
  memberCode: PropTypes.number,
  memberNumber: PropTypes.number,
  membershipTypeId: PropTypes.number,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  dateOfBirth: PropTypes.string,
  isPrimary: PropTypes.bool,
  activeMemberships: PropTypes.arrayOf(existingMembership).isRequired,
  expiredMemberships: PropTypes.arrayOf(existingMembership).isRequired,
})

// ----- DONATION -----

export const donationPrice = PropTypes.shape({
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
  infoText: PropTypes.string,
  categoryName: PropTypes.string,
  categorySubtitle: PropTypes.string,
  categoryPosition: PropTypes.number,
  description: PropTypes.string,
  customPrice: PropTypes.bool.isRequired,
  minimumAmount: PropTypes.number,
  maximumAmount: PropTypes.number,
  price: PropTypes.number,
  productId: PropTypes.number.isRequired,
  centamanId: PropTypes.number.isRequired,
  'timed?': PropTypes.bool.isRequired,
  'generalAdmission?': PropTypes.bool.isRequired,
  'extra?': PropTypes.bool.isRequired,
})

export const donation = PropTypes.shape({
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  featured: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  selectButtonText: PropTypes.string,
  prices: PropTypes.arrayOf(donationPrice).isRequired,
  webstore: PropTypes.string,
})

// ----- MODAL DATA -----

export const donationLevel = PropTypes.shape({
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  custom: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  modalId: PropTypes.number.isRequired,
  organizationId: PropTypes.number.isRequired,
  price: PropTypes.number,
})

export const productModalData = PropTypes.shape({
  id: PropTypes.number.isRequired,
  body: PropTypes.string,
  buttonText: PropTypes.string,
  centamanId: PropTypes.number.isRequired,
  donationLevels: PropTypes.arrayOf(donationLevel),
  header: PropTypes.string,
  image: PropTypes.object,
  location: PropTypes.string.isRequired,
  modalType: PropTypes.string.isRequired,
  organizationId: PropTypes.number.isRequired,
})

// ----- MEMBER -----

export const member = PropTypes.shape({
  membershipType,
})

// ----- SHARED -----

export const product = PropTypes.oneOfType([
  ticketProduct,
  membershipProduct,
  giftCardProduct,
])
