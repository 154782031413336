function gtag() {
  window.dataLayer.push(arguments)
}

function sendMysticConversions(gtagId) {
  if (!gtagId) return
  gtag('event', 'conversion', {
    allow_custom_scripts: true,
    send_to: 'DC-12015508/story00/story0+standard',
  })
}

export function initialize(gtagId) {
  if (!gtagId) return
  window.dataLayer = window.dataLayer || []
  gtag('js', new Date())
  gtag('config', gtagId)
}

export function sendConversion(gtagId, orgSlug = null) {
  if (!gtagId) return
  if (orgSlug && orgSlug === 'mystic-seaport') {
    sendMysticConversions(gtagId)
  }
}
