import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import DateInput from './DateInput'
import { isMobile } from 'utils'
import { noop } from 'lodash'

function getPlaceholderText(disableKeyboard) {
  return disableKeyboard
    ? 'Select a date'
    : 'Select or enter a date (e.g., 01 January, 1980)'
}

const propTypes = {
  alwaysEnableKeyboard: PropTypes.bool,
  placeholderText: PropTypes.string,
  onClickOutside: PropTypes.func,
}

const defaultProps = {
  alwaysEnableKeyboard: false,
  placeholderText: '',
  onClickOutside: noop,
}

/*
Variant of DateInput with changes:
1. Disables keyboard entry on mobile devices
2. Shows specific placeholder text depending on whether keyboard is enabled.
3. Calls onClickOutside with all component props.
*/

// Disable keyboard on date input (use class to accept Ref)
// https://github.com/Hacker0x01/react-datepicker/issues/1640#issuecomment-472369290
class KeyboardDisabledInput extends React.Component {
  render() {
    return <input {...this.props} readOnly />
  }
}

// Date Input component that will have the keyboard disabled (by default) on mobile devices
function CalendarInput(props) {
  const {
    alwaysEnableKeyboard,
    placeholderText: customPlaceholderText,
    onClickOutside,
    ...rest
  } = props
  const disableKeyboard = !alwaysEnableKeyboard && isMobile()
  const placeholderText =
    customPlaceholderText || getPlaceholderText(disableKeyboard)
  return (
    <DateInput
      placeholderText={placeholderText}
      customInput={disableKeyboard ? <KeyboardDisabledInput /> : undefined}
      onClickOutside={(e) => onClickOutside(props, e)}
      {...rest}
    />
  )
}

CalendarInput.propTypes = propTypes
CalendarInput.defaultProps = defaultProps

export default pure(CalendarInput)
