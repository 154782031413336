import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as apiSelectors } from 'lp-redux-api'
import {
  MainHeader,
  StickyContainer,
  Receipt,
  MembershipPerksBox,
  ProductEmptyState,
} from 'components'
import { selectors as giftMembershipSelectors } from 'base/giftMemberships/reducer'
import { selectors as globalSelectors } from 'global-reducer'
import * as apiActions from 'api-actions'
import { onMount, waitFor, modifyProps } from 'lp-hoc'
import { GiftMembershipForm } from 'base/giftMemberships/forms'
import { flashInvalidFormSubmitMessage, getBrandingConfig } from 'utils'
import * as routerActions from 'react-router-redux'
import { get, isEmpty } from 'lodash'
import { clearMessages } from 'redux-flash'

const propTypes = {
  giftMemberships: PropTypes.arrayOf(Types.giftMembership),
  cart: Types.cart.isRequired,
  updateGiftMembershipPriceSelections: PropTypes.func.isRequired,
  updateGiftMembershipCart: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  isCartLoading: PropTypes.bool.isRequired,
  webstore: PropTypes.string.isRequired,
  nextStepPath: PropTypes.string.isRequired,
  orgConfig: PropTypes.object.isRequired,
  receiptImagePath: PropTypes.string.isRequired,
  clearMessages: PropTypes.func.isRequired,
}

const defaultProps = {}

const perks = [
  'Unlimited admission to museum and park',
  'Access to members-only programs and events',
  'Presale opportunities for DPA programs and events',
  'Unlimited access to the Tower',
  '10% off in The Gift Shop',
  '10% off in The Cafe',
]

// Adds perks box and custom form

function SelectGiftMembership({
  giftMemberships,
  cart,
  updateGiftMembershipPriceSelections,
  isCartLoading,
  updateGiftMembershipCart,
  webstore,
  nextStepPath,
  push,
  orgConfig,
  receiptImagePath,
  clearMessages,
}) {
  if (isEmpty(giftMemberships))
    return (
      <ProductEmptyState title="Gift Membership">
        <p>Sorry, there are no gift memberships available at this time.</p>
      </ProductEmptyState>
    )

  return (
    <div className="step-container">
      <MainHeader title="Gift Membership" />

      <MembershipPerksBox
        perks={perks}
        title="A Gift Membership to Discovery Park of America Membership Includes:"
      />
      <div className="row">
        <div className="col-8">
          <h2>Give the Gift of Discovery </h2>
          <p>
            Giving a gift membership to Discovery Park of America is quick and
            easy. Simply select the type of membership you would like to give.
            Order as many as you like. We will email the gift membership
            acknowledgment(s) to you to give or email to your friends or loved
            ones. Complete instructions for activating the membership will be
            included. Giving the gift of discovery has never been easier.
          </p>
          <GiftMembershipForm
            giftMemberships={giftMemberships}
            initialValues={{
              priceSelections: cart.priceSelections,
              giftcardUdfs: {
                receiverCountry: orgConfig.DEFAULT_COUNTRY,
                ...cart.giftcardUdfs,
              },
            }}
            onChangePriceSelections={(priceSelections) =>
              updateGiftMembershipPriceSelections(cart, priceSelections)
            }
            onSubmit={({ priceSelections, ...params }) => {
              clearMessages()
              // DPA does not collect recipient details so they do not need to be added
              return updateGiftMembershipCart(cart, {
                priceSelectionsWithAttendeeDetails: priceSelections,
                ...params,
              })
            }}
            onSubmitSuccess={() =>
              push(`/${webstore}/gift-memberships/${nextStepPath}`)
            }
            onSubmitFail={flashInvalidFormSubmitMessage}
          />
        </div>
        <StickyContainer className="col-4">
          <Receipt
            title="Gift Memberships"
            cart={cart}
            isCartLoading={isCartLoading}
            displayTotal
            image={receiptImagePath}
          />
        </StickyContainer>
      </div>
    </div>
  )
}

SelectGiftMembership.propTypes = propTypes
SelectGiftMembership.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    cart: giftMembershipSelectors.cart(state),
    isCartLoading: apiSelectors.isLoading(
      state,
      apiActions.updateGiftMembershipPriceSelections
    ),
    giftMemberships: giftMembershipSelectors.giftMemberships(state),
    nextStepPath: giftMembershipSelectors.nextStepPath(state),
    webstore: globalSelectors.webstore(state),
    orgConfig: globalSelectors.config(state),
  }
}

const mapDispatchToProps = {
  fetchGiftMemberships: apiActions.fetchGiftMemberships,
  updateGiftMembershipPriceSelections:
    apiActions.updateGiftMembershipPriceSelections,
  updateGiftMembershipCart: apiActions.updateGiftMembershipCart,
  push: routerActions.push,
  clearMessages,
}

const modify = ({ webstore, orgConfig }) => {
  const branding = getBrandingConfig(orgConfig, webstore)
  const receiptImagePath = get(branding, 'giftMembershipsImage', '')
  return {
    receiptImagePath,
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  modifyProps(modify),
  onMount('fetchGiftMemberships'),
  waitFor('giftMemberships')
)(SelectGiftMembership)
