import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { selectors as globalSelectors } from 'global-reducer'
import { createDisplayOrgText } from 'utils'
import { displayKeys } from 'config'
import { modifyProps } from 'lp-hoc'

const propTypes = {
  displayOrgText: PropTypes.func.isRequired,
}

const defaultProps = {}

function ResetPasswordConfirmation({ displayOrgText }) {
  return (
    <div className="login-container reset-password">
      <div className="col-12">
        <h2>{displayOrgText(displayKeys.RESET_PASSWORD)}</h2>
        <p>{displayOrgText(displayKeys.RESET_PASSWORD_SUCCESS_MESSAGE)}</p>
        <Link to="/member/login">Log In</Link>
      </div>
    </div>
  )
}

ResetPasswordConfirmation.propTypes = propTypes
ResetPasswordConfirmation.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    config: globalSelectors.config(state),
  }
}

const mapDispatchToProps = {}

const initializeOrgHelpers = ({ config }) => {
  return {
    displayOrgText: createDisplayOrgText(config),
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  modifyProps(initializeOrgHelpers)
)(ResetPasswordConfirmation)
