import React from 'react'
import PropTypes from 'prop-types'
import { compose, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import {
  RenderedHTML,
  MainHeader,
  Receipt,
  PaymentConfirmation,
} from 'components'
import { selectors } from '../reducer'
import { onMount, waitFor, modifyProps } from 'lp-hoc'
import { Spinner } from 'lp-components'
import { selectors as globalSelectors } from 'global-reducer'
import * as routerActions from 'react-router-redux'
import { createDisplayOrgText, getBrandingConfig } from 'utils'
import { displayKeys } from 'config'
import { get } from 'lodash'

const propTypes = {
  orderConfirmation: PropTypes.object.isRequired,
  displayOrgText: PropTypes.func.isRequired,
  receiptImagePath: PropTypes.string.isRequired,
}

const defaultProps = {}

function GiftMembershipConfirmation({
  orderConfirmation,
  displayOrgText,
  receiptImagePath,
}) {
  return (
    <div className="step-container">
      <MainHeader title="Thank You!" disablePrevStep>
        <RenderedHTML>
          {displayOrgText(
            displayKeys.GIFT_MEMBERSHIP_PAYMENT_CONFIRMATION_SUBTITLE
          )}
        </RenderedHTML>
      </MainHeader>
      <div className="row confirmation-page">
        <div className="col-6">
          <Receipt
            title="Gift Memberships"
            cart={orderConfirmation.cart}
            displayTotal
            image={receiptImagePath}
          />
        </div>
        <div className="col-8">
          <PaymentConfirmation
            confirmation={orderConfirmation}
            info={displayOrgText(
              displayKeys.GIFT_MEMBERSHIP_PAYMENT_CONFIRMATION_INFO
            )}
          />
        </div>
      </div>
    </div>
  )
}

GiftMembershipConfirmation.propTypes = propTypes
GiftMembershipConfirmation.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    config: globalSelectors.config(state),
    webstore: globalSelectors.webstore(state),
    orderConfirmation: selectors.orderConfirmation(state),
  }
}

const mapDispatchToProps = {
  push: routerActions.push,
}

function onComponentDidMount({ orderConfirmation, push, webstore }) {
  if (!orderConfirmation) return push('/' + webstore + '/gift-memberships')
}

function initializeOrgHelpers({ config }) {
  return {
    displayOrgText: createDisplayOrgText(config),
  }
}

const modify = ({ webstore, config }) => {
  const branding = getBrandingConfig(config, webstore)
  const receiptImagePath = get(branding, 'giftMembershipsImage', '')
  return {
    receiptImagePath,
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  modifyProps(modify),
  onMount(onComponentDidMount),
  withPropsOnChange(['config'], initializeOrgHelpers),
  waitFor('orderConfirmation', Spinner)
)(GiftMembershipConfirmation)
