import React from 'react'
import { compose } from 'redux'
import { Field, FormSection, propTypes as formPropTypes } from 'redux-form'
import { ButtonArea, Input, InputError, SubmitButton } from 'lp-components'
import { lpForm } from 'lp-form'
import { displayKeys } from 'config'
import { createFieldValidator } from 'utils'

const propTypes = {
  ...formPropTypes,
}

const defaultProps = {}

const validateEmail = createFieldValidator('member.email', {
  presence: true,
  email: true,
})

function ForgotPasswordForm({
  handleSubmit,
  invalid,
  pristine,
  submitting,
  displayOrgText,
  error,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <FormSection name="member">
        <Field
          name="email"
          component={Input}
          label={displayOrgText(displayKeys.EMAIL)}
          validate={validateEmail}
        />
      </FormSection>
      <InputError className="formwide-error" error={error} invalid touched />
      <ButtonArea>
        <SubmitButton {...{ invalid, pristine, submitting }}>
          Submit
        </SubmitButton>
      </ButtonArea>
    </form>
  )
}

ForgotPasswordForm.propTypes = propTypes
ForgotPasswordForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'member-forgot-password',
  })
)(ForgotPasswordForm)
