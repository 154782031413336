import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import { selectors as apiSelectors } from 'lp-redux-api'
import { selectors as globalSelectors } from 'global-reducer'
// import { onMount, waitFor } from 'lp-hoc'
import { Spinner } from 'lp-components'
import * as apiActions from 'api-actions'
import * as actions from '../actions'
import { formValueSelector } from 'redux-form'
import { change as changeAction } from 'redux-form/lib'
// import { change as changeAction } from 'redux-form/lib/actions'
import * as flashActions from 'redux-flash'
import { MembershipContactForm } from '../forms'
import { get, noop } from 'lodash'
import {
  CouponCode,
  RenderedHTML,
  HighlightBox,
  StickyContainer,
  MainHeader,
  Receipt,
} from 'components'
import * as routerActions from 'react-router-redux'
import { selectors as memberSelectors } from '../../member/reducer'
import {
  connectParams,
  createDisplayOrgText,
  getAvailableMembers,
  padMembersArray,
} from 'utils'
import {
  MembershipCostInfoBox,
  SecondaryMembershipLimitModal,
} from '../components'
import { displayKeys } from 'config'
import { format, parse } from 'date-fns'
import classnames from 'classnames'

const propTypes = {
  allMembershipTypes: PropTypes.arrayOf(Types.membershipType).isRequired,
  selectedMembership: PropTypes.object,
  cart: PropTypes.object,
  canEditPrimaryMember: PropTypes.bool.isRequired,
  replaceCart: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
  hasAddOnMemberships: PropTypes.bool.isRequired,
  isExpired: PropTypes.bool.isRequired,
  isRenewing: PropTypes.bool.isRequired,
  expirationDate: PropTypes.instanceOf(Date),
  removeCoupon: PropTypes.func.isRequired,
  validateCoupon: PropTypes.func.isRequired,
  displayOrgText: PropTypes.func.isRequired,
  saveMembershipDetailsToCart: PropTypes.func.isRequired,
  updateMembershipCartQuantities: PropTypes.func.isRequired,
  webstore: PropTypes.string.isRequired,
  nextStepPath: PropTypes.string.isRequired,
  membershipTypes: PropTypes.arrayOf(Types.membershipType).isRequired,
  secondaryMembershipTypes: PropTypes.arrayOf(Types.membershipType).isRequired,
  primaryMembershipTypes: PropTypes.arrayOf(Types.membershipType).isRequired,
  currentStepName: PropTypes.string.isRequired,
  config: PropTypes.object.isRequired,
  isLoadingQuantities: PropTypes.bool.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  clearMessages: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  showSecondaryMembershipLimitModal: PropTypes.func.isRequired,
  setSelectedMembershipId: PropTypes.func.isRequired,
  currentMember: Types.member,
  activeAddOnMembers: PropTypes.arrayOf(Types.member),
  activeIncludedSecondaryMembers: PropTypes.arrayOf(Types.member),
  selectedSecondaryMembers: PropTypes.arrayOf(Types.member),
  primaryMember: Types.member,
  membershipId: PropTypes.number,
  primaryMembershipType: Types.membershipType,
}

const defaultProps = {
  selectedMembership: null,
  expirationDate: null,
  initialValues: {},
  redirectToAppropriateStep: noop,
}

function isSameMembershipFlow(cartProductId, membershipId) {
  return cartProductId === membershipId
}

function MembershipDetails({
  allMembershipTypes,
  membershipTypes,
  secondaryMembershipTypes,
  selectedMembership,
  cart,
  canEditPrimaryMember,
  expirationDate,
  replaceCart,
  replace,
  push,
  hasAddOnMemberships,
  isExpired,
  isRenewing,
  removeCoupon,
  validateCoupon,
  saveMembershipDetailsToCart,
  updateMembershipCartQuantities,
  webstore,
  nextStepPath,
  displayOrgText,
  currentStepName,
  primaryMembershipTypes,
  config,
  isLoadingQuantities,
  flashErrorMessage,
  clearMessages,
  showSecondaryMembershipLimitModal,
  currentMember,
  activeAddOnMembers,
  activeIncludedSecondaryMembers,
  primaryMember,
  membershipId,
  selectedSecondaryMembers,
  primaryMembershipType,
  setSelectedMembershipId,
  change,
}) {
  // Update membership id if the route param changes
  // The StepLayout will fetch the new membership info.
  useEffect(() => {
    if (!isSameMembershipFlow(cart.productId, membershipId)) {
      setSelectedMembershipId(membershipId)
    }
  }, [membershipId])

  // Initialize the cart on page enter.
  useEffect(() => {
    if (
      !selectedMembership ||
      isSameMembershipFlow(cart.productId, membershipId)
    )
      return
    const secondaryMembers = calculateSecondaryMembers({
      currentMember,
      activeIncludedSecondaryMembers,
      activeAddOnMembers,
      secondaryMembershipTypes,
      primaryMembershipType,
    })

    updateMembershipCartQuantities(cart, allMembershipTypes, {
      primaryMember,
      primaryMembershipType,
      secondaryMembers,
      addOnMembers: activeAddOnMembers,
      productId: membershipId, // selectedMembership.id,
    })
  }, [selectedMembership])

  // Change form data if the selected membership changes (for upgrade)
  useEffect(() => {
    if (
      !primaryMembershipType ||
      isSameMembershipFlow(cart.productId, membershipId)
    )
      return
    change(
      'member-contact',
      'primaryMember.membershipTypeId',
      primaryMembershipType.id
    )
    // NOTE: upgrading the product isn't set up to swap out secondary membership types yet.
    // The data shouldn't allow for it, but to be safe we'll clear them anyway
    // to make sure we don't do anything impossible.
    change('member-contact', 'secondaryMembers', [])
  }, [primaryMembershipType])

  if (!selectedMembership) return <Spinner />
  return (
    <div className="step-container">
      <div>
        <MainHeader
          title={currentStepName}
          disablePrevStep={!config.ENABLE_BACK_BUTTON_ON_MEMBERSHIP_DETAILS}
        />
        <div className="row">
          <div className="col-8">
            {isRenewing && (
              <HighlightBox className={classnames({ warning: isExpired })}>
                <RenderedHTML>
                  {isExpired
                    ? displayOrgText(
                        displayKeys.MEMBERSHIP_DETAILS_RENEWAL_NOTICE
                      )
                    : displayOrgText(
                        displayKeys.MEMBERSHIP_DETAILS_EXISTING_RENEWAL_NOTICE,
                        {
                          expirationDate: format(
                            parse(expirationDate),
                            'dddd, MMMM D, YYYY'
                          ),
                        }
                      )}
                </RenderedHTML>
              </HighlightBox>
            )}

            <RenderedHTML className="confirmation-info">
              {displayOrgText(displayKeys.MEMBERSHIP_DETAILS_INSTRUCTIONS, {
                displayName: selectedMembership.displayName,
              })}
            </RenderedHTML>

            {membershipTypes.length > 1 && config.MEMBERSHIP_SHOW_COSTS && (
              <MembershipCostInfoBox
                membershipTypes={membershipTypes}
                title={displayOrgText(
                  displayKeys.MEMBERSHIP_COST_INFO_BOX_TITLE
                )}
              >
                <RenderedHTML>
                  {isRenewing
                    ? displayOrgText(
                        displayKeys.MEMBERSHIP_RENEWAL_COST_INFO_BOX
                      )
                    : displayOrgText(displayKeys.MEMBERSHIP_COST_INFO_BOX)}
                </RenderedHTML>
              </MembershipCostInfoBox>
            )}

            <section className="section-group">
              <div className="section-header">
                <h2>{displayOrgText(displayKeys.PRIMARY_MEMBER_FORM_TITLE)}</h2>
              </div>
              <RenderedHTML className="confirmation-info subheading-info">
                {isRenewing
                  ? displayOrgText(
                      displayKeys.PRIMARY_MEMBER_RENEWAL_FORM_DETAILS
                    )
                  : displayOrgText(displayKeys.PRIMARY_MEMBER_FORM_DETAILS)}
              </RenderedHTML>

              <MembershipContactForm
                isRenewing={isRenewing}
                canEditPrimaryMember={canEditPrimaryMember}
                availableMembers={calculateAvailableMembers({
                  cart,
                  currentMember,
                  selectedSecondaryMembers,
                })}
                hasAddOnMemberships={hasAddOnMemberships}
                membership={selectedMembership}
                primaryMembershipTypes={primaryMembershipTypes}
                secondaryMembershipTypes={secondaryMembershipTypes}
                membershipTypes={membershipTypes}
                initialValues={{
                  primaryMember: {
                    ...cart.primaryMember,
                    membershipTypeId: primaryMembershipType?.id,
                  },
                  secondaryMembers: cart.secondaryMembers,
                }}
                onSubmit={(params) => {
                  clearMessages()
                  return saveMembershipDetailsToCart(
                    cart,
                    allMembershipTypes,
                    params
                  )
                }}
                onChangeQuantity={(params) =>
                  updateMembershipCartQuantities(
                    cart,
                    allMembershipTypes,
                    params
                  )
                }
                onSubmitSuccess={() =>
                  push(`/${webstore}/memberships/${nextStepPath}`)
                }
                onSubmitFail={(error, _, submitError) => {
                  if (!submitError) return
                  const message =
                    (error && error.message) ||
                    'An error occured while saving membership details. Please contact customer support'
                  return flashErrorMessage(message)
                }}
                useQuantitySelectors={config.USE_MEMBERSHIP_QUANTITY_SELECTORS}
                showSecondaryMembershipLimitModal={
                  showSecondaryMembershipLimitModal
                }
                onUpgradeProduct={async (membershipId) =>
                  replace(`/${webstore}/memberships/details/${membershipId}`)
                }
              />
            </section>
          </div>
          <StickyContainer className="col-4">
            <Receipt
              title={selectedMembership.displayName}
              image={selectedMembership.image}
              cart={cart}
              displayTotal
              isCartLoading={isLoadingQuantities}
            />
            <CouponCode
              coupon={cart.coupon}
              cartItems={cart.listItems}
              replaceCart={replaceCart}
              removeCoupon={removeCoupon}
              validateCoupon={validateCoupon}
            />
          </StickyContainer>
        </div>
      </div>
      <SecondaryMembershipLimitModal />
    </div>
  )
}

MembershipDetails.propTypes = propTypes
MembershipDetails.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    allMembershipTypes: selectors.allMembershipTypes(state),
    canEditPrimaryMember: memberSelectors.canEditPrimaryMember(state),
    hasAddOnMemberships: selectors.hasAddOnMemberships(state),
    selectedMembership: selectors.selectedMembership(state),
    membershipTypes: selectors.membershipTypes(state),
    primaryMembershipTypes: selectors.primaryMembershipTypes(state),
    primaryMembershipType: selectors.primaryMembershipType(state),
    secondaryMembershipTypes: selectors.secondaryMembershipTypes(state),
    currentStepName: selectors.currentStepName(state),
    expirationDate: selectors.expirationDate(state),
    isExpired: selectors.isExpired(state),
    isRenewing: selectors.isRenewing(state),
    nextStepPath: selectors.nextStepPath(state),
    config: globalSelectors.config(state),
    webstore: globalSelectors.webstore(state),
    cart: selectors.cart(state),
    currentMember: memberSelectors.currentMember(state),
    country: formValueSelector('member-contact')(
      state,
      'primaryMember.address.country'
    ),
    loggedIn: memberSelectors.loggedIn(state),
    isLoadingQuantities: apiSelectors.isLoading(
      state,
      apiActions.updateMembershipCartQuantities
    ),
    activeAddOnMembers: selectors.activeAddOnMembers(state),
    activeIncludedSecondaryMembers: selectors.activeIncludedSecondaryMembers(
      state
    ),
    selectedSecondaryMembers:
      formValueSelector('member-contact')(state, 'secondaryMembers') || [],
    primaryMember: selectors.primaryMember(state),
  }
}

const mapDispatchToProps = {
  setSelectedMembershipId: actions.setSelectedMembershipId,
  flashErrorMessage: flashActions.flashErrorMessage,
  clearMessages: flashActions.clearMessages,
  replaceCart: actions.replaceMembershipCart,
  push: routerActions.push,
  replace: routerActions.replace,
  validateCoupon: apiActions.validateMembershipCoupon,
  removeCoupon: apiActions.removeMembershipCoupon,
  saveMembershipDetailsToCart: apiActions.saveMembershipDetailsToCart,
  showSecondaryMembershipLimitModal: SecondaryMembershipLimitModal.show,
  updateMembershipCartQuantities: apiActions.updateMembershipCartQuantities,
  change: changeAction,
}

function initializeOrgHelpers({ config }) {
  return {
    displayOrgText: createDisplayOrgText(config),
  }
}

// HELPERS

function calculateAvailableMembers({
  cart,
  currentMember,
  selectedSecondaryMembers,
}) {
  const addOnMembers = cart.addOnMembers || []
  const secondaryMembers = get(currentMember, 'secondaryMembers', [])
  return getAvailableMembers(secondaryMembers, [
    ...selectedSecondaryMembers,
    ...addOnMembers,
  ])
}

function calculateSecondaryMembers({
  currentMember,
  activeIncludedSecondaryMembers,
  activeAddOnMembers,
  secondaryMembershipTypes,
  primaryMembershipType,
}) {
  // Determine if there are existing members on the account that _could_ be selected (if additional members are added)
  const hasRemainingMembersAvailable =
    currentMember.secondaryMembers.length -
      (activeIncludedSecondaryMembers.length + activeAddOnMembers.length) >
    0

  // Ensure the minimum # of members have been added for each secondary membership type
  const primaryMembershipId = primaryMembershipType
    ? primaryMembershipType.id
    : 0
  const secondaryMembers = padMembersArray(
    activeIncludedSecondaryMembers,
    secondaryMembershipTypes,
    primaryMembershipId,
    hasRemainingMembersAvailable
  )
  return secondaryMembers
}

export default compose(
  connectParams('membershipId'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withProps(initializeOrgHelpers)
)(MembershipDetails)
