/**
 * @name validateExpirationDate
 * @param {String} maskedValue - The masked value returned by a form field (e.g., 04/31)
 * @returns {String|undefined} Error message (if applicable)
 */

import splitDate from './splitDate'
import toFullYear from './toFullYear'

function validateExpirationDate(maskedValue = '') {
  const dates = splitDate(maskedValue)
  if (isInvalidEntry(dates))
    return 'Please enter a valid date. For example, 02/28'

  const [month, year] = dates.map(Number)
  if (month < 1 || month > 12)
    return 'Please enter a valid month between 01 and 12'

  const today = new Date()
  const currentMonth = today.getMonth() + 1 // JS indexes months at 0
  const currentYear = Number(today.getFullYear())
  const fullYear = Number(toFullYear(year, today))

  if (fullYear < currentYear)
    return 'Please use a card with a valid expiration date where the year is not in the past'
  if (fullYear === currentYear && month < currentMonth)
    return 'Please use a card with a valid expiration date that is not in the past'
}

// ----- PRIVATE -----

// Checks that the user has provided 4 digits (2 for month and 2 for year)
function isInvalidEntry(dates) {
  const [month, year] = dates
  const twoDigitPattern = /\d{2}/

  return (
    !month ||
    !year ||
    !twoDigitPattern.test(month) ||
    !twoDigitPattern.test(year)
  )
}

export default validateExpirationDate
