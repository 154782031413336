import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { modal } from 'lp-hoc'
import { Link } from 'react-router'

const propTypes = {
  children: PropTypes.node,
  handleHide: PropTypes.func.isRequired,
  upgradeLink: PropTypes.string.isRequired,
}

const defaultProps = {}

function MembershipUpgradeModal({ children, upgradeLink, handleHide }) {
  return (
    <div>
      <button onClick={handleHide} className="modal-close">
        ×
      </button>
      {children}
      <Link className="primary" to={upgradeLink}>
        Buy Now
      </Link>{' '}
      <br />
      <button type="button" className="modal-link" onClick={handleHide}>
        {' '}
        No Thanks{' '}
      </button>
    </div>
  )
}

MembershipUpgradeModal.propTypes = propTypes
MembershipUpgradeModal.defaultProps = defaultProps

export default compose(modal({ name: 'membership-upgrade' }))(
  MembershipUpgradeModal
)
