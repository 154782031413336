import React from 'react'
import PropTypes from 'prop-types'
// import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { onMount, waitFor, connectParams } from 'lp-hoc'
import { selectors } from '../reducer'
import * as actions from '../actions'
import * as apiActions from 'api-actions'
import * as flashActions from 'redux-flash'
import * as routerActions from 'react-router-redux'
import { selectors as globalSelectors } from 'global-reducer'

import TimedTicketDetails from './TimedTicketDetails'
import GeneralAdmissionTicketDetails from './GeneralAdmissionTicketDetails'

const propTypes = {
  isSelectedTicketTimed: PropTypes.bool.isRequired,
}

const defaultProps = {}

function TicketDetails({ isSelectedTicketTimed, ...props }) {
  // This component. is responsible for fetching the ticket info.
  // In lieu of different routes, it branches to different views based on the ticket type.
  return isSelectedTicketTimed ? (
    <TimedTicketDetails {...props} />
  ) : (
    <GeneralAdmissionTicketDetails {...props} />
  )
}

TicketDetails.propTypes = propTypes
TicketDetails.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    selectedTicket: selectors.selectedTicket(state),
    isSelectedTicketTimed: selectors.isSelectedTicketTimed(state),
    webstore: globalSelectors.webstore(state),
  }
}

const mapDispatchToProps = {
  selectTicket: actions.selectTicket,
  fetchTicket: apiActions.fetchTicket,
  flashErrorMessage: flashActions.flashErrorMessage,
  clearTicketTypes: actions.clearTicketTypes,
  emptyCart: actions.emptyCart,
  push: routerActions.push,
}

const setSelectedTicket = ({
  selectedTicket,
  fetchTicket,
  selectTicket,
  emptyCart,
  clearTicketTypes,
  push,
  flashErrorMessage,
  webstore,
  ticketId,
}) => {
  if (selectedTicket && selectedTicket.id === ticketId) return
  return fetchTicket(ticketId)
    .then((ticket) => {
      emptyCart()
      clearTicketTypes()
      selectTicket(ticket)
    })
    .catch(() => {
      push(`/${webstore}/tickets`)
      flashErrorMessage('Ticket unavailable. Please select a ticket below', {
        timeout: 6000,
      })
    })
}

const ticketSelected = ({ selectedTicket, ticketId }) => {
  if (!selectedTicket || selectedTicket.id !== ticketId) return false
  return true
}

export default compose(
  connectParams('ticketId'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  onMount(setSelectedTicket),
  waitFor(ticketSelected)
)(TicketDetails)
