import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { selectors as globalSelectors } from 'global-reducer'
import { onMount } from 'lp-hoc'
import * as routerActions from 'react-router-redux'
import { routerSelectors } from 'utils'

const propTypes = {
  children: PropTypes.node.isRequired,
}

const defaultProps = {}

function Layout({ children }) {
  return <div>{children}</div>
}

Layout.propTypes = propTypes
Layout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    pathname: routerSelectors.pathname(state),
    redirectUrl: routerSelectors.redirectUrl(state),
    webstore: globalSelectors.webstore(state),
  }
}

const mapDispatchToProps = {
  replace: routerActions.replace,
}

// If a user has already selected a webstore, then force navigation to include it to maintain branding, etc.
const redirectToWebstoreRoute = ({
  pathname,
  replace,
  webstore,
  redirectUrl,
}) => {
  if (!webstore || pathname.includes(webstore)) return
  return replace({
    pathname: '/' + webstore + pathname,
    state: { redirectUrl },
  })
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  onMount(redirectToWebstoreRoute)
)(Layout)
