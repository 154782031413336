import React from 'react'
// import PropTypes from 'prop-types'
import Sticky from 'react-stickynode'

const propTypes = Sticky.propTypes

const defaultProps = {
  top: 120,
  bottomBoundary: 'main',
}

// Wraps children with "sticky" styling.
// Essentially just react-stickynode with some default props.

function StickyContainer(props) {
  return <Sticky {...props} />
}

StickyContainer.propTypes = propTypes
StickyContainer.defaultProps = defaultProps

export default StickyContainer
