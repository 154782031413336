/**
 * @name createShowFormField
 * @param {Object} orgConfig - An object representing configuration details specific to the current Org
 * @param {String} formName - The name of the form being investigated. This path can be deeply nested using dot notation to account for form sections
 * @returns {Function} - Returns a function that can be used to calculate which
 * fields to show for the specified form for the current organization
 */

/* eslint-disable no-console */
import { get } from 'lodash'

function createShowFormField(orgConfig, formName, type = '') {
  const fieldsPath = type ? formName + '.' + type : formName
  const allowableFields = get(orgConfig.fields, fieldsPath)
  if (!validConfiguration(allowableFields, fieldsPath)) return () => false

  return function showFormField(fieldPath) {
    const value = get(allowableFields, fieldPath)

    return !!value
  }
}

// ------- HELPERS -------

function validConfiguration(fields, fieldsPath) {
  const valid = fields && Object.keys(fields).length > 0
  if (!valid) {
    console.warn(
      `WARNING: No form fields for the path "${fieldsPath}" are defined for this organization. By default, no form fields will be rendered for this form and the application may behave unexpectedly.`
    )
  }

  return valid
}

export default createShowFormField
