import React from 'react'
import { Route, IndexRoute, Redirect } from 'react-router'
import * as Views from './views'
import StepLayout from './StepLayout'

const Routes = (
  <Route path="donate">
    <IndexRoute component={Views.SelectDonationType} />
    <Route path="main" component={Views.MainDonationRedirect} />
    <Route component={StepLayout}>
      <Route path="details(/:id)" component={Views.DonationDetails} />
      <Route path="payment" component={Views.DonationPaymentInfo} />
    </Route>
    <Route path="confirmation" component={Views.DonationConfirmation} />
    <Redirect from="*" to="/donate" />
  </Route>
)

export default Routes
