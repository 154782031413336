import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { onMount, waitFor } from 'lp-hoc'
import * as apiActions from 'api-actions'
import { selectors as membershipSelectors } from 'base/membership/reducer'
import { selectors as memberSelectors } from 'base/member/reducer'
import { selectors as globalSelectors } from 'global-reducer'
import { routerSelectors } from 'utils'
import {
  DisplayMemberships,
  MembershipPerksBox,
  ProductEmptyState,
  MainHeader,
} from 'components'
import { displayKeys } from 'config'
import { Link } from 'react-router'

const propTypes = {
  currentPath: PropTypes.string.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  productsByCategory: PropTypes.arrayOf(Types.productCategory).isRequired,
  primaryMember: Types.primaryMember,
  webstore: PropTypes.string.isRequired,
  displayOrgText: PropTypes.func.isRequired,
}

const defaultProps = {
  primaryMember: null,
}

const perks = [
  'All day, every day free access to the entire waterpark',
  '20% discount on races at Speedsportz Racing Park',
  'All day, every day free access to all aerial activities',
  '10% discount on merchandise, food, and non-alcoholic beverages',
  'All day, every day free access to Wild Isle',
  '10% discount on cabana rentals',
]

function SelectMembership({
  currentPath,
  loggedIn,
  productsByCategory,
  primaryMember,
  webstore,
  displayOrgText,
}) {
  return (
    <div className="step-container">
      {!productsByCategory.length ? (
        <ProductEmptyState title={displayKeys.SELECT_MEMBERSHIP_TYPE}>
          <p>
            {displayOrgText(displayKeys.SELECT_MEMBERSHIP_NO_MEMBERSHIPS_FOUND)}
          </p>
        </ProductEmptyState>
      ) : (
        <React.Fragment>
          <MembershipPerksBox title="Season Pass Perks Include" perks={perks} />
          <div className="row">
            <MainHeader
              title={displayOrgText(displayKeys.SELECT_MEMBERSHIP_TITLE)}
              disablePrevStep
            >
              <p>
                {displayOrgText(displayKeys.SELECT_MEMBERSHIP_INSTRUCTIONS)}
              </p>
              {!loggedIn && (
                <p>
                  If you have an existing{' '}
                  {displayOrgText(displayKeys.SELECT_MEMBERSHIP_TYPE)}, please{' '}
                  <Link
                    to={{
                      pathname: '/member/login',
                      state: { redirectUrl: currentPath },
                    }}
                  >
                    log in
                  </Link>{' '}
                  to renew.
                </p>
              )}
              <br />
              <br />
            </MainHeader>
            <DisplayMemberships
              currentPath={currentPath}
              loggedIn={loggedIn}
              productsByCategory={productsByCategory}
              primaryMember={primaryMember}
              webstore={webstore}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

SelectMembership.propTypes = propTypes
SelectMembership.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentPath: routerSelectors.currentPath(state),
    loggedIn: memberSelectors.loggedIn(state),
    productsByCategory: membershipSelectors.productsByCategory(state),
    primaryMember: membershipSelectors.primaryMember(state),
    webstore: globalSelectors.webstore(state),
    displayOrgText: globalSelectors.displayOrgText(state),
  }
}

const mapDispatchToProps = {
  fetchMemberships: apiActions.fetchMemberships,
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  onMount('fetchMemberships'),
  waitFor('productsByCategory')
)(SelectMembership)
