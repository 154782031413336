import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose, withState, withHandlers, withProps } from 'recompose'
import { lpForm } from 'lp-form'
import { Spinner } from 'lp-components'
import {
  Expandable,
  HorizontalCard,
  CalendarInput,
  SectionHeader,
} from 'components'
import AddOnPriceField from '../components/AddOnPriceField'
import AddOnTimeField from '../components/AddOnTimeField'
import { addMonths, format } from 'date-fns'
import { get, size, noop } from 'lodash/fp'
import { flatMap, set, forEach, groupBy } from 'lodash'
import { Field, FormSection, getFormValues } from 'redux-form'
import { stripTimezone, calculateVisibleCalendarRange } from 'utils'
import * as cacheHelpers from '../cacheHelpers'
import { onMount } from 'lp-hoc'
import * as Types from 'types'

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.func.isRequired,
  fetchTimesForDate: PropTypes.func.isRequired,
  fetchPricesForTime: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  cart: PropTypes.object,
  getSelectedDate: PropTypes.func.isRequired,
  getSelectedTime: PropTypes.func.isRequired,
  getSelectedPrices: PropTypes.func.isRequired,
  getExcludedDatesForMonth: PropTypes.func.isRequired,
  addOns: PropTypes.arrayOf(Types.addOn).isRequired,
  initialValues: PropTypes.object.isRequired,
  timedTicketCache: PropTypes.object.isRequired,
  selectTimeHeader: PropTypes.string.isRequired,
  getCurrentTransactionQuantity: PropTypes.func,
  warnTransactionCapExceeded: PropTypes.func,
}

const defaultProps = {
  warnTransactionCapExceeded: noop,
}

function getSelectedTimeObj(selectedTimeId, availableTimes) {
  if (!selectedTimeId || !availableTimes) return null
  return availableTimes.find((time) => time.centamanId === selectedTimeId)
}

// Deselect prices by setting quantities to 0
function deselectPrices(priceSelections) {
  return priceSelections.map((priceSelection) => ({
    ...priceSelection,
    quantity: 0,
  }))
}

function SelectTimedAddOnsForm({
  handleSubmit,
  addOns,
  initialValues,
  isLoading,
  getExcludedDatesForMonth,
  timedTicketCache,
  fetchTimesForDate,
  getSelectedDate,
  getSelectedTime,
  change,
  cart,
  fetchPricesForTime,
  getSelectedPrices,
  selectTimeHeader,
  getCurrentTransactionQuantity,
  warnTransactionCapExceeded,
}) {
  return (
    <form onSubmit={handleSubmit} noValidate>
      {addOns.map((addOn) => {
        const initialValue = initialValues[`product-${addOn.id}`]
        const initialPrices = initialValue ? initialValue.prices : null
        const selectedDate = getSelectedDate(addOn.id)
        const availableTimes = cacheHelpers.getTimes(timedTicketCache, addOn.id)
        const selectedTimeId = getSelectedTime(addOn.id)
        const selectedTime = getSelectedTimeObj(selectedTimeId, availableTimes)
        const availablePrices = cacheHelpers.getPrices(
          timedTicketCache,
          addOn.id
        )
        const selectedPrices = getSelectedPrices(addOn.id)
        return (
          <Expandable key={addOn.id} startExpanded={!!initialPrices}>
            {(expanded, setExpanded) => (
              <div className="horizontal-item expand">
                <HorizontalCard
                  active={expanded}
                  descriptionHtml={addOn.descriptionHtml}
                  displayName={addOn.displayName}
                  onClick={() => {
                    // Fetch times when opening card
                    if (!expanded) fetchTimesForDate(addOn.id, selectedDate)
                    setExpanded(!expanded)
                  }}
                  imageUrl={addOn.image}
                  buttonText={addOn.selectButtonText}
                />
                {expanded && (
                  <div className="horizontal-item-expand">
                    <FormSection name={`product-${addOn.id}`}>
                      <SectionHeader title="Select Date" />
                      <Field
                        name="ticketDate"
                        label={false}
                        component={CalendarInput}
                        dateFormat="dddd, MMMM DD, YYYY"
                        placeholderText="Choose"
                        minDate={new Date()}
                        format={stripTimezone}
                        onMonthChange={(moment) =>
                          getExcludedDatesForMonth(addOn.id, moment.toDate())
                        }
                        excludeDates={cacheHelpers.getExcludedDates(
                          timedTicketCache,
                          addOn.id
                        )}
                        onChange={(e, value, _, name) => {
                          const newDate = format(value, 'YYYY-MM-DD')
                          // clear old selections
                          change(`product-${addOn.id}.ticketTime`, null)
                          change(
                            `product-${addOn.id}.prices`,
                            deselectPrices(selectedPrices)
                          )
                          fetchTimesForDate(addOn.id, newDate)
                          // Due to a quirk in DateInput, we need to call change manually.
                          return change(name, newDate)
                        }}
                      />
                      {selectedDate && availableTimes && !isLoading(addOn.id) && (
                        <>
                          {availableTimes.length > 0 ? (
                            <>
                              <SectionHeader title={selectTimeHeader} />
                              <div>
                                <AddOnTimeField
                                  name="ticketTime"
                                  availableTimes={availableTimes}
                                  onChange={(e, value, _, name) => {
                                    change(
                                      `product-${addOn.id}.prices`,
                                      deselectPrices(selectedPrices)
                                    )
                                    change(name, value)
                                    fetchPricesForTime(
                                      addOn.id,
                                      selectedDate,
                                      value
                                    )
                                  }}
                                />
                              </div>
                            </>
                          ) : (
                            <p>There are no tickets available for this date.</p>
                          )}
                        </>
                      )}
                      {selectedTime && (
                        <div className="select-item-group-grid">
                          {availablePrices.map((price) => {
                            const currentTransactionQuantity = getCurrentTransactionQuantity(
                              price.productId,
                              cart
                            )
                            return (
                              <AddOnPriceField
                                key={price.id}
                                name="prices"
                                price={price}
                                selectedPrices={selectedPrices}
                                vacancy={selectedTime.vacancy}
                                currentTransactionQuantity={
                                  currentTransactionQuantity
                                }
                                warnTransactionCapExceeded={
                                  warnTransactionCapExceeded
                                }
                                transactionQuantityCap={addOn.maxQuantity}
                              />
                            )
                          })}
                        </div>
                      )}
                      {isLoading(addOn.id) && (
                        <Spinner className="spinner-bottom-space" />
                      )}
                    </FormSection>
                  </div>
                )}
              </div>
            )}
          </Expandable>
        )
      })}
    </form>
  )
}

SelectTimedAddOnsForm.propTypes = propTypes
SelectTimedAddOnsForm.defaultProps = defaultProps

function modifyInitialValues({
  initialValues: priceSelections,
  addOns,
  initialDate,
}) {
  const initialValues = {}
  // First, add an initial date for all addons.
  // Default to overall initial date if one is provided.
  addOns.forEach((addOn) => {
    const ticketDate = initialDate || new Date()
    set(initialValues, `product-${addOn.id}.ticketDate`, ticketDate)
  })
  // Then, add times and prices for all existing selections
  const pricesByAddon = groupBy(priceSelections, 'productId')
  forEach(pricesByAddon, (prices, addOnId) => {
    const examplePrice = prices[0]
    const { startDate, tourEventId } = examplePrice
    set(initialValues, `product-${addOnId}.ticketDate`, startDate)
    set(initialValues, `product-${addOnId}.ticketTime`, tourEventId)
    set(initialValues, `product-${addOnId}.prices`, prices)
  })
  return {
    initialValues,
  }
}

// Fetch blackout dates for the next three months, for all addOns.
function fetchInitialExcludedDates({ addOns, fetchExcludedDates }) {
  addOns.forEach((addOn) =>
    fetchExcludedDates(addOn.id, {
      startDate: new Date(),
      endDate: addMonths(new Date(), 3),
    })
  )
}

// Cards with initial values start expanded, so they need the relevant info.
function fetchInitialInfoForExpandedCards({
  initialValues,
  addOns,
  // getSelectedDate,
  fetchTimesForDate,
  fetchPricesForTime,
}) {
  addOns.forEach(async (addOn) => {
    const initialValue = initialValues[`product-${addOn.id}`]
    if (initialValue && initialValue.prices) {
      await fetchTimesForDate(addOn.id, initialValue.ticketDate, {
        autoselect: false,
      })
      await fetchPricesForTime(
        addOn.id,
        initialValue.ticketDate,
        initialValue.ticketTime
      )
    }
  })
}

function beforeSubmit(values) {
  return flatMap(values, ({ ticketDate, prices = [], ticketTime }) => {
    return prices.map((price) => ({
      centamanId: price.centamanId,
      quantity: price.quantity,
      startDate: ticketDate,
      endDate: ticketDate,
      tourEventId: ticketTime,
    }))
  })
}

export default compose(
  connect((state) => ({
    formValues: getFormValues('SelectTimedAddOnsForm')(state) || {},
  })),
  withProps(modifyInitialValues),
  lpForm({
    beforeSubmit,
    submitOnChange: true,
    name: 'SelectTimedAddOnsForm',
  }),
  withState('loadingIds', 'setLoadingIds', {}), // dictionary for holding loading states
  withHandlers({
    markAsLoading: ({ loadingIds, setLoadingIds }) => (id) =>
      setLoadingIds({ ...loadingIds, [id]: true }),
    markAsNotLoading: ({ loadingIds, setLoadingIds }) => (id) =>
      setLoadingIds({ ...loadingIds, [id]: false }),
    isLoading: ({ loadingIds }) => (id) => !!loadingIds[id],
  }),
  withHandlers({
    getSelectedDate: ({ formValues }) => (addOnId) => {
      return get(`product-${addOnId}.ticketDate`, formValues)
    },
    getSelectedTime: ({ formValues }) => (addOnId) => {
      return get(`product-${addOnId}.ticketTime`, formValues)
    },
    getSelectedPrices: ({ formValues }) => (addOnId) => {
      return get(`product-${addOnId}.prices`, formValues) || []
    },
    getInitialPrice: ({ formValues }) => (addOnId) => {
      return get(`product-${addOnId}.initialPrice`, formValues)
    },
    /**
     * Fetches available ticket prices for a specific time. If only one exists, this is auto selected. **/
    fetchPricesForTime: ({
      markAsLoading,
      markAsNotLoading,
      fetchTimedAddOnPrices,
      clearTimedAddOnPrices,
    }) => async (id, ticketDate, offerId) => {
      markAsLoading(id)
      clearTimedAddOnPrices(id)
      await fetchTimedAddOnPrices(id, ticketDate, offerId)
      markAsNotLoading(id)
    },
    /**
     * Fetches available ticket times for a specific date. If only one exists, this is auto selected
     * and ticket prices for that time are fetched. **/
    fetchTimesForDate: ({
      fetchTimedAddOnTimes,
      markAsLoading,
      markAsNotLoading,
      clearTimedAddOnTimes,
      fetchTimedAddOnPrices,
      clearTimedAddOnPrices,
      change,
    }) => async (id, ticketDate, { autoselect = true } = {}) => {
      markAsLoading(id)
      try {
        clearTimedAddOnTimes(id)
        const { times } = await fetchTimedAddOnTimes(id, ticketDate)
        if (!autoselect || size(times) !== 1) return
        // If there's only one time, auto-select it.
        const firstTime = times[0]
        if (firstTime.soldOut) return
        const timeId = firstTime.centamanId
        change(`product-${id}.ticketTime`, timeId)
        clearTimedAddOnPrices(id)
        await fetchTimedAddOnPrices(id, ticketDate, timeId)
      } finally {
        markAsNotLoading(id)
      }
    },
    getExcludedDatesForMonth: ({ fetchExcludedDates, timedTicketCache }) => (
      addOnId,
      month
    ) => {
      // Use visible calendar range
      const [startDate, endDate] = calculateVisibleCalendarRange(month)
      // Don't proceed if excluded dates have already been calculated
      if (
        !cacheHelpers.hasExcludedDatesForMonth(
          timedTicketCache,
          addOnId,
          endDate
        )
      )
        fetchExcludedDates(addOnId, { startDate, endDate })
    },
  }),
  onMount(fetchInitialExcludedDates),
  onMount(fetchInitialInfoForExpandedCards)
)(SelectTimedAddOnsForm)
