/**
 * Utility that provides the implementation for Spreedly's iFrame Javacript API v1. This represents an interface with the following methods:
 *
 * 1. init(envKey) - initializes the script based on Spreedly env var and sets styling
 * 2. submitPaymentDetails() - Returns a promise with the mapped Spreedly response, including customer details and the card token. This method can then be chained off of to submit orders and complete a purchase for both ticketing and memberships.
 *
 * Spreedly API Documentation: https://docs.spreedly.com/reference/iframe/v1/
 *
 **/
import {
  mapSpreedlyResponse,
  mapSpreedlyFormErrors,
  splitDate,
  toFullYear,
} from 'utils'
import { camelizeKeys } from 'humps'
import { set } from 'lodash'

// ------ INITIALIZATION -------
const init = (envKey) => {
  if (!process.env.SPREEDLY_IFRAME_URL) {
    // eslint-disable-next-line
    return console.warn(
      'WARNING: Spreedly not initialized, SPREEDLY_IFRAME_URL not provided.'
    )
  }
  const script = document.createElement('script')
  script.setAttribute('src', process.env.SPREEDLY_IFRAME_URL)
  script.onload = () => {
    if (window.Spreedly) {
      // Initialize Spreedly fields
      window.Spreedly.init(envKey, {
        numberEl: 'spreedly-number',
        cvvEl: 'spreedly-cvv',
      })

      // Add global event to enable clearing errors in real-time
      window.Spreedly.on('fieldEvent', (name, type, _, inputProperties) => {
        if (type === 'input' && inputProperties[`${name}Length`] === 0) return

        var event = new CustomEvent('paymentProcessorFieldEvent', {
          detail: { name, type },
        })
        window.dispatchEvent(event)
      })

      // Add labels and apply styling to iFrame fields
      window.Spreedly.on('ready', function() {
        window.Spreedly.setLabel('number', 'Card Number')
        window.Spreedly.setLabel('cvv', 'Security Code')
        window.Spreedly.setFieldType('number', 'text')
        window.Spreedly.setNumberFormat('prettyFormat')
        window.Spreedly.setStyle(
          'number',
          `height:50px; font-size:14px; padding-left: 12px; width: 100%;`
        )
        window.Spreedly.setStyle(
          'cvv',
          `height:50px; font-size:14px; padding-left: 12px; width: 100%;`
        )
      })
    }
  }
  document.body.appendChild(script)
}

// ------ SUBMISSION -------
const tokenizePromise = (additionalFields) =>
  new Promise((resolve, reject) => {
    window.Spreedly.tokenizeCreditCard(additionalFields)
    window.Spreedly.on('errors', (errors) =>
      reject(mapSpreedlyFormErrors(errors))
    )
    window.Spreedly.on('paymentMethod', (_, params) => resolve(params))
  })

// Each payment provider should treat dealing with a "free" (i.e., $0) purchase differently. Spreedly will _not_ need to tokenize the card.
const submitPaymentDetails = (total, paymentFields) => {
  if (total === 0) return Promise.resolve(mapSpreedlyResponse({}))

  const { fullName, expirationDate, streetAddress1, zip } = paymentFields

  const [month, year] = splitDate(expirationDate)

  const additionalFields = {
    full_name: fullName,
    month,
    year: toFullYear(year),
    address1: streetAddress1,
    zip,
    retained: true,
  }

  return tokenizePromise(additionalFields).then((params) => {
    const missingField = checkForFieldsMissingFromSpreedly(params)
    if (missingField)
      throw new Error(`Could not validate the ${missingField} for this card.`)
    // Pass in an optional second argument for the fields that should potentially NOT be overwritten by the data returned from Spreedly
    return mapSpreedlyResponse(camelizeKeys(params))
  })
}

const checkForFieldsMissingFromSpreedly = (params) => {
  if (!params.verification_value || !params.verification_value.length >= 3)
    return 'security code'
  return null
}

// ------ VALIDATION -------
const validatePromise = () => {
  return new Promise((resolve) => {
    try {
      window.Spreedly.validate()
      // eslint-disable-next-line no-empty
    } catch (e) {}
    window.Spreedly.on('validation', resolve)
  })
}

const validate = () => {
  if (!window.Spreedly) return Promise.resolve()
  return validatePromise().then(({ validCvv, validNumber }) => {
    if (validNumber && validCvv) return
    const errors = {}
    if (!validNumber)
      set(errors, 'paymentDetails.number', ['Invalid card number'])
    if (!validCvv) set(errors, 'paymentDetails.cvv', ['Invalid cvv'])
    throw errors
  })
}

export default {
  init,
  submitPaymentDetails,
  validate,
}
