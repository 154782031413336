import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { Field } from 'redux-form'
import { SubmitButton, RadioGroup, Input } from 'lp-components'
import * as Types from 'types'

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  productModalData: Types.productModalData.isRequired,
}

const defaultProps = {}

function AddOnProductForm({ handleSubmit, submitting, productModalData }) {
  const { modalType } = productModalData

  const productOptionsKey = Types.modalProductOptions[modalType.toUpperCase()]

  const productOptions = productModalData[productOptionsKey]

  const includesCustomOption = productOptions.find((option) => option.custom)

  const formOptions = productOptions
    .filter((o) => !o.custom)
    .map(({ price, displayName }) => {
      return {
        key: displayName,
        value: price,
      }
    })
  return (
    <form id="product-modal-form" onSubmit={handleSubmit}>
      <div className="level-options">
        <Field
          component={RadioGroup}
          options={formOptions}
          name={modalType}
          noValidate
          className="radio-item"
        />
        {includesCustomOption && (
          <Field
            component={Input}
            name={`${modalType}-custom`}
            label={'Other: $'}
            className="radio-item custom"
          />
        )}
      </div>
      <div className="modal-form-buttons">
        <SubmitButton {...{ submitting }}>
          {productModalData.buttonText}
        </SubmitButton>
      </div>
    </form>
  )
}

AddOnProductForm.propTypes = propTypes
AddOnProductForm.defaultProps = defaultProps

export default compose(
  lpForm({
    constraints: {},
  })
)(AddOnProductForm)
