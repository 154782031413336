import React from 'react'
import PropTypes from 'prop-types'
// import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import { selectors as memberSelectors } from '../../member/reducer'
import { modifyProps, waitFor } from 'lp-hoc'
import { Spinner } from 'lp-components'
import { CouponCode, StickyContainer, MainHeader, Receipt } from 'components'
import { selectors as apiSelectors } from 'lp-redux-api'
import * as apiActions from 'api-actions'
import * as actions from '../actions'
import * as routerActions from 'react-router-redux'
import { selectors as globalSelectors } from 'global-reducer'
import { MembershipAddOnsForm } from '../forms'
import { groupBy, mapKeys, mapValues } from 'lodash'
import { clearMessages } from 'redux-flash'

const propTypes = {
  allMembershipTypes: PropTypes.array.isRequired,
  availableAddOns: PropTypes.array,
  cart: PropTypes.object.isRequired,
  displayOrgText: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  isCartLoading: PropTypes.bool.isRequired,
  isRenewing: PropTypes.bool.isRequired,
  push: PropTypes.func.isRequired,
  replaceCart: PropTypes.func.isRequired,
  removeCoupon: PropTypes.func.isRequired,
  validateCoupon: PropTypes.func.isRequired,
  webstore: PropTypes.string.isRequired,
  nextStepPath: PropTypes.string.isRequired,
  selectedMembership: PropTypes.object.isRequired,
  currentStepName: PropTypes.string.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  updateAddOnMembershipCartQuantities: PropTypes.func.isRequired,
  saveMembershipDetailsToCart: PropTypes.func.isRequired,
  clearMessages: PropTypes.func.isRequired,
}

const defaultProps = {
  availableAddOns: [],
}

function SelectAddOns({
  allMembershipTypes,
  availableAddOns,
  cart,
  displayOrgText,
  initialValues,
  isCartLoading,
  isRenewing,
  push,
  replaceCart,
  removeCoupon,
  validateCoupon,
  webstore,
  selectedMembership,
  nextStepPath,
  currentStepName,
  startDate,
  updateAddOnMembershipCartQuantities,
  saveMembershipDetailsToCart,
  clearMessages,
}) {
  return (
    <div className="step-container">
      <div>
        <MainHeader title={currentStepName}>
          <p>Please select the add-ons you would like to purchase below.</p>
        </MainHeader>
        <div className="row">
          <div className="col-8">
            <div className="confirmation-info">
              {availableAddOns.length ? (
                <p>
                  Below are the optional add-ons you can include with your
                  membership purchase.
                </p>
              ) : (
                <p>
                  There are currently no optional add-ons available for this
                  membership.
                </p>
              )}
            </div>
            <MembershipAddOnsForm
              addOns={availableAddOns}
              productId={selectedMembership.centamanId}
              initialValues={initialValues}
              onSubmit={(addOnMembers) => {
                clearMessages()
                return saveMembershipDetailsToCart(cart, allMembershipTypes, {
                  addOnMembers,
                })
              }}
              onSubmitSuccess={() =>
                push(`/${webstore}/memberships/${nextStepPath}`)
              }
              displayOrgText={displayOrgText}
              startDate={startDate}
              isRenewing={isRenewing}
              onChangeQuantity={(addOnMembers) => {
                return updateAddOnMembershipCartQuantities(
                  cart,
                  allMembershipTypes,
                  {
                    addOnMembers,
                  }
                )
              }}
            />
          </div>
          <StickyContainer className="col-4">
            <Receipt
              title={selectedMembership.displayName}
              image={selectedMembership.image}
              cart={cart}
              displayTotal
              isCartLoading={isCartLoading}
            />
            <CouponCode
              coupon={cart.coupon}
              cartItems={cart.listItems}
              replaceCart={replaceCart}
              removeCoupon={removeCoupon}
              validateCoupon={validateCoupon}
            />
          </StickyContainer>
        </div>
      </div>
    </div>
  )
}

SelectAddOns.propTypes = propTypes
SelectAddOns.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    allMembershipTypes: selectors.allMembershipTypes(state),
    availableAddOns: selectors.availableAddOns(state),
    cart: selectors.cart(state),
    currentMember: memberSelectors.currentMember(state),
    currentStepName: selectors.currentStepName(state),
    displayOrgText: globalSelectors.displayOrgText(state),
    isCartLoading: apiSelectors.isLoading(
      state,
      apiActions.updateAddOnMembershipCartQuantities
    ),
    isRenewing: selectors.isRenewing(state),
    nextStepPath: selectors.nextStepPath(state),
    selectedMembership: selectors.selectedMembership(state),
    startDate: selectors.startDate(state),
    webstore: globalSelectors.webstore(state),
    primaryMember: selectors.primaryMember(state),
    membershipTypes: selectors.membershipTypes(state),
  }
}

const mapDispatchToProps = {
  push: routerActions.push,
  removeCoupon: apiActions.removeMembershipCoupon,
  replaceCart: actions.replaceMembershipCart,
  saveMembershipDetailsToCart: apiActions.saveMembershipDetailsToCart,
  updateAddOnMembershipCartQuantities:
    apiActions.updateAddOnMembershipCartQuantities,
  validateCoupon: apiActions.validateMembershipCoupon,
  clearMessages,
}

const mapAddOnMembersToFormFields = (members = []) => {
  const addOnMembersById = groupBy(members, 'membershipTypeId')
  const addOnMemberFormSections = mapKeys(
    addOnMembersById,
    (_, key) => `addOn-${key}`
  )

  return mapValues(addOnMemberFormSections, (members) => ({ members }))
}

const setInitialValues = ({ cart }) => {
  return {
    initialValues: mapAddOnMembersToFormFields(cart.addOnMembers),
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  waitFor(['selectedMembership', 'availableAddOns'], Spinner),
  modifyProps(setInitialValues)
)(SelectAddOns)
