import { identity } from 'lodash'

// Splits an array into three arrays based which items return truthy values when run through an iteratee.
// The three parts are [ ...falsey ], [ ...truthy ], [ ...falsey ]
// Only splits once- objects that pass the iteratee after the first split will be lumped into the third group.
// Ex. splitBy([ 1, 2, 3, 4, 3, 2, 1, 5], item => item > 2)
// ->
// [[ 1, 2 ], [ 3, 4, 3 ], [ 2, 1, 5 ]]

function splitBy(array, iteratee = identity) {
  const start = []
  const middle = []
  const end = []
  array.forEach((item) => {
    const matches = iteratee(item)
    if (!matches) return middle.length ? end.push(item) : start.push(item)
    if (matches) return end.length ? end.push(item) : middle.push(item)
  })
  return [start, middle, end]
}

export default splitBy
