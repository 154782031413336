import React from 'react'
import PropTypes from 'prop-types'
import { compose, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import { selectors as memberSelectors } from '../../member/reducer'
import { selectors as globalSelectors } from 'global-reducer'
import { Spinner } from 'lp-components'
import {
  RenderedHTML,
  PaymentConfirmation,
  InfoBox,
  MainHeader,
  Receipt,
  UpsellCard,
} from 'components'
import { waitFor, onMount } from 'lp-hoc'
import * as routerActions from 'react-router-redux'
import { createDisplayOrgText } from 'utils'
import { displayKeys } from 'config'
import * as Types from 'types'

const propTypes = {
  currentMember: Types.member.isRequired,
  displayOrgText: PropTypes.func.isRequired,
  membershipConfirmation: PropTypes.object.isRequired,
  selectedMembership: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  showValidPeriod: PropTypes.bool.isRequired,
}

const defaultProps = {}

function MembershipConfirmation({
  currentMember,
  displayOrgText,
  membershipConfirmation,
  selectedMembership,
  showValidPeriod,
  config,
}) {
  const newMembershipInfo = displayOrgText(displayKeys.NEW_MEMBERSHIP_INFO_BOX)
  const isRenewing =
    currentMember && currentMember.membershipTypeId === selectedMembership.id
  return (
    <div className="step-container membership-confirmation">
      <MainHeader title="Thank You!" disablePrevStep>
        <RenderedHTML>
          {displayOrgText(displayKeys.PAYMENT_CONFIRMATION_SUBTITLE)}
        </RenderedHTML>
        {config.CONFIRMATION_UPSELL_LINK && (
          <UpsellCard
            content={displayOrgText(displayKeys.CONFIRMATION_UPSELL_TEXT)}
            path={config.CONFIRMATION_UPSELL_LINK}
          />
        )}
      </MainHeader>
      {!isRenewing && newMembershipInfo && (
        <InfoBox
          title={displayOrgText(displayKeys.NEW_MEMBERSHIP_INFO_BOX_TITLE)}
        >
          <RenderedHTML>{newMembershipInfo}</RenderedHTML>
        </InfoBox>
      )}
      <div className="row confirmation-page">
        <div className="col-6">
          <Receipt
            title={selectedMembership.displayName}
            image={selectedMembership.image}
            cart={membershipConfirmation.cart}
            displayTotal
          />
        </div>
        <div className="col-8">
          <PaymentConfirmation
            confirmation={membershipConfirmation}
            info={displayOrgText(
              displayKeys.MEMBERSHIP_PAYMENT_CONFIRMATION_INFO
            )}
            displayValidPeriod={showValidPeriod}
          />
        </div>
      </div>
    </div>
  )
}

MembershipConfirmation.propTypes = propTypes
MembershipConfirmation.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    webstore: globalSelectors.webstore(state),
    config: globalSelectors.config(state),
    membershipConfirmation: selectors.orderConfirmation(state),
    selectedMembership: selectors.selectedMembership(state),
    currentMember: memberSelectors.currentMember(state),
  }
}

const mapDispatchToProps = {
  push: routerActions.push,
}

function onComponentDidMount({ membershipConfirmation, push, webstore }) {
  if (!membershipConfirmation) return push('/' + webstore + '/memberships')
}

function initializeOrgHelpers({ config }) {
  return {
    displayOrgText: createDisplayOrgText(config),
    showValidPeriod: config.MEMBERSHIP_CONFIRMATION_SHOW_VALID_PERIOD,
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  onMount(onComponentDidMount),
  withPropsOnChange(['config'], initializeOrgHelpers),
  waitFor('membershipConfirmation', Spinner)
)(MembershipConfirmation)
