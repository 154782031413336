import React from 'react'
import PropTypes from 'prop-types'
import { compose, withHandlers, withState } from 'recompose'
import { LabeledField, stripNamespace } from 'lp-components'
import { onMount, onUnmount } from 'lp-hoc'
import classnames from 'classnames'

const propTypes = {
  containerId: PropTypes.string.isRequired,
  focused: PropTypes.bool,
}

const defaultProps = {
  focused: false,
}

function IFrameInput(props) {
  const { containerId, focused } = props
  return (
    <LabeledField {...props}>
      <div
        id={containerId}
        className={classnames('iFrame-input', { focused })}
      />
    </LabeledField>
  )
}

IFrameInput.propTypes = propTypes
IFrameInput.defaultProps = defaultProps

const modifyHandlers = {
  handleFieldEvent: ({ input: { onBlur, name, onChange }, setFocused }) => (
    e
  ) => {
    const { name: fieldName, type } = e.detail
    if (stripNamespace(name) !== fieldName) return
    if (type === 'focus') return setFocused(true)
    if (type === 'input') return onChange()
    if (type === 'blur') {
      setFocused(false)
      return onBlur()
    }
  },
}

function onComponentDidMount({ handleFieldEvent }) {
  window.addEventListener('paymentProcessorFieldEvent', handleFieldEvent)
}

function onComponentDidUnmount({ handleFieldEvent }) {
  window.removeEventListener('paymentProcessorFieldEvent', handleFieldEvent)
}

export default compose(
  withState('focused', 'setFocused', false),
  withHandlers(modifyHandlers),
  onMount(onComponentDidMount),
  onUnmount(onComponentDidUnmount)
)(IFrameInput)
