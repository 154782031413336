import { handleActions } from 'redux-actions'
import { selectorForSlice, setState, unsetState } from 'lp-redux-utils'
import { setOnSuccess } from 'lp-redux-api'
import * as actions from 'global-actions'
import * as apiActions from 'api-actions'
import { createSelector } from 'reselect'
import {
  ticketingSteps as baseTicketingSteps,
  membershipSteps as baseMembershipSteps,
  donationSteps as baseDonationSteps,
  giftMembershipSteps as baseGiftMembershipSteps,
} from 'config'
import { noop } from 'lodash'
import { createDisplayOrgText } from 'utils'

const reducerKey = 'global'
const slice = 'root.global'

const initialState = {}

const select = selectorForSlice(slice)

const reducer = handleActions(
  {
    [apiActions.fetchOrganization]: setOnSuccess('organization'),
    [actions.setWebstore]: setState('webstore'),
    [actions.clearWebstore]: unsetState('webstore'),
    [actions.setOrgConfig]: setState('config'),
  },
  initialState
)

const selectors = {
  webstore: select('webstore', ''),
  organization: select('organization'),
  config: select('config'),
  hasPromoCodes: select('organization.hasPromoCodes'),
  ticketingSteps: select('config.ticketingSteps', baseTicketingSteps),
  membershipSteps: select('config.membershipSteps', baseMembershipSteps),
  donationSteps: select('config.donationSteps', baseDonationSteps),
  giftMembershipSteps: select(
    'config.giftMembershipSteps',
    baseGiftMembershipSteps
  ),
  active: select('organization.active'),
  status: select('organization.status'),
  timezoneOffset: select('organization.timezoneOffset'),
  contactPhone: select('organization.contactPhone'),
  contactEmail: select(
    'organization.contactEmail',
    'centaman@launchpadlab.com'
  ),
  footerLinks: select('organization.footerLinks'),
  donationCommentText: select('organization.donationCommentText'),
}

selectorForSlice.ticketingSteps = createSelector(
  [selectors.config],
  function(config) {
    if (!config) return []
    return config.ticketingSteps || baseTicketingSteps
  }
)

selectorForSlice.membershipSteps = createSelector(
  [selectors.config],
  function(config) {
    if (!config) return []
    return config.membershipSteps || baseMembershipSteps
  }
)

selectorForSlice.donationSteps = createSelector(
  [selectors.config],
  function(config) {
    if (!config) return []
    return config.donationSteps || baseDonationSteps
  }
)

selectors.displayOrgText = createSelector(
  [selectors.config],
  function(config) {
    if (!config) return noop
    return createDisplayOrgText(config)
  }
)

export { reducer, selectors, reducerKey }
