import React from 'react'
import ReactDOM from 'react-dom'
import { getAppForDomain } from './domainMap'
import { getCurrentDomain } from 'utils'
import '../../scss/application.scss'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/dist/themes/light-border.css'

const currentDomain = getCurrentDomain()

const App = getAppForDomain(currentDomain)

ReactDOM.render(<App />, document.getElementById('root'))
