import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { lpForm } from 'lp-form'
import {
  Checkbox,
  Input,
  RadioGroup,
  Select,
  SubmitButton,
} from 'lp-components'
import { Field, FormSection, formValueSelector } from 'redux-form'
import { isEmpty, isNil, startCase } from 'lodash'
import { displayCurrency, findStates, regionData } from 'utils'
import { RadioGroupPriceSelection } from 'base/donation/components'

const propTypes = {
  country: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  prices: PropTypes.arrayOf(Types.donationPrice),
  sendCardToReceiver: PropTypes.bool,
  submitting: PropTypes.bool.isRequired,
}

const defaultProps = {
  country: null,
  sendCardToReceiver: false,
}

const validateThresholds = (donationPrice) => {
  const { price, minimumAmount, maximumAmount } = donationPrice
  if (!price) return

  if (!isNil(maximumAmount) && price > maximumAmount)
    return `Donation amount cannot exceed ${displayCurrency(maximumAmount)}`
  if (!isNil(minimumAmount) && price < minimumAmount)
    return `Donation amount must be at least ${displayCurrency(minimumAmount)}`
}

const validatePrice = (donationPrice) => {
  if (!donationPrice) return 'Donation amount must be selected'
  if (!donationPrice.price) return 'Donation amount must be provided' // If "different amount is selected, make sure a price is provided"

  return validateThresholds(donationPrice)
}

function DonationForm({
  country,
  handleSubmit,
  onSubmit,
  prices,
  sendCardToReceiver,
  submitting,
}) {
  const [showHonorarium, setShowHonorarium] = useState(false)
  const stateOptions = useMemo(() => findStates(regionData, country), [country])

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="donation-selection select-amount">
        <Field
          name="price"
          component={RadioGroupPriceSelection}
          options={prices}
          aria-label="Select donation amount"
          validate={validatePrice}
          onChange={(e, price) => {
            // Do not update the cart when thresholds are exceeded
            if (validateThresholds(price)) return
            onSubmit({ price })
          }}
        />
      </div>
      <h2>Honorarium or Memorial</h2>
      <div className="donation-selection">
        <div className="action-item-container honor-memory-container">
          <fieldset className="checkbox">
            <label className="checkbox-align">
              <input
                type="checkbox"
                checked={showHonorarium}
                onChange={() => setShowHonorarium(!showHonorarium)}
              />
              My donation is being given as an honorarium or memorial
            </label>
          </fieldset>
        </div>
        {showHonorarium && (
          <div className="action-item-container honor-memory-container">
            <FormSection name="donationUdfs">
              <Field
                name="type"
                label="I would like my gift to be made:"
                component={RadioGroup}
                options={[
                  { key: 'In Honor of', value: 'honorOfDonation' },
                  { key: 'In Memory of', value: 'memoryOfDonation' },
                ]}
                required
              />
              <div className="short-inputs">
                <Field
                  name="giverFirstName"
                  label="First Name"
                  component={Input}
                  type="text"
                  required
                />
                <Field
                  name="giverLastName"
                  label="Last Name"
                  component={Input}
                  type="text"
                  required
                />
              </div>
              <Field
                name="sendCardToReceiver"
                component={Checkbox}
                label="I would like a card sent acknowledging my gift. If checked, you will be asked to provide the mailing address for the card recipient below."
                aria-describedby="gift-sla"
                className="checkbox"
              />
              {sendCardToReceiver && (
                <React.Fragment>
                  <Field
                    name="receiverFirstName"
                    label="First Name"
                    component={Input}
                    type="text"
                    required
                  />
                  <Field
                    name="receiverLastName"
                    label="Last Name"
                    component={Input}
                    type="text"
                    required
                  />
                  <Field
                    name="receiverCountry"
                    label="Country"
                    component={Select}
                    placeholder="Select"
                    options={regionData}
                    normalize={(value) => startCase(value.toLowerCase())}
                    format={(value) => (value ? value.toUpperCase() : '')}
                  />
                  <Field
                    name="receiverStreetAddress"
                    label="Address"
                    component={Input}
                    type="text"
                    required
                  />
                  <div className="short-inputs">
                    <Field name="receiverCity" label="City" component={Input} />
                    {!isEmpty(stateOptions) && (
                      <Field
                        name="receiverState"
                        label="State"
                        component={Select}
                        placeholder="Select"
                        options={stateOptions}
                        normalize={(value) => startCase(value.toLowerCase())}
                        format={(value) => (value ? value.toUpperCase() : '')}
                      />
                    )}
                    {isEmpty(stateOptions) && (
                      <Field
                        name="receiverState"
                        label="State"
                        component={Input}
                      />
                    )}
                    <Field
                      name="receiverZip"
                      label="Zip"
                      component={Input}
                      type="tel"
                    />
                  </div>
                  <p id="gift-sla">
                    A card acknowledging your gift in honor or memory of an
                    individual will be mailed within 72 hours.
                  </p>
                </React.Fragment>
              )}
            </FormSection>
          </div>
        )}
      </div>
      <SubmitButton className="full-width-button" {...submitting}>
        Continue
      </SubmitButton>
    </form>
  )
}

DonationForm.propTypes = propTypes
DonationForm.defaultProps = defaultProps

const valueSelectors = formValueSelector('DpaDonationForm')

function mapStateToProps(state) {
  return {
    country: valueSelectors(state, 'donationUdfs.receiverCountry'),
    sendCardToReceiver: valueSelectors(
      state,
      'donationUdfs.sendCardToReceiver'
    ),
  }
}

const mapDispatchToProps = {}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  lpForm({
    name: 'DpaDonationForm',
    constraints: {
      type: {
        presence: true,
      },
      giverFirstName: { presence: true, length: { maximum: 250 } },
      giverLastName: { presence: true, length: { maximum: 250 } },
      receiverFirstName: { presence: true, length: { maximum: 250 } },
      receiverLastName: { presence: true, length: { maximum: 250 } },
      receiverStreetAddress: { presence: true, length: { maximum: 250 } },
      receiverCity: { presence: true, length: { maximum: 250 } },
      receiverCountry: { presence: true, length: { maximum: 250 } },
      receiverState: { presence: true, length: { maximum: 250 } },
      receiverZip: { presence: true, length: { maximum: 10 } },
    },
  })
)(DonationForm)
