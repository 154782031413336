import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { lpForm } from 'lp-form'
import { modifyProps, onUpdate } from 'lp-hoc'
import {
  FieldArray,
  FormSection,
  propTypes as formPropTypes,
  getFormValues,
} from 'redux-form'
import { Expandable, HorizontalCard } from 'components'
import { ButtonArea, SubmitButton } from 'lp-components'
import { flatMap } from 'lodash'
import { getOr } from 'lodash/fp'
import { selectors } from '../reducer'
import { selectors as memberSelectors } from '../../member/reducer'
import SecondaryMembershipContactFields from './SecondaryMembershipContactFields'
import { getAvailableMembers } from 'utils'

const propTypes = {
  ...formPropTypes,
  addOns: PropTypes.array,
  availableMembers: PropTypes.array,
  displayOrgText: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  isRenewing: PropTypes.bool.isRequired,
  formValues: PropTypes.object,
  productId: PropTypes.number.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
}

const defaultProps = {
  addOns: [],
  availableMembers: [],
  formValues: {},
}

function MembershipAddOnsForm({
  change, // bound redux-form action
  handleSubmit,
  initialValues,
  submitting,
  addOns,
  availableMembers,
  displayOrgText,
  isRenewing,
  formValues,
  productId,
  startDate,
}) {
  return (
    <form onSubmit={handleSubmit}>
      {addOns.map((addOn) => {
        const startExpanded = !!initialValues[`addOn-${addOn.id}`]

        return (
          <Expandable key={addOn.id} startExpanded={startExpanded}>
            {(expanded, setExpanded) => (
              <div className="horizontal-item">
                <HorizontalCard
                  active={expanded}
                  descriptionHtml={addOn.details}
                  displayName={addOn.displayName}
                  imageUrl={addOn.image}
                  onClick={() => {
                    setExpanded(!expanded)

                    // Remove all members on close
                    if (expanded) {
                      change(`addOn-${addOn.id}.members`, [])
                      return
                    }
                  }}
                  // Hide for now, unti it can be controlled by API. Recommend using price's display details.
                  // price={addOn.price}
                />
                {expanded && (
                  <div className="horizontal-item-expand">
                    {addOn.type === Types.addOnTypes.MEMBERSHIP && (
                      <FormSection name={`addOn-${addOn.id}`}>
                        <FieldArray
                          name="members"
                          component={SecondaryMembershipContactFields}
                          productId={productId}
                          displayOrgText={displayOrgText}
                          members={getOr(
                            [],
                            `addOn-${addOn.id}.members`,
                            formValues
                          )}
                          membershipTypes={[addOn]}
                          allowTypeSelection={false}
                          startDate={startDate}
                          isRenewing={isRenewing}
                          availableMembers={availableMembers}
                        />
                      </FormSection>
                    )}
                  </div>
                )}
              </div>
            )}
          </Expandable>
        )
      })}

      <ButtonArea className="full-width-button">
        <SubmitButton {...{ submitting }}>Continue</SubmitButton>
      </ButtonArea>
    </form>
  )
}

MembershipAddOnsForm.propTypes = propTypes
MembershipAddOnsForm.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    cart: selectors.cart(state),
    currentMember: memberSelectors.currentMember(state),
    formValues: getFormValues('membership-add-ons')(state),
  }
}

const mapDispatchToProps = {}

const convertFormValuesToMembers = (formValues) =>
  flatMap(formValues, (section) => section.members)

const modify = ({ currentMember, formValues, cart }) => {
  const addOnMembers = convertFormValuesToMembers(formValues)
  return {
    addOnMembers,
    availableMembers: getAvailableMembers(currentMember.secondaryMembers, [
      ...cart.secondaryMembers,
      ...addOnMembers,
    ]),
  }
}

const watchAddOnQuantityChange = (
  { formValues, addOnMembers, onChangeQuantity },
  prevProps
) => {
  if (!formValues) return
  if (addOnMembers.length === prevProps.addOnMembers.length) return

  return onChangeQuantity(addOnMembers)
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  modifyProps(modify),
  onUpdate(watchAddOnQuantityChange),
  lpForm({
    name: 'membership-add-ons',
    beforeSubmit: convertFormValuesToMembers,
  })
)(MembershipAddOnsForm)
