// Cart auth token
export const [
  getCartToken,
  setCartToken,
  clearCartToken,
] = createStorageAccessor('streamlined-checkout-token', { persist: false })

// Membership auth token
export const [
  getMemberToken,
  setMemberToken,
  clearMemberToken,
] = createStorageAccessor('streamlined-checkout-member-token', {
  persist: true,
})

export function isLoggedIn() {
  return !!getMemberToken()
}

// Membership upgrade modal
export const [
  getMembershipUpgradeModalShown,
  setMembershipUpgradeModalShown,
  clearMembershipUpgradeModalShown,
] = createStorageAccessor('membershipUpgradeModalShown', { persist: false })

/* HELPERS */

// Get item from localStorage, falling back to session storage
function getItem(key) {
  const item = localStorage.getItem(key) || sessionStorage.getItem(key)
  return item ? JSON.parse(item) : null
}

// Remove item from local storage and session storage
function removeItem(key) {
  localStorage.removeItem(key)
  sessionStorage.removeItem(key)
}

// Set item in local storage or session storage (if specified)
export function setItem(key, value, options = {}) {
  const { persist = true } = options
  const stringifiedValue = JSON.stringify(value)
  removeItem(key)
  return persist
    ? localStorage.setItem(key, stringifiedValue)
    : sessionStorage.setItem(key, stringifiedValue)
}

/**
 * Given a key, returns functions for getting, setting, and clearing that key
 * @example
 *
 * export const [
 *  getSelectedMembership,
 *  setSelectedMembership,
 *  clearSelectedMembership
 * ] = createStorageAccessor('selectedMembership', { persist: false })
 *
 **/
export function createStorageAccessor(key, defaultOptions = {}) {
  const get = () => getItem(key)
  const set = (value, options = {}) =>
    setItem(key, value, { ...defaultOptions, ...options })
  const clear = () => removeItem(key)

  return [get, set, clear]
}
