// Return the config for the given orgSlug.
// Relies on filename convention to grab the right one.
function requireConfig(orgSlug) {
  return require('./' + orgSlug + '.config').default
}

export function getConfigForOrg(orgSlug) {
  try {
    return requireConfig(orgSlug)
  } catch (e) {
    // eslint-disable-next-line
    console.warn(
      'Unable to find config for orgSlug ' +
        orgSlug +
        ', falling back to demo config.'
    )
    return requireConfig('demo')
  }
}
