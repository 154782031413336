import React from 'react'
// import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import sydneyImage from 'images/background/sydney.jpg'
import dubboImage from 'images/background/dubbo.jpg'

const propTypes = {}

const defaultProps = {}

function Home() {
  const { TZ_SYDNEY_TICKET_ID, TZ_DUBBO_TICKET_ID } = process.env

  return (
    <div className="step-container text-center">
      <div className="main-header">
        <h1>Which Zoo Would You Like to Visit?</h1>
      </div>
      <div className="select-location-container">
        <Link
          to={'/sydney/tickets/details/' + TZ_SYDNEY_TICKET_ID}
          className="select-location"
        >
          <div className="location-image">
            <img src={sydneyImage} />
          </div>
          <div className="button-primary"> Sydney Tickets </div>
        </Link>
        <Link
          to={'/dubbo/tickets/details/' + TZ_DUBBO_TICKET_ID}
          className="select-location"
        >
          <div className="location-image">
            <img src={dubboImage} />
          </div>
          <div className="button-primary"> Dubbo Tickets </div>
        </Link>
      </div>
    </div>
  )
}

Home.propTypes = propTypes
Home.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Home)
