import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import { Field } from 'redux-form'
import { Input, Button, Select } from 'lp-components'
import MembershipTypeField from './MembershipTypeField'
import * as Types from 'types'
import {
  generateMemberOptionGroups,
  getAvailableMembersForMembershipType,
  isAfterDay,
  stripTimezone,
  validateDateOfBirth,
} from 'utils'
import moment from 'moment'
import { format, parse } from 'date-fns'
import { countBy, memoize, noop, size } from 'lodash'
import { get, set } from 'lodash/fp'
import { CalendarInput, HighlightBox, RenderedHTML } from 'components'
import { displayKeys, NEW_MEMBERSHIP_ID } from 'config'
import MembershipTypeQuantityInputs from './MembershipTypeQuantityInputs'

const propTypes = {
  availableMembers: PropTypes.array,
  productId: PropTypes.number.isRequired,
  resetSubmitFlags: PropTypes.func,
  fields: PropTypes.object,
  startDate: PropTypes.instanceOf(Date).isRequired,
  allowTypeSelection: PropTypes.bool,
  membershipTypes: PropTypes.arrayOf(Types.membershipType),
  members: PropTypes.arrayOf(PropTypes.object).isRequired,
  primaryMember: PropTypes.object,
  isRenewing: PropTypes.bool.isRequired,
  displayOrgText: PropTypes.func.isRequired,
  onExceedMax: PropTypes.func,
}

const defaultProps = {
  availableMembers: [],
  availableMembersById: {},
  allowTypeSelection: false,
  membershipTypes: [],
  primaryMember: null,
  onExceedMax: noop,
  resetSubmitFlags: noop,
}

// eslint-disable-next-line
function DisplayInput({ input: { value } }) {
  return <span>{value}</span>
}

const createValidator = (name, maxLength) => {
  return function validate(value) {
    if (value === undefined || value === null || value === '')
      return `${name} can't be blank`
    if (size(value) > maxLength)
      return `${name} cannot exceed ${maxLength} characters`
  }
}

const validateFirstName = createValidator('First Name', 30)
const validateLastName = createValidator('Last Name', 30)

const validateDob = (membershipType, startDate, value) => {
  const { minimumAge, maximumAge, requireCustomerDateOfBirth } = membershipType
  return validateDateOfBirth(value, {
    isRequired: requireCustomerDateOfBirth,
    startDate,
    minimumAge,
    maximumAge,
  })
}

// Memoizing this function stops Redux-Form from constantly re-rendering an inline function definition (https://github.com/erikras/redux-form/issues/3288#issuecomment-320709952)
const createValidateDob = memoize(
  (membershipType, startDate) => {
    if (!membershipType) return
    return function(value) {
      return validateDob(membershipType, startDate, value)
    }
  },
  (...args) => JSON.stringify(args)
) // define custom resolver as memoize OOTB will only look at first key

// Return the index of a specific membership type (e.g., Adult Pass #3); Relies on the members array being grouped by type
function getMembershipTypeIndex(members = [], membershipTypeId, currentIndex) {
  const baseIndex = members.findIndex(
    (member) => member.membershipTypeId === membershipTypeId
  )

  return currentIndex - baseIndex
}

function SecondaryMembershipContactFields({
  availableMembers,
  productId,
  resetSubmitFlags,
  startDate,
  fields,
  membershipTypes,
  allowTypeSelection,
  members,
  primaryMember,
  isRenewing,
  displayOrgText,
  onExceedMax,
}) {
  const membershipTypeCount = countBy(members, 'membershipTypeId')
  return (
    <div>
      {!allowTypeSelection && (
        <MembershipTypeQuantityInputs
          availableMembers={availableMembers}
          membershipTypes={membershipTypes}
          primaryMember={primaryMember}
          members={members}
          addMember={(i, member) => fields.insert(i, member)}
          removeMember={(i) => fields.remove(i)}
          onExceedMax={onExceedMax}
          startDate={startDate}
        />
      )}
      {members &&
        !!members.length &&
        fields.map((member, i) => {
          const currentMember = members[i]
          const existingMember = !!currentMember.memberNumber
          const membershipType =
            membershipTypes.find(
              ({ id }) => id === currentMember.membershipTypeId
            ) || {} // if type selection is allowed, a membership type may _not_ be known initially

          // Do not show a container if the type is already selected AND no details will be collected
          if (!allowTypeSelection && !membershipType.collectCustomerName)
            return null

          const restrictEdit = isRenewing && existingMember // only existing members will have an id
          const restrictDOBEdit = restrictEdit && !!currentMember.dateOfBirth // allow edit if they're missing a DOB
          const activeMembership = (currentMember.activeMemberships || []).find(
            (membership) => membership.packageId === productId
          )
          const isFuture =
            existingMember &&
            activeMembership &&
            isAfterDay(parse(activeMembership.joinDate), new Date())
          const availableMembersWithinAgeRange = getAvailableMembersForMembershipType(
            availableMembers,
            membershipType,
            startDate
          )
          const canRemove =
            !membershipType.minQuantity ||
            membershipTypeCount[currentMember.membershipTypeId] >
              membershipType.minQuantity

          return (
            <div key={i} className="secondary-member-container">
              <p className="secondary-member-label">
                <strong>
                  {allowTypeSelection && `Additional Member #${i + 1}:`}
                  {!allowTypeSelection && (
                    <React.Fragment>
                      {`${membershipType.displayName} #${getMembershipTypeIndex(
                        members,
                        currentMember.membershipTypeId,
                        i + 1
                      )}`}
                      <Field
                        name={`${member}.membershipType.displayName`}
                        component={DisplayInput}
                      />
                    </React.Fragment>
                  )}
                </strong>
              </p>
              {canRemove && (
                <span>
                  <button
                    className="remove-button"
                    type="button"
                    onClick={() => fields.remove(i)}
                  >
                    Remove
                  </button>
                </span>
              )}
              <div className="form-container secondary-member-form">
                {isFuture && (
                  <HighlightBox className="small">
                    <RenderedHTML>
                      {displayOrgText(
                        displayKeys.SECONDARY_MEMBER_MEMBERSHIP_DATES,
                        {
                          joinDate: format(
                            activeMembership.joinDate,
                            'MMMM D, YYYY'
                          ),
                          expDate: format(
                            activeMembership.expirationDate,
                            'MMMM D, YYYY'
                          ),
                        }
                      )}
                    </RenderedHTML>
                  </HighlightBox>
                )}
                {allowTypeSelection && (
                  <MembershipTypeField
                    name={`${member}.membershipTypeId`}
                    membershipTypes={membershipTypes}
                  />
                )}
                {get('collectCustomerName', membershipType) && (
                  <div>
                    <Field
                      name={`${member}.id`}
                      label="Selection"
                      component={Select}
                      placeholder="Choose an option"
                      parse={(val) => {
                        if (isNaN(val)) return val
                        return Number(val)
                      }}
                      optionGroups={generateMemberOptionGroups(
                        currentMember,
                        availableMembersWithinAgeRange
                      )}
                      onChange={(e, val) => {
                        // If "new" is selected instead of an existing member, then remove the existing member and replace it with a "new" member
                        if (
                          val === NEW_MEMBERSHIP_ID &&
                          currentMember.id &&
                          currentMember.id !== NEW_MEMBERSHIP_ID
                        ) {
                          fields.remove(i)
                          return fields.insert(i, {
                            id: NEW_MEMBERSHIP_ID,
                            membershipTypeId: membershipType.id,
                          })
                        }

                        const member = availableMembers.find(
                          ({ id }) => id === Number(val)
                        )
                        if (!member) return

                        fields.remove(i)
                        fields.insert(
                          i,
                          set('membershipTypeId', membershipType.id, member)
                        )
                      }}
                      required
                    />
                    {currentMember.id === NEW_MEMBERSHIP_ID && (
                      <div className="short-inputs">
                        <Field
                          name={`${member}.firstName`}
                          component={Input}
                          type="text"
                          disabled={restrictEdit}
                          validate={validateFirstName}
                        />
                        <Field
                          name={`${member}.lastName`}
                          component={Input}
                          type="text"
                          disabled={restrictEdit}
                          validate={validateLastName}
                        />
                      </div>
                    )}
                    {membershipType.collectCustomerDateOfBirth && (
                      <Field
                        name={`${member}.dateOfBirth`}
                        label={'Date of Birth'}
                        hint={
                          membershipType.requireCustomerDateOfBirth
                            ? ''
                            : ' (optional)'
                        }
                        component={CalendarInput}
                        dateFormat="MMMM D, YYYY"
                        autoComplete="off"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        maxDate={moment()}
                        format={stripTimezone}
                        validate={createValidateDob(membershipType, startDate)}
                        normalize={(date) => {
                          if (!date) return date
                          return format(date, 'YYYY-MM-DD')
                        }}
                        disabled={restrictDOBEdit}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          )
        })}
      {allowTypeSelection && (
        <Button
          className="button-dark"
          onClick={() => {
            resetSubmitFlags() // resets the form's submit failed state so that the form wide error does not show
            fields.push({})
          }}
          invalid={!primaryMember || !primaryMember.membershipTypeId}
        >
          + Add Member
        </Button>
      )}
    </div>
  )
}

SecondaryMembershipContactFields.propTypes = propTypes
SecondaryMembershipContactFields.defaultProps = defaultProps

export default pure(SecondaryMembershipContactFields)
