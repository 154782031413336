/**
 * @name calculateCalendarRangeEndDate
 * @param {Object} startDate - The start date of the range
 * @param {Number} [months] - The number of months the range should cover
 * @returns {Object} - Returns the date corresponding to the last possible date that may
 * be visible on a DateInput calendar widget (including overlap from the next month).
 *
 * @example
 * calculateCalendarRangeEndDate(new Date(2018, 11, 10), 2) // month in JS is 0 indexed so date is 12/10/2018
 * // 3/2/2019
 **/

import { addDays, addMonths, endOfMonth } from 'date-fns'

export default function calculateCalendarRangeEndDate(startDate, months) {
  const monthEnd = endOfMonth(addMonths(startDate, months))
  return addDays(monthEnd, 6 - monthEnd.getDay())
}
