import { handleActions } from 'redux-actions'
import { setOnSuccess } from 'lp-redux-api'
import * as apiActions from 'api-actions'
import { selectorForSlice, setState, unsetState } from 'lp-redux-utils'
import * as actions from './actions'
import {
  findExistingMembership,
  getProductCategories,
  getUTCDate,
  isAfterDay,
  removeStep,
  routerSelectors,
  setCategoryProducts,
  setIf,
} from 'utils'
import { createSelector } from 'reselect'
import { selectors as globalSelectors } from 'global-reducer'
import { selectors as memberSelectors } from '../member/reducer'
import { compact, first, groupBy, isEmpty, map, sortBy, uniqBy } from 'lodash'
import { get, set } from 'lodash/fp'
import { addDays, parse } from 'date-fns'
import { compose } from 'recompose'
import * as Types from 'types'

const reducerKey = 'membership'
const slice = 'root.membership'

const initialCartState = {}

const initialState = {}

const reducer = handleActions(
  {
    [apiActions.fetchMemberships]: setOnSuccess('memberships'),
    [apiActions.fetchMembershipCart]: setOnSuccess('cart'),
    [apiActions.validateMembershipCoupon]: setOnSuccess('cart'),
    [apiActions.removeMembershipCoupon]: setOnSuccess('cart'),
    [actions.replaceMembershipCart]: setState('cart'),
    [actions.setSelectedMembershipId]: setState('selectedMembershipId'),
    [apiActions.saveMembershipDetailsToCart]: setOnSuccess(
      'cart',
      ({ payload: { data } }) => {
        return data.attributes
      }
    ),
    [apiActions.updateMembershipCartQuantities]: setOnSuccess(
      'cart',
      ({ payload: { data } }) => {
        return data.attributes
      }
    ),
    [apiActions.updateAddOnMembershipCartQuantities]: setOnSuccess('cart'),
    [actions.setMembershipConfirmation]: setState('orderConfirmation'),
    [actions.emptyMembershipCart]: setState('cart', initialCartState),
    [actions.setSelectedMembership]: setState('membership'),
    [actions.clearSelectedMembership]: unsetState('membership'),
    [apiActions.addOrRemoveModalProduct]: setOnSuccess('cart'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  memberships: select('memberships'),
  selectedMembership: select('membership'),
  primaryMembershipTypes: select('membership.primaryPrices', []),
  secondaryMembershipTypes: select('membership.secondaryPrices', []),
  cartItems: select('cart.listItems', []),
  cart: select('cart'),
  orderConfirmation: select('orderConfirmation.attributes'),
  availableAddOns: select('membership.addOns'),
  selectedMembershipId: select('selectedMembershipId'),
}

selectors.productCategories = createSelector(
  [selectors.memberships],
  function(products) {
    if (!products) return

    const categories = getProductCategories(products)
    return sortBy(categories, 'position')
  }
)

// Organize memberships by category for display on index
selectors.productsByCategory = createSelector(
  [selectors.memberships, selectors.productCategories],
  function(products, productCategories) {
    if (!products || !productCategories) return

    return map(productCategories, (category) =>
      setCategoryProducts(category, products)
    )
  }
)

// Consolidate all membership types
selectors.membershipTypes = createSelector(
  [selectors.primaryMembershipTypes, selectors.secondaryMembershipTypes],
  function(primaryMembershipTypes, secondaryMembershipTypes) {
    return uniqBy(
      [...primaryMembershipTypes, ...secondaryMembershipTypes],
      'id'
    )
  }
)

selectors.addOnMembershipTypes = createSelector(
  [selectors.availableAddOns],
  function(addOns) {
    if (!addOns) return []

    return addOns.filter((addOn) => addOn.type === Types.addOnTypes.MEMBERSHIP)
  }
)

selectors.hasAddOnMemberships = createSelector(
  [selectors.addOnMembershipTypes],
  function(addOns) {
    return !!addOns.length
  }
)

selectors.allMembershipTypes = createSelector(
  [selectors.membershipTypes, selectors.addOnMembershipTypes],
  function(membershipTypes, addOnMembershipTypes) {
    return [...membershipTypes, ...addOnMembershipTypes]
  }
)

// Computed from global membership steps
selectors.steps = createSelector(
  [globalSelectors.membershipSteps, selectors.availableAddOns],
  function(membershipSteps, availableAddOns) {
    // Only show add-on step if there are add-ons for the current ticket.
    return availableAddOns && availableAddOns.length
      ? membershipSteps
      : removeStep(membershipSteps, 'add-ons')
  }
)

// Compute current step by looking at the pathname
selectors.currentStepIndex = createSelector(
  [selectors.steps, routerSelectors.pathname],
  function(steps, pathname) {
    const currentStepIndex = steps.findIndex((step) =>
      pathname.includes(step.route)
    )
    if (currentStepIndex === -1) return null
    return currentStepIndex
  }
)

selectors.currentStep = createSelector(
  [selectors.steps, selectors.currentStepIndex],
  function(steps, currentStepIndex) {
    return steps[currentStepIndex]
  }
)

selectors.currentStepName = createSelector(
  [selectors.currentStep],
  function(currentStep) {
    return currentStep ? currentStep.name : ''
  }
)

selectors.nextStep = createSelector(
  [selectors.steps, selectors.currentStepIndex],
  function(steps, currentStepIndex) {
    return steps[currentStepIndex + 1]
  }
)

selectors.nextStepPath = createSelector(
  [selectors.nextStep],
  function(nextStep) {
    return nextStep ? nextStep.route : 'confirmation'
  }
)

selectors.primaryMembershipType = createSelector(
  [selectors.primaryMembershipTypes],
  function(primaryMembershipTypes) {
    if (!primaryMembershipTypes || primaryMembershipTypes.length > 1) return

    return first(primaryMembershipTypes)
  }
)

selectors.primaryMember = createSelector(
  [
    memberSelectors.currentMember,
    selectors.cart,
    selectors.selectedMembership,
    selectors.primaryMembershipType,
    globalSelectors.config,
  ],
  function(
    currentMember,
    cart,
    selectedMembership,
    primaryMembershipType,
    config
  ) {
    if (!currentMember) return

    const cartInfo = cartMatchesSelectedMembership(cart, selectedMembership)
      ? cart
      : clearPrimaryMemberFromCart(cart)

    const primaryMember =
      getPrimaryMember({ cartInfo, currentMember, config }) ||
      createPrimaryMember({ config })

    const existingActiveMembership = findExistingMembership(
      primaryMember,
      get('centamanId', selectedMembership),
      { onlyActive: true }
    )

    // default to cart, but if undefined, modify membership type if
    // there is an existing active membership or a new primary (if only 1 exists)
    const membershipTypeId =
      get('primaryMember.membershipTypeId', cartInfo) ||
      get('membershipTypeId', existingActiveMembership) ||
      get('centamanId', primaryMembershipType)

    return set('membershipTypeId', membershipTypeId, primaryMember)
  }
)

selectors.secondaryMembersWithActiveMembership = createSelector(
  [
    memberSelectors.currentMember,
    selectors.selectedMembership,
    selectors.allMembershipTypes,
  ],
  function(currentMember, selectedMembership, allMembershipTypes) {
    if (!currentMember || !selectedMembership || !allMembershipTypes) return

    // Only include members with an active membership that have a membershipTypeId that match the currently offered prices
    const availableMembershipTypeIds = groupBy(allMembershipTypes, 'centamanId')
    const mappedSecondaryMembers = compact(
      currentMember.secondaryMembers.map((member) => {
        const activeMembership = member.activeMemberships.find(
          (membership) => membership.packageId === selectedMembership.centamanId
        )
        if (
          !activeMembership ||
          !availableMembershipTypeIds[activeMembership.membershipTypeId]
        )
          return null

        return set(
          'membershipTypeId',
          activeMembership.membershipTypeId,
          member
        )
      })
    )

    return mappedSecondaryMembers
  }
)

// Secondary members who currently have an active "included" secondary membership
selectors.activeIncludedSecondaryMembers = createSelector(
  [
    selectors.secondaryMembersWithActiveMembership,
    selectors.secondaryMembershipTypes,
  ],
  function(secondaryMembers, secondaryMembershipTypes) {
    if (!secondaryMembers || !secondaryMembershipTypes) return

    return secondaryMembers.filter((member) =>
      hasValidMembershipType(member, secondaryMembershipTypes)
    )
  }
)

// Secondary members who currently have an active add on membership
selectors.activeAddOnMembers = createSelector(
  [
    selectors.secondaryMembersWithActiveMembership,
    selectors.addOnMembershipTypes,
  ],
  function(secondaryMembers, addOnMembershipTypes) {
    if (!secondaryMembers || !addOnMembershipTypes) return

    return secondaryMembers.filter((member) => {
      return addOnMembershipTypes.find(
        (type) => type.centamanId === member.membershipTypeId
      )
    })
  }
)

selectors.existingPrimaryMembership = createSelector(
  [selectors.primaryMember, selectors.selectedMembership],
  function(primaryMember, selectedMembership) {
    if (!primaryMember || !selectedMembership) return
    return findExistingMembership(primaryMember, selectedMembership.centamanId)
  }
)

selectors.isRenewing = createSelector(
  [selectors.existingPrimaryMembership],
  function(existingPrimaryMembership) {
    return !!existingPrimaryMembership
  }
)

selectors.expirationDate = createSelector(
  [selectors.existingPrimaryMembership],
  function(existingPrimaryMembership) {
    if (!existingPrimaryMembership) return
    return parse(existingPrimaryMembership.expirationDate)
  }
)

selectors.isExpired = createSelector(
  [selectors.isRenewing, selectors.expirationDate],
  function(isRenewing, expirationDate) {
    return isRenewing && !isAfterDay(expirationDate)
  }
)

selectors.startDate = createSelector(
  [selectors.expirationDate, globalSelectors.timezoneOffset],
  function(expirationDate, orgTimezoneOffset = 0) {
    // For a new membership, the membership is effective immediately (i.e., today) based on the ORG's timezone
    if (!expirationDate || !isAfterDay(expirationDate)) {
      return new Date(getUTCDate().getTime() + orgTimezoneOffset)
    }

    // Renewal periods will start the day AFTER the current expiration date
    return addDays(expirationDate, 1)
  }
)

// ----- PRIVATE HELPERS -----

function hasValidMembershipType(member, membershipTypes) {
  return membershipTypes.find(
    (type) => type.centamanId === member.membershipTypeId
  )
}

function cartMatchesSelectedMembership(cart, selectedMembership) {
  const cartMembershipId = get('productId', cart, '')
  const selectedMembershipId = get('id', selectedMembership, '')
  return cartMembershipId === selectedMembershipId
}

// Get primary member from data sources, if it exists. Treat cart data as highest priority but always set latest primary membership type
function getPrimaryMember({ cart, currentMember, config }) {
  if (cart && get('primaryMember.firstName', cart)) return cart.primaryMember

  // After registering with email and name details, member number is guaranteed to be generated
  if (currentMember && get('primaryMember.memberNumber', currentMember)) {
    // Account holder must be mapped to the indicated primary membership type
    return compose(
      setIf(
        isEmpty(currentMember.primaryMember.activeMemberships) &&
          isEmpty(currentMember.primaryMember.expiredMemberships),
        'dateOfBirth',
        null
      ),
      setIf(
        !get('primaryMember.address.country', currentMember) &&
          config.DEFAULT_COUNTRY,
        'address.country',
        config.DEFAULT_COUNTRY
      )
    )(currentMember.primaryMember)
  }

  return null
}

function createPrimaryMember({ config }) {
  const primaryMember = {
    firstName: '',
    lastName: '',
    address: {
      country: config.DEFAULT_COUNTRY,
    },
    activeMemberships: [],
    expiredMemberships: [],
  }

  return primaryMember
}

function clearPrimaryMemberFromCart(cart) {
  return { ...cart, primaryMember: {} }
}

export { reducer, selectors, reducerKey }
