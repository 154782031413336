import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import {
  DisplayMemberships,
  MembershipPerksBox,
  MainHeader,
  ProductEmptyState,
} from 'components'
import { selectors as membershipSelectors } from 'base/membership/reducer'
import { selectors as memberSelectors } from 'base/member/reducer'
import { selectors as globalSelectors } from 'global-reducer'
import * as apiActions from 'api-actions'
import { modifyProps, onMount, waitFor } from 'lp-hoc'
import { routerSelectors } from 'utils'
import { displayKeys } from 'config'

const propTypes = {
  currentPath: PropTypes.string.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  productsByCategory: PropTypes.arrayOf(Types.productCategory),
  primaryMember: Types.primaryMember,
  webstore: PropTypes.string.isRequired,
  displayOrgText: PropTypes.func.isRequired,
}

const defaultProps = {
  primaryMember: null,
}

const perks = [
  'Unlimited admission to museum and park',
  'Access to members-only programs and events',
  'Presale opportunities for DPA programs and events',
  'Unlimited access to the Tower',
  '10% off in The Gift Shop',
  '10% off in The Cafe',
]

function SelectMembership({
  currentPath,
  loggedIn,
  productsByCategory,
  primaryMember,
  webstore,
  displayOrgText,
}) {
  return (
    <div className="step-container">
      {!productsByCategory.length ? (
        <ProductEmptyState title={displayKeys.SELECT_MEMBERSHIP_TYPE}>
          <p>
            {displayOrgText(displayKeys.SELECT_MEMBERSHIP_NO_MEMBERSHIPS_FOUND)}
          </p>
        </ProductEmptyState>
      ) : (
        <React.Fragment>
          <div className="main-header">
            <p>
              The mission of Discovery Park of America is to inspire children
              and adults to see beyond. When you become a member, you join us in
              making a difference in the lives of visitors from our region and
              around the world.
            </p>
          </div>
          <MembershipPerksBox
            perks={perks}
            title="Your Discovery Park of America Membership Includes:"
          />
          <div className="row">
            <MainHeader
              title={displayOrgText(displayKeys.SELECT_MEMBERSHIP_TITLE)}
              disablePrevStep
            >
              <p>
                {displayOrgText(displayKeys.SELECT_MEMBERSHIP_INSTRUCTIONS)}
              </p>
              {!loggedIn && (
                <p>
                  If you have an existing{' '}
                  {displayOrgText(displayKeys.SELECT_MEMBERSHIP_TYPE)}, please{' '}
                  <Link
                    to={{
                      pathname: '/member/login',
                      state: { redirectUrl: currentPath },
                    }}
                  >
                    log in
                  </Link>{' '}
                  to renew.
                </p>
              )}
              <br />
              <br />
              <div className="callout-container">
                <p>
                  If you would like to gift a membership,{' '}
                  <Link to={`/${webstore}/gift-memberships`}>
                    purchase one here
                  </Link>
                  .
                </p>
              </div>
            </MainHeader>
            <DisplayMemberships
              currentPath={currentPath}
              productsByCategory={productsByCategory}
              loggedIn={loggedIn}
              primaryMember={primaryMember}
              webstore={webstore}
            />
          </div>
          <div className="org-info">
            <p>
              <strong>
                You may also join or renew today at the DPA ticket counter or by
                calling <a href="tel:+17318855455">731-885-5455</a>.
              </strong>
            </p>
            <p>
              <strong>
                To serve you better, we are upgrading our member database. If
                you have any problems logging in, please contact{' '}
                <a href="mailto:memberships@discoveryparkofamerica.com">
                  memberships@discoveryparkofamerica.com
                </a>
                .
              </strong>
            </p>
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

SelectMembership.propTypes = propTypes
SelectMembership.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentPath: routerSelectors.currentPath(state),
    loggedIn: memberSelectors.loggedIn(state),
    allMemberships: membershipSelectors.memberships(state),
    allProductsByCategory: membershipSelectors.productsByCategory(state),
    primaryMember: membershipSelectors.primaryMember(state),
    webstore: globalSelectors.webstore(state),
    displayOrgText: globalSelectors.displayOrgText(state),
  }
}

const mapDispatchToProps = {
  fetchMemberships: apiActions.fetchMemberships,
}

// Filter out the charter membership products from each category if the user does _not_ already have a membership
const maybeFilterCharterMembership = (products, charterId, hasCharter) => {
  if (hasCharter) return products

  return products.map((category) => {
    return {
      ...category,
      products: category.products.filter(
        (product) => product.centamanId !== charterId
      ),
    }
  })
}

const getMembershipsForUserType = ({
  loggedIn,
  allProductsByCategory,
  primaryMember,
}) => {
  if (!allProductsByCategory) return {}
  const DPA_CHARTER_MEMBERSHIP_ID = Number(
    process.env.DPA_CHARTER_MEMBERSHIP_ID
  )

  const hasCharter =
    loggedIn &&
    primaryMember.activeMemberships.some(
      (membership) => membership.packageId === DPA_CHARTER_MEMBERSHIP_ID
    )

  return {
    productsByCategory: maybeFilterCharterMembership(
      allProductsByCategory,
      DPA_CHARTER_MEMBERSHIP_ID,
      hasCharter
    ),
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  onMount('fetchMemberships'),
  modifyProps(getMembershipsForUserType),
  waitFor('productsByCategory')
)(SelectMembership)
