import PropTypes from 'prop-types'
import { compose, withState } from 'recompose'

const propTypes = {
  children: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  setExpanded: PropTypes.func.isRequired,
}

const defaultProps = {}

/**
 * This function is used to allow for a component to leverage the concept of having an expanded
 * section without requiring each individual component to implement internal expanded state. Unlike
 * most other HOCs used in this application, this expects a function supplied as its children.
 **/
function Expandable({ children, expanded, setExpanded }) {
  return children(expanded, setExpanded)
}

Expandable.propTypes = propTypes
Expandable.defaultProps = defaultProps

export default compose(
  withState(
    'expanded',
    'setExpanded',
    ({ startExpanded = false }) => startExpanded
  )
)(Expandable)
