import { get } from 'lodash'
import { compose, update, set } from 'lodash/fp'
import { format, isBefore, endOfMonth, isAfter } from 'date-fns'

/* Timed ticket cache helpers */

// Only save availability limit when it surpasses the old one
function saveAvailabilityRangeLimit(oldLimit, newLimit) {
  if (!oldLimit) return true
  return isAfter(newLimit, oldLimit)
}

// Set excluded dates for a product
export function setExcludedDates(cache, productId, excludedDates, endDate) {
  return updateEntry(cache, productId, (entry) => {
    return compose(
      // Add excluded dates to excluded dates array
      update('excludedDates', (oldExcludedDates = []) => [
        ...oldExcludedDates,
        ...excludedDates,
      ]),
      // Save the last date for which we've calculated availability
      update('availabilityRangeLimit', (oldLimit) => {
        const newLimit = format(endDate, 'YYYY-MM-DD')
        return saveAvailabilityRangeLimit(oldLimit, newLimit)
          ? newLimit
          : oldLimit
      })
    )(entry)
  })
}

// Set available times (offers) for a product
export function setTimes(cache, productId, times) {
  return updateEntry(cache, productId, (entry) => {
    return set('times', times, entry)
  })
}

// Set available prices (types) for a product
export function setPrices(cache, productId, prices) {
  return updateEntry(cache, productId, (entry) => {
    return set('prices', prices, entry)
  })
}

// Returns true if there are excluded dates in the cache for the given month
export function hasExcludedDatesForMonth(cache, productId, month) {
  const entry = getEntry(cache, productId)
  const availabilityRangeLimit = get(entry, 'availabilityRangeLimit')
  if (!availabilityRangeLimit) return false
  // If the end of the month is before the range limit, excluded dates have already been calculated
  return isBefore(endOfMonth(month), availabilityRangeLimit)
}

// Return excluded dates for a product
export function getExcludedDates(cache, productId) {
  const entry = getEntry(cache, productId)
  return entry.excludedDates || []
}

// Return available times for a product
export function getTimes(cache, productId) {
  const entry = getEntry(cache, productId)
  return entry.times
}

// Return available times for a product
export function getPrices(cache, productId) {
  const entry = getEntry(cache, productId)
  return entry.prices || []
}

// Update entry in the cache for a product
export function updateEntry(cache, productId, updaterFunc) {
  const entry = getEntry(cache, productId)
  return set(productId, updaterFunc(entry), cache)
}

// Get entry in the cache for a product
export function getEntry(cache, productId) {
  return get(cache, productId, {})
}
