import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as globalSelectors } from 'global-reducer'
import { selectors as memberSelectors } from './member/reducer'
import * as memberActions from './member/actions'
import { modifyProps, onMount } from 'lp-hoc'
import { getBrandingConfig, isDevelopment } from 'utils'
import GA from 'google-analytics'
import GTM from 'google-tag-manager'
import * as globalSiteTag from 'global-site-tag'
import { Helmet } from 'react-helmet'
import { NavBar, Footer, SkipNavLink, ReadMoreModal } from 'components'

const propTypes = {
  branding: PropTypes.object,
  children: PropTypes.node.isRequired,
  currentMember: PropTypes.object,
  footerLinks: PropTypes.arrayOf(Types.footerLink),
  loggedIn: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  organization: PropTypes.object,
  showResponsiveNav: PropTypes.bool,
}

const defaultProps = {
  branding: {
    title: 'Streamlined Checkout',
    navLinks: [
      { name: 'Tickets', path: '/all/tickets' },
      { name: 'Memberships', path: '/all/memberships' },
    ],
    homeUrl: '/',
    showLogin: true,
    pageTitle: 'Centaman | Streamlined Checkout',
    favicon: 'data:,',
  },
  currentMember: null,
  footerLinks: [],
  showResponsiveNav: false,
}

const getLogoutDisplay = (currentMember) => {
  if (!currentMember || !currentMember.primaryMember.firstName) return 'Logout'

  return (
    currentMember.primaryMember.firstName +
    ' ' +
    currentMember.primaryMember.lastName +
    ' | Logout'
  )
}

const showFooter = (footerLinks) => {
  return footerLinks && !!footerLinks.length
}

function Layout({
  organization,
  children,
  loggedIn,
  logout,
  currentMember,
  branding: {
    title,
    pageTitle,
    favicon,
    navLinks,
    showLogin,
    className,
    logo,
    homeUrl,
  },
  footerLinks,
  showResponsiveNav,
}) {
  return (
    <div className={className}>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="icon" href={favicon} />
      </Helmet>

      <SkipNavLink targetId="main-content">Skip to main content</SkipNavLink>

      <NavBar
        homeUrl={homeUrl}
        logo={logo || organization.logo?.url}
        title={title}
        showNav={showResponsiveNav}
        rightNavLinks={navLinks}
        showLogin={showLogin}
        loggedIn={loggedIn}
        logout={logout}
        logoutDisplay={getLogoutDisplay(currentMember)}
      />

      <main className="content" id="main-content">
        {children}
      </main>

      {showFooter(footerLinks) && <Footer links={footerLinks} />}

      <ReadMoreModal />
    </div>
  )
}

Layout.propTypes = propTypes
Layout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    config: globalSelectors.config(state),
    currentMember: memberSelectors.currentMember(state),
    footerLinks: globalSelectors.footerLinks(state),
    loggedIn: memberSelectors.loggedIn(state),
    organization: globalSelectors.organization(state),
    webstore: globalSelectors.webstore(state),
  }
}

const mapDispatchToProps = {
  logout: memberActions.logout,
}

const modify = ({ config, webstore }) => {
  const branding = getBrandingConfig(config, webstore)
  return {
    branding,
    showResponsiveNav:
      branding && (branding.showLogin || !!branding.navLinks.length), // only show hamburger menu when links are available
  }
}

const enableAnalyticsTracking = ({ config }) => {
  if (isDevelopment()) return

  // Attempt to initialize an org's analytics (if provided)
  const analytics = config.analytics || {}
  if (analytics.ga) GA.initialize(analytics.ga)
  if (analytics.gtm) GTM.initialize(analytics.gtm)
  if (analytics.gtag) globalSiteTag.initialize(analytics.gtag)

  // Always attempt to initialize LPL's GTM container
  const { LPL_GTM_CONTAINER_ID } = process.env
  if (LPL_GTM_CONTAINER_ID) GTM.initialize(LPL_GTM_CONTAINER_ID)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  modifyProps(modify),
  onMount(enableAnalyticsTracking)
)(Layout)
