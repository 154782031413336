import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

const defaultProps = {
  className: '',
}

function HighlightBox({ children, className }) {
  return (
    <div className={'info-box-highlight ' + className}>
      <div className="confirmation-info">{children}</div>
    </div>
  )
}

HighlightBox.propTypes = exact(propTypes)
HighlightBox.defaultProps = defaultProps

export default pure(HighlightBox)
