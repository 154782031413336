import React from 'react'
// import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'

const propTypes = {}

const defaultProps = {}

function Styleguide() {
  return (
    <div className="styleguide">
      <p> Put new components here. </p>
    </div>
  )
}

Styleguide.propTypes = propTypes

Styleguide.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Styleguide)
