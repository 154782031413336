import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { Field, FormSection } from 'redux-form'
import { modifyProps } from 'lp-hoc'
import { QuantityInput } from 'components'

const propTypes = {
  price: Types.price.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

const defaultProps = {}

function SelectAddOnPriceForm({ handleSubmit, price }) {
  return (
    <form onSubmit={handleSubmit}>
      <FormSection name="selectedAddOns">
        <Field
          key={price.id}
          aria-label={`Select ${price.displayname} amount`}
          name={`addOn-${price.centamanId}.quantity`}
          component={QuantityInput}
          itemName={price.displayName}
          itemDescription={price.details}
          price={price.price}
          tooltip={price.infoText}
          min={price.minQuantity}
          max={price.maxQuantity}
          format={(val) => {
            if (!val) return 0
            return val
          }}
        />
      </FormSection>
    </form>
  )
}

// Set initial values for ALL form fields, despite being broken up in UI (limitation of Add On design)
const setInitialFormValues = (addOns, currentSelections = []) => {
  let initialAddOnForm = {}
  // Set everything to zero by default
  addOns.forEach((addOn) => {
    initialAddOnForm[`addOn-${addOn.centamanId}`] = { quantity: 0 }
  })

  // Update quantities based on what exists in the cart
  currentSelections.forEach((selection) => {
    initialAddOnForm[`addOn-${selection.centamanId}`] = {
      quantity: selection.quantity,
    }
  })

  return { selectedAddOns: initialAddOnForm }
}

const modify = ({ onSubmit, addOns, currentSelections }) => {
  return {
    onSubmit: ({ selectedAddOns }) => {
      const selected = Object.keys(selectedAddOns).map((key) => ({
        centamanId: Number(key.replace('addOn-', '')),
        quantity: selectedAddOns[key].quantity,
      }))

      // Filters addOns from redux-form that have a quantity greater than 1 and then adds this value to the key of the existing add on object in state so that the API does not have to re-retrieve that information
      const addOnsToUpdate = addOns.reduce((acc, addOn) => {
        const formAddOn = selected.find(
          (selected) => selected.centamanId === addOn.centamanId
        )
        if (!formAddOn || formAddOn.quantity === 0) return [...acc]

        return [...acc, { ...addOn, quantity: formAddOn.quantity }]
      }, [])

      return onSubmit(addOnsToUpdate)
    },

    initialValues: setInitialFormValues(addOns, currentSelections),
  }
}

SelectAddOnPriceForm.propTypes = propTypes
SelectAddOnPriceForm.defaultProps = defaultProps

export default compose(
  modifyProps(modify),
  lpForm({
    name: 'ticketingForm',
    submitOnChange: true,
    destroyOnUnmount: false,
  })
)(SelectAddOnPriceForm)
