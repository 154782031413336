/**
 * Util for stripping out html tags and get the character length of just
 * the text within the provided html to determine if it will fit inside
 * a container, or instead need a way to expand/be diplayed in full in a modal.
 *
 * @name getHtmlTextLength
 * @param {String} html - Html string to measure (typically sent from API)
 * @returns {Number} - Returns a number for total amount of characters of just the text
 */

export default function getHtmlTextLength(html) {
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = html
  return tempDiv.textContent.length
}
