import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { modal } from 'lp-hoc'

const propTypes = {
  addMoreInstructions: PropTypes.string,
  baseMessage: PropTypes.string.isRequired,
  hasAddOnMemberships: PropTypes.bool.isRequired,
  handleHide: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
}

const defaultProps = {
  addMoreInstructions: '',
}

const getMessage = (baseMessage, addMoreInstructions, hasAddOnMemberships) => {
  if (!hasAddOnMemberships) return baseMessage

  return baseMessage + ' ' + addMoreInstructions
}

function SecondaryMembershipLimitModal({
  title,
  baseMessage,
  addMoreInstructions,
  handleHide,
  hasAddOnMemberships,
}) {
  return (
    <div>
      <button onClick={handleHide} className="modal-close">
        ×
      </button>
      <h2>{title}</h2>
      <p>{getMessage(baseMessage, addMoreInstructions, hasAddOnMemberships)}</p>
      <button type="button" className="button-primary" onClick={handleHide}>
        Okay
      </button>
    </div>
  )
}

SecondaryMembershipLimitModal.propTypes = propTypes
SecondaryMembershipLimitModal.defaultProps = defaultProps

export default compose(modal({ name: 'secondary-membership-limit' }))(
  SecondaryMembershipLimitModal
)
