import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import * as actions from './actions'
import { connectParams } from 'lp-hoc'
import { Spinner } from 'lp-components'
import { StepNav } from 'components'
import * as Types from 'types'
import { selectors } from './reducer'
import { isNil } from 'lodash'

const propTypes = {
  children: PropTypes.node,
  steps: PropTypes.arrayOf(Types.step).isRequired,
  currentStepIndex: PropTypes.number,
  fetchCart: PropTypes.func.isRequired,
  fetchMembership: PropTypes.func.isRequired,
  setSelectedMembership: PropTypes.func.isRequired,
  setSelectedMembershipId: PropTypes.func.isRequired,
  membershipIdParam: PropTypes.number,
  selectedMembershipId: PropTypes.number,
  cart: Types.cart,
}

const defaultProps = {
  currentStepIndex: null,
}

function StepLayout({
  children,
  steps,
  currentStepIndex,
  fetchCart,
  cart,
  fetchMembership,
  selectedMembershipId,
  membershipIdParam,
  setSelectedMembershipId,
  setSelectedMembership,
}) {
  // fetch cart on mount.
  useEffect(() => {
    // on mount - clear data in redux and fetch cart data
    setSelectedMembership(null)
    setSelectedMembershipId(null)
    fetchCart().then((cart) => {
      // If membershipId param is not present, set it from the cart
      const membershipId = membershipIdParam || cart.productId
      setSelectedMembershipId(membershipId)
    })
    // on unmount - clear data in redux
    return function cleanup() {
      setSelectedMembership(null)
      setSelectedMembershipId(null)
    }
  }, [])
  useEffect(() => {
    // We need a cancelled flag to avoid setting state after unmount (https://juliangaramendy.dev/use-promise-subscription/)
    let cancelled = false
    // Fetch new membership info whenever membership ID changes
    if (selectedMembershipId)
      fetchMembership(selectedMembershipId).then((membership) => {
        if (!cancelled) setSelectedMembership(membership)
      })
    return function cleanup() {
      cancelled = true
    }
  }, [selectedMembershipId])
  if (!cart) return <Spinner />
  return (
    <div>
      {!isNil(currentStepIndex) && (
        <StepNav steps={steps} currentStepIndex={currentStepIndex} />
      )}
      {children}
    </div>
  )
}

StepLayout.propTypes = propTypes
StepLayout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    cart: selectors.cart(state),
    steps: selectors.steps(state),
    currentStepIndex: selectors.currentStepIndex(state),
    selectedMembershipId: selectors.selectedMembershipId(state),
  }
}

const mapDispatchToProps = {
  fetchCart: apiActions.fetchMembershipCart,
  fetchMembership: apiActions.fetchMembership,
  setSelectedMembershipId: actions.setSelectedMembershipId,
  setSelectedMembership: actions.setSelectedMembership,
}

export default compose(
  connectParams(({ membershipId }) => ({ membershipIdParam: membershipId })),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(StepLayout)
