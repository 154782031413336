/**
 * A similar function to date-fns' isAfter that ignores timestamps. Uses date-fns under the hood.
 *
 * @name isAfterDay
 * @param {Date|String} date - The date to compare
 * @param {Date|String} [dateToCompare=new Date()] - The date that is getting compared. Defaults to today
 * @returns {Boolean} - Returns true if the first date is after the second one
 **/
import { differenceInDays } from 'date-fns'

export default function isAfterDay(date, dateToCompare = new Date()) {
  return differenceInDays(date, dateToCompare) > 0
}
