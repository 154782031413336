import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import classnames from 'classnames'

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hideIcon: PropTypes.bool,
  title: PropTypes.string,
}

const defaultProps = {
  className: '',
  hideIcon: false,
  title: 'Information',
}

function InfoBox({ children, className, hideIcon, title }) {
  return (
    <div className={classnames('information-box', className)}>
      {!hideIcon && (
        <img
          className="info-icon"
          src="https://i.postimg.cc/3xZC9qKq/info-icon.png"
          alt=""
          aria-hidden="true"
        />
      )}
      <h2>{title}</h2>
      <div>{children}</div>
    </div>
  )
}

InfoBox.propTypes = exact(propTypes)
InfoBox.defaultProps = defaultProps

export default pure(InfoBox)
