import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { createDisplayOrgText, scrollToHash, routerSelectors } from 'utils'
import { selectors } from '../reducer'
import { selectors as globalSelectors } from 'global-reducer'
import * as apiActions from 'api-actions'
import * as actions from '../actions'
import { modifyProps, onMount, waitFor } from 'lp-hoc'
import { Spinner } from 'lp-components'
import classnames from 'classnames'
import { kebabCase } from 'lodash'
import {
  HorizontalCard,
  MainHeader,
  ProductEmptyState,
  RenderedHTML,
  SectionHeader,
  ReadMoreModal,
} from 'components'
import { displayKeys } from 'config'
import { Link } from 'react-router'
import * as LS from 'local-storage'
import { selectors as memberSelectors } from 'base/member/reducer'

const propTypes = {
  children: PropTypes.node,
  displayOrgText: PropTypes.func.isRequired,
  productsByCategory: PropTypes.arrayOf(Types.productCategory).isRequired,
  webstore: PropTypes.string.isRequired,
  hasMemberDiscounts: PropTypes.bool,
  loggedIn: PropTypes.bool.isRequired,
  currentPath: PropTypes.string.isRequired,
  showReadMoreModal: PropTypes.func.isRequired,
}

const defaultProps = {
  children: null,
  hasMemberDiscounts: false,
}

// Only return a generic header title if there are _multiple_ categories (supports backwards compatability for existing customers)
const getGenericHeader = (categories) => {
  if (categories.length === 1) return null
  return 'Events'
}

function SelectEvent({
  children,
  displayOrgText,
  productsByCategory,
  webstore,
  hasMemberDiscounts,
  loggedIn,
  currentPath,
  showReadMoreModal,
}) {
  if (!productsByCategory.length)
    return (
      <ProductEmptyState title="Events">
        <p>Sorry, there are no events available at this time.</p>
      </ProductEmptyState>
    )

  return (
    <div className="step-container">
      <MainHeader
        title={displayOrgText(displayKeys.SELECT_EVENT)}
        disablePrevStep
      >
        <p>{displayOrgText(displayKeys.SELECT_TICKET_INSTRUCTIONS)}</p>
        {hasMemberDiscounts && !loggedIn && (
          <p>
            If you are a member,{' '}
            <Link
              to={{
                pathname: '/member/login',
                state: { redirectUrl: currentPath },
              }}
            >
              log in
            </Link>{' '}
            for price discounts.
          </p>
        )}
      </MainHeader>
      <div className="row">
        <div className="col-12">
          {productsByCategory.map((category, idx) => {
            const categoryTitle =
              category.name || getGenericHeader(productsByCategory)
            return (
              <div key={category.name || idx}>
                {!!categoryTitle && (
                  <SectionHeader
                    id={kebabCase(categoryTitle)}
                    title={categoryTitle}
                  >
                    {!!category.subtitle && (
                      <RenderedHTML>{category.subtitle}</RenderedHTML>
                    )}
                  </SectionHeader>
                )}
                {category.products.map((product) => (
                  <div
                    key={product.id}
                    className={classnames('horizontal-item', {
                      'item-highlighted': product.featured,
                    })}
                  >
                    <HorizontalCard
                      imageUrl={product.productImage}
                      path={`/${webstore}/tickets/details/${product.id}`}
                      displayName={product.displayName}
                      price={product.amount}
                      descriptionHtml={product.descriptionHtml}
                      buttonText={product.selectButtonText || 'Select Item'}
                      learnMoreLink={product.learnMoreLink}
                      showReadMoreModal={showReadMoreModal}
                    />
                  </div>
                ))}
              </div>
            )
          })}
          {children}
          <div className="org-info">
            <RenderedHTML>
              {displayOrgText(displayKeys.TICKET_SELECTION_FOOTER_INSTRUCTIONS)}
            </RenderedHTML>
          </div>
        </div>
      </div>
    </div>
  )
}

SelectEvent.propTypes = propTypes
SelectEvent.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    config: globalSelectors.config(state),
    productsByCategory: selectors.productsByCategory(state),
    webstore: globalSelectors.webstore(state),
    currentPath: routerSelectors.currentPath(state),
    loggedIn: memberSelectors.loggedIn(state),
  }
}

const mapDispatchToProps = {
  emptyCart: actions.emptyCart,
  clearSelectedTicket: actions.clearSelectedTicket,
  clearProducts: actions.clearProducts,
  fetchProducts: () =>
    apiActions.fetchProducts({ navigationCategory: Types.EVENTS_NAV_CATEGORY }),
  showReadMoreModal: ReadMoreModal.show,
}

const initializeOrgHelpers = ({ config }) => {
  return {
    displayOrgText: createDisplayOrgText(config),
    hasMemberDiscounts: config.HAS_MEMBER_DISCOUNTS,
  }
}

// Reset prior selections if returning to page
function resetSelections({ emptyCart, clearSelectedTicket }) {
  LS.clearCartToken()
  clearSelectedTicket()
  emptyCart()
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  modifyProps(initializeOrgHelpers),
  onMount('clearProducts'),
  onMount('fetchProducts'),
  onMount(resetSelections),
  waitFor('productsByCategory', Spinner),
  onMount(scrollToHash)
)(SelectEvent)
