import { applyMiddleware, compose, createStore, combineReducers } from 'redux'
import { routerReducer, routerMiddleware } from 'react-router-redux'
import { browserHistory } from 'react-router'
import thunkMiddleware from 'redux-thunk'
import { reducer as formReducer } from 'redux-form'
import { reducer as modalReducer } from 'redux-modal'
import { createMiddleware as createApiMiddleware } from 'api'
import { reducer as apiReducer } from 'lp-redux-api'
import {
  reducer as flashReducer,
  middleware as flashMiddleware,
} from 'redux-flash'
import { reducer as rootReducer, reducerKey as rootKey } from './reducer'
import * as globalActions from 'global-actions'
import { isDevelopment } from 'utils'

function initializeStore({ orgSlug, orgConfig }) {
  // Combine the reducers into one that Redux can handle.
  // The keys below are important, as data in the store will be namespaced by them
  // and each reducer only receives the slice of state according to the key.
  const reducer = combineReducers({
    form: formReducer,
    modal: modalReducer,
    [rootKey]: rootReducer,
    routing: routerReducer,
    api: apiReducer,
    flash: flashReducer,
  })
  // Add support for the Redux Dev Tools in chrome.
  const devToolCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const composeEnhancers = isDevelopment() ? devToolCompose : compose
  // Configure flash options globally
  const flashOptions = { timeout: false }
  // Combine the middlewares into one so Redux can handle
  const enhancers = composeEnhancers(
    applyMiddleware(
      createApiMiddleware({ orgSlug }),
      thunkMiddleware,
      flashMiddleware(flashOptions),
      routerMiddleware(browserHistory)
    )
  )
  const initialState = {}
  const store = createStore(reducer, initialState, enhancers)
  // If inital orgConfig is provided, set it in the state
  if (orgConfig) store.dispatch(globalActions.setOrgConfig(orgConfig))
  // Make store available globally
  window.store = store
  return store
}

export default initializeStore
