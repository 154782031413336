import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { SelectDonationType as BaseSelectDonationType } from 'base/donation/views'
import mysticDonateImage from 'images/background/mystic-other-ways-to-give.jpg'

const propTypes = {}

const defaultProps = {}

function SelectDonationType() {
  return (
    <BaseSelectDonationType>
      <div className="horizontal-item" id="donation-link-container">
        <div className="left-content" id="donation-link-select">
          <div className="image">
            <img src={mysticDonateImage} alt="" />
          </div>
          <a
            className="button-primary"
            href="https://www.mysticseaport.org/support/ways-to-give"
          >
            Select Item
          </a>
        </div>
        <div className="right-content" id="donation-link-copy">
          <div className="right-content-overflow">
            <h3>Other Ways to Give</h3>
            <div className="item-copy" id="donation-links">
              <p>
                There are many ways to give, both large and small, and we invite
                you to join us in our mission to foster an enduring connection
                with America’s maritime heritage. For more information please
                click on the links below or call 860-572-5365. Thank you for
                your generosity!
              </p>
              <ul>
                <li>
                  <a href="https://mysticseaport.makeaplannedgift.org/appreciated-securities">
                    Stock
                  </a>
                </li>
                <li>
                  <a href="https://mysticseaport.makeaplannedgift.org/give-from-your-ira">
                    IRA QCD Charitable Rollover Donations
                  </a>
                </li>
                <li>
                  <a href="https://www.mysticseaport.org/support/ways-to-give">
                    Gifts in Kind
                  </a>
                </li>
                <li>
                  <a href="https://mysticseaport.makeaplannedgift.org/real-estate">
                    Real Estate
                  </a>
                </li>
                <li>
                  <a href="https://mysticseaport.makeaplannedgift.org/">
                    Planned Giving
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </BaseSelectDonationType>
  )
}

SelectDonationType.propTypes = propTypes
SelectDonationType.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SelectDonationType)
