/**
 * @name padMembersArray
 * @param {Array} includedMembers - Members to include by default
 * @param {Array} secondarysecondaryMembershipTypes - Types of secondary membership available
 * @param {Number} primaryMembershipTypeId - Primary membership ID
 * @param {Boolean} hasMembersRemaining - Flag indicating if there are users available on the account to select
 * @returns {Array} - Returns an array of members padded with dummy values (if needed) to meet the minimum quantity requirements for each membership type (subtracting the primary if it is the same as a secondary option). Once the number of members added is greater than the number available to choose from, padded members will automatically be set to "new pass holder" and require member information.
 */

import splitBy from './splitBy'
import { rightPadArray } from './trimArray'
import { set } from 'lodash/fp'
import { NEW_MEMBERSHIP_ID } from '../config'

function padMembersArray(
  includedMembers,
  secondaryMembershipTypes,
  primaryMembershipTypeId,
  hasMembersRemaining
) {
  const updatedMinSecondaryMembershipTypes = secondaryMembershipTypes.map(
    (secondaryMembershipType) => {
      // If primary membership type is also being used for secondary, reduce the min quantity to account for that already being created
      if (
        secondaryMembershipType.id === primaryMembershipTypeId &&
        secondaryMembershipType.minQuantity > 0
      ) {
        const minQuantity = secondaryMembershipType.minQuantity - 1
        return { ...secondaryMembershipType, minQuantity }
      }
      return secondaryMembershipType
    }
  )

  const paddedMembers = updatedMinSecondaryMembershipTypes.reduce(
    padMembersForMembershipType,
    includedMembers
  )

  return hasMembersRemaining
    ? paddedMembers
    : paddedMembers.map(preselectNewMember)
}

// ----- PRIVATE -----

function padMembersForMembershipType(members, secondaryMembershipType) {
  const [start, matchingMembers, end] = splitBy(members, (member) => {
    return member.membershipTypeId === secondaryMembershipType.id
  })
  const newMember = { membershipTypeId: secondaryMembershipType.id }

  const paddedMembers = rightPadArray(
    matchingMembers,
    secondaryMembershipType.minQuantity,
    newMember
  )

  return [...start, ...paddedMembers, ...end]
}

function preselectNewMember(member) {
  if (member.id) return member
  return set('id', NEW_MEMBERSHIP_ID, member)
}

export default padMembersArray
