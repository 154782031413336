import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import * as Types from 'types'

const propTypes = {
  steps: PropTypes.arrayOf(Types.step).isRequired,
  currentStepIndex: PropTypes.number.isRequired,
}

const defaultProps = {}

const isCurrentStep = (index, currentStepIndex) => index === currentStepIndex
const isPreviousStep = (index, currentStepIndex) => currentStepIndex > index
const isLastCompleteStep = (index, currentStepIndex) =>
  currentStepIndex - index === 1

function StepNav({ steps, currentStepIndex }) {
  const currentStep = currentStepIndex + 1
  const maxSteps = steps.length

  return (
    <div
      className="steps-block"
      role="progressbar"
      aria-valuemin="1"
      aria-valuemax={maxSteps}
      aria-valuenow={currentStep}
      aria-valuetext={`Step ${currentStep} of ${maxSteps}: ${steps[currentStepIndex].name}`}
    >
      <ul className="steps">
        {steps.map((step, i) => (
          <li
            key={step.name}
            className={classnames({
              'is-active': isCurrentStep(i, currentStepIndex),
              'is-complete': isPreviousStep(i, currentStepIndex),
              last: isLastCompleteStep(i, currentStepIndex),
            })}
          >
            <span />
            <p>{step.name}</p>
          </li>
        ))}
      </ul>
    </div>
  )
}

StepNav.propTypes = propTypes
StepNav.defaultProps = defaultProps

export default StepNav
