/**
 * Factory for payment provider implementations. Each implementation is expected to adhere to the following interface methods:
 * 1. init()
 * 2. submitPaymentDetails()
 */
import { spreedly } from 'utils'

// List of Providers
const SPREEDLY = 'spreedly'

// Hash of provider names and implementations
const providerLookup = {
  [SPREEDLY]: spreedly,
}

// Provider Factory
export const getPaymentProcessor = (provider = SPREEDLY) =>
  providerLookup[provider]
