/**
 * @name createDisplayOrg
 * @param {Object} orgConfig - An object representing configuration details specific to the current Org
 * @returns {Function} - Returns a function that can be used to interpolate data properties for a given display key (supports dot notation for nested paths)
 */

/* eslint-disable no-console */
import { get, template, templateSettings } from 'lodash'

// Set the template to interpolate variables between two brackets (a la mustache)
templateSettings.interpolate = /{{([\s\S]+?)}}/g

// Returns interpolated string
function createDisplayOrgText(orgConfig) {
  const displayText = orgConfig.displayText
  if (!validConfiguration(displayText)) return () => ''

  return function displayOrgText(key, values) {
    if (!key) console.warn('WARNING: an undefined display key was used.')
    return template(get(displayText, key, ''))(values)
  }
}

// ------- HELPERS -------

function validConfiguration(displayText) {
  const valid = displayText && Object.keys(displayText).length > 0
  if (!valid) {
    console.warn(
      'WARNING: No display keys have been defined for this organization. By default, org specific text will not be loaded throughout the application.'
    )
  }

  return valid
}

export default createDisplayOrgText
