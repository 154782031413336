import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import { MainHeader, Receipt, StickyContainer, RenderedHTML } from 'components'
import { selectors as baseSelectors } from 'base/donation/reducer'
import { selectors as globalSelectors } from 'global-reducer'
import { Spinner } from 'lp-components'
import { selectors as apiSelectors } from 'lp-redux-api'
import { connectParams, modifyProps } from 'lp-hoc'
import { flashInvalidFormSubmitMessage, redirectUnless, setIf } from 'utils'
import * as routerActions from 'react-router-redux'
import * as flashActions from 'redux-flash'
import { DonationForm as CustomDPADonationForm } from '../forms'
import { DonationForm as StandardDonationForm } from '../../../base/donation/forms'
import { debounce, first } from 'lodash'

const propTypes = {
  addDonationTypeToCart: PropTypes.func.isRequired,
  cart: Types.cart,
  donationCommentText: PropTypes.string,
  fetchDonationProduct: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  clearMessages: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  isCartLoading: PropTypes.bool.isRequired,
  replace: PropTypes.func.isRequired,
  selectedDonation: Types.donation,
  stepName: PropTypes.string.isRequired,
  updateDonationCart: PropTypes.func.isRequired,
  webstore: PropTypes.string.isRequired,
  moveToNextStep: PropTypes.func.isRequired,
  orgConfig: PropTypes.object,
}

const defaultProps = {
  selectedDonation: null,
  donationCommentText: '',
}

// Helper for forcing separate boolean flags into a single radio input value
const getInitialTypeValue = ({ honorOfDonation, memoryOfDonation } = {}) => {
  if (honorOfDonation) return 'honorOfDonation'
  if (memoryOfDonation) return 'memoryOfDonation'
  return ''
}

// Made an array in case there are multiple funds where "in Memory/Honor of" applies
const inMemoryHonorOfDonationFundIds = [process.env.DPA_IN_MEMORY_DONATION_ID]

function showInMemoryHonorOfForm(selectedDonationId) {
  return inMemoryHonorOfDonationFundIds.includes(selectedDonationId)
}

function DonationDetails({
  addDonationTypeToCart,
  cart,
  donationCommentText,
  fetchDonationProduct,
  flashErrorMessage,
  clearMessages,
  id,
  isCartLoading,
  replace,
  selectedDonation,
  stepName,
  updateDonationCart,
  webstore,
  moveToNextStep,
  orgConfig,
}) {
  useEffect(() => {
    async function selectDonation() {
      try {
        const donation = await fetchDonationProduct(id)
        if (cart.productId === donation.id) return

        addDonationTypeToCart(donation)
      } catch (e) {
        flashErrorMessage(
          'Donation type unavailable. Please select a type below',
          { timeout: 6000 }
        )
        replace(`/${webstore}/donate`)
      }
    }
    selectDonation()
  }, [id])

  if (!selectedDonation || selectedDonation.id !== id) return <Spinner />

  return (
    <div className="step-container">
      <MainHeader title={stepName} />
      <div className="row">
        <div className="col-8">
          {selectedDonation.introHeading && (
            <h2>{selectedDonation.introHeading}</h2>
          )}
          {selectedDonation.introParagraph && (
            <RenderedHTML>{selectedDonation.introParagraph}</RenderedHTML>
          )}
          {showInMemoryHonorOfForm(selectedDonation.id) ? (
            <React.Fragment>
              <p>
                If this is an honor or memory gift, you will be asked to share
                that information below. When completing the contact information,
                please type the name as you want it to appear in the
                acknowledgement that is sent to the family. (Example:&nbsp; John
                Doe&nbsp; /&nbsp; Mr. And Mrs. John Doe&nbsp; /&nbsp; May and
                John Doe&nbsp; /&nbsp; The Doe Family)
              </p>

              <CustomDPADonationForm
                prices={selectedDonation.prices}
                initialValues={{
                  price: first(cart.priceSelections), // assumes only 1 donation can be added per cart
                  donationUdfs: {
                    receiverCountry: orgConfig.DEFAULT_COUNTRY,
                    ...cart.donationUdfs,
                    type: getInitialTypeValue(cart.donationUdfs),
                  },
                }}
                onSubmit={debounce(
                  ({ price, donationUdfs: { type, ...udfs } = {} }) => {
                    clearMessages()
                    // Clear selection if there is no price (i.e., "Different Amount" is selected)
                    if (!price.price)
                      return updateDonationCart({ priceSelections: [] })

                    const cartUpdates = setIf(!!type, 'donationUdfs', {
                      ...udfs,
                      honorOfDonation: type === 'honorOfDonation',
                      memoryOfDonation: type === 'memoryOfDonation',
                    })({ priceSelections: [{ ...price, quantity: 1 }] })

                    return updateDonationCart(cartUpdates)
                  },
                  500
                )}
                onSubmitSuccess={moveToNextStep}
                onSubmitFail={flashInvalidFormSubmitMessage}
              />
            </React.Fragment>
          ) : (
            <StandardDonationForm
              prices={selectedDonation.prices}
              commentText={donationCommentText}
              initialValues={{
                price: first(cart.priceSelections),
              }}
              onSubmit={debounce(({ price, ...fields }) => {
                clearMessages()
                if (!price.price)
                  return updateDonationCart({ priceSelections: [] })

                return updateDonationCart({
                  ...fields,
                  priceSelections: [{ ...price, quantity: 1 }],
                })
              }, 500)}
              onSubmitSuccess={moveToNextStep}
              onSubmitFail={flashInvalidFormSubmitMessage}
            />
          )}
        </div>
        <StickyContainer className="col-4">
          <Receipt
            title={selectedDonation.displayName}
            cart={cart}
            image={selectedDonation.image}
            isCartLoading={isCartLoading}
            displayTotal
          />
        </StickyContainer>
      </div>
    </div>
  )
}

DonationDetails.propTypes = propTypes
DonationDetails.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    cart: baseSelectors.cart(state),
    donationCommentText: globalSelectors.donationCommentText(state),
    isCartLoading: apiSelectors.isLoading(state, apiActions.updateDonationCart),
    nextStepPath: baseSelectors.nextStepPath(state),
    selectedDonation: baseSelectors.selectedDonation(state),
    stepName: baseSelectors.currentStepName(state),
    webstore: globalSelectors.webstore(state),
    orgConfig: globalSelectors.config(state),
  }
}

const mapDispatchToProps = {
  addDonationTypeToCart: apiActions.addDonationTypeToCart,
  fetchDonationProduct: apiActions.fetchDonationProduct,
  flashErrorMessage: flashActions.flashErrorMessage,
  clearMessages: flashActions.clearMessages,
  push: routerActions.push,
  replace: routerActions.replace,
  updateDonationCart: apiActions.updateDonationCart,
}

const modify = ({ push, webstore, nextStepPath }) => {
  return {
    moveToNextStep: () => push(`/${webstore}/donate/${nextStepPath}`),
  }
}

export default compose(
  connectParams('id'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  modifyProps(modify),
  redirectUnless('id')
)(DonationDetails)
