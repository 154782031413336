import Raven from 'raven-js'
import { isProduction } from 'utils'

const { NODE_ENV, SENTRY_ENV, SENTRY_URL } = process.env

/* eslint-disable no-console */

function init() {
  if (Raven.isSetup()) return
  if (!SENTRY_URL)
    return console.warn(
      'WARNING: Sentry not configured. "SENTRY_URL" environment variable must be defined.'
    )
  try {
    Raven.config(SENTRY_URL, {
      environment: SENTRY_ENV || NODE_ENV,
      shouldSendCallback: isProduction,
    }).install()
  } catch (e) {
    console.warn(`WARNING: Sentry intialization failed: ${e}`)
  }
}

init()

export function logException(err, context) {
  if (!Raven.isSetup()) return
  return Raven.captureException(err, { extra: context })
}
