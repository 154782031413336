import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import { QuantityInput } from 'components'
import * as Types from 'types'
import {
  setIf,
  getMembershipTypeQuantity,
  getAvailableMembersForMembershipType,
} from 'utils'
import { get, findLastIndex, range } from 'lodash'
import { NEW_MEMBERSHIP_ID } from 'config'

const propTypes = {
  addMember: PropTypes.func.isRequired,
  availableMembers: PropTypes.array.isRequired,
  members: PropTypes.arrayOf(PropTypes.object),
  membershipTypes: PropTypes.arrayOf(Types.membershipType),
  primaryMember: PropTypes.object,
  onExceedMax: PropTypes.func.isRequired,
  removeMember: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
}

const defaultProps = {
  primaryMember: null,
}

// If there are no available users to select from, default the member selection as "new"
function createNewMembers(membershipType, quantity, hasAvailableMembers) {
  return range(quantity).fill(
    setIf(!hasAvailableMembers, 'id', NEW_MEMBERSHIP_ID)({
      membershipTypeId: membershipType.id,
    })
  )
}

// Locates the last existing member OR the index at the end of the array
function findIndexToModify({ members, membershipTypeId, isRemoving }) {
  const lastIndex = findLastIndex(members, [
    'membershipTypeId',
    membershipTypeId,
  ])
  if (lastIndex < 0) return members.length

  // For adding members, the index to modify comes _after_ the latest member
  return isRemoving ? lastIndex : lastIndex + 1
}

// Include the primary member's membership in the total calculation for min/max validations of secondary members
function getMembershipTypeConstraints(membershipType, primaryMembershipTypeId) {
  if (membershipType.id !== primaryMembershipTypeId) return membershipType

  const { minQuantity, maxQuantity } = membershipType
  return {
    // subtract the primary from the total min
    minQuantity: minQuantity > 0 ? minQuantity - 1 : 0,
    // subtract the primary from the total max
    maxQuantity: maxQuantity > 0 ? maxQuantity - 1 : Infinity,
  }
}

/*
 * Fields for modifying an array of members with membership type quantities
 *
 * Note: Using a registered field with the same name as a FieldArray does not
 * allow for in-line field validations.
 */
function MembershipTypeQuantityInputs({
  addMember,
  availableMembers,
  members,
  membershipTypes,
  primaryMember,
  removeMember,
  onExceedMax,
  startDate,
}) {
  return (
    <div className="select-item-group-grid">
      {membershipTypes.map((membershipType) => {
        const value = getMembershipTypeQuantity(members, membershipType)
        const { minQuantity, maxQuantity } = getMembershipTypeConstraints(
          membershipType,
          get(primaryMember, 'membershipTypeId')
        )
        const availableMembersForMembershipType = getAvailableMembersForMembershipType(
          availableMembers,
          membershipType,
          startDate
        )
        const hasAvailableMembers =
          availableMembersForMembershipType &&
          !!availableMembersForMembershipType.length

        return (
          <QuantityInput
            key={membershipType.id}
            min={minQuantity}
            max={maxQuantity}
            itemName={membershipType.displayName}
            itemDescription={membershipType.addOn ? '' : membershipType.details} // Do not display price details for add-ons
            onExceedMax={() => onExceedMax(membershipType)}
            tooltip={membershipType.infoText}
            input={{
              value,
              onChange: (quantity) => {
                // No change, do nothing
                if (quantity === value) return

                const numMembersToChange = quantity - value
                const indexToModify = findIndexToModify({
                  members,
                  membershipTypeId: membershipType.id,
                  isRemoving: numMembersToChange < 0,
                })

                // Add new member(s), starting from the index _after_ the last existing member (or the end of the array)
                if (numMembersToChange > 0) {
                  return createNewMembers(
                    membershipType,
                    numMembersToChange,
                    hasAvailableMembers
                  ).forEach((newMember, relativePosition) => {
                    addMember(indexToModify + relativePosition, newMember)
                  })
                }

                // Otherwise delete member(s), starting from the last index and counting _down_
                // e.g. range(-2) => [0, -1]
                return range(numMembersToChange).forEach((relativePosition) => {
                  removeMember(indexToModify + relativePosition)
                })
              },
            }}
          />
        )
      })}
    </div>
  )
}

MembershipTypeQuantityInputs.propTypes = propTypes
MembershipTypeQuantityInputs.defaultProps = defaultProps

export default pure(MembershipTypeQuantityInputs)
