/**
 * @name getProductCategories
 * @param {Array} products - Takes an array of products (ticketing, memberships, etc.)
 * @returns {Array|undefined} - Returns unique categories (if products exist)
 */

import { uniqBy } from 'lodash'

function getProductCategories(products) {
  const categories = products.map((product) => {
    return {
      name: product.categoryName,
      subtitle: product.categorySubtitle,
      position: product.categoryPosition,
    }
  })

  return uniqBy(categories, 'name')
}

export default getProductCategories
