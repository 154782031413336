import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { Field, FormSection, propTypes as formPropTypes } from 'redux-form'
import { ButtonArea, Input, InputError, SubmitButton } from 'lp-components'
import { lpForm } from 'lp-form'
import { displayKeys } from 'config'
import { createFieldValidator } from 'utils'

const propTypes = {
  ...formPropTypes,
  onForgot: PropTypes.func.isRequired,
}

const defaultProps = {}

const validateLastName = createFieldValidator('member.lastName', {
  presence: true,
})
const validateMemberNumber = createFieldValidator('member.memberNumber', {
  presence: true,
  numericality: true,
})

function MemberNumLoginForm({
  handleSubmit,
  submitting,
  displayOrgText,
  error,
  onForgot,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <FormSection name="member">
        <Field
          name="memberNumber"
          component={Input}
          label={displayOrgText(displayKeys.MEMBER_ID)}
          validate={validateMemberNumber}
        />
        <Field
          name="lastName"
          component={Input}
          label={displayOrgText(displayKeys.LAST_NAME)}
          validate={validateLastName}
        />
      </FormSection>
      <InputError className="formwide-error" error={error} invalid touched />
      <ButtonArea>
        <SubmitButton submitting={submitting}>Login</SubmitButton>
        <a role="button" className="link-primary" onClick={onForgot}>
          {displayOrgText(displayKeys.FORGOT_MEMBER_ID)}
        </a>
      </ButtonArea>
    </form>
  )
}

MemberNumLoginForm.propTypes = propTypes
MemberNumLoginForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'member-num-login',
  })
)(MemberNumLoginForm)
