import { handleActions } from 'redux-actions'
import { selectorForSlice, setState } from 'lp-redux-utils'
import { setOnSuccess } from 'lp-redux-api'
import * as apiActions from 'api-actions'
import * as actions from './actions'
import { createSelector } from 'reselect'
import { selectors as globalSelectors } from 'global-reducer'
import * as LS from 'local-storage'
import { routerSelectors } from 'utils'

const reducerKey = 'giftMemberships'
const slice = 'root.giftMemberships'

const initialCartState = {
  listItems: [],
  priceSelections: [],
  addOnSelections: [],
}

const initialState = {}

const reducer = handleActions(
  {
    [apiActions.updateGiftMembershipPriceSelections]: setOnSuccess(
      'cart',
      ({ payload: { data } }) => {
        LS.setCartToken(data.additionalData.cartToken)
        return data.attributes
      }
    ),
    [apiActions.updateGiftMembershipCart]: setOnSuccess(
      'cart',
      ({ payload: { data } }) => {
        LS.setCartToken(data.additionalData.cartToken)
        return data.attributes
      }
    ),
    [apiActions.fetchGiftMembershipCart]: setOnSuccess('cart'),
    [apiActions.fetchGiftMemberships]: setOnSuccess('giftMemberships'),
    [apiActions.addOrRemoveModalProduct]: setOnSuccess('cart'),
    [actions.emptyCart]: setState('cart', initialCartState),
    [apiActions.createGiftMembershipOrder]: setOnSuccess('orderConfirmation'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  giftMemberships: select('giftMemberships'),
  orderConfirmation: select('orderConfirmation'),
  cart: select('cart'),
}

// Extend this to compute conditional steps (e.g., add-ons)
selectors.steps = createSelector(
  [globalSelectors.giftMembershipSteps],
  function(giftMembershipSteps) {
    return giftMembershipSteps
  }
)

selectors.currentStepIndex = createSelector(
  [selectors.steps, routerSelectors.pathname],
  function(steps, pathname) {
    return steps.findIndex((step) => pathname.includes(step.route))
  }
)

selectors.currentStep = createSelector(
  [selectors.steps, selectors.currentStepIndex],
  function(steps, currentStepIndex) {
    return steps[currentStepIndex]
  }
)

selectors.currentStepName = createSelector(
  [selectors.currentStep],
  function(currentStep) {
    return currentStep ? currentStep.name : ''
  }
)

selectors.nextStep = createSelector(
  [selectors.steps, selectors.currentStepIndex],
  function(steps, currentStepIndex) {
    return steps[currentStepIndex + 1]
  }
)

selectors.nextStepPath = createSelector(
  [selectors.nextStep],
  function(nextStep) {
    return nextStep ? nextStep.route : 'confirmation'
  }
)

export { reducer, selectors, reducerKey }
