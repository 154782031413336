import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { modifyProps, onMount, waitFor } from 'lp-hoc'
import * as actions from '../actions'
import {
  CouponCode,
  StickyContainer,
  MainHeader,
  Receipt,
  AddOnProduct,
} from 'components'
import { BillingDetailsForm } from 'forms'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import * as routerActions from 'react-router-redux'
import { getPaymentProcessor } from 'payment'
import { selectors as globalSelectors } from 'global-reducer'
import { Spinner, Modal } from 'lp-components'
import * as LS from 'local-storage'
import { flashInvalidFormSubmitMessage, mapAdditionalCartFields } from 'utils'
import * as memberActions from '../../member/actions'
import { get, includes } from 'lodash/fp'
import GTM from 'google-tag-manager'
import { clearMessages } from 'redux-flash'

const propTypes = {
  cart: PropTypes.object.isRequired,
  emptyMembershipCart: PropTypes.func.isRequired,
  fetchMembershipAccount: PropTypes.func.isRequired,
  webstore: PropTypes.string.isRequired,
  nextStepPath: PropTypes.string.isRequired,
  submitOrder: PropTypes.func.isRequired,
  replaceCart: PropTypes.func.isRequired,
  removeCoupon: PropTypes.func.isRequired,
  validateCoupon: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  selectedMembership: PropTypes.object.isRequired,
  currentStepName: PropTypes.string.isRequired,
  paymentProcessor: PropTypes.object.isRequired,
  unsetMember: PropTypes.func.isRequired,
  clearMessages: PropTypes.func.isRequired,
  addOrRemoveModalProduct: PropTypes.func.isRequired,
}

const defaultProps = {}

function MembershipPaymentInfo({
  cart,
  emptyMembershipCart,
  fetchMembershipAccount,
  webstore,
  nextStepPath,
  push,
  submitOrder,
  replaceCart,
  removeCoupon,
  validateCoupon,
  selectedMembership,
  currentStepName,
  paymentProcessor,
  unsetMember,
  clearMessages,
  addOrRemoveModalProduct,
}) {
  const modals = selectedMembership.modals
  const productModalData =
    modals &&
    modals.find(
      (m) =>
        m.location === Types.addOnProductModalLocations.PAYMENT_PAGE &&
        includes(Types.addOnProductModalTypes, m.modalType)
    )

  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (productModalData && !sessionStorage.getItem('membershipModalViewed')) {
      setTimeout(() => {
        sessionStorage.setItem('membershipModalViewed', true)
        setShowModal(true)
      }, Types.modalSettings.TIMEOUT)
    }
  }, [productModalData])

  return (
    <div className="step-container">
      <div>
        <MainHeader title={currentStepName} />
        <div className="row">
          <div className="col-8">
            <BillingDetailsForm
              name={Types.billingFormTypes.MEMBERSHIP}
              paymentProcessor={paymentProcessor}
              onSubmit={(params) => {
                clearMessages()
                return submitOrder(params)
              }}
              onSubmitSuccess={() => {
                if (LS.getMemberToken()) fetchMembershipAccount() // refetch newly saved membership details
                LS.clearCartToken()
                emptyMembershipCart()
                push(`/${webstore}/memberships/${nextStepPath}`)
              }}
              onSubmitFail={(errors, dispatch, submitError) => {
                // Clear user information and redirect to explanatory page on Centaman timeout
                if (
                  get('meta.error.status', submitError) ===
                  Types.statusCodes.GATEWAY_TIMEOUT
                ) {
                  unsetMember() // forces global layout to refetch account details
                  emptyMembershipCart()
                  return push(
                    `/${webstore}/memberships/timeout?id=${cart.productId}`
                  )
                }
                return flashInvalidFormSubmitMessage(
                  errors,
                  dispatch,
                  submitError
                )
              }}
            />
          </div>
          {productModalData && showModal && (
            <Modal onClose={() => setShowModal(false)}>
              <AddOnProduct
                productModalData={productModalData}
                cart={cart}
                addOrRemoveModalProduct={addOrRemoveModalProduct}
                isInModal={true}
                hideModal={() => setShowModal(false)}
              />
            </Modal>
          )}
          <StickyContainer className="col-4">
            <Receipt
              title={selectedMembership.displayName}
              image={selectedMembership.image}
              cart={cart}
              displayTotal
            />
            <CouponCode
              coupon={cart.coupon}
              cartItems={cart.listItems}
              replaceCart={replaceCart}
              removeCoupon={removeCoupon}
              validateCoupon={validateCoupon}
            />
            {productModalData && (
              <AddOnProduct
                productModalData={productModalData}
                addOrRemoveModalProduct={addOrRemoveModalProduct}
                cart={cart}
              />
            )}
          </StickyContainer>
        </div>
      </div>
    </div>
  )
}

MembershipPaymentInfo.propTypes = propTypes
MembershipPaymentInfo.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    cart: selectors.cart(state),
    currentStepName: selectors.currentStepName(state),
    nextStepPath: selectors.nextStepPath(state),
    organization: globalSelectors.organization(state),
    webstore: globalSelectors.webstore(state),
    selectedMembership: selectors.selectedMembership(state),
  }
}

const mapDispatchToProps = {
  fetchMembershipAccount: apiActions.fetchMembershipAccount,
  push: routerActions.push,
  setMembershipConfirmation: actions.setMembershipConfirmation,
  replaceCart: actions.replaceMembershipCart,
  removeCoupon: apiActions.removeMembershipCoupon,
  validateCoupon: apiActions.validateMembershipCoupon,
  createMembershipMembers: apiActions.createMembershipMembers,
  updateMembershipCartPayment: apiActions.updateMembershipCartPayment,
  validateMembershipOrder: apiActions.validateMembershipOrder,
  createMembershipOrder: apiActions.createMembershipOrder,
  emptyMembershipCart: actions.emptyMembershipCart,
  unsetMember: memberActions.unsetMember,
  clearMessages,
  addOrRemoveModalProduct: apiActions.addOrRemoveModalProduct,
}

const initializeOrgHelpers = ({ organization }) => {
  return {
    paymentProcessor: getPaymentProcessor(organization.paymentConfig.service),
  }
}

const initializePaymentProcessor = ({ organization, paymentProcessor }) => {
  return paymentProcessor.init(
    organization.paymentConfig.envVariables.environmentKey
  )
}

function modify({
  cart,
  setMembershipConfirmation,
  paymentProcessor,
  createMembershipMembers,
  updateMembershipCartPayment,
  validateMembershipOrder,
  createMembershipOrder,
  selectedMembership,
}) {
  let cartItems = cart
  return {
    submitOrder: (params) => {
      return createMembershipMembers()
        .then(({ attributes }) => {
          cartItems = attributes
          return paymentProcessor.submitPaymentDetails(
            cart.total,
            params.paymentDetails
          )
        })
        .then(({ cardReference, cardType, lastFourDigits }) =>
          updateMembershipCartPayment({
            customerDetails: {
              ...params.customerDetails,
              cardType,
              lastFourDigits,
            },
            cardReference,
            cart: mapAdditionalCartFields(cartItems, params),
          })
        )
        .then(validateMembershipOrder)
        .then(createMembershipOrder)
        .then((confirmation) => {
          GTM.sendTransactionData(confirmation.attributes, {
            productName: selectedMembership.displayName,
          })
          setMembershipConfirmation(confirmation)
        })
    },
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  modifyProps(initializeOrgHelpers),
  waitFor(['organization', 'selectedMembership'], Spinner),
  onMount(initializePaymentProcessor),
  modifyProps(modify)
)(MembershipPaymentInfo)
