import { combineReducers } from 'redux'
import {
  reducer as ticketingReducer,
  reducerKey as ticketingReducerKey,
} from './ticketing'
import {
  reducer as giftMembershipsReducer,
  reducerKey as giftMembershipsReducerKey,
} from './giftMemberships'
import {
  reducer as memberReducer,
  reducerKey as memberReducerKey,
} from './member'
import {
  reducer as membershipReducer,
  reducerKey as membershipReducerKey,
} from './membership'
import {
  reducer as globalReducer,
  reducerKey as globalReducerKey,
} from './globalReducer'
import {
  reducer as donationReducer,
  reducerKey as donationReducerKey,
} from './donation'
import { clearState } from './member/actions'
import { pick } from 'lodash'

const reducerKey = 'root'

// Global CLEAR_STATE action for multiple users with the same session
const reducer = (state, action) => {
  // Keep org and member data so that initial values are not reset
  if (action.type === clearState.toString())
    state = pick(state, [globalReducerKey, memberReducerKey])
  return rootReducer(state, action)
}

const rootReducer = combineReducers({
  [ticketingReducerKey]: ticketingReducer,
  [memberReducerKey]: memberReducer,
  [membershipReducerKey]: membershipReducer,
  [donationReducerKey]: donationReducer,
  [globalReducerKey]: globalReducer,
  [giftMembershipsReducerKey]: giftMembershipsReducer,
})

export { reducer, reducerKey }
