import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { modal } from 'lp-hoc'

const propTypes = {
  children: PropTypes.node,
  onRenew: PropTypes.func.isRequired,
  onViewAll: PropTypes.func.isRequired,
}

const defaultProps = {}

function RenewalPathModal({ children, onViewAll, onRenew }) {
  return (
    <div className="small-modal">
      {children}
      <div className="button-group">
        <button type="button" className="button-secondary" onClick={onViewAll}>
          View All
        </button>
        <button type="button" className="button-primary" onClick={onRenew}>
          Renew
        </button>
      </div>
    </div>
  )
}

RenewalPathModal.propTypes = propTypes
RenewalPathModal.defaultProps = defaultProps

export default compose(
  modal({ name: 'renewal-path', disableOutsideClick: true })
)(RenewalPathModal)
