import React from 'react'
import PropTypes from 'prop-types'
import { displayCurrency } from 'utils'
import classnames from 'classnames'
import { noop, isNumber, isFinite } from 'lodash'
import { ToggleTip } from 'components'

const propTypes = {
  itemName: PropTypes.string,
  itemDescription: PropTypes.string,
  input: PropTypes.object,
  min: PropTypes.number,
  max: PropTypes.number,
  onExceedMax: PropTypes.func,
  showVacancy: PropTypes.bool,
  price: PropTypes.number,
  tooltip: PropTypes.string,
  transactionQuantityCap: PropTypes.number,
  currentTransactionQuantity: PropTypes.number.isRequired,
  warnTransactionCapExceeded: PropTypes.func.isRequired,
}

const defaultProps = {
  min: 0,
  max: null,
  onExceedMax: noop,
  price: null,
  tooltip: '',
  updateCart: noop,
  transactionQuantityCap: null,
  currentTransactionQuantity: 0,
  warnTransactionCapExceeded: noop,
}

function QuantityLabel({ min, max }) {
  const hasMax = isFinite(max)
  if (!hasMax && !min) return ''
  if (!hasMax && min > 0) return `(${min}+)`
  if (max !== min) return `(${min} - ${max})`
  return `(${min} required)`
}

function QuantityInput({
  itemName,
  itemDescription,
  input: { value, onChange },
  showVacancy,
  min,
  max,
  onExceedMax,
  price,
  tooltip,
  transactionQuantityCap,
  currentTransactionQuantity,
  warnTransactionCapExceeded,
}) {
  const describedById = itemName + 'currentTotal'
  return (
    <div className="select-item-amount">
      <h5 className={classnames('amount', { selected: value > 0 })}>
        {value || 0}
      </h5>
      <span>
        <div className="select-item-content">
          <div className="item-name">
            <h5>{itemName}</h5>
            {tooltip && (
              <ToggleTip content={tooltip} allowHTML>
                {
                  <button className="toggletip" type="button">
                    ?
                  </button>
                }
              </ToggleTip>
            )}
          </div>
          {isNumber(price) && (
            <p className="item-price">
              {displayCurrency(price, { displayFree: true, appendStr: 'each' })}
            </p>
          )}
          <p className="item-description">
            {itemDescription && itemDescription + ' '}
            {showVacancy && <QuantityLabel min={min} max={max} />}
          </p>
        </div>
      </span>
      <div className="button-group">
        <button
          type="button"
          className="add-amount"
          aria-describedby={describedById}
          aria-label={`Add ${itemName}`}
          onClick={() => {
            if (
              transactionQuantityCap &&
              currentTransactionQuantity >= transactionQuantityCap
            ) {
              return warnTransactionCapExceeded(transactionQuantityCap)
            }

            const newValue = Number(value) + 1
            if (isNumber(max) && newValue > max) return onExceedMax(max) // do not exceed maximum
            if (isNumber(min) && newValue < min) return onChange(min) // do not allow entry below minimum (jumps from 0 to min)
            return onChange(newValue)
          }}
        >
          +
        </button>
        <button
          type="button"
          className="subtract-amount"
          aria-label={`Remove ${itemName}`}
          aria-describedby={describedById}
          onClick={() => {
            const newValue = Number(value) - 1
            if (newValue < min) return
            return onChange(newValue)
          }}
        >
          -
        </button>
        <span id={describedById} className="visually-hidden" aria-live="polite">
          {`${itemName} item quantity ` + value + '. '}
          {isNumber(price) &&
            value > 0 &&
            `\nItem total ${displayCurrency(price * value, {
              displayFree: true,
            })}`}
        </span>
      </div>
    </div>
  )
}

QuantityInput.propTypes = propTypes
QuantityInput.defaultProps = defaultProps

export default QuantityInput
