import React from 'react'
import PropTypes from 'prop-types'
import { compose, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import * as actions from '../actions'
import * as apiActions from 'api-actions'
import { connectParams, onMount, modifyProps, waitFor, onUpdate } from 'lp-hoc'
import { SelectTicketsForm, TicketDateForm, SelectTimeForm } from '../forms'
import classnames from 'classnames'
import { isEmpty, noop, get, size } from 'lodash'
import { Spinner, SubmitButton } from 'lp-components'
import * as routerActions from 'react-router-redux'
import { Link } from 'react-router'
import * as flashActions from 'redux-flash'
import { getFormValues } from 'redux-form'
import { MembershipUpgradeModal } from '../components/'
import { selectors as apiSelectors } from 'lp-redux-api'
import { selectors as globalSelectors } from 'global-reducer'
import {
  CouponCode,
  InfoBox,
  RenderedHTML,
  StickyContainer,
  MainHeader,
  Receipt,
  SectionHeader,
} from 'components'
import { displayKeys } from 'config'
import * as Types from 'types'
import {
  blurInput,
  calculateVisibleCalendarRange,
  createDisplayOrgText,
} from 'utils'
import * as LS from 'local-storage'
import { format, addMonths } from 'date-fns'
import * as cacheHelpers from '../cacheHelpers'

const propTypes = {
  cart: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  displayOrgText: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  clearMessages: PropTypes.func.isRequired,
  isCartLoading: PropTypes.bool.isRequired,
  isFetchingTickets: PropTypes.bool.isRequired,
  webstore: PropTypes.string,
  nextStepPath: PropTypes.string.isRequired,
  prices: PropTypes.array,
  pricesByCategory: PropTypes.array,
  push: PropTypes.func.isRequired,
  selectedTicket: PropTypes.object,
  updateCart: PropTypes.func.isRequired,
  replaceCart: PropTypes.func.isRequired,
  validateCoupon: PropTypes.func.isRequired,
  removeCoupon: PropTypes.func.isRequired,
  currentStepName: PropTypes.string.isRequired,
  selectedOffer: Types.offer,
  selectedTicketDate: PropTypes.string,
  setTicketDate: PropTypes.func.isRequired,
  timedTicketOffers: PropTypes.arrayOf(Types.offer).isRequired,
  updateOfferSelection: PropTypes.func.isRequired,
  getExcludedDatesForMonth: PropTypes.func.isRequired,
  loadingTicketTimes: PropTypes.bool.isRequired,
  excludedDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
  timeFormValues: PropTypes.object,
  contactEmail: PropTypes.string.isRequired,
}

const defaultProps = {
  selectedTicket: {},
  displayOrgText: noop,
  selectedOffer: null,
}

function TicketDetails({
  cart,
  config,
  displayOrgText,
  flashErrorMessage,
  clearMessages,
  isCartLoading,
  isFetchingTickets,
  webstore,
  nextStepPath,
  prices,
  pricesByCategory,
  push,
  selectedTicket,
  updateCart,
  replaceCart,
  validateCoupon,
  removeCoupon,
  currentStepName,
  selectedOffer,
  selectedTicketDate,
  setTicketDate,
  updateOfferSelection,
  excludedDates,
  getExcludedDatesForMonth,
  timedTicketOffers,
  loadingTicketTimes,
  timeFormValues,
  contactEmail,
}) {
  const transactionQuantityCap = selectedTicket.maxQuantity
  const hasSelectedPrices =
    !!cart.priceSelections && cart.priceSelections.length > 0
  const isTicketUnavailable = !selectedTicket.nextAvailableDate
  // We only show the quantitiy selector after a time has been selected.
  const hasSelectedOffer = !!get(timeFormValues, 'offer')
  return (
    <div className="step-container">
      <div>
        <MainHeader title={currentStepName} />
        <div className="row">
          <div className="col-8">
            <div className="confirmation-info">
              <RenderedHTML>
                {displayOrgText(displayKeys.TICKET_DETAILS_INSTRUCTIONS, {
                  displayName: selectedTicket.displayName,
                })}
              </RenderedHTML>
              {!!selectedTicket.details && <p>{selectedTicket.details}</p>}
            </div>
            <div>
              {selectedTicket.nextAvailableDate ? (
                <div>
                  <SectionHeader title="Select Date" />
                  <TicketDateForm
                    productId={selectedTicket.id}
                    excludedDates={excludedDates}
                    initialValues={{ ticketDate: selectedTicketDate }}
                    onSubmit={setTicketDate}
                    onSubmitSuccess={() => blurInput('ticketDate')}
                    onClickOutside={() => blurInput('ticketDate')}
                    onMonthChange={getExcludedDatesForMonth}
                  />
                  <React.Fragment>
                    {loadingTicketTimes ? (
                      <Spinner className="spinner-bottom-space" />
                    ) : !selectedTicket.allDay && isEmpty(timedTicketOffers) ? (
                      <p>
                        Sorry, there are no scheduled times for this event on
                        the date selected. Please try a different date.
                      </p>
                    ) : (
                      <SelectTimeForm
                        timedTicketOffers={timedTicketOffers}
                        initialValues={{ offer: selectedOffer }}
                        selectTimeHeader={displayOrgText(
                          displayKeys.SELECT_TIME
                        )}
                        onSubmit={({ offer }) =>
                          updateOfferSelection(
                            offer.productId,
                            offer.centamanId
                          )
                        }
                      />
                    )}
                  </React.Fragment>
                </div>
              ) : (
                <p>
                  {' '}
                  Sorry, there are no upcoming dates available for this event.{' '}
                </p>
              )}
            </div>
            {hasSelectedOffer && (
              <React.Fragment>
                {isFetchingTickets || !prices ? (
                  <Spinner className="spinner-bottom-space" />
                ) : (
                  <React.Fragment>
                    {selectedTicket.allDay && isEmpty(prices) ? (
                      <p>
                        Sorry, this event is not scheduled on this date. Please
                        try a different date.
                      </p>
                    ) : (
                      <React.Fragment>
                        <div>
                          <SelectTicketsForm
                            vacancy={get(selectedOffer, 'vacancy')}
                            showVacancy={get(
                              selectedOffer,
                              'showRemainingQuantity'
                            )}
                            tickets={prices}
                            ticketsByCategory={pricesByCategory}
                            onSubmit={updateCart}
                            debounceSubmit={500}
                            initialValues={cart.priceSelections}
                            transactionQuantityCap={transactionQuantityCap}
                          />
                        </div>
                        {config.SHOW_TICKET_DETAILS_INFO_BOX &&
                          !isFetchingTickets && (
                            <InfoBox>
                              <RenderedHTML>
                                {displayOrgText(
                                  displayKeys.TICKET_DETAILS_INFO_BOX,
                                  {
                                    contactEmail,
                                  }
                                )}
                              </RenderedHTML>
                            </InfoBox>
                          )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            {isTicketUnavailable ? (
              <Link
                className="button-primary full-width-button"
                to={webstore + '/tickets'}
              >
                Return to all tickets
              </Link>
            ) : (
              <SubmitButton
                className={classnames('full-width-button', {
                  'is-disabled': isEmpty(cart.priceSelections),
                })}
                form="ticketingForm"
                onClick={() => {
                  clearMessages()
                  if (isEmpty(cart.priceSelections))
                    return flashErrorMessage(
                      'Please select at least one ticket'
                    )

                  return push(`/${webstore}/tickets/${nextStepPath}`)
                }}
                aria-disabled={isEmpty(cart.priceSelections)}
              >
                Continue
              </SubmitButton>
            )}
          </div>
          <StickyContainer className="col-4">
            <Receipt
              title={selectedTicket.displayName}
              image={selectedTicket.image}
              cart={cart}
              displayTotal
              isCartLoading={isCartLoading}
            />
            {hasSelectedPrices && (
              <CouponCode
                coupon={cart.coupon}
                cartItems={cart.listItems}
                replaceCart={replaceCart}
                removeCoupon={removeCoupon}
                validateCoupon={validateCoupon}
              />
            )}
          </StickyContainer>
        </div>
      </div>
      <MembershipUpgradeModal upgradeLink="/all/memberships/main">
        <RenderedHTML>
          {displayOrgText(displayKeys.UPGRADE_MEMBERSHIP_MODAL_CONTENT)}
        </RenderedHTML>
      </MembershipUpgradeModal>
    </div>
  )
}

TicketDetails.propTypes = propTypes
TicketDetails.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    selectedTicketDate: selectors.selectedTicketDate(state),
    loadingTicketTimes: selectors.loadingTicketTimes(state),
    timedTicketOffers: selectors.timedTicketOffers(state),
    ticketTimes: selectors.ticketTimes(state),
    cart: selectors.cart(state),
    excludedDates: selectors.excludedDates(state),
    timedTicketCache: selectors.timedTicketCache(state),
    selectedOffer: selectors.selectedOffer(state),
    selectedTicket: selectors.selectedTicket(state),
    prices: selectors.prices(state),
    pricesByCategory: selectors.pricesByCategory(state),
    nextStepPath: selectors.nextStepPath(state),
    timeFormValues: getFormValues('SelectTimeForm')(state),
    isFetchingTickets:
      apiSelectors.isLoading(state, apiActions.fetchTicketTypes) ||
      apiSelectors.isLoading(state, apiActions.addTicketToCart),
    isCartLoading:
      apiSelectors.isLoading(
        state,
        apiActions.updateTicketCartPriceSelections
      ) || apiSelectors.isLoading(state, apiActions.addTicketToCart),
    webstore: globalSelectors.webstore(state),
    config: globalSelectors.config(state),
    membershipUpgradeModalShown: selectors.membershipUpgradeModalShown(state),
    currentStepName: selectors.currentStepName(state),
    contactEmail: globalSelectors.contactEmail(state),
  }
}

const mapDispatchToProps = {
  selectTicket: actions.selectTicket,
  addTicketToCart: apiActions.addTicketToCart,
  updateCart: apiActions.updateTicketCartPriceSelections,
  fetchTicket: apiActions.fetchTicket,
  replaceCart: actions.replaceTicketingCart,
  flashErrorMessage: flashActions.flashErrorMessage,
  clearMessages: flashActions.clearMessages,
  validateCoupon: apiActions.validateTicketCoupon,
  removeCoupon: apiActions.removeTicketCoupon,
  push: routerActions.push,
  setMembershipUpgradeModalShown: actions.setMembershipUpgradeModalShown,
  showMembershipUpgradeModal: () => MembershipUpgradeModal.show(),
  fetchTicketTimes: apiActions.fetchTicketTimes,
  fetchTimedTicketTypes: apiActions.fetchTimedTicketTypes,
  fetchExcludedDates: apiActions.fetchExcludedDates,
  setTicketDate: actions.setTicketDate,
  setLoadingTicketTimes: actions.setLoadingTicketTimes,
}

function modify({
  cart,
  config,
  updateCart,
  setTicketDate,
  selectedTicketDate,
  addTicketToCart,
  replaceCart,
  fetchTimedTicketTypes,
  fetchTicketTimes,
  setLoadingTicketTimes,
}) {
  return {
    initialOfferValues: {
      offers: cart.offerId,
    },
    updateCart: (tickets) => updateCart(cart, tickets),
    displayOrgText: createDisplayOrgText(config),
    setTicketDate: ({ ticketDate }) =>
      setTicketDate(format(ticketDate, 'YYYY-MM-DD')),
    /**
     * Fetches available ticket times for a specific date. If only one exists, this is auto selected
     * and ticket types for that time are fetched. Otherwise, the user will have to manually select
     * the ticket time they prefer before seeing the ticket types that are available.
     **/
    fetchTicketTimesAndUpdateCart: async (
      id,
      ticketDate,
      { autoselect = true } = {}
    ) => {
      setLoadingTicketTimes(true)
      try {
        const times = await fetchTicketTimes(id, ticketDate)
        if (!autoselect || size(times) !== 1) return
        const firstTime = times[0]
        if (firstTime.soldOut) return
        const offerId = firstTime.centamanId
        const { attributes, additionalData } = await addTicketToCart(
          id,
          offerId,
          ticketDate
        )
        replaceCart(attributes)
        LS.setCartToken(additionalData.cartToken)
        await fetchTimedTicketTypes(id, ticketDate, offerId)
      } finally {
        setLoadingTicketTimes(false)
      }
    },
    updateOfferSelection: (productId, offerId) => {
      return addTicketToCart(productId, offerId, selectedTicketDate).then(
        ({ attributes, additionalData }) => {
          replaceCart(attributes)
          LS.setCartToken(additionalData.cartToken)

          return fetchTimedTicketTypes(productId, selectedTicketDate, offerId)
        }
      )
    },
  }
}

function modifyHandlers() {
  return {
    getExcludedDatesForMonth: ({
      fetchExcludedDates,
      selectedTicket,
      timedTicketCache,
    }) => (month) => {
      // Pass visible calendar range to callback
      const [startDate, endDate] = calculateVisibleCalendarRange(month)
      // Don't proceed if excluded dates have already been calculated
      if (
        cacheHelpers.hasExcludedDatesForMonth(
          timedTicketCache,
          selectedTicket.id,
          endDate
        )
      )
        return
      return fetchExcludedDates(selectedTicket.id, { startDate, endDate })
    },
  }
}

const fetchTimedTicketInfo = ({
  selectedTicket,
  fetchExcludedDates,
  selectedTicketDate,
  fetchTicketTimesAndUpdateCart,
  cart,
}) => {
  const today = format(new Date(), 'YYYY-MM-DD')
  const initialDate = selectedTicketDate || today
  // Fetch ticket times for initial date
  const hasSelectedTicket = !isEmpty(cart.priceSelections)
  fetchTicketTimesAndUpdateCart(selectedTicket.id, initialDate, {
    autoselect: !hasSelectedTicket,
  })
  // Fetch blackout dates for the next three months.
  fetchExcludedDates(selectedTicket.id, {
    startDate: new Date(),
    endDate: addMonths(new Date(), 3),
  })
}

const watchDateChange = (
  { selectedTicketDate, selectedTicket, fetchTicketTimesAndUpdateCart },
  oldProps
) => {
  if (selectedTicketDate === oldProps.selectedTicketDate) return
  // Fetch new time info whenever the selected date changes
  return fetchTicketTimesAndUpdateCart(selectedTicket.id, selectedTicketDate)
}

const maybeShowUpgradeModal = ({
  config,
  membershipUpgradeModalShown,
  setMembershipUpgradeModalShown,
  showMembershipUpgradeModal,
}) => {
  // Only show upsell prompt if requested by the org and if the user has not already seen it for their current session
  if (config.PROMPT_MEMBERSHIP_UPGRADE && !membershipUpgradeModalShown) {
    setTimeout(showMembershipUpgradeModal, 1000) // delay slightly for a smoother transition
    setMembershipUpgradeModalShown(true)
  }
}

export default compose(
  connectParams('ticketId'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  modifyProps(modify),
  withHandlers(modifyHandlers),
  onMount(maybeShowUpgradeModal),
  onMount(fetchTimedTicketInfo),
  onUpdate(watchDateChange),
  waitFor('selectedTicketDate')
)(TicketDetails)
