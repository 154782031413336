/**
 * Source code taken from @launchpadlab/lp-components@3.14.0 and modified significantly to
 * couple this tightly with the selection of a Donation price from Centaman's api and with slight
 * accessibility best practice modifications.
 *
 * Changes:
 * - Moved {...rest} to apply to outermost grouping element
 * - Added a div with role="radiogroup"
 * - Explicitly set the value of each input to option (a Donation price)
 * - Added a paragraph tag to account for dynamic description text passed in from Centaman API
 * - Added another Input which will render to accept custom amounts
 */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Input,
  InputError,
  omitLabelProps,
  serializeOptions,
} from 'lp-components'
import { set } from 'lodash/fp'

const propTypes = {
  options: PropTypes.array.isRequired,
}

const defaultProps = {
  options: [],
}

function RadioGroupPriceSelection(props) {
  const {
    input: { value, onChange, name },
    meta: { touched, invalid, error },
    options,
    ...rest
  } = omitLabelProps(props)
  const optionObjects = serializeOptions(options)
  const [textInputTouched, setTextInputTouched] = useState(false)

  return (
    <fieldset className="RadioGroup" {...rest}>
      {optionObjects.map((option) => {
        const checked = value.centamanId === option.centamanId

        return (
          <React.Fragment key={option.centamanId}>
            <div className="action-item-container">
              <fieldset>
                {/* For custom styling, we need to use the adjacent sibling selector and thus the label must follow the input */}
                <input
                  key={option.centamanId}
                  id={`${name}.${option.centamanId}`}
                  type="radio"
                  name={name}
                  value={option}
                  onChange={() => onChange(option)}
                  checked={checked}
                />
                <label
                  className="no-margin-bottom"
                  htmlFor={`${name}.${option.centamanId}`}
                >
                  {option.displayName}
                </label>
                {!!option.details && (
                  <p className="radio-description">{option.details}</p>
                )}
                {option.customPrice && checked && (
                  <Input
                    className="specific-amount"
                    label="Please Specify Amount"
                    type="number"
                    min={option.minimumAmount}
                    max={option.maximumAmount}
                    input={{
                      name: `${name}.${option.centamanId}.customPrice`,
                      value: `${value.price || ''}`,
                      onChange: (e) =>
                        onChange(set('price', Number(e.target.value), option)),
                    }}
                    // Only show errors for this field once it's been blurred.
                    // We need to do this manually since submitting the form sets everything to touched.
                    onBlur={() => setTextInputTouched(true)}
                    meta={{ invalid, error, touched: textInputTouched }}
                    // Hide the error label for this component, only showing the field-wide one below.
                    errorComponent={() => null}
                  />
                )}
              </fieldset>
            </div>
          </React.Fragment>
        )
      })}
      <InputError
        {...{ error, touched: touched || textInputTouched, invalid, name }}
      />
    </fieldset>
  )
}

RadioGroupPriceSelection.propTypes = propTypes
RadioGroupPriceSelection.defaultProps = defaultProps

export default RadioGroupPriceSelection
