import React from 'react'
import { routes as BaseRoutes } from 'base'
import Layout from './Layout'
import { Route } from 'react-router'
import WebstoreLayout from 'base/WebstoreLayout'
import * as Views from './views'

// import { IndexRoute } from 'react-router'
// import * as Views from './views'

function Routes() {
  return (
    // Override base routes by adding children routes below
    <BaseRoutes indexPath="/all/tickets" orgSlug="john-ball" layout={Layout}>
      <Route path=":webstore" component={WebstoreLayout}>
        {/*Any routes that depends on the selected org */}
        <Route path="tickets" component={Views.SelectTicket}></Route>
      </Route>
      {/*Any other routes that do not depend on the selected org */}
    </BaseRoutes>
  )
}

export default Routes
