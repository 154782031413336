import React from 'react'
import { IndexRoute } from 'react-router'
import { routes as BaseRoutes } from 'base'
import * as Views from './views'
import Layout from './Layout'

function Routes() {
  return (
    <BaseRoutes indexPath="/" orgSlug="demo" layout={Layout}>
      <IndexRoute component={Views.Home} />
    </BaseRoutes>
  )
}

export default Routes
