// App-wide config (routes, env vars) goes here.

const ASSET_CDN_HOST = 'd2tyforyq9qmy9.cloudfront.net'

export const orgFontAssets = ({ orgName }) => {
  return `https://${ASSET_CDN_HOST}/fonts/${orgName}/fonts.css`
}

export const NEW_MEMBERSHIP_ID = 0

export const displayKeys = {
  ADDRESS: 'ADDRESS',
  BILLING_STREET_ADDRESS: 'BILLING_STREET_ADDRESS',
  MEMBER_STREET_ADDRESS: 'MEMBER_STREET_ADDRESS',
  EMAIL: 'EMAIL',
  EMAIL_BILLING: 'EMAIL_BILLING',
  PHONE: 'PHONE',
  ZIP: 'ZIP',
  BILLING_TITLE: 'BILLING_TITLE',
  CONTACT_INFO_TITLE: 'CONTACT_INFO_TITLE',
  CREDIT_CARD_NUMBER: 'CREDIT_CARD_NUMBER',
  EXPIRATION_DATE: 'EXPIRATION_DATE',
  COUNTRY: 'COUNTRY',
  UPGRADE_MEMBERSHIP_MODAL_CONTENT: 'UPGRADE_MEMBERSHIP_MODAL_CONTENT',
  TICKET_DETAILS_INSTRUCTIONS: 'TICKET_DETAILS_INSTRUCTIONS',
  TICKET_SELECTION_FOOTER_INSTRUCTIONS: 'TICKET_SELECTION_FOOTER_INSTRUCTIONS',
  PAYMENT_CONFIRMATION_SUBTITLE: 'PAYMENT_CONFIRMATION_SUBTITLE',
  MEMBERSHIP_COST_INFO_BOX: 'MEMBERSHIP_COST_INFO_BOX',
  MEMBERSHIP_RENEWAL_COST_INFO_BOX: 'MEMBERSHIP_RENEWAL_COST_INFO_BOX',
  MEMBERSHIP_COST_INFO_BOX_TITLE: 'MEMBERSHIP_COST_INFO_BOX_TITLE',
  TICKET_DETAILS_INFO_BOX: 'TICKET_DETAILS_INFO_BOX',
  MEMBER_LOGIN_TITLE: 'MEMBER_LOGIN_TITLE',
  MEMBER_EMAIL_LOGIN_INSTRUCTIONS: 'MEMBER_EMAIL_LOGIN_INSTRUCTIONS',
  MEMBER_ID_LOGIN_INSTRUCTIONS: 'MEMBER_ID_LOGIN_INSTRUCTIONS',
  PASSWORD: 'PASSWORD',
  CONFIRM_PASSWORD: 'CONFIRM_PASSWORD',
  MEMBER_ID: 'MEMBER_ID',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  FORGOT_MEMBER_ID: 'FORGOT_MEMBER_ID',
  MEMBER_REGISTRATION_TITLE: 'MEMBER_REGISTRATION_TITLE',
  MEMBER_REGISTRATION_INSTRUCTIONS: 'MEMBER_REGISTRATION_INSTRUCTIONS',
  NEW_MEMBERSHIP_INFO_BOX: 'NEW_MEMBERSHIP_INFO_BOX',
  NEW_MEMBERSHIP_INFO_BOX_TITLE: 'NEW_MEMBERSHIP_INFO_BOX_TITLE',
  MEMBER_EMAIL_LOGIN_SWITCH_TITLE: 'MEMBER_EMAIL_LOGIN_SWITCH_TITLE',
  MEMBER_EMAIL_LOGIN_SWITCH_LINK_LABEL: 'MEMBER_EMAIL_LOGIN_SWITCH_LINK_LABEL',
  MEMBER_ID_LOGIN_SWITCH_TITLE: 'MEMBER_ID_LOGIN_SWITCH_TITLE',
  MEMBER_ID_LOGIN_SWITCH_LINK_LABEL: 'MEMBER_ID_LOGIN_SWITCH_LINK_LABEL',
  MEMBER_LOGIN_INFO_BOX: 'MEMBER_LOGIN_INFO_BOX',
  MEMBER_LOGIN_INFO_BOX_TITLE: 'MEMBER_LOGIN_INFO_BOX_TITLE',
  MEMBER_LOGIN_TOOLTIP: 'MEMBER_LOGIN_TOOLTIP',
  MEMBER_REGISTRATION_TOOLTIP: 'MEMBER_REGISTRATION_TOOLTIP',
  MEMBERSHIP_LABEL: 'MEMBERSHIP_LABEL',
  MEMBERSHIP_DETAILS_INSTRUCTIONS: 'MEMBERSHIP_DETAILS_INSTRUCTIONS',
  MEMBERSHIP_DETAILS_RENEWAL_NOTICE: 'MEMBERSHIP_DETAILS_RENEWAL_NOTICE',
  MEMBERSHIP_DETAILS_EXISTING_RENEWAL_NOTICE:
    'MEMBERSHIP_DETAILS_EXISTING_RENEWAL_NOTICE',
  MEMBERSHIP_PAYMENT_CONFIRMATION_INFO: 'MEMBERSHIP_PAYMENT_CONFIRMATION_INFO',
  MEMBERSHIP_TIMEOUT_HEADING: 'MEMBERSHIP_TIMEOUT_HEADING',
  MEMBERSHIP_TIMEOUT_INSTRUCTIONS: 'MEMBERSHIP_TIMEOUT_INSTRUCTIONS',
  MEMBERSHIP_TIMEOUT_ADDITIONAL_HELP_HEADING:
    'MEMBERSHIP_TIMEOUT_ADDITIONAL_HELP_HEADING',
  MEMBERSHIP_TIMEOUT_ADDITIONAL_HELP_INSTRUCTIONS:
    'MEMBERSHIP_TIMEOUT_ADDITIONAL_HELP_INSTRUCTIONS',
  MEMBERSHIP_TIMEOUT_ADDITIONAL_HELP_INSTRUCTIONS_CONTACT:
    'MEMBERSHIP_TIMEOUT_ADDITIONAL_HELP_INSTRUCTIONS_CONTACT',
  EXISTING_MEMBER_MODAL_CONTENT: 'EXISTING_MEMBER_MODAL_CONTENT',
  TITLE: 'TITLE',
  FIRST_NAME: 'FIRST_NAME',
  LAST_NAME: 'LAST_NAME',
  DATE_OF_BIRTH: 'DATE_OF_BIRTH',
  STATE: 'STATE',
  CITY: 'CITY',
  SUBURB: 'SUBURB',
  CREDIT_CARD_NAME: 'CREDIT_CARD_NAME',
  RESET_PASSWORD: 'RESET_PASSWORD',
  RESET_PASSWORD_INSTRUCTIONS: 'RESET_PASSWORD_INSTRUCTIONS',
  RESET_PASSWORD_SUCCESS_MESSAGE: 'RESET_PASSWORD_SUCCESS_MESSAGE',
  PRIMARY_MEMBER_FORM_TITLE: 'PRIMARY_MEMBER_FORM_TITLE',
  PRIMARY_MEMBER_FORM_DETAILS: 'PRIMARY_MEMBER_FORM_DETAILS',
  PRIMARY_MEMBER_RENEWAL_FORM_DETAILS: 'PRIMARY_MEMBER_RENEWAL_FORM_DETAILS',
  SECONDARY_MEMBER_EXPIRED_WARNING_MESSAGE:
    'SECONDARY_MEMBER_EXPIRED_WARNING_MESSAGE',
  MEMBER_ATTENDEE_DETAILS_FORM_TITLE: 'MEMBER_ATTENDEE_DETAILS_FORM_TITLE',
  SECONDARY_MEMBER_FORM_TITLE: 'SECONDARY_MEMBER_FORM_TITLE',
  SECONDARY_MEMBER_FORM_DETAILS: 'SECONDARY_MEMBER_FORM_DETAILS',
  SECONDARY_MEMBER_MEMBERSHIP_DATES: 'SECONDARY_MEMBER_MEMBERSHIP_DATES',
  SECONDARY_MEMBER_LIMIT_MODAL_ADD_MORE_INSTRUCTIONS:
    'SECONDARY_MEMBER_LIMIT_MODAL_ADD_MORE_INSTRUCTIONS',
  SECONDARY_MEMBER_LIMIT_MODAL_BASE_MESSAGE:
    'SECONDARY_MEMBER_LIMIT_MODAL_BASE_MESSAGE',
  SECONDARY_MEMBER_LIMIT_MODAL_TITLE: 'SECONDARY_MEMBER_LIMIT_MODAL_TITLE',
  SELECT_MEMBERSHIP_TYPE: 'SELECT_MEMBERSHIP_TYPE',
  SELECT_MEMBERSHIP_TITLE: 'SELECT_MEMBERSHIP_TITLE',
  SELECT_MEMBERSHIP_INSTRUCTIONS: 'SELECT_MEMBERSHIP_INSTRUCTIONS',
  SELECT_MEMBERSHIP_CURRENT_MEMBERSHIP_OVERLAY:
    'SELECT_MEMBERSHIP_CURRENT_MEMBERSHIP_OVERLAY',
  SELECT_MEMBERSHIP_NO_MEMBERSHIPS_FOUND:
    'SELECT_MEMBERSHIP_NO_MEMBERSHIPS_FOUND',
  SELECT_EVENT: 'SELECT_EVENT',
  SELECT_TOUR: 'SELECT_TOUR',
  SELECT_MERCHANDISE: 'SELECT_MERCHANDISE',
  SELECT_TICKET: 'SELECT_TICKET',
  SELECT_TIME: 'SELECT_TIME',
  SELECT_TICKET_INSTRUCTIONS: 'SELECT_TICKET_INSTRUCTIONS',
  SELECT_TOUR_INSTRUCTIONS: 'SELECT_TOUR_INSTRUCTIONS',
  TICKET_PAYMENT_CONFIRMATION_INFO: 'TICKET_PAYMENT_CONFIRMATION_INFO',
  TICKET_PAYMENT_CONFIRMATION_SUBTITLE: 'TICKET_PAYMENT_CONFIRMATION_SUBTITLE',
  DONATION_PAYMENT_CONFIRMATION_SUBTITLE:
    'DONATION_PAYMENT_CONFIRMATION_SUBTITLE',
  DONATION_PAYMENT_CONFIRMATION_INFO: 'DONATION_PAYMENT_CONFIRMATION_INFO',
  DONATION_AMOUNT_DETAILS: 'DONATION_AMOUNT_DETAILS',
  CONFIRMATION_UPSELL_TEXT: 'CONFIRMATION_UPSELL_TEXT',
  GIFT_MEMBERSHIP_PAYMENT_CONFIRMATION_SUBTITLE:
    'GIFT_MEMBERSHIP_PAYMENT_CONFIRMATION_SUBTITLE',
  GIFT_MEMBERSHIP_PAYMENT_CONFIRMATION_INFO:
    'GIFT_MEMBERSHIP_PAYMENT_CONFIRMATION_INFO',
  GIFT_MEMBERSHIP_ADDITIONAL_INSTRUCTIONS:
    'GIFT_MEMBERSHIP_ADDITIONAL_INSTRUCTIONS',
}

export const displayText = {
  [displayKeys.ADDRESS]: 'Address',
  [displayKeys.BILLING_STREET_ADDRESS]: 'Billing Street Address',
  [displayKeys.MEMBER_STREET_ADDRESS]: 'Street Address',
  [displayKeys.BILLING_TITLE]: 'Billing Details',
  [displayKeys.CONTACT_INFO_TITLE]: 'Purchaser Contact Information',
  [displayKeys.COUNTRY]: 'Country',
  [displayKeys.CREDIT_CARD_NUMBER]: 'Credit Card Number',
  [displayKeys.EMAIL]: 'Email',
  [displayKeys.EMAIL_BILLING]: 'Email',
  [displayKeys.EXPIRATION_DATE]: 'Expiration Date',
  [displayKeys.PHONE]: 'Phone',
  [displayKeys.ZIP]: 'Zip Code',
  [displayKeys.UPGRADE_MEMBERSHIP_MODAL_CONTENT]: `
    <h2> Upgrade To A Membership </h2>
    <p>
      Get 365 days of fun by signing up for a membership!
    </p>
  `,
  [displayKeys.MEMBERSHIP_COST_INFO_BOX]: '',
  [displayKeys.MEMBERSHIP_RENEWAL_COST_INFO_BOX]: '',
  [displayKeys.MEMBERSHIP_COST_INFO_BOX_TITLE]: 'Membership Costs',
  [displayKeys.TICKET_DETAILS_INSTRUCTIONS]:
    '<p>You have chosen <strong>{{ displayName }}</strong>. Please enter your ticket details below.</p>',
  [displayKeys.TICKET_SELECTION_FOOTER_INSTRUCTIONS]: '',
  [displayKeys.MEMBERSHIP_DETAILS_INSTRUCTIONS]:
    '<p>You have chosen the <strong>{{ displayName }}</strong> membership. Please enter your membership details below.',
  [displayKeys.MEMBERSHIP_DETAILS_EXISTING_RENEWAL_NOTICE]:
    '<p>Your membership expires on {{ expirationDate }}. Please proceed below to <strong>renew</strong> your membership.</p>',
  [displayKeys.MEMBERSHIP_DETAILS_RENEWAL_NOTICE]:
    '<p>Your membership has expired. Please proceed below to <strong>renew</strong> your membership.</p>',
  [displayKeys.PAYMENT_CONFIRMATION_SUBTITLE]: `
    <p>You have successfully placed your order.</p>
    <p>A copy of your purchase has been sent to the email provided.</p>
  `,
  [displayKeys.TICKET_PAYMENT_CONFIRMATION_INFO]: '',
  [displayKeys.TICKET_DETAILS_INFO_BOX]: `<p>For any questions or concerns, please contact customer service at <a href="mailto:{{ contactEmail }}">{{ contactEmail }}</a>.</p>`,
  [displayKeys.MEMBER_LOGIN_TITLE]: 'Already a Member?',
  [displayKeys.MEMBER_EMAIL_LOGIN_SWITCH_TITLE]: 'Issues logging in?',
  [displayKeys.MEMBER_EMAIL_LOGIN_SWITCH_LINK_LABEL]:
    'Click here to log in with member/card number and last name',
  [displayKeys.MEMBER_ID_LOGIN_SWITCH_TITLE]: 'Issues logging in?',
  [displayKeys.MEMBER_ID_LOGIN_SWITCH_LINK_LABEL]:
    'Click here to log in with email and password',
  [displayKeys.MEMBER_EMAIL_LOGIN_INSTRUCTIONS]: `<p>Log in to renew your membership or click <strong>Forgot Password</strong> below to set up your online access if you do not yet have a password.</p>`,
  [displayKeys.MEMBER_ID_LOGIN_INSTRUCTIONS]: `<p>Log in to renew your membership or click <strong>Forgot Member / Card Number</strong> to set up your online access.</p>`,
  [displayKeys.PASSWORD]: 'Password',
  [displayKeys.CONFIRM_PASSWORD]: 'Confirm Password',
  [displayKeys.MEMBER_ID]: 'Member / Card Number',
  [displayKeys.FORGOT_PASSWORD]: 'Forgot Password?',
  [displayKeys.FORGOT_MEMBER_ID]: 'Forgot Member / Card Number?',
  [displayKeys.MEMBER_REGISTRATION_TITLE]: 'Become a Member',
  [displayKeys.MEMBER_REGISTRATION_TOOLTIP]: '',
  [displayKeys.MEMBER_REGISTRATION_INSTRUCTIONS]:
    'Not a member yet? Become a member today!',
  [displayKeys.NEW_MEMBERSHIP_INFO_BOX]: '',
  [displayKeys.NEW_MEMBERSHIP_INFO_BOX_TITLE]: 'Information',
  [displayKeys.MEMBER_LOGIN_INFO_BOX]: '',
  [displayKeys.MEMBER_LOGIN_INFO_BOX_TITLE]: 'Information',
  [displayKeys.MEMBER_LOGIN_TOOLTIP]: '',
  [displayKeys.EXISTING_MEMBER_MODAL_CONTENT]: `
    <h2>Oops!</h2>
    <p>It looks like this email address is already registered. Please log in to proceed with your purchase or renewal.<a href="/member/forgot-password"> Reset your password</a> if you forgot it or never set one up.<br/><br/> You can also try logging in with your <a href="/member/login?method=memberNumber">member / card number and last name.</a></p>
  `,
  [displayKeys.TITLE]: 'Title',
  [displayKeys.FIRST_NAME]: 'First Name',
  [displayKeys.LAST_NAME]: 'Last Name',
  [displayKeys.DATE_OF_BIRTH]: 'Date of Birth',
  [displayKeys.COUNTRY]: 'Country',
  [displayKeys.CITY]: 'City',
  [displayKeys.STATE]: 'State',
  [displayKeys.SUBURB]: 'Suburb',
  [displayKeys.CREDIT_CARD_NAME]: 'Name on Card',
  [displayKeys.RESET_PASSWORD]: 'Reset Password',
  [displayKeys.RESET_PASSWORD_INSTRUCTIONS]:
    'Please select a new password. New password must contain one uppercase letter and be at least 8 characters long.',
  [displayKeys.RESET_PASSWORD_SUCCESS_MESSAGE]:
    'Your new password has been set.',
  [displayKeys.PRIMARY_MEMBER_FORM_TITLE]: 'Primary Member Details',
  [displayKeys.PRIMARY_MEMBER_FORM_DETAILS]: '',
  [displayKeys.PRIMARY_MEMBER_RENEWAL_FORM_DETAILS]: '',
  [displayKeys.SECONDARY_MEMBER_EXPIRED_WARNING_MESSAGE]:
    'This member expired on {{ expDate }}. Please remove or confirm membership type to renew.',
  [displayKeys.MEMBER_ATTENDEE_DETAILS_FORM_TITLE]: 'Additional Details',
  [displayKeys.SECONDARY_MEMBER_FORM_TITLE]: 'Add Additional Members',
  [displayKeys.SECONDARY_MEMBER_FORM_DETAILS]: '',
  [displayKeys.SECONDARY_MEMBER_MEMBERSHIP_DATES]: `<p>This member is active on {{ joinDate }} and expires on {{ expDate }}.</p>`,
  [displayKeys.SECONDARY_MEMBER_LIMIT_MODAL_ADD_MORE_INSTRUCTIONS]:
    'Continue to the next step to add additional paid members at their individual member rates.',
  [displayKeys.SECONDARY_MEMBER_LIMIT_MODAL_BASE_MESSAGE]:
    'This membership only includes up to {{ limitQuantity }} free "{{ membershipType }}" member(s).',
  [displayKeys.SECONDARY_MEMBER_LIMIT_MODAL_TITLE]:
    'Additional {{ membershipType }} Members',
  [displayKeys.SELECT_EVENT]: 'Select Event',
  [displayKeys.SELECT_TOUR]: 'Select Tour',
  [displayKeys.SELECT_MERCHANDISE]: 'Select Merchandise',
  [displayKeys.SELECT_TICKET]: 'Select Ticket',
  [displayKeys.SELECT_TICKET_INSTRUCTIONS]:
    'Please select the ticket you would like to purchase below.',
  [displayKeys.SELECT_TOUR_INSTRUCTIONS]:
    'Please select the tour you would like to purchase below.',
  [displayKeys.SELECT_TIME]: 'Select Time',
  [displayKeys.MEMBERSHIP_LABEL]: 'membership',
  [displayKeys.MEMBERSHIP_PAYMENT_CONFIRMATION_INFO]: '',
  [displayKeys.MEMBERSHIP_TIMEOUT_HEADING]: 'Oh No! Request timed out.',
  [displayKeys.MEMBERSHIP_TIMEOUT_INSTRUCTIONS]: `
    <p>We have run into a problem on our end. To verify that your payment has gone through, please click the button below to view the details page.</p>
    <br />
    <p>If the status is completed, your information will show up in the membership details section and a renewal message will be present at the top of the screen.</p>
  `,
  [displayKeys.MEMBERSHIP_TIMEOUT_ADDITIONAL_HELP_HEADING]:
    'Contact Customer Service',
  [displayKeys.MEMBERSHIP_TIMEOUT_ADDITIONAL_HELP_INSTRUCTIONS]: `
    <p>If you would prefer to confirm your membership via telephone, please contact customer service.</p>
  `,
  [displayKeys.MEMBERSHIP_TIMEOUT_ADDITIONAL_HELP_INSTRUCTIONS_CONTACT]: `
    <p>If you would prefer to confirm your membership via telephone, please contact customer service at <a href="tel:{{ contactPhone }}">{{ contactPhone }}</a>.</p>
  `,
  [displayKeys.TICKET_PAYMENT_CONFIRMATION_SUBTITLE]: `
    <p>You have successfully placed your order.</p>
    <p>A copy of your purchase has been sent to the email provided.</p>
  `,
  [displayKeys.SELECT_MEMBERSHIP_TYPE]: 'membership',
  [displayKeys.SELECT_MEMBERSHIP_TITLE]: 'Select Membership',
  [displayKeys.SELECT_MEMBERSHIP_INSTRUCTIONS]:
    'Please select the membership you would like to purchase below.',
  [displayKeys.SELECT_MEMBERSHIP_CURRENT_MEMBERSHIP_OVERLAY]:
    'Current Membership',
  [displayKeys.SELECT_MEMBERSHIP_NO_MEMBERSHIPS_FOUND]:
    'Sorry, there are no memberships available at this time.',
  [displayKeys.DONATION_PAYMENT_CONFIRMATION_SUBTITLE]: `
    <p>We appreciate your generous donation.</p>
    <p>A receipt for your donation has been sent to the email provided.</p>
  `,
  [displayKeys.DONATION_PAYMENT_CONFIRMATION_INFO]: '',
  [displayKeys.DONATION_AMOUNT_DETAILS]: `
    <p>
      You have chosen <strong>{{ displayName }}</strong>. Please specify your donation
      amount below.
    </p>
  `,
  [displayKeys.GIFT_MEMBERSHIP_PAYMENT_CONFIRMATION_SUBTITLE]: `
    <p>You have successfully placed your order.</p>
    <p>A copy of your purchase has been sent to the email provided.</p>
  `,
  [displayKeys.GIFT_MEMBERSHIP_PAYMENT_CONFIRMATION_INFO]: '',
  [displayKeys.GIFT_MEMBERSHIP_ADDITIONAL_INSTRUCTIONS]: '',
}

export const ticketingSteps = [
  {
    name: 'Ticket Details',
    route: 'details',
  },
  {
    name: 'Select Add-Ons',
    route: 'add-ons',
  },
  {
    name: 'Payment Info',
    route: 'payment',
  },
]

export const membershipSteps = [
  {
    name: 'Membership Details',
    route: 'details',
  },
  {
    name: 'Select Add-Ons',
    route: 'add-ons',
  },
  {
    name: 'Payment Info',
    route: 'payment',
  },
]

export const donationSteps = [
  {
    name: 'Donation Amount',
    route: 'details',
  },
  {
    name: 'Payment Info',
    route: 'payment',
  },
]

export const giftMembershipSteps = [
  {
    name: 'Select Memberships',
    route: 'select',
  },
  {
    name: 'Payment Info',
    route: 'payment',
  },
]
