// Browsers will do this automatically on page load,
// but sometimes we need to manually trigger it after elements have rendered.

function getHeaderHeight() {
  const header = window.document.querySelector('header')
  return header ? header.offsetHeight : 0
}

function scrollToHash() {
  const hash = window.location.hash
  if (!hash) return
  const id = hash.split('#').pop()
  const el = document.getElementById(id)
  if (!el) return
  const headerHeight = getHeaderHeight()
  el.scrollIntoView()
  // Adjust for header height
  window.scrollBy(0, -1 * headerHeight)
}

export default scrollToHash
