import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { lpForm } from 'lp-form'
import { Input, SubmitButton } from 'lp-components'
import { Field, FormSection } from 'redux-form'
import { noop, isEmpty } from 'lodash'
import { RenderedHTML } from 'components'
import { CheckboxGroupQuantityInput } from '../components'
import { selectors as globalSelectors } from 'global-reducer'
import { createDisplayOrgText } from 'utils'
import { displayKeys } from 'config'

const propTypes = {
  displayOrgText: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onChangePriceSelections: PropTypes.func,
  change: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  giftMemberships: PropTypes.arrayOf(Types.giftMembership),
  collectRecipientDetails: PropTypes.bool,
  priceSelections: PropTypes.array.isRequired,
}

const defaultProps = {
  onChangePriceSelections: noop,
  collectRecipientDetails: false,
}

function quantitiesExist(priceSelections) {
  return priceSelections.some((priceSelection) => priceSelection.quantity > 0)
}

function validatePriceSelections(priceSelections) {
  if (!priceSelections || !quantitiesExist(priceSelections))
    return 'Must select at least one membership.'
}

function GiftMembershipForm({
  handleSubmit,
  giftMemberships,
  submitting,
  onChangePriceSelections,
  change,
  collectRecipientDetails,
  displayOrgText,
  priceSelections,
}) {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="gift-selection select-amount">
        <Field
          name="priceSelections"
          component={CheckboxGroupQuantityInput}
          options={giftMemberships}
          aria-label="Select gift memberships"
          validate={validatePriceSelections}
          onChange={(value, _, __, fieldName) => {
            onChangePriceSelections(value)
            return change(fieldName, value)
          }}
        />
      </div>
      {collectRecipientDetails && (
        <div>
          <FormSection name="recipientDetails">
            <h2>Primary Recipient</h2>
            <div className="action-item-container">
              <Field
                name="firstName"
                label="First Name"
                component={Input}
                type="text"
                required
              />
              <Field
                name="lastName"
                label="Last Name"
                component={Input}
                type="text"
                required
              />
            </div>
          </FormSection>
        </div>
      )}
      <RenderedHTML>
        {displayOrgText(displayKeys.GIFT_MEMBERSHIP_ADDITIONAL_INSTRUCTIONS)}
      </RenderedHTML>
      <SubmitButton
        className={classnames('full-width-button', {
          'is-disabled': isEmpty(priceSelections),
        })}
        {...submitting}
      >
        Continue
      </SubmitButton>
    </form>
  )
}

GiftMembershipForm.propTypes = propTypes
GiftMembershipForm.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    config: globalSelectors.config(state),
  }
}

function initializeOrgHelpers({ config }) {
  return {
    displayOrgText: createDisplayOrgText(config),
  }
}

export default compose(
  connect(mapStateToProps),
  withPropsOnChange(['config'], initializeOrgHelpers),
  lpForm({
    name: 'GiftMembershipForm',
    constraints: {
      'recipientDetails.firstName': {
        presence: true,
        length: { maximum: 250 },
      },
      'recipientDetails.lastName': { presence: true, length: { maximum: 250 } },
    },
  })
)(GiftMembershipForm)
