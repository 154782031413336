import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { modal } from 'lp-hoc'
import RenderedHTML from './RenderedHTML'

const propTypes = {
  handleHide: PropTypes.func.isRequired,
  terms: PropTypes.string.isRequired,
}

const defaultProps = {}

function TermsModal({ terms, handleHide }) {
  return (
    <div>
      <h2>Terms and Conditions</h2>
      <RenderedHTML>{terms}</RenderedHTML>
      <button onClick={handleHide} className="modal-close">
        ×
      </button>
    </div>
  )
}

TermsModal.propTypes = propTypes
TermsModal.defaultProps = defaultProps

export default compose(modal({ name: 'terms-conditions-modal' }))(TermsModal)
