// Organization configuration file
import { displayText as baseDisplayText, displayKeys } from 'config'
import tarongaUmbrellaLogo from 'images/logos/taronga/taronga.svg'
import sydneyLogo from 'images/logos/taronga/sydney.svg'
import dubboLogo from 'images/logos/taronga/dubbo.svg'
import tarongaDubboLogo from 'images/logos/taronga/logo-lockup.svg'
import favicon from 'images/favicons/tz-favicon.ico'
import * as Types from 'types'

const config = {}

// ----- CONSTANTS -----

config.DEFAULT_COUNTRY = 'AUSTRALIA'
config.PROMPT_MEMBERSHIP_UPGRADE = false
config.SHOW_TICKET_DETAILS_INFO_BOX = true
config.SHOW_MEMBER_LOGIN_INFO_BOX = true
config.USE_MEMBERSHIP_QUANTITY_SELECTORS = true
config.ENABLE_BACK_BUTTON_ON_MEMBERSHIP_DETAILS = false
config.ENABLE_RECAPTCHA = true
config.MEMBERSHIP_SHOW_COSTS = true
config.MEMBERSHIP_CONFIRMATION_SHOW_VALID_PERIOD = false
config.DISPLAY_CARD_LOGOS = false
config.MEMBER_LOGIN_METHOD = Types.loginFormMethods.email
config.ENABLE_MEMBER_REGISTRATION = true

// ----- BRANDING -----

// This is the fallback used if a webstore cannot be located. The paths should always be prepended with 'all'
config.defaultBranding = {
  className: 'taronga-zoo',
  title: 'Taronga Zoo',
  // navLinks: [{ name: 'Annual Pass', path: '/all/memberships/main' }],
  // showLogin: true,
  navLinks: [],
  showLogin: false,
  logo: tarongaUmbrellaLogo,
  homeUrl: '/',
  pageTitle: 'Taronga Zoo | Streamlined Checkout',
  favicon,
}

config.webstoreBranding = {
  dubbo: {
    className: 'taronga-zoo dubbo',
    title: 'Dubbo Zoo',
    // navLinks: [{ name: 'Annual Pass', path: '/all/memberships/main' }],
    // showLogin: true,
    navLinks: [],
    showLogin: false,
    logo: dubboLogo,
    homeUrl: '/',
    pageTitle: 'Dubbo Zoo | Streamlined Checkout',
    favicon,
  },
  sydney: {
    title: 'Taronga Zoo',
    className: 'taronga-zoo sydney',
    // navLinks: [{ name: 'Annual Pass', path: '/all/memberships/main' }],
    // showLogin: true,
    navLinks: [],
    showLogin: false,
    logo: sydneyLogo,
    homeUrl: '/',
    pageTitle: 'Taronga Zoo | Streamlined Checkout',
    favicon,
  },
  all: {
    ...config.defaultBranding,
    logo: tarongaDubboLogo,
    className: 'taronga-zoo sydney-dubbo',
    // navLinks: [{ name: 'Annual Pass', path: '/all/memberships/main' }],
    // showLogin: true,
    navLinks: [],
    showLogin: false,
    pageTitle: 'Taronga Zoo Friends Annual Pass | Streamlined Checkout',
    favicon,
  },
}

// ----- FORM FIELDS -----

/**
 * This object controls the fields that are visible for a particular form. The fields must
 * be nested within a key that is specific to a form name. Only the fields that the organization
 * wants to render need to be specified.
 */

config.fields = {
  'ticketing-billing-details': {
    paymentDetails: {
      zip: false,
    },
    customerDetails: {
      firstAndLastName: true,
      email: true,
      phone: true,
      country: true,
      zip: true,
    },
  },
  'membership-billing-details': {
    paymentDetails: {
      zip: false,
    },
    customerDetails: false,
  },
  'member-contact': {
    primaryMember: {
      firstName: true,
      lastName: true,
      phone: true,
      dateOfBirth: true,
      address: {
        streetAddress1: true,
        suburb: true,
        state: true,
        zip: true,
        country: true,
      },
    },
  },
}

// ----- DISPLAY TEXT -----

/**
 * This object overrides the display text that is defined globally with string values that are
 * specific to the organization. Mustache-esque templating is available for dynamic strings using
 * {{ insertFieldName }} for interpolation.
 */

config.displayText = {
  ...baseDisplayText,
  [displayKeys.MEMBERSHIP_LABEL]: 'annual pass',
  [displayKeys.CREDIT_CARD_NUMBER]: 'Card Number',
  [displayKeys.ZIP]: 'Post Code',
  [displayKeys.STATE]: 'State / Province',
  [displayKeys.SUBURB]: 'Suburb / City',
  [displayKeys.UPGRADE_MEMBERSHIP_MODAL_CONTENT]: `
    <h2>Kids Go FREE!</h2>
    <p>
      Get 365 days of zoo fun from just $99 with a <strong> Zoo Friends Annual Pass</strong>.
      Plus, two kids go free!
    </p>
  `,
  [displayKeys.TICKET_DETAILS_INSTRUCTIONS]: `
    <p>You have chosen {{ displayName }}. Please enter your ticket details below. Children under 4 years are free.</p>
  `,
  [displayKeys.TICKET_PAYMENT_CONFIRMATION_INFO]:
    'Your PDF tickets and order confirmation have been sent to the email address provided. If you have not received this email, please check your junk folder before contacting our team for assistance.',
  [displayKeys.TICKET_DETAILS_INFO_BOX]: `
    <p>
      For groups of 12 or more please see the Group Bookings pages for
      <a href="https://taronga.org.au/sydney-zoo/group-rates"> Taronga Zoo Sydney </a>
      or <a href="https://taronga.org.au/dubbo-zoo/group-rates"> Taronga Western Plains Zoo Dubbo</a>.
      For all other enquiries please phone <a href="tel:+61299692777">+61 2 9969 2777</a>.
    </p>
  `,
  [displayKeys.NEW_MEMBERSHIP_INFO_BOX]: `
    <p>
      For new memberships, please visit the Zoo Friends office at Taronga Zoo Sydney,
      or the main ticket desk at Taronga Western Plains Zoo Dubbo,
      to collect your membership cards before entering the zoo.
    </p>
  `,
  [displayKeys.NEW_MEMBERSHIP_INFO_BOX_TITLE]:
    'Collecting Your Membership Card',
  [displayKeys.MEMBER_LOGIN_TITLE]: 'Existing Account?',
  [displayKeys.MEMBER_EMAIL_LOGIN_INSTRUCTIONS]:
    '<p>Sign in to purchase or renew annual passes.</p>',
  [displayKeys.MEMBER_ID_LOGIN_INSTRUCTIONS]:
    '<p>Sign in to purchase or renew annual passes.</p>',
  [displayKeys.FORGOT_PASSWORD]: "Don't have a password / forgot password?",
  [displayKeys.MEMBER_REGISTRATION_TITLE]: 'New Account',
  [displayKeys.MEMBER_REGISTRATION_INSTRUCTIONS]:
    'Register to buy a new annual pass.',
  [displayKeys.MEMBER_LOGIN_INFO_BOX]:
    '<p>Buying an Annual Pass as a gift? Complete your purchase via the <a href="https://www.thankq.net.au/zoofriendsmembership">Gift Membership Form</a> and check \'this is a gift\' before proceeding.</p>',
  [displayKeys.MEMBER_LOGIN_INFO_BOX_TITLE]: 'Gift Membership',
  [displayKeys.EXISTING_MEMBER_MODAL_CONTENT]: `
    <h2>Oops!</h2>
    <p>It looks like this email address has been used for a previous online purchase. Please login with your password or to register for your password <a href="/member/forgot-password">click here.</a></p>
  `,
  [displayKeys.MEMBERSHIP_COST_INFO_BOX_TITLE]:
    'Online prices - includes 10% discount',
  [displayKeys.MEMBERSHIP_COST_INFO_BOX]: `
    <p class="small-text"><br />
      2 named kids free per adult or concession annual pass.
      There is a $20 joining fee for all new membership purchases, charged once per membership group.<br />

      <br /><b>Note</b><br />
      Concession ID may be required for entry with a concession pass.<br /><br />

      A transferable guest pass can be used by anyone but it can only be purchased in conjunction with an Adult or Concession annual pass. Maximum of two transferable guest passes per membership group.
    </p>
  `,
  [displayKeys.MEMBERSHIP_RENEWAL_COST_INFO_BOX]: `
    <p class="small-text"><br />
      2 named kids free per adult or concession annual pass.<br />

      <br /><b>Note</b><br />
      Concession ID may be required for entry with a concession pass.<br /><br />

      A transferable guest pass can be used by anyone but it can only be purchased in conjunction with an Adult or Concession annual pass. Maximum of two transferable guest passes per membership group.
    </p>
  `,
  [displayKeys.RESET_PASSWORD]: 'Create New Password',
  [displayKeys.CONFIRM_PASSWORD]: 'Password Confirm',
  [displayKeys.PRIMARY_MEMBER_FORM_DETAILS]: `
    <p>
      Enter the details for the Primary Member in your group. This is
      the person who will receive renewal notifications and
      communications about your membership.
    </p>
  `,
  [displayKeys.PRIMARY_MEMBER_RENEWAL_FORM_DETAILS]: `
    <p>
      If the Primary Member in your membership has changed, please contact the Zoo Friends
      office to complete your renewal, or visit us on site.
    </p>
  `,
  [displayKeys.SECONDARY_MEMBER_FORM_DETAILS]: `
  <p>
    Please enter details below of all other members in your group including free children.
    Click the Add Member button to add more members and the Remove link to remove a member.
  </p>
`,
  [displayKeys.MEMBERSHIP_DETAILS_INSTRUCTIONS]: '',
  [displayKeys.PAYMENT_CONFIRMATION_SUBTITLE]: `
    <p>You have successfully purchased your annual pass.</p>
    <p>A copy of your purchase has been sent to the email provided.</p>
  `,
  [displayKeys.MEMBERSHIP_DETAILS_EXISTING_RENEWAL_NOTICE]: `<p>Your annual pass expires on {{ expirationDate }}. Please proceed below to renew your annual pass.</p>`,
  [displayKeys.MEMBERSHIP_DETAILS_RENEWAL_NOTICE]:
    'Your annual pass has expired. Please proceed below to renew your annual pass.',
  [displayKeys.MEMBER_STREET_ADDRESS]: 'Shipping Address',
  [displayKeys.MEMBER_LOGIN_TOOLTIP]:
    'If you have previously registered your email address please sign in here to purchase or renew your annual pass. If you have forgotten your password you can retrieve it here.',
  [displayKeys.MEMBER_REGISTRATION_TOOLTIP]:
    'If you have not previously registered your email address please register here and create your account before purchasing your annual passes. Thank you.',
}

// ----- ANALYTICS -----

/**
 * This object provides certain analytics packages to be enabled if an identifier is provided.
 */

config.analytics = {
  ga: '',
  gtm: process.env.TZ_GTM_CONTAINER_ID,
}
// ----- STEPS -----

config.ticketingSteps = [
  {
    name: 'Choose Your Tickets',
    route: 'details',
  },
  {
    name: 'Select Add-Ons',
    route: 'add-ons',
  },
  {
    name: 'Enter Your Details',
    route: 'payment',
  },
]

config.membershipSteps = [
  {
    name: 'Annual Pass Details',
    route: 'details',
  },
  {
    name: 'Payment Details',
    route: 'payment',
  },
]

export default config
