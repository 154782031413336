import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  // Input,
  InputError,
  omitLabelProps,
} from 'lp-components'
import {
  QuantityInput,
  SectionHeader,
  RenderedHTML,
  PriceDisplay,
} from 'components'
import { curry } from 'lodash/fp'
import { uniq, groupBy, map, isEmpty } from 'lodash'
import { toggleItemInArray } from 'utils'
import * as Types from 'types'

const propTypes = {
  options: PropTypes.arrayOf(Types.product).isRequired,
}

const defaultProps = {
  options: [],
}

// Modified from RadioGroupPriceSelection.js.
// The options are products. The value is an array of price selections for those products.

const isSamePrice = curry((a, b) => a.centamanId === b.centamanId)

function getQuantityForPrice(prices, price) {
  const matchingPrice = prices.find(isSamePrice(price))
  if (!matchingPrice) return 0
  return matchingPrice.quantity
}

function updateQuantity(prices, price, quantity) {
  // If it's not in the prices array yet, add it.
  if (!prices.find(isSamePrice(price)))
    return [...prices, { ...price, quantity }]
  // Otherwise, just update the quantity
  return prices.map((p) => (isSamePrice(p, price) ? { ...p, quantity } : p))
}

function removePriceQuantitiesForProduct(prices, product) {
  if (isEmpty(prices)) return [{ productId: product.id }]
  return prices.map((price) => {
    if (price.productId === product.id) return { ...price, quantity: 0 }
    return price
  })
}

function getSelectedOptionIdsFromInitialValue(initialValue, products) {
  const selectedPrices = initialValue.filter(
    (priceSelection) => priceSelection.quantity > 0
  )
  const selectedProductIds = uniq(
    selectedPrices.map((price) => price.productId)
  )
  const selectedProducts = products.filter((product) =>
    selectedProductIds.includes(product.id)
  )
  // debugger
  return selectedProducts.map((product) => product.centamanId)
}

function CheckboxGroupQuantityInput(props) {
  const {
    input: { value: rawValue, onChange, name },
    meta: { touched, invalid, error, initial: initialValue },
    options,
    ...rest
  } = omitLabelProps(props)
  const value = rawValue || [] // Takes care of initial state where value is an empty string
  const [selectedOptionIds, setSelectedOptionIds] = useState(
    getSelectedOptionIdsFromInitialValue(initialValue, options)
  )
  const optionsByCategory = groupBy(
    options,
    (option) => option.categoryName || ''
  )
  return (
    <React.Fragment>
      <fieldset className="RadioGroup" {...rest}>
        {map(optionsByCategory, (options, categoryName) => (
          <div key={categoryName}>
            {categoryName && <SectionHeader title={categoryName} />}
            {options.map((option) => {
              const checked = selectedOptionIds.includes(option.centamanId)
              return (
                <React.Fragment key={option.centamanId}>
                  <div className="action-item-container clearfix">
                    <fieldset className="checkbox">
                      <label className="checkbox-align">
                        <input
                          key={option.centamanId}
                          id={`${name}.${option.centamanId}`}
                          type="checkbox"
                          name={name}
                          onChange={() => {
                            // Toggle option selected
                            const newSelectedOptionIds = toggleItemInArray(
                              option.centamanId,
                              selectedOptionIds
                            )
                            setSelectedOptionIds(newSelectedOptionIds)
                            // If option was removed, clear the prices for that option too.
                            const wasRemoved = !newSelectedOptionIds.includes(
                              option.centamanId
                            )
                            if (wasRemoved)
                              return onChange(
                                removePriceQuantitiesForProduct(value, option)
                              )
                          }}
                          checked={checked}
                        />
                        {option.displayName}
                      </label>
                    </fieldset>
                    <div>
                      <PriceDisplay
                        price={option.price}
                        phrase={option.pricePhrase}
                      />
                      <RenderedHTML className="item-copy">
                        {option.descriptionHtml}
                      </RenderedHTML>
                    </div>
                    {checked && (
                      <>
                        <div className="separator"></div>
                        <h4>Select Amount</h4>
                        <div className="select-item-group-grid">
                          {option.prices.map((price) => (
                            <QuantityInput
                              key={price.centamanId}
                              input={{
                                value: getQuantityForPrice(value, price),
                                onChange: (quantity) =>
                                  onChange(
                                    updateQuantity(value, price, quantity)
                                  ),
                              }}
                              min={price.minQuantity}
                              max={price.maxQuantity}
                              itemName={price.displayName}
                              itemDescription={price.details}
                              price={price.price}
                            />
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                </React.Fragment>
              )
            })}
          </div>
        ))}
      </fieldset>
      <InputError {...{ error, touched, invalid, name }} />
    </React.Fragment>
  )
}

CheckboxGroupQuantityInput.propTypes = propTypes
CheckboxGroupQuantityInput.defaultProps = defaultProps

export default CheckboxGroupQuantityInput
