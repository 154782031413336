import React from 'react'
import { compose } from 'redux'
import { Field, FormSection, propTypes as formPropTypes } from 'redux-form'
import { ButtonArea, Input, SubmitButton } from 'lp-components'
import { lpForm } from 'lp-form'

function MemberLookupForm({ handleSubmit, invalid, pristine, submitting }) {
  return (
    <form onSubmit={handleSubmit}>
      <FormSection name="member">
        <Field name="lastName" component={Input} type="text" />
        <Field
          name="email"
          label={'Email Address'}
          component={Input}
          type="email"
        />
      </FormSection>
      <ButtonArea>
        <SubmitButton
          className="primary"
          {...{ invalid, pristine, submitting }}
        >
          Send Member Details
        </SubmitButton>
      </ButtonArea>
    </form>
  )
}

MemberLookupForm.propTypes = formPropTypes

export default compose(
  lpForm({
    name: 'member-login',
    constraints: {
      'member.lastName': { presence: true },
      'member.email': { presence: true, email: true },
    },
  })
)(MemberLookupForm)
