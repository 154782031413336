// Organization configuration file
import { displayText as baseDisplayText, displayKeys } from 'config'
import favicon from 'images/favicons/mystic-seaport.png'
import logoImage from 'images/logos/mystic-seaport/mystic-seaport.png'
import giftMembershipsImage from 'images/background/mystic_gift_memberships.jpg'
import * as Types from 'types'

const config = {}

// ----- CONSTANTS -----

config.DEFAULT_COUNTRY = 'UNITED STATES'
config.PROMPT_MEMBERSHIP_UPGRADE = false
config.SHOW_TICKET_DETAILS_INFO_BOX = false
config.SHOW_MEMBER_LOGIN_INFO_BOX = false
config.USE_MEMBERSHIP_QUANTITY_SELECTORS = true
config.ENABLE_BACK_BUTTON_ON_MEMBERSHIP_DETAILS = true
config.MEMBERSHIP_SHOW_COSTS = false
config.MEMBERSHIP_CONFIRMATION_SHOW_VALID_PERIOD = true
config.HAS_MEMBER_DISCOUNTS = true
config.DISPLAY_CARD_LOGOS = false
config.MEMBER_LOGIN_METHOD = Types.loginFormMethods.email
config.ENABLE_MEMBER_REGISTRATION = true
config.ENABLE_RECAPTCHA = true

// ----- BRANDING -----

// This is the fallback used if a webstore cannot be located. The paths should always be prepended with 'all'
config.defaultBranding = {
  className: 'mystic-seaport',
  title: 'Mystic Seaport',
  navLinks: [
    { name: 'Tickets', path: '/all/tickets' },
    { name: 'Memberships', path: '/all/memberships' },
    { name: 'Gifting', path: `/all/gift-memberships` },
    {
      name: 'Programs',
      externalUrl:
        'https://webstore1.centaman.net/MYSTICSEAPORTMUSEUM/Programs/tabid/66/Default.aspx',
    },
    { name: 'Donations', path: '/all/donate' },
  ],
  showLogin: true,
  logo: logoImage,
  homeUrl: 'https://www.mysticseaport.org/',
  pageTitle: 'Mystic Seaport | Streamlined Checkout',
  giftMembershipsImage,
  favicon,
}

config.webstoreBranding = {
  all: config.defaultBranding,
}

// ----- FORM FIELDS -----

/**
 * This object controls the fields that are visible for a particular form. The fields must
 * be nested within a key that is specific to a form name. Only the fields that the organization
 * wants to render need to be specified.
 */

config.fields = {
  'ticketing-billing-details': {
    paymentDetails: {
      zip: false,
    },
    customerDetails: {
      firstAndLastName: true,
      email: true,
      phone: true,
      zip: true,
    },
  },
  'membership-billing-details': {
    paymentDetails: {
      zip: false,
    },
    customerDetails: false,
  },
  'member-contact': {
    primaryMember: {
      title: true,
      firstName: true,
      lastName: true,
      dateOfBirth: false,
      address: {
        country: true,
        streetAddress1: true,
        city: true,
        state: true,
        zip: true,
      },
    },
  },
  'gift-membership-billing-details': {
    paymentDetails: {
      zip: false,
    },
    customerDetails: {
      firstAndLastName: true,
      email: true,
      phone: true,
      streetAddress1: true,
      city: true,
      state: true,
      zip: true,
    },
  },
  'donation-billing-details': {
    paymentDetails: {
      zip: false,
    },
    customerDetails: {
      firstAndLastName: true,
      email: true,
      phone: true,
      streetAddress1: true,
      city: true,
      state: true,
      zip: true,
    },
  },
}

// ----- DISPLAY TEXT -----

/**
 * This object overrides the display text that is defined globally with string values that are
 * specific to the organization. Mustache-esque templating is available for dynamic strings using
 * {{ insertFieldName }} for interpolation.
 */

config.displayText = {
  ...baseDisplayText,
  [displayKeys.CREDIT_CARD_NUMBER]: 'Card Number',
  [displayKeys.EMAIL_BILLING]:
    'Email (Please verify to ensure receipt delivery)',
  [displayKeys.SELECT_TICKET]: 'General Admission',
  [displayKeys.SELECT_TICKET_INSTRUCTIONS]: '',
  [displayKeys.DONATION_AMOUNT_DETAILS]: `
    <p>
      Thank you for donating to <strong>{{ displayName }}</strong>! If you would like to make this a tribute
      gift, please fill out the comment box after selecting your donation amount below.
    </p>
  `,
  [displayKeys.GIFT_MEMBERSHIP_PAYMENT_CONFIRMATION_SUBTITLE]: `
    <p>You have successfully placed your order.</p>
    <p>A copy of your purchase has been sent to the email provided.</p>
    <br />
    <p class="smaller-subtitle">Please contact the Mystic Seaport Membership Department with any questions: <a href="tel:+1-860-572-5339">(860) 572-5339</a> or <a href="mailto:membership@mysticseaport.org">membership@mysticseaport.org</a>.
  `,
  [displayKeys.GIFT_MEMBERSHIP_ADDITIONAL_INSTRUCTIONS]: `
    <p>
      Upon purchase, you will receive a gift certificate voucher for each gift membership you have purchased.
      Please present the emailed certificate to the gift recipient.
    </p>
    <p>
      When the recipient returns the completed
      form to the Membership Department, the Museum Membership will be activated for one year.
    </p>
  `,
}

// ----- ANALYTICS -----

/**
 * This object provides certain analytics packages to be enabled if an identifier is provided.
 */

config.analytics = {
  ga: process.env.MS_GA_CONTAINER_ID,
  gtm: process.env.MS_GTM_CONTAINER_ID,
  gtag: process.env.MS_GTAG_ID,
}

export default config
