import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Layout as BaseLayout } from 'base'
import { orgFontAssets } from 'config'
import { Helmet } from 'react-helmet'
import { selectors as globalSelectors } from 'global-reducer'
import { MaintenanceState } from 'components'

const propTypes = {
  children: PropTypes.node.isRequired,
  status: PropTypes.string,
  underMaintenance: PropTypes.bool,
}

const defaultProps = {
  status: '',
  underMaintenance: false,
}

const fontUrl = orgFontAssets({ orgName: 'mystic-seaport' })

function Layout({ children, status, underMaintenance }) {
  return (
    <BaseLayout>
      <Helmet>
        <link href={fontUrl} rel="stylesheet" />
      </Helmet>
      {underMaintenance ? <MaintenanceState status={status} /> : children}
    </BaseLayout>
  )
}

Layout.propTypes = propTypes
Layout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    status: globalSelectors.status(state),
    underMaintenance: !globalSelectors.active(state),
  }
}

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout)
