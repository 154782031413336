import React from 'react'
import PropTypes from 'prop-types'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { CalendarInput } from 'components'
import { stripTimezone } from 'utils'
import { noop } from 'lodash'

const propTypes = {
  ...formPropTypes,
  onClickOutside: PropTypes.func.isRequired,
  onMonthChange: PropTypes.func,
  excludedDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
}

const defaultProps = {
  onMonthChange: noop,
  excludedDates: [],
}

function TicketDateForm({
  handleSubmit,
  onClickOutside,
  submit,
  onMonthChange,
  excludedDates,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="ticketDate"
        label={false}
        component={CalendarInput}
        dateFormat="dddd, MMMM DD, YYYY"
        placeholderText="Choose"
        minDate={new Date()}
        submit={submit}
        onClickOutside={onClickOutside}
        format={stripTimezone}
        onMonthChange={(moment) => onMonthChange(moment.toDate())}
        excludeDates={excludedDates}
      />
    </form>
  )
}

TicketDateForm.propTypes = propTypes
TicketDateForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'ticketing-date',
    constraints: {
      ticketDate: { presence: true },
    },
    submitOnChange: true,
  })
)(TicketDateForm)
