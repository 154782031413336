import React from 'react'
import { Route, IndexRedirect } from 'react-router'
import * as Views from './views'
import StepLayout from './StepLayout'

const Routes = (
  <Route path="gift-memberships">
    <Route component={StepLayout}>
      <Route path="select" component={Views.SelectGiftMembership} />
      <Route path="payment" component={Views.GiftMembershipPaymentInfo} />
    </Route>
    <Route path="confirmation" component={Views.GiftMembershipConfirmation} />
    <IndexRedirect to="select" />
  </Route>
)

export default Routes
