import { compose } from 'recompose'
import { connect } from 'react-redux'
import { onMount } from 'lp-hoc'
import * as routerActions from 'react-router-redux'
import { routerSelectors } from 'utils'

// React Router v3 version of a <Redirect /> route (with enhancements related to capturing previous location state)
function Redirect() {
  return null
}

function mapStateToProps(state) {
  return {
    currentPath: routerSelectors.currentPath(state),
    search: routerSelectors.search(state),
  }
}

const mapDispatchToProps = {
  replace: routerActions.replace,
}

function redirect({
  replace,
  to,
  preserveQuery,
  saveUrl,
  search,
  currentPath,
}) {
  return replace({
    pathname: to,
    search: preserveQuery ? search : '',
    state: saveUrl ? { redirectUrl: currentPath } : {},
  })
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  onMount(redirect)
)(Redirect)
