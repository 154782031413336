import { handleActions } from 'redux-actions'
import { selectorForSlice, setState } from 'lp-redux-utils'
import { setOnSuccess } from 'lp-redux-api'
import * as apiActions from 'api-actions'
import * as actions from './actions'
import { createSelector } from 'reselect'
import { selectors as globalSelectors } from 'global-reducer'
import {
  getProductCategories,
  routerSelectors,
  setCategoryProducts,
} from 'utils'
import * as LS from 'local-storage'
import { map, sortBy } from 'lodash'

const reducerKey = 'donation'
const slice = 'root.donation'

const initialCartState = {
  listItems: [],
  priceSelections: [],
  addOnSelections: [],
}

const initialState = {}

const reducer = handleActions(
  {
    [apiActions.addDonationTypeToCart]: setOnSuccess(
      'cart',
      ({ payload: { data } }) => {
        LS.setCartToken(data.additionalData.cartToken)
        return data.attributes
      }
    ),
    [apiActions.fetchDonationCart]: setOnSuccess('cart'),
    [apiActions.fetchDonationProducts]: setOnSuccess('products'),
    [apiActions.fetchDonationProduct]: setOnSuccess('selectedDonation'),
    [apiActions.updateDonationCart]: setOnSuccess('cart'),
    [apiActions.createDonationOrder]: setOnSuccess('donationConfirmation'),
    [actions.emptyCart]: setState('cart', initialCartState),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  cart: select('cart'),
  donationConfirmation: select('donationConfirmation'),
  products: select('products'),
  selectedDonation: select('selectedDonation'),
}

selectors.productCategories = createSelector(
  [selectors.products],
  function(products) {
    if (!products) return
    const categories = getProductCategories(products)
    return sortBy(categories, 'position')
  }
)

selectors.productsByCategory = createSelector(
  [selectors.products, selectors.productCategories],
  function(products, productCategories) {
    if (!products || !productCategories) return

    return map(productCategories, (category) =>
      setCategoryProducts(category, products)
    )
  }
)

// Extend this to compute conditional steps (e.g., add-ons)
selectors.steps = createSelector(
  [globalSelectors.donationSteps],
  function(donationSteps) {
    return donationSteps
  }
)

selectors.currentStepIndex = createSelector(
  [selectors.steps, routerSelectors.pathname],
  function(steps, pathname) {
    return steps.findIndex((step) => pathname.includes(step.route))
  }
)

selectors.currentStep = createSelector(
  [selectors.steps, selectors.currentStepIndex],
  function(steps, currentStepIndex) {
    return steps[currentStepIndex]
  }
)

selectors.currentStepName = createSelector(
  [selectors.currentStep],
  function(currentStep) {
    return currentStep ? currentStep.name : ''
  }
)

selectors.nextStep = createSelector(
  [selectors.steps, selectors.currentStepIndex],
  function(steps, currentStepIndex) {
    return steps[currentStepIndex + 1]
  }
)

selectors.nextStepPath = createSelector(
  [selectors.nextStep],
  function(nextStep) {
    return nextStep ? nextStep.route : 'confirmation'
  }
)

export { reducer, selectors, reducerKey }
