import React from 'react'
import PropTypes from 'prop-types'
import { AddOnProductForm } from 'forms'
import * as Types from 'types'
import { noop } from 'lodash'

const propTypes = {
  productModalData: Types.productModalData.isRequired,
  addOrRemoveModalProduct: PropTypes.func.isRequired,
  cart: Types.cart.isRequired,
  isInModal: PropTypes.bool,
  hideModal: PropTypes.func,
}

const defaultProps = {
  isInModal: false,
  hideModal: noop,
}

function AddOnProduct({
  productModalData,
  cart,
  addOrRemoveModalProduct,
  isInModal,
  hideModal,
}) {
  const { image, header, body, modalType } = productModalData

  const previouslyAddedAddOnProduct = cart.listItems?.find(
    (item) => item.centamanId === productModalData.centamanId
  )

  return (
    <div id="add-on-product" className="form-container">
      {image && image.url && (
        <div className="image-wrapper">
          <img src={image.url} />
        </div>
      )}
      <h2>{header}</h2>
      <p>{body}</p>
      {previouslyAddedAddOnProduct && (
        <div className="selected-product">
          <p>
            <strong>Selected {modalType}:</strong> $
            {previouslyAddedAddOnProduct.price.toFixed(2)}
            <button
              onClick={() => addOrRemoveModalProduct(cart, productModalData)}
              type="button"
              className="link-warn"
            >
              {' '}
              Remove
            </button>
          </p>
        </div>
      )}
      {!previouslyAddedAddOnProduct && (
        <AddOnProductForm
          productModalData={productModalData}
          initialValues={productModalData}
          name={isInModal ? `${modalType}-modal` : modalType}
          onSubmit={(productModalData) => {
            if (isInModal) hideModal()
            return addOrRemoveModalProduct(cart, productModalData)
          }}
        />
      )}
      {isInModal && (
        <div className="modal-form-buttons">
          <button onClick={hideModal} className="decline-modal-product">
            No, thank you.
          </button>
        </div>
      )}
    </div>
  )
}

AddOnProduct.propTypes = propTypes
AddOnProduct.defaultProps = defaultProps

export default AddOnProduct
