/**
 * @name debouncePromise
 * @param {Function} func - The function to debounce (must return a promise)
 * @param {number} wait=0 - The duration to delay (ms)
 * @returns {Function} - Returns a debounced function that returns a promise that will execute
 * the inner function only after X seconds have elapsed from the last invocation
 */

export default function debouncePromise(func, wait = 0) {
  let lastInvokeTime = 0
  let timer = null

  return function(...args) {
    return new Promise((resolve, reject) => {
      const time = Date.now()

      if (time - lastInvokeTime < wait) {
        clearTimeout(timer)
      }

      timer = setTimeout(() => {
        return func(...args)
          .then(resolve)
          .catch(reject)
      }, wait)

      lastInvokeTime = time
    })
  }
}
