import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { TimeSelectButtonInput, SectionHeader } from 'components'
import * as Types from 'types'
import { Field } from 'redux-form'

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  selectTimeHeader: PropTypes.string.isRequired,
  timedTicketOffers: PropTypes.arrayOf(Types.offer).isRequired,
}

const defaultProps = {}

function SelectTimeForm({
  handleSubmit,
  selectTimeHeader,
  timedTicketOffers,
  submitting,
}) {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <SectionHeader title={selectTimeHeader} />
      <div>
        <Field
          name="offer"
          component={TimeSelectButtonInput}
          timedTicketOffers={timedTicketOffers}
          loading={submitting}
        />
      </div>
    </form>
  )
}

SelectTimeForm.propTypes = propTypes
SelectTimeForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'SelectTimeForm',
    constraints: {},
    submitOnChange: true,
  })
)(SelectTimeForm)
