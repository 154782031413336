import {
  pick,
  isNil,
  memoize,
  get,
  isBoolean,
  isString,
  isNumber,
  size,
  values,
} from 'lodash'

// Type enums

export const FieldTypes = {
  DATE: 'Date',
  BIT: 'Bit',
  TEXT: 'Text',
  DECIMAL: 'Decimal',
}

export const InputTypes = {
  COUNTRY: 'country',
  CHECKBOX: 'checkbox',
  DATE: 'date',
  EMAIL: 'email',
  FLOAT: 'float',
  INTEGER: 'integer',
  PHONE: 'phone',
  SELECT: 'select',
  STRING: 'string',
  TEXT: 'text',
  WAIVER: 'waiver',
}

// Returns a normalize() function that takes the value output from Redux form
// and returns a UDF object with the minimum required fields for posting to the cart.

export function createUdfFieldNormalizer(udf) {
  const requiredFields = pick(udf, [
    'id',
    'collectionId',
    'fieldName',
    'fieldType',
  ])
  return function normalize(value) {
    if (isNil(value) || value === '') return requiredFields
    return {
      ...requiredFields,
      value,
    }
  }
}

// Returns a validate() function that takes the value output from Redux form
// Memoizes to prevent react from continually recreating the validate function on render
export const createUdfFieldValidator = memoize((udf) => {
  return function validate(udfValue) {
    const value = get(udfValue, 'value')
    const presenceError = validatePresence(value, udf)
    if (presenceError) return presenceError
    const lengthError = validateLength(value, udf)
    if (lengthError) return lengthError
  }
})

export function udfFieldFormatter(udfValue) {
  if (!udfValue) return ''
  // API converts this value into a string
  if (isBitUdf(udfValue)) return castToBoolean(udfValue.value)
  return udfValue.value
}

function validateLength(value, udf) {
  // Do not validate field length for booleans (known Centaman limitation where field length is set to 1)
  if (isBitUdf(udf)) return
  if (udf.fieldLength && size(value) > Number(udf.fieldLength))
    return `${udf.label} can't be longer than ${udf.fieldLength} characters`
}

function validatePresence(value, udf) {
  if (!udf.isRequired) return
  if (isBitUdf(udf) && !value) return `You must agree to the ${udf.label}`
  if (isNil(value) || value === '') return `${udf.label} can't be blank`
}

export function getInputType(udf) {
  const inputType = udf.inputType || ''
  // If options exist, force into a dropdown (regardless of fieldType)
  if (Array.isArray(udf.options) && udf.options.length) return InputTypes.SELECT
  if (isTextUdf(udf)) return getTextType(inputType)
  if (isBitUdf(udf)) return getBitType(inputType, udf.modalContent)
  if (isDecimalUdf(udf)) return getDecimalType(inputType)
  return udf.fieldType
}

function getTextType(inputType) {
  // Default to a regular text input
  return isDefinedType(inputType) ? inputType : InputTypes.STRING
}

function getBitType(inputType, modalContent) {
  if (modalContent) return InputTypes.WAIVER
  // Default all Booleans to checkbox (if not defined)
  return isDefinedType(inputType) ? inputType : InputTypes.CHECKBOX
}

function getDecimalType(inputType) {
  // Default all Numerics to integer (if not defined)
  return isDefinedType(inputType) ? inputType : InputTypes.INTEGER
}

/* helpers for our helpers */

function isDefinedType(inputType) {
  // Returns true if contained in enum
  return values(InputTypes).includes(inputType)
}

function isBitUdf(udf) {
  return udf.fieldType === FieldTypes.BIT
}

function isDecimalUdf(udf) {
  return udf.fieldType === FieldTypes.DECIMAL
}

function isTextUdf(udf) {
  return udf.fieldType === FieldTypes.TEXT
}

function castToBoolean(value) {
  if (isBoolean(value)) return value
  if (isString(value)) return value === 'true'
  if (isNumber(value)) return value !== 0
  return false
}
