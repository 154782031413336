/**
 * @name getPostcodePattern
 * @param {String} country - Country corresponding to the selected country (e.g., United States or Australia)
 * @returns {RegExp} - Returns regex pattern. If the country cannot be located, it will default to
 * a more generic INTL pattern
 */

const defaultCountry = 'INTL'

const countryPatterns = {
  'UNITED STATES': /([0-9]{5})(?:[-\s]*([0-9]{4}))?$/,
  AUSTRALIA: /\d{4}$/,
  [defaultCountry]: /(?:[A-Z0-9]+([- ]?[A-Z0-9]+)*)?$/i,
}

// Borrowed from https://github.com/melwynfurtado/postcode-validator, but tweaked to default to INTL
export default function validatePostcode(country = defaultCountry) {
  if (!countryPatterns[country]) return countryPatterns[defaultCountry]

  return countryPatterns[country.toUpperCase()]
}
