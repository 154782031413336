import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { ForgotPasswordForm } from '../forms'
import { selectors as globalSelectors } from 'global-reducer'
import { modifyProps } from 'lp-hoc'
import { createDisplayOrgText } from 'utils'
import * as apiActions from 'api-actions'
import * as routerActions from 'react-router-redux'
import { clearMessages } from 'redux-flash'

const propTypes = {
  displayOrgText: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  requestPasswordReset: PropTypes.func.isRequired,
  contactEmail: PropTypes.string.isRequired,
  clearMessages: PropTypes.func.isRequired,
}

const defaultProps = {}

function ForgotPassword({
  displayOrgText,
  push,
  requestPasswordReset,
  contactEmail,
  clearMessages,
}) {
  return (
    <div>
      <div className="login-container reset-password">
        <div className="col-12">
          <h2>Reset Password</h2>
          <p>
            Please enter your email and instructions will be sent for password
            reset.
          </p>
          <ForgotPasswordForm
            initialValues={{
              member: {
                resetUrl: window.location.origin + '/member/reset-password',
              },
            }}
            onSubmit={(params) => {
              clearMessages()
              return requestPasswordReset(params).catch((e) => {
                // If the email isn't found, then the user should still be pushed to the confirmation page (onSubmitSuccess)
                if (e.status === 404) return
                throw new Error(
                  `We are unable to send password reset instructions at this time. Please try again later. If you are still are having problems, contact customer service at ${contactEmail}.`
                )
              })
            }}
            onSubmitSuccess={() => push('/member/forgot-password-confirmation')}
            displayOrgText={displayOrgText}
          />
          <Link to="/member/login">Back to Login</Link>
        </div>
      </div>
    </div>
  )
}

ForgotPassword.propTypes = propTypes
ForgotPassword.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    config: globalSelectors.config(state),
    contactEmail: globalSelectors.contactEmail(state),
  }
}

const mapDispatchToProps = {
  push: routerActions.push,
  requestPasswordReset: apiActions.requestPasswordReset,
  clearMessages,
}

const initializeOrgHelpers = ({ config }) => {
  return {
    displayOrgText: createDisplayOrgText(config),
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  modifyProps(initializeOrgHelpers)
)(ForgotPassword)
