import React from 'react'
import PropTypes from 'prop-types'
import { HorizontalCard } from 'components'

const propTypes = {
  name: PropTypes.string.isRequired,
  descriptionHtml: PropTypes.string,
  image: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
}

const defaultProps = {
  descriptionHtml: '',
}

function CustomProductCard({ name, descriptionHtml, image, path }) {
  return (
    <div className="horizontal-item">
      <HorizontalCard
        displayName={name}
        imageUrl={image}
        path={path}
        descriptionHtml={descriptionHtml}
        buttonText="Select"
      />
    </div>
  )
}

CustomProductCard.propTypes = propTypes
CustomProductCard.defaultProps = defaultProps

export default CustomProductCard
