import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { InfoBox, RenderedHTML } from 'components'

const propTypes = {
  perks: PropTypes.array,
  children: PropTypes.node,
  title: PropTypes.string,
}

const defaultProps = {
  perks: [],
  title: 'Membership Perks Include',
}

function MembershipPerksBox({ perks, children, title }) {
  return (
    <InfoBox title={title} hideIcon className="information-details-box">
      <ul>
        {perks.map((perk, idx) => (
          <li key={idx}>
            <RenderedHTML>{perk}</RenderedHTML>
          </li>
        ))}
      </ul>
      {children}
    </InfoBox>
  )
}

MembershipPerksBox.propTypes = exact(propTypes)
MembershipPerksBox.defaultProps = defaultProps

export default pure(MembershipPerksBox)
