import { handleActions } from 'redux-actions'
import { setOnSuccess } from 'lp-redux-api'
import * as apiActions from 'api-actions'
import { selectorForSlice, setState, unsetState } from 'lp-redux-utils'
import * as actions from './actions'
import { createSelector } from 'reselect'
import {
  first,
  flatten,
  get,
  groupBy,
  isEmpty,
  map,
  max,
  sortBy,
  uniqBy,
  flatMap,
} from 'lodash'
import { set } from 'lodash/fp'
import { format } from 'date-fns'
import { selectors as globalSelectors } from 'global-reducer'
import {
  getProductCategories,
  removeStep,
  routerSelectors,
  setCategoryProducts,
} from 'utils'
import * as LS from 'local-storage'
// import * as Types from 'types'
import * as cacheHelpers from './cacheHelpers'

const reducerKey = 'ticketing'
const slice = 'root.ticketing'

const today = format(new Date(), 'YYYY-MM-DD')

const initialCartState = {
  listItems: [],
  priceSelections: [],
  addOnSelections: [],
}

const initialState = {
  membershipUpgradeModalShown: LS.getMembershipUpgradeModalShown() || false,
  selectedTicket: {},
  loadingTicketTimes: false,
  timedTicketCache: {},
}

const reducer = handleActions(
  {
    [apiActions.fetchAddOnOffers]: setOnSuccess('addOnOfferTypes'),
    [apiActions.fetchAddOnTicketTypes]: setOnSuccess('addOnTicketTypes'),
    [apiActions.fetchExtraPrices]: setOnSuccess('addOnTicketTypes'),
    [apiActions.fetchAddOnTimedTickets]: setOnSuccess('addOnTimedTickets'),
    [apiActions.fetchAddOnTicketTimes]: setOnSuccess('addOnTicketTimes'),
    [apiActions.fetchProducts]: setOnSuccess('products'),
    [apiActions.fetchTicket]: setOnSuccess('selectedTicket'),
    [apiActions.fetchTicketCart]: setOnSuccess('cart'),
    [apiActions.fetchTicketTimes]: setOnSuccess('ticketTimes'),
    [apiActions.fetchTicketTypes]: setOnSuccess('ticketTypes'),
    [apiActions.fetchTimedTicketTypes]: setOnSuccess('ticketTypes'),
    [apiActions.updateTicketCartPriceSelections]: setOnSuccess('cart'),
    [apiActions.updateTicketCartAddOnSelections]: setOnSuccess('cart'),
    [apiActions.validateTicketCoupon]: setOnSuccess('cart'),
    [apiActions.removeTicketCoupon]: setOnSuccess('cart'),
    [actions.clearProducts]: unsetState('products'),
    [actions.selectTicket]: setState('selectedTicket'),
    [actions.setTicketConfirmation]: setState('ticketConfirmation'),
    [apiActions.addOrRemoveModalProduct]: setOnSuccess('cart'),
    [actions.clearAddOnTicketTypes]: unsetState('addOnTicketTypes'),
    [actions.clearAddOnTicketTimes]: unsetState('addOnTicketTimes'),
    [actions.clearTimedAddOnTimes]: setState(
      'timedTicketCache',
      ({ payload: addOnId }, state) => {
        const cache = get(state, 'timedTicketCache')
        return cacheHelpers.setTimes(cache, addOnId, [])
      }
    ),
    [actions.clearTimedAddOnPrices]: setState(
      'timedTicketCache',
      ({ payload: addOnId }, state) => {
        const cache = get(state, 'timedTicketCache')
        return cacheHelpers.setPrices(cache, addOnId, [])
      }
    ),
    [actions.setCart]: setState('cart'),
    [actions.setMembershipUpgradeModalShown]: setState(
      'membershipUpgradeModalShown',
      ({ payload }) => {
        // Add side effect of persisting in local storage
        LS.setMembershipUpgradeModalShown(payload)
        return payload
      }
    ),
    [actions.addTicket]: (state, { payload }) => {
      let tickets
      // If a ticket already exists, update it in its existing sequence. Else append it to the list
      if (
        state.ticketingForm.tickets.find(
          (ticket) => ticket.centamanId === payload.centamanId
        )
      ) {
        tickets = state.ticketingForm.tickets.map((ticket) => {
          if (ticket.centamanId !== payload.centamanId) return ticket
          return payload
        })
      } else {
        tickets = [...state.ticketingForm.tickets, payload]
      }
      return set('ticketingForm.tickets', tickets, state)
    },
    [actions.removeTicket]: (state, { payload }) => {
      const tickets = state.ticketingForm.tickets.reduce((acc, ticket) => {
        if (ticket.centamanId !== payload.centamanId) return [...acc, ticket]
        else if (payload.quantity === 0) return [...acc]

        return [...acc, payload]
      }, [])

      return set('ticketingForm.tickets', tickets, state)
    },
    [actions.setCurrentAddOn]: setState('currentAddOn'),
    [actions.clearSelectedAddOns]: setState('ticketingForm.addOns', () => []),
    [actions.setSelectedAddOns]: (state, { payload }) => {
      const formObj = {
        ...state.ticketingForm,
        addOns: payload,
      }
      return set('ticketingForm', formObj, state)
    },
    [actions.removeAddOn]: (state, { payload }) => {
      const addOns = [
        ...state.ticketingForm.addOns.filter(
          (addOn) => addOn.id !== payload.id
        ),
      ]
      const formObj = {
        ...state.ticketingForm,
        addOns: [...addOns, payload],
      }
      return set('ticketingForm', formObj, state)
    },
    [actions.addAddOn]: (state, { payload }) => {
      const addOns = [
        ...state.ticketingForm.addOns.filter((addOn) => {
          addOn.id !== payload.id
        }),
      ]
      const formObj = {
        ...state.ticketingForm,
        addOns: [...addOns, payload],
      }
      return set('ticketingForm', formObj, state)
    },
    [actions.setTicketDate]: (state, { payload }) => {
      return set('cart.startDate', payload, state)
    },
    [actions.replaceTicketingCart]: setState('cart'),
    [actions.emptyCart]: setState('cart', () => initialCartState),
    [actions.clearTicketTypes]: unsetState('ticketTypes'),
    [actions.clearSelectedTicket]: unsetState('selectedTicket'),
    [actions.setLoadingTicketTimes]: setState('loadingTicketTimes'),
    [apiActions.fetchExcludedDates]: setOnSuccess(
      'timedTicketCache',
      (
        {
          payload: {
            data: { productId, excludedDates, endDate },
          },
        },
        state
      ) => {
        const cache = get(state, 'timedTicketCache')
        return cacheHelpers.setExcludedDates(
          cache,
          productId,
          excludedDates,
          endDate
        )
      }
    ),
    [apiActions.fetchTimedAddOnTimes]: setOnSuccess(
      'timedTicketCache',
      (
        {
          payload: {
            data: { addOnId, times },
          },
        },
        state
      ) => {
        const cache = get(state, 'timedTicketCache')
        return cacheHelpers.setTimes(cache, addOnId, times)
      }
    ),
    [apiActions.fetchTimedAddOnPrices]: setOnSuccess(
      'timedTicketCache',
      (
        {
          payload: {
            data: { addOnId, prices },
          },
        },
        state
      ) => {
        const cache = get(state, 'timedTicketCache')
        return cacheHelpers.setPrices(cache, addOnId, prices)
      }
    ),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  addOns: select('ticketTypes.addOns'),
  membershipUpgradeModalShown: select('membershipUpgradeModalShown'),
  products: select('products'),
  selectedTicket: select('selectedTicket'),
  ticketConfirmation: select('ticketConfirmation.attributes'),
  allPrices: select('ticketTypes.prices'),
  offers: select('selectedTicket.offers'),
  addOnPrices: select('addOnTicketTypes.prices'),
  extraPrice: select('addOnTicketTypes'),
  cart: select('cart'),
  ticketTimes: select('ticketTimes'),
  addOnTicketTimes: select('addOnTicketTimes'),
  ticketTypesFailure: select('ticketTypes.failure.status'),
  ticketTimesFailure: select('ticketTimes.failure.status'),
  loadingTicketTimes: select('loadingTicketTimes'),
  coupon: select('cart.coupon'),
  timedTicketCache: select('timedTicketCache'),
}

selectors.selectedTicketDate = createSelector(
  [selectors.selectedTicket, selectors.cart],
  function(selectedTicket, cart) {
    if (!selectedTicket) return today
    // select greatest date between default and nextAvailable to avoid fetching data for earlier date that is no longer available
    const upcomingTicketDates = [
      selectedTicket.defaultStartDate,
      selectedTicket.nextAvailableDate,
    ].filter((date) => date >= today)
    const ticketStartDate = max(upcomingTicketDates)
    // keep selected date, if any, or use date from selectedTicket, or today
    return cart.startDate || ticketStartDate || today
  }
)

selectors.isSelectedTicketTimed = createSelector(
  [selectors.selectedTicket],
  function(selectedTicket) {
    if (!selectedTicket) return
    return selectedTicket['timed?']
  }
)

selectors.excludedDates = createSelector(
  [selectors.selectedTicket, selectors.timedTicketCache],
  function(selectedTicket, timedTicketCache) {
    if (!selectedTicket) return []
    return cacheHelpers.getExcludedDates(timedTicketCache, selectedTicket.id)
  }
)

selectors.productCategories = createSelector(
  [selectors.products],
  function(products) {
    if (!products) return

    const categories = getProductCategories(products)
    return sortBy(categories, 'position')
  }
)

selectors.productsByCategory = createSelector(
  [selectors.products, selectors.productCategories],
  function(products, productCategories) {
    if (!products || !productCategories) return

    return map(productCategories, (category) =>
      setCategoryProducts(category, products)
    )
  }
)

selectors.ticketCategories = createSelector(
  [selectors.ticketTimes],
  function(ticketTimes) {
    if (!ticketTimes) return []
    if (!ticketTimes.length) return []
    return uniqBy(
      ticketTimes.map(({ name, position }) => ({
        name,
        position,
        groupedTickets: ticketTimes.filter((time) => time.name === name),
      })),
      'name'
    )
  }
)

selectors.timedTicketOffers = createSelector(
  [selectors.ticketCategories],
  function(ticketCategories) {
    if (!ticketCategories) return []
    return flatMap(ticketCategories, (category) => category.groupedTickets)
  }
)

selectors.addOnTicketCategories = createSelector(
  [selectors.addOnTicketTimes],
  function(addOnTicketTimes) {
    if (!addOnTicketTimes) return []
    if (!addOnTicketTimes.length) return []
    return uniqBy(
      addOnTicketTimes.map(({ name, position }) => ({
        name,
        position,
        groupedAddOnTickets: addOnTicketTimes.filter(
          (time) => time.name === name
        ),
      })),
      'name'
    )
  }
)

selectors.selectedOffer = createSelector(
  [selectors.timedTicketOffers, selectors.cart],
  (timedTicketOffers, cart) => {
    if (isEmpty(timedTicketOffers) || !cart.offerId) return
    return timedTicketOffers.find((offer) => offer.centamanId === cart.offerId)
  }
)

selectors.prices = createSelector(
  [
    selectors.allPrices,
    selectors.isSelectedTicketTimed,
    selectors.selectedOffer,
  ],
  function(allPrices, isSelectedTicketTimed, selectedOffer) {
    if (!allPrices) return allPrices
    if (!isSelectedTicketTimed) return allPrices
    // If it's a timed ticket and there's a selected offer, filter prices by it.
    if (!selectedOffer) return null
    const pricesForOffer = allPrices.filter(
      (price) => price.offerId === selectedOffer.centamanId
    )
    // If there are no prices for offer, they must be loading, so we'll return a nil state.
    return pricesForOffer.length ? pricesForOffer : null
  }
)

selectors.pricesByCategory = createSelector(
  [selectors.prices],
  (prices) => {
    if (!prices || !prices.length) return []

    const groupedByPosition = groupBy(prices, 'categoryPosition')

    return map(groupedByPosition, (values) => {
      const item = first(values) // there should always be at least 1 item
      return {
        categoryName: get(item, 'categoryName'),
        categorySubtitle: get(item, 'categorySubtitle'),
        prices: values,
      }
    })
  }
)

// Computed from global ticketing steps
selectors.steps = createSelector(
  [globalSelectors.ticketingSteps, selectors.addOns],
  function(ticketingSteps, addOns) {
    const hasAddons = !!(addOns && addOns.length)
    // Only show add-on step if there are add-ons for the current ticket.
    return hasAddons ? ticketingSteps : removeStep(ticketingSteps, 'add-ons')
  }
)

// Compute current step by looking at the pathname
selectors.currentStepIndex = createSelector(
  [selectors.steps, routerSelectors.pathname],
  function(steps, pathname) {
    return steps.findIndex((step) => pathname.includes(step.route))
  }
)

selectors.currentStep = createSelector(
  [selectors.steps, selectors.currentStepIndex],
  function(steps, currentStepIndex) {
    return steps[currentStepIndex]
  }
)

selectors.currentStepName = createSelector(
  [selectors.currentStep],
  function(currentStep) {
    return currentStep ? currentStep.name : ''
  }
)

selectors.nextStep = createSelector(
  [selectors.steps, selectors.currentStepIndex],
  function(steps, currentStepIndex) {
    return steps[currentStepIndex + 1]
  }
)

selectors.nextStepPath = createSelector(
  [selectors.nextStep],
  function(nextStep) {
    return nextStep ? nextStep.route : 'confirmation'
  }
)

selectors.hidePaymentDetails = createSelector(
  [selectors.cart],
  function(cart) {
    return !cart || cart.total === 0
  }
)

selectors.allAddOnPrices = createSelector(
  [selectors.addOns],
  function(addOns) {
    if (!addOns) return
    return flatten(addOns.map((addOn) => addOn.prices))
  }
)

export { reducer, selectors, reducerKey }
