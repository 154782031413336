import React from 'react'
import { routes as BaseRoutes } from 'base'
import * as Views from './views'
import { Route, IndexRoute } from 'react-router'
import Layout from './Layout'
import WebstoreLayout from 'base/WebstoreLayout'

function Routes() {
  return (
    <BaseRoutes indexPath="/" orgSlug="big-rivers" layout={Layout}>
      <IndexRoute component={Views.Home} />
      <Route path=":webstore" component={WebstoreLayout}>
        <Route path="memberships" component={Views.SelectMembership} />
      </Route>
    </BaseRoutes>
  )
}

export default Routes
