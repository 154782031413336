import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { DisplayMemberships, MainHeader, ProductEmptyState } from 'components'
import { Spinner } from 'lp-components'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import { onMount, waitFor } from 'lp-hoc'
import { Link } from 'react-router'
import { selectors as globalSelectors } from 'global-reducer'
import { selectors as memberSelectors } from 'base/member/reducer'
import { routerSelectors } from 'utils'
import { displayKeys } from 'config'

const propTypes = {
  currentPath: PropTypes.string.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  productsByCategory: PropTypes.arrayOf(Types.productCategory),
  primaryMember: Types.primaryMember,
  webstore: PropTypes.string.isRequired,
  displayOrgText: PropTypes.func.isRequired,
}

const defaultProps = {
  primaryMember: null,
}

function SelectMembership({
  currentPath,
  loggedIn,
  productsByCategory,
  primaryMember,
  webstore,
  displayOrgText,
}) {
  return (
    <div className="step-container">
      {!productsByCategory.length ? (
        <ProductEmptyState title={displayKeys.SELECT_MEMBERSHIP_TYPE}>
          <p>
            {displayOrgText(displayKeys.SELECT_MEMBERSHIP_NO_MEMBERSHIPS_FOUND)}
          </p>
        </ProductEmptyState>
      ) : (
        <div className="row">
          <MainHeader
            title={displayOrgText(displayKeys.SELECT_MEMBERSHIP_TITLE)}
            disablePrevStep
          >
            <p>{displayOrgText(displayKeys.SELECT_MEMBERSHIP_INSTRUCTIONS)}</p>
            {!loggedIn && (
              <p>
                If you have an existing{' '}
                {displayOrgText(displayKeys.SELECT_MEMBERSHIP_TYPE)}, please{' '}
                <Link
                  to={{
                    pathname: '/member/login',
                    state: { redirectUrl: currentPath },
                  }}
                >
                  log in
                </Link>{' '}
                to renew.
              </p>
            )}
          </MainHeader>
          <DisplayMemberships
            currentPath={currentPath}
            loggedIn={loggedIn}
            productsByCategory={productsByCategory}
            primaryMember={primaryMember}
            webstore={webstore}
          />
        </div>
      )}
    </div>
  )
}

SelectMembership.propTypes = propTypes
SelectMembership.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentPath: routerSelectors.currentPath(state),
    loggedIn: memberSelectors.loggedIn(state),
    productsByCategory: selectors.productsByCategory(state),
    primaryMember: selectors.primaryMember(state),
    webstore: globalSelectors.webstore(state),
    displayOrgText: globalSelectors.displayOrgText(state),
  }
}

const mapDispatchToProps = {
  fetchMemberships: apiActions.fetchMemberships,
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  onMount('fetchMemberships'),
  waitFor('productsByCategory', Spinner)
)(SelectMembership)
