import * as Orgs from './orgs'

const domainMap = {
  /* Production domains */
  'tickets.bigriverswaterpark.com': Orgs.bigRivers,
  'checkout.mysticseaport.org': Orgs.mysticSeaport,
  'ticketing.discoveryparkofamerica.com': Orgs.discoveryPark,
  'sales.taronga.org.au': Orgs.tarongaZoo,
  'shop.jbzoo.org': Orgs.johnBall,
  'fresno-production.launchpadtest.com': Orgs.fresnoChaffee,
  'checkout.fresnochaffeezoo.org': Orgs.fresnoChaffee,
  'zootickets.clevelandmetroparks.com': Orgs.clevelandMetroparks,
  'tickets.clemetzoo.com': Orgs.clevelandMetroparks,

  /* Testing domains */

  // Taronga
  'tarongazoo.launchpadattractions.com': Orgs.tarongaZoo,
  'tarongazoo-preprod.launchpadtest.com': Orgs.tarongaZoo,
  // 'taronga-checkout-client-dev.herokuapp.com': Orgs.tarongaZoo, // Taronga Staging
  // 'test.centaman.new.taronga.org.au': Orgs.tarongaZoo, // Taronga Production Verification
  // 'tarongazoo.preprod.launchpadattractions.com': Orgs.tarongaZoo,

  // Discovery Park
  'discoverypark-client-dev.herokuapp.com': Orgs.discoveryPark,
  'discoverypark.launchpadattractions.com': Orgs.discoveryPark,
  'discoverypark-preprod.launchpadtest.com': Orgs.discoveryPark,
  'discoverypark.preprod.launchpadattractions.com': Orgs.discoveryPark,

  // Big Rivers
  'grandtexas.launchpadattractions.com': Orgs.bigRivers, // legacy name
  'bigrivers.launchpadattractions.com': Orgs.bigRivers,
  'bigrivers-preprod.launchpadtest.com': Orgs.bigRivers,
  'test.checkout.bigriverswaterpark.com': Orgs.bigRivers,

  // Mystic Seaport
  'mysticseaport.launchpadattractions.com': Orgs.mysticSeaport,
  'mysticseaport.preprod.launchpadattractions.com': Orgs.mysticSeaport,
  'mysticseaport-preprod.launchpadtest.com': Orgs.mysticSeaport,

  // Fresno Chaffee
  'fresnochaffee-client-dev.herokuapp.com': Orgs.fresnoChaffee,
  'fresnochaffee.launchpadattractions.com': Orgs.fresnoChaffee,
  'fresnochaffee-preprod.launchpadattractions.com': Orgs.fresnoChaffee,
  'fresnochaffee-preprod.launchpadtest.com': Orgs.fresnoChaffee,

  // John Ball
  'johnball-client-dev.herokuapp.com': Orgs.johnBall,
  'johnball.launchpadattractions.com': Orgs.johnBall,
  'johnball-preprod.launchpadtest.com': Orgs.johnBall,

  // Cleveland Metroparks
  'clevelandmetroparks-client-dev.herokuapp.com': Orgs.clevelandMetroparks,
  'clevelandmetroparks.launchpadattractions.com': Orgs.clevelandMetroparks,
  'clevelandmetroparks.launchpadtest.com': Orgs.clevelandMetroparks,
  'clevelandmetroparks-production.launchpadtest.com': Orgs.clevelandMetroparks,
  'clevelandmetroparks-preprod.launchpadtest.com': Orgs.clevelandMetroparks,

  // Demo
  'lpldemo.launchpadattractions.com': Orgs.demo,
}

// Return the correct app for the given domain
export function getAppForDomain(domain) {
  const app = domainMap[domain]
  if (app) return app
  // eslint-disable-next-line
  console.warn(
    'No app found for domain ' + domain + ', falling back to demo app.'
  )
  return Orgs.demo
}

export default domainMap
