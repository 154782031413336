import React from 'react'
import PropTypes from 'prop-types'
import { compose, withState } from 'recompose'
import { connect } from 'react-redux'
import { StepNav } from 'components'
import * as apiActions from 'api-actions'
import { onMount, waitFor } from 'lp-hoc'
import { Spinner } from 'lp-components'
import * as Types from 'types'
import { selectors } from './reducer'
import { get } from 'lodash'

const propTypes = {
  children: PropTypes.node.isRequired,
  steps: PropTypes.arrayOf(Types.step).isRequired,
  currentStepIndex: PropTypes.number.isRequired,
}

const defaultProps = {}

function StepLayout({ children, currentStepIndex, steps }) {
  return (
    <div>
      <StepNav steps={steps} currentStepIndex={currentStepIndex} />
      {children}
    </div>
  )
}

StepLayout.propTypes = propTypes
StepLayout.defaultProps = defaultProps

const loadInitialCartData = ({ fetchCart, fetchProduct, setInitialized }) => {
  fetchCart()
    .then((cart) => {
      if (!get(cart, 'productId')) return

      return fetchProduct(cart.productId)
    })
    .then(() => setInitialized(true))
}

function mapStateToProps(state) {
  return {
    steps: selectors.steps(state),
    currentStepIndex: selectors.currentStepIndex(state),
  }
}

const mapDispatchToProps = {
  fetchCart: apiActions.fetchDonationCart,
  fetchProduct: apiActions.fetchDonationProduct,
}

export default compose(
  withState('initialized', 'setInitialized', false),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  onMount(loadInitialCartData),
  waitFor('initialized', Spinner)
)(StepLayout)
