import React from 'react'
import { compose } from 'redux'
import { Link } from 'react-router'
import { Field, FormSection, propTypes as formPropTypes } from 'redux-form'
import { ButtonArea, Input, InputError, SubmitButton } from 'lp-components'
import { lpForm } from 'lp-form'
import { displayKeys } from 'config'
import { createFieldValidator } from 'utils'

const propTypes = {
  ...formPropTypes,
}

const defaultProps = {}

const validateEmail = createFieldValidator('member.email', {
  presence: true,
  email: true,
})
const validatePassword = createFieldValidator('member.password', {
  presence: true,
})

function MemberEmailLoginForm({
  handleSubmit,
  submitting,
  displayOrgText,
  error,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <FormSection name="member">
        <Field
          name="email"
          component={Input}
          label={displayOrgText(displayKeys.EMAIL)}
          validate={validateEmail}
          autoComplete="username"
        />
        <Field
          name="password"
          component={Input}
          type="password"
          label={displayOrgText(displayKeys.PASSWORD)}
          validate={validatePassword}
          autoComplete="current-password"
        />
      </FormSection>
      <InputError className="formwide-error" error={error} invalid touched />
      <ButtonArea>
        <SubmitButton submitting={submitting}>Login</SubmitButton>
        <Link to="/member/forgot-password">
          {displayOrgText(displayKeys.FORGOT_PASSWORD)}
        </Link>
      </ButtonArea>
    </form>
  )
}

MemberEmailLoginForm.propTypes = propTypes
MemberEmailLoginForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'member-email-login',
  })
)(MemberEmailLoginForm)
