/**
 * @name getAvailableMembers
 * @param {Array} allMembers - All of the secondary members that exist on the account.
 * @param {Array} selectedMembers - All of the members that have already been selected for a membership during a particular transaction.
 * @returns {Array} - Returns an array of the members that exist on the account that have not _already_ been selected by the user for a membership.
 */

import { differenceWith } from 'lodash'

function getAvailableMembers(allMembers, selectedMembers) {
  return differenceWith(
    allMembers,
    selectedMembers,
    (member, selectedMember) => member.id === selectedMember.id
  )
}

export default getAvailableMembers
