import { compact, countBy, map } from 'lodash'

// Given an array of members, return the quantities of each membership type
// Output is an object of { [membershipTypId]: quantity }

function getMembershipTypeQuantities(members) {
  return countBy(compact(map(members, 'membershipTypeId')))
}

export default getMembershipTypeQuantities
