import React from 'react'
import { routes as BaseRoutes } from 'base'
import Layout from './Layout'
import * as Views from './views'
import { Route, Redirect } from 'react-router'
import WebstoreLayout from 'base/WebstoreLayout'
import DonationStepLayout from 'base/donation/StepLayout'
import GiftMembershipsStepLayout from 'base/giftMemberships/StepLayout'

function Routes() {
  return (
    <BaseRoutes
      indexPath="/all/tickets"
      orgSlug="discovery-park"
      layout={Layout}
    >
      <Route path=":webstore" component={WebstoreLayout}>
        <Route path="memberships" component={Views.SelectMembership} />
        <Route component={GiftMembershipsStepLayout}>
          <Route
            path="gift-memberships/select"
            component={Views.SelectGiftMembership}
          />
          <Redirect path="gift-memberships" to="gift-memberships/select" />
        </Route>
        <Route component={DonationStepLayout}>
          <Route
            path="donate/details(/:id)"
            component={Views.DonationDetails}
          />
        </Route>
      </Route>
    </BaseRoutes>
  )
}

export default Routes
