import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { MemberLookupForm } from '../forms'
import * as apiActions from 'api-actions'
import * as flashActions from 'redux-flash'

const propTypes = {
  memberLookupFail: PropTypes.func,
  memberLookupSuccess: PropTypes.func,
  requestMemberDetails: PropTypes.func.isRequired,
}

const defaultProps = {}

function MemberLookup({
  memberLookupFail,
  memberLookupSuccess,
  requestMemberDetails,
}) {
  return (
    <div className="row">
      <div className="login-container">
        <div className="col-6 login-container-left">
          <h2>Retreive your Membership</h2>
          <p>
            Please enter your personal details to receive an email with your
            membership details.
          </p>
          <p>The email will include the following:</p>
          <ul>
            <li>Member ID</li>
            <li>Expiration Date</li>
            <li>Primary Contact Last Name</li>
          </ul>
        </div>
        <div className="col-6">
          <MemberLookupForm
            onSubmit={requestMemberDetails}
            onSubmitSuccess={memberLookupSuccess}
            onSubmitFail={memberLookupFail}
          />
        </div>
      </div>
    </div>
  )
}

MemberLookup.propTypes = propTypes

MemberLookup.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  requestMemberDetails: (params) => {
    flashActions.clearMessages()
    return apiActions.requestMemberDetails(params)
  },
  memberLookupSuccess: (response) =>
    flashActions.flashMessage(response.message),
  memberLookupFail: (errors) =>
    flashActions.flashErrorMessage(
      `Sorry there was an error: ${errors.message}`
    ),
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(MemberLookup)
