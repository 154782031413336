import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import { Button, Spinner } from 'lp-components'
import * as actions from '../actions'
import { waitFor } from 'lp-hoc'

const propTypes = {
  currentMember: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
}

const defaultProps = {}

function MemberStatus({ currentMember, logout }) {
  return (
    <div className="login-container reset-password account-info">
      <div className="col-12">
        <h2>Account Overview</h2>
        <p>You are currently logged in.</p>
        <ul>
          <li>
            <b>Member ID</b>
            <br /> {currentMember.primaryMemberId}
          </li>
          <li>
            <b>Email</b>
            <br /> {currentMember.primaryMember.email}
          </li>
        </ul>
        <Button type="primary" onClick={logout}>
          Log out
        </Button>
      </div>
    </div>
  )
}

MemberStatus.propTypes = propTypes
MemberStatus.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentMember: selectors.currentMember(state),
  }
}

const mapDispatchToProps = {
  logout: actions.logout,
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  waitFor('currentMember', Spinner)
)(MemberStatus)
