/**
 * @name findExistingMembership
 * @param {Object} member - The member to search
 * @param {Number} id - The id of the membership
 * @param {Object} [options] - Optional options object
 * @param {Boolean} [options.onlyActive=false] - Allows user to specify a search through only active memberships
 * @returns {Object|undefined} - Returns either the last existing membership found (so as to return latest expiration date) or undefined, prioritizing active memberships
 */

import { findLast } from 'lodash'

function findExistingMembership(member, id, { onlyActive = false } = {}) {
  if (!member || !id) return

  const activeMembership = findLast(
    member.activeMemberships,
    ({ packageId }) => packageId === id
  )
  if (onlyActive) return activeMembership

  return (
    activeMembership ||
    findLast(member.expiredMemberships, ({ packageId }) => packageId === id)
  )
}

export default findExistingMembership
