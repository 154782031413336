import { push } from 'react-router-redux'
import { createAction } from 'redux-actions'

export const clearMemberSession = createAction('CLEAR_MEMBER_SESSION')
export const unsetMember = createAction('UNSET_MEMBER')
export const setMemberSession = createAction('SET_MEMBER_SESSION')
export const clearState = createAction('CLEAR_STATE')

export const logout = () => (dispatch) => {
  dispatch(clearState())
  dispatch(clearMemberSession())
  dispatch(push('/member/login'))
}
