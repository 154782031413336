import React from 'react'
// import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'

const propTypes = {}

const defaultProps = {}

function Home() {
  return <div className="content">Discovery Park Home Page</div>
}

Home.propTypes = propTypes
Home.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Home)
