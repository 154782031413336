// Organization configuration file
import {
  displayText as baseDisplayText,
  ticketingSteps as baseTicketingSteps,
  membershipSteps as baseMembershipSteps,
  // displayKeys,
} from 'config'
import zooLogo from 'images/logos/demo/zoo-logo.svg'
import amusementLogo from 'images/logos/demo/amusement-logo.svg'
import generalLogo from 'images/logos/demo/lpl-logo.svg'
import * as Types from 'types'

const config = {}

// ----- CONSTANTS -----

config.DEFAULT_COUNTRY = 'UNITED STATES'
config.PROMPT_MEMBERSHIP_UPGRADE = false
config.SHOW_TICKET_DETAILS_INFO_BOX = false
config.SHOW_MEMBER_LOGIN_INFO_BOX = false
config.USE_MEMBERSHIP_QUANTITY_SELECTORS = true
config.ENABLE_BACK_BUTTON_ON_MEMBERSHIP_DETAILS = true
config.MEMBERSHIP_SHOW_COSTS = false
config.MEMBERSHIP_CONFIRMATION_SHOW_VALID_PERIOD = true
config.DISPLAY_CARD_LOGOS = false
config.MEMBER_LOGIN_METHOD = Types.loginFormMethods.email
config.ENABLE_MEMBER_REGISTRATION = true

// ----- BRANDING -----

// This is the fallback used if a webstore cannot be located. The paths should always be prepended with 'all'
config.defaultBranding = {
  title: 'LPL Demo Org',
  className: 'lpl-demo',
  logo: generalLogo,
  navLinks: [],
  homeUrl: '/',
  showLogin: false,
  pageTitle: 'LPL Demo | Streamlined Checkout',
}

config.webstoreBranding = {
  zoo: {
    title: 'LPL Zoo',
    className: 'lpl-demo zoo',
    logo: zooLogo,
    navLinks: [
      { name: 'Tickets', path: '/zoo/tickets' },
      { name: 'Memberships', path: '/zoo/memberships' },
      { name: 'Donations', path: '/zoo/donate' },
    ],
    homeUrl: 'https://launchpadlab.com/',
    showLogin: true,
    pageTitle: 'LPL Zoo | Streamlined Checkout',
  },
  amusement: {
    title: 'LPL Amusement',
    className: 'lpl-demo amusement',
    logo: amusementLogo,
    navLinks: [
      { name: 'Tickets', path: '/amusement/tickets' },
      { name: 'Memberships', path: '/amusement/memberships' },
    ],
    pageTitle: 'LPL Amusement | Streamlined Checkout',
  },
  all: config.defaultBranding,
}

// ----- FORM FIELDS -----

/**
 * This object controls the fields that are visible for a particular form. The fields must
 * be nested within a key that is specific to a form name. Only the fields that the organization
 * wants to render need to be specified.
 */

config.fields = {
  'ticketing-billing-details': {
    paymentDetails: {
      zip: true,
    },
    customerDetails: {
      firstAndLastName: true,
      email: true,
      zip: true,
      phone: true,
    },
  },
  'membership-billing-details': {
    paymentDetails: {
      zip: true,
    },
    customerDetails: false,
  },
  'member-contact': {
    primaryMember: {
      firstName: true,
      lastName: true,
      dateOfBirth: true,
      address: {
        country: true,
        streetAddress1: true,
        city: true,
        state: true,
        zip: true,
      },
    },
  },
  'donation-billing-details': {
    paymentDetails: {
      zip: false,
    },
    customerDetails: {
      firstAndLastName: true,
      email: true,
      phone: true,
      country: true,
      streetAddress1: true,
      city: true,
      state: true,
      zip: true,
    },
  },
}

// ----- DISPLAY TEXT -----

/**
 * This object overrides the display text that is defined globally with string values that are
 * specific to the organization. Mustache-esque templating is available for dynamic strings using
 * {{ insertFieldName }} for interpolation.
 *
 * Import displayKeys from 'config' when overriding values, e.g.,
 * {
 *   ...baseDisplayText
 *   [displayKeys.EMAIL]: 'Email Address'
 * }
 */

config.displayText = {
  ...baseDisplayText,
}

// ----- STEPS -----

/**
 * These objects control the existence and ordering of steps for various flows.
 */

config.ticketingSteps = baseTicketingSteps
config.membershipSteps = baseMembershipSteps

export default config
