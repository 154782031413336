import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { modal } from 'lp-hoc'
import RenderedHTML from './RenderedHTML'

const propTypes = {
  handleHide: PropTypes.func.isRequired,
  policy: PropTypes.string.isRequired,
}

const defaultProps = {}

function PrivacyModal({ policy, handleHide }) {
  return (
    <div>
      <h2>Privacy Policy</h2>
      <RenderedHTML>{policy}</RenderedHTML>
      <button onClick={handleHide} className="modal-close">
        ×
      </button>
    </div>
  )
}

PrivacyModal.propTypes = propTypes
PrivacyModal.defaultProps = defaultProps

export default compose(modal({ name: 'privacy-modal' }))(PrivacyModal)
