import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as routerActions from 'react-router-redux'
import { startCase } from 'lodash'

const propTypes = {
  children: PropTypes.node,
  disablePrevStep: PropTypes.bool,
  goBack: PropTypes.func.isRequired,
  title: PropTypes.string,
}

const defaultProps = {
  children: null,
  disablePrevStep: false,
  title: '',
}

function MainHeader({ children, disablePrevStep, goBack, title }) {
  return (
    <div className="main-header">
      <h1>{startCase(title)}</h1>
      {!disablePrevStep && (
        <a onClick={goBack} className="previous-page">
          Back
        </a>
      )}
      {children}
    </div>
  )
}

MainHeader.propTypes = propTypes

MainHeader.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  goBack: routerActions.goBack,
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(MainHeader)
