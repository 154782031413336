import React from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import visaLogo from 'images/logos/credit-cards/light/visa.png'
import masterCardLogo from 'images/logos/credit-cards/light/master-card.png'
import discoverLogo from 'images/logos/credit-cards/light/discover.png'
import americanExpressLogo from 'images/logos/credit-cards/light/american-express.png'

const propTypes = {}

const defaultProps = {}

function CreditCardLogos() {
  return (
    <div className="card-logos-group">
      <img src={visaLogo} />
      <img src={masterCardLogo} />
      <img src={discoverLogo} />
      <img src={americanExpressLogo} />
    </div>
  )
}

CreditCardLogos.propTypes = exact(propTypes)
CreditCardLogos.defaultProps = defaultProps

export default pure(CreditCardLogos)
