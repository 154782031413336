import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { isNumber } from 'lodash'
import { displayCurrency } from 'utils'

const propTypes = {
  phrase: PropTypes.string,
  price: PropTypes.number,
}

const defaultProps = {
  phrase: '',
  price: null,
}

// Prepend price phrase (if it exists); e.g., 'Starting from $79.99'
const createPriceContent = (price, pricePhrase) => {
  const currencyDisplayText = displayCurrency(price, { displayFree: true })
  if (!pricePhrase) return currencyDisplayText
  return pricePhrase + ' ' + currencyDisplayText
}

function PriceDisplay({ phrase, price }) {
  if (!isNumber(price)) return null

  return (
    <div className="price">
      <p>{createPriceContent(price, phrase)}</p>
    </div>
  )
}

PriceDisplay.propTypes = exact(propTypes)
PriceDisplay.defaultProps = defaultProps

export default pure(PriceDisplay)
