// import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as routerActions from 'react-router-redux'
import { Spinner } from 'lp-components'
import { selectors } from '../reducer'
import * as actions from '../actions'
import * as apiActions from 'api-actions'
import { onMount, waitFor } from 'lp-hoc'
import { selectors as globalSelectors } from 'global-reducer'
import { first } from 'lodash'

const propTypes = {
  memberships: PropTypes.array.isRequired,
  webstore: PropTypes.string.isRequired,
}

const defaultProps = {}

// This component renders nothing, it just redirects to a membership details route.
function MainMembershipRedirect() {
  return null
}

MainMembershipRedirect.propTypes = propTypes
MainMembershipRedirect.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    memberships: selectors.memberships(state),
    webstore: globalSelectors.webstore(state),
  }
}

const mapDispatchToProps = {
  fetchMemberships: apiActions.fetchMemberships,
  setSelectedMembership: actions.setSelectedMembership,
  replace: routerActions.replace,
}

const redirectToMainMembership = ({
  memberships,
  replace,
  webstore,
  setSelectedMembership,
}) => {
  // Choose the main one if and only if one exists
  if (!memberships || memberships.length !== 1)
    return replace(`/${webstore}/memberships`)

  const mainMembership = first(memberships)
  setSelectedMembership(mainMembership) // preload membership in state
  return replace(`/${webstore}/memberships/details/${mainMembership.id}`)
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  onMount('fetchMemberships'),
  waitFor('memberships', Spinner),
  onMount(redirectToMainMembership)
)(MainMembershipRedirect)
