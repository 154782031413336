// import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as routerActions from 'react-router-redux'
import { Spinner } from 'lp-components'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import { onMount, waitFor } from 'lp-hoc'
import { selectors as globalSelectors } from 'global-reducer'
import { first } from 'lodash'

const propTypes = {
  products: PropTypes.array.isRequired,
  webstore: PropTypes.string.isRequired,
}

const defaultProps = {}

// This component renders nothing, it just redirects to a donation details route.
function MainDonationRedirect() {
  return null
}

MainDonationRedirect.propTypes = propTypes
MainDonationRedirect.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    products: selectors.products(state),
    webstore: globalSelectors.webstore(state),
  }
}

const mapDispatchToProps = {
  fetchProducts: apiActions.fetchDonationProducts,
  replace: routerActions.replace,
}

// We can use whatever logic we want to determine what the "main" donation is.
// For now, we're just using the first one.
const redirectToMainDonation = ({ products, replace, webstore }) => {
  const mainDonation = first(products)
  if (!mainDonation) return replace(`/${webstore}/donate`)

  return replace(`/${webstore}/donate/details/${mainDonation.id}`)
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  onMount('fetchProducts'),
  waitFor('products', Spinner),
  onMount(redirectToMainDonation)
)(MainDonationRedirect)
