/**
 * The methods supported by IE are deprecated and strongly recommended against by MDN, hence
 * utilizing a polyfill for IE instead of leveraging the base solution across all browsers.
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent#Polyfill
 *
 * @name polyfillCustomEvent
 * @returns Undefined but sets CustomEvent on the window object to allow it to be used as a constructor.
 */

export default function polyfillCustomEvent() {
  if (typeof window.CustomEvent === 'function') return false

  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined }
    var evt = document.createEvent('CustomEvent')
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail)

    return evt
  }

  CustomEvent.prototype = window.Event.prototype
  window.CustomEvent = CustomEvent
}
