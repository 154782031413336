// values for mystic seaport RACE_ETH udf

export const raceEthnicityOptions = [
  {
    key: 'Black/African American/of African descent',
    value: 'Black/African American/of African descent',
  },
  {
    key:
      'American Indian/Native American/Alaska Native/Native Hawaiian/Pacific Islander/Indigenous American',
    value:
      'American Indian/Native American/Alaska Native/Native Hawaiian/Pacific Islander/Indigenous American',
  },
  {
    key: 'Asian/South Asian/Asian American',
    value: 'Asian/South Asian/Asian American',
  },
  {
    key: 'Hispanic/Latino/Latina/Latinx/Latin American',
    value: 'Hispanic/Latino/Latina/Latinx/Latin American',
  },
  {
    key: 'Middle Eastern',
    value: 'Middle Eastern',
  },
  {
    key: 'Multi-racial/Mixed race or ethnicity',
    value: 'Multi-racial/Mixed race or ethnicity',
  },
  {
    key: 'White ',
    value: 'White ', // note: need to keep space to match centaman picklist value on submit
  },
  {
    key: 'Any other race or ethnicity not described above',
    value: 'Any other race or ethnicity not described above',
  },
  {
    key: 'I decline to answer.',
    value: 'I decline to answer.',
  },
]

export default raceEthnicityOptions
