import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import { onMount, waitFor, modifyProps } from 'lp-hoc'
import * as apiActions from 'api-actions'
import { Link } from 'react-router'
import { selectors as apiSelectors } from 'lp-redux-api'
import { Spinner } from 'lp-components'
import { SelectAddOnsForm, SelectTimedAddOnsForm } from '../forms'
import { CouponCode, StickyContainer, MainHeader, Receipt } from 'components'
import * as flashActions from 'redux-flash'
import * as routerActions from 'react-router-redux'
import { isEmpty, negate, noop, sum } from 'lodash'
import { selectors as globalSelectors } from 'global-reducer'
import * as actions from '../actions'
import { isTimedTicket, createDisplayOrgText } from 'utils'
import { displayKeys } from 'config'

const propTypes = {
  addOns: PropTypes.arrayOf(Types.addOn).isRequired,
  allAddOnPrices: PropTypes.arrayOf(Types.price).isRequired,
  addOnPrices: PropTypes.arrayOf(Types.price),
  cart: PropTypes.object,
  displayOrgText: PropTypes.func.isRequired,
  isCartLoading: PropTypes.bool.isRequired,
  selectedTicket: Types.ticket.isRequired,
  webstore: PropTypes.string.isRequired,
  updateTicketCartAddOnSelections: PropTypes.func.isRequired,
  replaceCart: PropTypes.func.isRequired,
  removeCoupon: PropTypes.func.isRequired,
  validateCoupon: PropTypes.func.isRequired,
  nextStepPath: PropTypes.string.isRequired,
  currentStepName: PropTypes.string.isRequired,
  timedTicketCache: PropTypes.object.isRequired,
  selectedTicketDate: PropTypes.string,
  fetchAddOnTicketTypes: PropTypes.func.isRequired,
  fetchAddOnTicketTimes: PropTypes.func.isRequired,
  fetchTimedAddOnTimes: PropTypes.func.isRequired,
  clearTimedAddOnTimes: PropTypes.func.isRequired,
  fetchTimedAddOnPrices: PropTypes.func.isRequired,
  clearTimedAddOnPrices: PropTypes.func.isRequired,
  fetchExcludedDates: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func,
}

const defaultProps = {
  initialValues: {},
  addOnPrices: [],
  displayOrgText: noop,
}

function getQuantityForPrice(price, quantities) {
  return quantities.find((quantity) => quantity.centamanId === price.centamanId)
}

function changeSelectionQuantities(allPrices, priorSelections, newQuantities) {
  // Turn prior selections into the type of objects that come from the forms
  const priorQuantities = priorSelections.map(
    ({ centamanId, quantity, startDate, endDate, tourEventId }) => ({
      centamanId,
      quantity,
      startDate,
      endDate,
      tourEventId,
    })
  )
  const pricesWithUpdatedQuantities = allPrices.map((price) => {
    const quantityInfo = getQuantityForPrice(price, newQuantities) ||
      getQuantityForPrice(price, priorQuantities) || { quantity: 0 }
    return {
      ...price,
      ...quantityInfo,
    }
  })
  return pricesWithUpdatedQuantities.filter((price) => price.quantity > 0)
}

function SelectAddOns({
  addOns,
  allAddOnPrices,
  timedTicketCache,
  fetchExcludedDates,
  cart,
  isCartLoading,
  selectedTicket,
  updateTicketCartAddOnSelections,
  webstore,
  replaceCart,
  removeCoupon,
  validateCoupon,
  nextStepPath,
  currentStepName,
  fetchTimedAddOnTimes,
  clearTimedAddOnTimes,
  fetchTimedAddOnPrices,
  clearTimedAddOnPrices,
  selectedTicketDate,
  displayOrgText,
  flashErrorMessage,
}) {
  const generalAdmissionAddOns = addOns.filter(negate(isTimedTicket))
  const timedAddOns = addOns.filter(isTimedTicket)
  const getCurrentTransactionQuantity = (addOnId, cart) => {
    if (cart.addOnSelections.length === 0) return 0
    const matchingPriceSelections = cart.addOnSelections.filter(
      (selection) => selection.productId === addOnId
    )
    const quantities = matchingPriceSelections.map(
      (selection) => selection.quantity
    )
    return sum(quantities)
  }
  const warnTransactionCapExceeded = (cap) => {
    flashErrorMessage(`Only ${cap} tickets can be purchased at a time.`)
  }
  return (
    <div className="step-container">
      <div>
        <MainHeader title={currentStepName}>
          <p>Please select the add-ons you would like to purchase below.</p>
        </MainHeader>
        <div className="row">
          <div className="col-8">
            <div className="confirmation-info">
              {addOns.length ? (
                <p>
                  Below are the optional add-ons you can include with your
                  ticket purchase.
                </p>
              ) : (
                <p>
                  There are currently no optional add-ons available for this
                  ticket.
                </p>
              )}
            </div>
            <div>
              <div>
                {/* GA */}
                <SelectAddOnsForm
                  addOns={generalAdmissionAddOns}
                  allAddOnPrices={allAddOnPrices}
                  cart={cart}
                  onSubmit={(quantities) => {
                    updateTicketCartAddOnSelections(
                      cart,
                      changeSelectionQuantities(
                        allAddOnPrices,
                        cart.addOnSelections,
                        quantities
                      )
                    )
                  }}
                  debounceSubmit={500}
                  initialValues={cart.addOnSelections.filter(
                    negate(isTimedTicket)
                  )}
                  getCurrentTransactionQuantity={getCurrentTransactionQuantity}
                  warnTransactionCapExceeded={warnTransactionCapExceeded}
                  flashErrorMessage={flashErrorMessage}
                />
                <SelectTimedAddOnsForm
                  initialDate={selectedTicketDate}
                  addOns={timedAddOns}
                  cart={cart}
                  debounceSubmit={500}
                  selectTimeHeader={displayOrgText(displayKeys.SELECT_TIME)}
                  onSubmit={(quantities) =>
                    updateTicketCartAddOnSelections(
                      cart,
                      changeSelectionQuantities(
                        allAddOnPrices,
                        cart.addOnSelections,
                        quantities
                      )
                    )
                  }
                  initialValues={cart.addOnSelections.filter(isTimedTicket)}
                  getCurrentTransactionQuantity={getCurrentTransactionQuantity}
                  warnTransactionCapExceeded={warnTransactionCapExceeded}
                  // Ticket info cache, and various callbacks
                  {...{
                    timedTicketCache,
                    fetchExcludedDates,
                    fetchTimedAddOnTimes,
                    clearTimedAddOnTimes,
                    fetchTimedAddOnPrices,
                    clearTimedAddOnPrices,
                  }}
                />
              </div>
            </div>
            <Link
              className="button-primary full-width-button"
              to={`/${webstore}/tickets/${nextStepPath}`}
            >
              Continue
            </Link>
          </div>
          <StickyContainer className="col-4">
            <Receipt
              title={selectedTicket.displayName}
              image={selectedTicket.image}
              cart={cart}
              displayTotal
              isCartLoading={isCartLoading}
            />
            <CouponCode
              coupon={cart.coupon}
              cartItems={cart.listItems}
              replaceCart={replaceCart}
              removeCoupon={removeCoupon}
              validateCoupon={validateCoupon}
            />
          </StickyContainer>
        </div>
      </div>
    </div>
  )
}

SelectAddOns.propTypes = propTypes
SelectAddOns.defaultProps = defaultProps

const checkCartForTicket = ({ cart, flashErrorMessage, push, webstore }) => {
  if (!cart || !cart.productId) {
    flashErrorMessage('Please select a ticket below', { timeout: 6000 })
    return push(`/${webstore}/tickets`)
  }

  if (cart.productId && isEmpty(cart.priceSelections)) {
    flashErrorMessage('Please add at least one ticket', { timeout: 6000 })
    return push(`/${webstore}/tickets/details/${cart.productId}`)
  }
}

function mapStateToProps(state) {
  return {
    timedTicketCache: selectors.timedTicketCache(state),
    addOns: selectors.addOns(state),
    allAddOnPrices: selectors.allAddOnPrices(state),
    cart: selectors.cart(state),
    currentStepName: selectors.currentStepName(state),
    isCartLoading:
      apiSelectors.isLoading(
        state,
        apiActions.updateTicketCartAddOnSelections
      ) || apiSelectors.isLoading(state, apiActions.addTicketToCart),
    nextStepPath: selectors.nextStepPath(state),
    selectedTicket: selectors.selectedTicket(state),
    webstore: globalSelectors.webstore(state),
    selectedTicketDate: selectors.selectedTicketDate(state),
    prices: selectors.prices(state),
    addOnPrices: selectors.addOnPrices(state),
    extraPrice: selectors.extraPrice(state),
    config: globalSelectors.config(state),
  }
}

const mapDispatchToProps = {
  flashErrorMessage: flashActions.flashErrorMessage,
  push: routerActions.push,
  removeCoupon: apiActions.removeTicketCoupon,
  replaceCart: actions.replaceTicketingCart,
  validateCoupon: apiActions.validateTicketCoupon,
  updateTicketCartAddOnSelections: apiActions.updateTicketCartAddOnSelections,
  fetchProducts: apiActions.fetchProducts,
  clearAddOnTicketTypes: actions.clearAddOnTicketTypes,
  clearAddOnTicketTimes: actions.clearAddOnTicketTimes,
  clearTimedAddOnTimes: actions.clearTimedAddOnTimes,
  fetchAddOnTicketTimes: apiActions.fetchAddOnTicketTimes,
  fetchExtraPrices: apiActions.fetchExtraPrices,
  fetchAddOnTicketTypes: apiActions.fetchAddOnTicketTypes,
  fetchExcludedDates: apiActions.fetchExcludedDates,
  fetchTicketTimes: apiActions.fetchTicketTimes,
  fetchTimedAddOnTimes: apiActions.fetchTimedAddOnTimes,
  addTicketToCart: apiActions.addTicketToCart,
  clearTimedAddOnPrices: actions.clearTimedAddOnPrices,
  fetchTimedAddOnPrices: apiActions.fetchTimedAddOnPrices,
}

function modify({ config }) {
  return {
    displayOrgText: createDisplayOrgText(config),
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  modifyProps(modify),
  onMount(checkCartForTicket),
  waitFor('addOns', Spinner)
)(SelectAddOns)
