import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { SelectTicket as BaseSelectTicket } from 'base/ticketing/views'
import { onMount } from '@launchpadlab/lp-hoc'
import HighCapacityModal from '../components/HighCapacityModal'

const propTypes = {}

const defaultProps = {}

function SelectTicket() {
  return (
    <div>
      <BaseSelectTicket></BaseSelectTicket>
      <div className={'high-capacity-modal'}>
        <HighCapacityModal />
      </div>
    </div>
  )
}

SelectTicket.propTypes = propTypes
SelectTicket.defaultProps = defaultProps

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = {
  showHighCapacityModal: HighCapacityModal.show,
}

function initialize({ showHighCapacityModal }) {
  // Display high capacity if during high capacity weekend
  const highCapacityStartDate = new Date('May 19, 2023 00:00:00'),
    highCapacityEndDate = new Date('May 21, 2023 00:00:00'),
    currentDate = new Date()

  if (
    currentDate > highCapacityStartDate &&
    currentDate < highCapacityEndDate
  ) {
    setTimeout(showHighCapacityModal, 1000)
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  onMount(initialize)
)(SelectTicket)
