import React from 'react'
import { Route, IndexRoute, Redirect } from 'react-router'
import * as Views from './views'
import StepLayout from './StepLayout'
import { AuthorizedRoute } from 'lp-components'
import { isAuthenticated } from 'auth'

const Routes = (
  <Route path="memberships">
    <IndexRoute component={Views.SelectMembership} />
    <Route path="main" component={Views.MainMembershipRedirect} />
    <AuthorizedRoute authFunction={isAuthenticated} redirect="/member/login">
      <Route component={StepLayout}>
        <Route
          path="details(/:membershipId)"
          component={Views.MembershipDetails}
        />
        <Route path="add-ons" component={Views.SelectAddOns} />
        <Route path="payment" component={Views.MembershipPaymentInfo} />
        <Route path="confirmation" component={Views.MembershipConfirmation} />
      </Route>
    </AuthorizedRoute>
    <Route path="timeout" component={Views.MembershipTimeout} />
    <Redirect from="*" to="/memberships" />
  </Route>
)

export default Routes
