import TagManager from 'react-gtm-module'
import { startCase } from 'lodash'

// Google Tag Manager interface

class GoogleTagManager {
  constructor() {
    this._enabled = false
  }

  initialize(gtmId) {
    this._enabled = true
    return TagManager.initialize({ gtmId })
  }

  // Requires tag to use enhanced ecommerce tracking
  sendTransactionData(transaction, { productName } = {}) {
    if (!this._enabled) return

    const mappedTransaction = {
      purchase: {
        actionField: {
          id: Number(transaction.receipt) || transaction.receipt, // convert to integer if possible, otherwise return string
          affiliation: 'Centaman Streamlined Checkout',
          revenue: transaction.total,
          currency: transaction.currency,
          tax: transaction.cart.taxes,
          shipping: 0,
          coupon: transaction.cart.coupon || '',
          option: transaction.paymentMethod,
        },
        products: transaction.cart.listItems.map((item) => ({
          id: item.centamanId,
          name: item.name,
          brand: startCase(transaction.webstore) || 'All',
          category: item.resourceType,
          price: item.price,
          quantity: item.quantity,
          variant: productName || '',
          coupon: item.coupon || '',
        })),
      },
    }

    // Trigger GTM tag (must be configured to listen for purchase-success)
    return window.dataLayer.push({
      event: 'purchase-success',
      ecommerce: mappedTransaction,
    })
  }
}

export default new GoogleTagManager()
