import React from 'react'
import PropTypes from 'prop-types'
import { Input, InputLabel, Checkbox, Select, Textarea } from 'lp-components'
import * as Types from 'types'
import { Field } from 'redux-form'
import { blurInput, regionData, stripTimezone } from 'utils'
import {
  createUdfFieldNormalizer,
  createUdfFieldValidator,
  udfFieldFormatter,
  getInputType,
  InputTypes,
} from './helpers'
import { memoize, noop } from 'lodash'
import { format } from 'date-fns'
import CalendarInput from '../CalendarInput'

const propTypes = {
  udf: Types.udf.isRequired,
  name: PropTypes.string.isRequired,
  showModal: PropTypes.func,
}

const defaultProps = {
  showModal: noop,
}

// eslint-disable-next-line
const createWaiverLabel = memoize((label) => (props) => (
  <InputLabel {...props}>
    <strong>{label}</strong>
  </InputLabel>
))

// Factory for different input types
function UdfField({ name, showModal, udf, ...rest }) {
  const baseProps = {
    name,
    label: udf.label,
    required: udf.isRequired,
    requiredIndicator: '*',
    validate: createUdfFieldValidator(udf),
    normalize: createUdfFieldNormalizer(udf),
    format: udfFieldFormatter,
    ...rest,
  }

  const inputType = getInputType(udf)

  switch (inputType) {
    case InputTypes.FLOAT:
    case InputTypes.INTEGER:
      return (
        <Field
          {...baseProps}
          component={Input}
          type="number"
          min="0"
          step={inputType === InputTypes.INTEGER ? '1' : '.01'}
        />
      )
    case InputTypes.CHECKBOX:
      return <Field {...baseProps} component={Checkbox} />
    case InputTypes.WAIVER: {
      return (
        <Field
          {...baseProps}
          component={Checkbox}
          labelComponent={createWaiverLabel(udf.label)}
          onChange={(e, { value }) => {
            // Prevent redux-form change dispatch if trying to check the checkbox _without_ showing the terms modal
            if (value) {
              e.preventDefault()
              return showModal(e, udf, name)
            }
          }}
        />
      )
    }
    case InputTypes.SELECT: {
      const options = udf.options.map((option) => option.trim())
      return (
        <Field
          {...baseProps}
          component={Select}
          options={options}
          placeholder="Select"
        />
      )
    }
    case InputTypes.COUNTRY: {
      const options = regionData.COUNTRIES
      return (
        <Field
          {...baseProps}
          component={Select}
          options={options}
          placeholder="Select"
        />
      )
    }
    case InputTypes.DATE:
      return (
        <Field
          {...baseProps}
          component={CalendarInput}
          dateFormat="dddd, DD MMMM, YYYY"
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          autoComplete="off"
          placeholderText="Select"
          onClickOutside={({ input }) => blurInput(input.name)}
          format={(udf) => {
            if (!udf) return ''
            return stripTimezone(udf.value)
          }}
          parse={(date) => {
            if (!date) return date
            return format(date, 'YYYY-MM-DD')
          }}
        />
      )
    case InputTypes.TEXT:
      return (
        <Field
          {...baseProps}
          component={Textarea}
          maxLength={Number(udf.fieldLength)}
          hideCharacterCount
        />
      )
    case InputTypes.PHONE:
      return <Field {...baseProps} component={Input} type="tel" />
    case InputTypes.EMAIL:
      return <Field {...baseProps} component={Input} type="email" />
    default:
      return <Field {...baseProps} component={Input} />
  }
}

UdfField.propTypes = propTypes
UdfField.defaultProps = defaultProps

export default UdfField
