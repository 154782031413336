import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import DOMPurify from 'dompurify'
import classnames from 'classnames'

const propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
}

const defaultProps = {
  className: '',
}

function RenderedHTML({ children, className, ...rest }) {
  if (!children) return null
  return (
    <div
      className={classnames('user-generated-content', className)}
      {...rest}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(children, { FORBID_ATTR: ['style'] }),
      }}
    />
  )
}

RenderedHTML.propTypes = propTypes
RenderedHTML.defaultProps = defaultProps

export default pure(RenderedHTML)
