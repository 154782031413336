import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { InfoBox } from 'components'
import * as Types from 'types'
import { filter } from 'lodash'
import { displayCurrency } from 'utils'

const propTypes = {
  membershipTypes: PropTypes.arrayOf(Types.membershipType),
  children: PropTypes.node,
  title: PropTypes.string,
}

const defaultProps = {
  membershipTypes: [],
  title: 'Membership Costs',
}

function MembershipCostInfoBox({ membershipTypes, children, title }) {
  const typesToDisplay = filter(membershipTypes, 'price')
  return (
    <InfoBox title={title}>
      <ul>
        {typesToDisplay.map(({ id, displayName, price }) => (
          <li key={id}>
            {displayName}: {displayCurrency(price)}
          </li>
        ))}
      </ul>
      {children}
    </InfoBox>
  )
}

MembershipCostInfoBox.propTypes = exact(propTypes)
MembershipCostInfoBox.defaultProps = defaultProps

export default pure(MembershipCostInfoBox)
