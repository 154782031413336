import React from 'react'
import { compose } from 'redux'
import { Field, FormSection, propTypes as formPropTypes } from 'redux-form'
import { ButtonArea, Input, InputError, SubmitButton } from 'lp-components'
import { lpForm } from 'lp-form'
import { displayKeys } from 'config'
import { createFieldValidator } from 'utils'

const propTypes = {
  ...formPropTypes,
}

const defaultProps = {}

const validatePassword = createFieldValidator('member.password', {
  presence: true,
  length: {
    minimum: 8,
    message: 'Password must have at least 8 characters',
  },
  format: {
    pattern: /.*[A-Z]{1,}.*/,
    message: 'Password must contain at least one uppercase letter',
  },
})
const validateConfirmPassword = createFieldValidator('member.confirmPassword', {
  presence: true,
  equality: {
    attribute: 'member.password',
    message: 'Passwords must match',
  },
})

function ResetPasswordForm({
  handleSubmit,
  invalid,
  pristine,
  submitting,
  displayOrgText,
  error,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <FormSection name="member">
        <Field
          name="password"
          component={Input}
          type="password"
          label={displayOrgText(displayKeys.PASSWORD)}
          validate={validatePassword}
          autoComplete="new-password"
        />
        <Field
          name="confirmPassword"
          component={Input}
          type="password"
          label={displayOrgText(displayKeys.CONFIRM_PASSWORD)}
          validate={validateConfirmPassword}
          autoComplete="new-password"
        />
      </FormSection>
      <InputError className="formwide-error" error={error} invalid touched />
      <ButtonArea>
        <SubmitButton {...{ invalid, pristine, submitting }}>
          Submit
        </SubmitButton>
      </ButtonArea>
    </form>
  )
}

ResetPasswordForm.propTypes = propTypes
ResetPasswordForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'member-reset-password',
  })
)(ResetPasswordForm)
