import React from 'react'
import PropTypes from 'prop-types'
import {
  blurDirty,
  fieldPropTypes,
  omitLabelProps,
  LabeledField,
} from 'lp-components'
import { compose } from 'recompose'
import MaskedInputField from 'react-maskedinput'

const propTypes = {
  ...fieldPropTypes,
  type: PropTypes.string,
  children: PropTypes.node,
}

const defaultProps = {
  type: 'text',
}

function MaskedInput(props) {
  const {
    input: { name, value, onBlur, onChange },
    meta, // eslint-disable-line no-unused-vars
    className, // eslint-disable-line no-unused-vars
    type,
    children,
    ...rest
  } = omitLabelProps(props)
  return (
    <LabeledField {...props}>
      <div className="input-wrapper">
        <MaskedInputField
          {...{
            id: name,
            name,
            type,
            value,
            onBlur,
            onChange,
            ...rest,
          }}
        />
        {children}
      </div>
    </LabeledField>
  )
}

MaskedInput.defaultProps = defaultProps
MaskedInput.propTypes = propTypes

export default compose(blurDirty())(MaskedInput)
