import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import HorizontalCard from './HorizontalCard'
import RenderedHTML from './RenderedHTML'
import SectionHeader from './SectionHeader'
import ReadMoreModal from './ReadMoreModal'
import classnames from 'classnames'
import { findExistingMembership } from 'utils'
import { selectors as globalSelectors } from 'global-reducer'
import { connect } from 'react-redux'
import { displayKeys } from 'config'

const propTypes = {
  displayOrgText: PropTypes.func.isRequired,
  productsByCategory: PropTypes.arrayOf(Types.productCategory),
  primaryMember: Types.primaryMember,
  webstore: PropTypes.string.isRequired,
  showReadMoreModal: PropTypes.func.isRequired,
}

const defaultProps = {}

// Only return a generic header title if there are _multiple_ categories (supports backwards compatability for existing customers)
const getGenericHeader = (productsByCategory) => {
  if (productsByCategory.length === 1) return null
  return 'Memberships'
}

function omitUpgradeProducts(products) {
  return products.filter((product) => !product.isUpgrade)
}

function OmitProduct(props) {
  const {
    category,
    primaryMember,
    displayOrgText,
    webstore,
    showReadMoreModal,
  } = props
  return omitUpgradeProducts(category.products).map((product) => {
    const isCurrentMembership = !!findExistingMembership(
      primaryMember,
      product.centamanId,
      { onlyActive: true }
    )
    const currentMembershipLabel = isCurrentMembership
      ? displayOrgText(displayKeys.SELECT_MEMBERSHIP_CURRENT_MEMBERSHIP_OVERLAY)
      : ''
    return (
      <div
        key={product.id}
        className={classnames('horizontal-item', {
          'item-highlighted': product.featured,
          'item-banner': isCurrentMembership,
        })}
      >
        <HorizontalCard
          path={`/${webstore}/memberships/details/${product.id}`}
          descriptionHtml={product.descriptionHtml}
          displayName={product.displayName}
          imageUrl={product.productImage}
          price={product.startingPrice}
          learnMoreLink={product.learnMoreLink}
          showReadMoreModal={showReadMoreModal}
          currentMembershipLabel={currentMembershipLabel}
        />
      </div>
    )
  })
}

function ProductCategory(props) {
  const { productsByCategory, ...rest } = props
  return productsByCategory.map((category, idx) => {
    const categoryTitle = category.name || getGenericHeader(productsByCategory)
    return (
      <div key={category.name || idx}>
        {!!categoryTitle && (
          <SectionHeader title={categoryTitle}>
            {!!category.subtitle && (
              <RenderedHTML>{category.subtitle}</RenderedHTML>
            )}
          </SectionHeader>
        )}
        <OmitProduct category={category} {...rest} />
      </div>
    )
  })
}

function DisplayMemberships(props) {
  return (
    <div className="col-12">
      <ProductCategory {...props} />
    </div>
  )
}

DisplayMemberships.propTypes = propTypes
DisplayMemberships.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    displayOrgText: globalSelectors.displayOrgText(state),
  }
}

const mapDispatchToProps = {
  showReadMoreModal: ReadMoreModal.show,
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DisplayMemberships)
