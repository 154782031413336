import React from 'react'
import PropTypes from 'prop-types'
import { compose, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import {
  RenderedHTML,
  MainHeader,
  Receipt,
  PaymentConfirmation,
} from 'components'
import { selectors } from '../reducer'
import { onMount, waitFor } from 'lp-hoc'
import { Spinner } from 'lp-components'
import { selectors as globalSelectors } from 'global-reducer'
import * as routerActions from 'react-router-redux'
import { createDisplayOrgText } from 'utils'
import { displayKeys } from 'config'

const propTypes = {
  donationConfirmation: PropTypes.object.isRequired,
  selectedDonation: PropTypes.object.isRequired,
  displayOrgText: PropTypes.func.isRequired,
}

const defaultProps = {}

function DonationConfirmation({
  donationConfirmation,
  selectedDonation,
  displayOrgText,
}) {
  return (
    <div className="step-container">
      <MainHeader title="Thank You!" disablePrevStep>
        <RenderedHTML>
          {displayOrgText(displayKeys.DONATION_PAYMENT_CONFIRMATION_SUBTITLE)}
        </RenderedHTML>
      </MainHeader>
      <div className="row confirmation-page">
        <div className="col-6">
          <Receipt
            title={selectedDonation.displayName}
            cart={donationConfirmation.cart}
            image={selectedDonation.image}
            displayTotal
          />
        </div>
        <div className="col-8">
          <PaymentConfirmation
            confirmation={donationConfirmation}
            info={displayOrgText(
              displayKeys.DONATION_PAYMENT_CONFIRMATION_INFO
            )}
          />
        </div>
      </div>
    </div>
  )
}

DonationConfirmation.propTypes = propTypes
DonationConfirmation.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    config: globalSelectors.config(state),
    webstore: globalSelectors.webstore(state),
    donationConfirmation: selectors.donationConfirmation(state),
    selectedDonation: selectors.selectedDonation(state),
  }
}

const mapDispatchToProps = {
  push: routerActions.push,
}

function onComponentDidMount({ donationConfirmation, push, webstore }) {
  if (!donationConfirmation) return push('/' + webstore + '/donate')
}

function initializeOrgHelpers({ config }) {
  return {
    displayOrgText: createDisplayOrgText(config),
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  onMount(onComponentDidMount),
  withPropsOnChange(['config'], initializeOrgHelpers),
  waitFor('donationConfirmation', Spinner)
)(DonationConfirmation)
