import React from 'react'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { Input, SubmitButton } from 'lp-components'
import { Field, propTypes as formPropTypes } from 'redux-form'

const propTypes = {
  ...formPropTypes,
}

const defaultProps = {}

function CouponCodeForm({
  handleSubmit,
  pristine,
  invalid,
  submitting,
  submit,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <div className="promo-code">
        <Field
          name="coupon"
          label="Promo Code"
          component={Input}
          onBlur={submit}
        />
        <SubmitButton
          style="primary promo-code-button"
          {...{ pristine, invalid, submitting }}
        >
          Apply
        </SubmitButton>
      </div>
    </form>
  )
}

CouponCodeForm.propTypes = propTypes
CouponCodeForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'coupon-code',
  })
)(CouponCodeForm)
