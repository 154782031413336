import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { onMount, waitFor, onUpdate, onUnmount } from 'lp-hoc'
import { connectParams } from 'utils'
import * as globalActions from 'global-actions'
import { selectors as globalSelectors } from 'global-reducer'

const propTypes = {
  children: PropTypes.node.isRequired,
}

const defaultProps = {}

// This layout takes care of loading webstore data into the store.

function WebstoreLayout({ children }) {
  return <div>{children}</div>
}

WebstoreLayout.propTypes = propTypes

WebstoreLayout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    webstore: globalSelectors.webstore(state),
  }
}

const mapDispatchToProps = {
  setWebstore: globalActions.setWebstore,
  clearWebstore: globalActions.clearWebstore,
}

function setWebstoreFromParams({ webstoreFromParams, setWebstore }) {
  return setWebstore(webstoreFromParams)
}

function onComponentDidUpdate(
  { webstore, setWebstore, webstoreFromParams },
  prevProps
) {
  if (webstore !== prevProps.webstore) return setWebstore(webstore)
  if (webstore !== webstoreFromParams) return setWebstore(webstoreFromParams) // if navigating within the matched :webstore routes (e.g., Ticketing to Memberships)
}

export default compose(
  connectParams(({ webstore }) => ({ webstoreFromParams: webstore })),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  onMount(setWebstoreFromParams),
  onUnmount('clearWebstore'),
  onUpdate(onComponentDidUpdate),
  waitFor('webstore', () => null)
)(WebstoreLayout)
