import React from 'react'
import { Route } from 'react-router'
import * as Views from './views'
import Layout from './Layout'
import { AuthorizedRoute, UnauthorizedRoute } from 'lp-components'
import { isAuthenticated } from 'auth'

const Routes = (
  <Route path="member" component={Layout}>
    <UnauthorizedRoute authFunction={isAuthenticated} redirect="/member/status">
      <Route path="login" component={Views.MemberLogin} />
      <Route path="lookup" component={Views.MemberLookup} />
      <Route path="forgot-password" component={Views.ForgotPassword} />
      <Route
        path="forgot-password-confirmation"
        component={Views.ForgotPasswordConfirmation}
      />
      <Route path="reset-password" component={Views.ResetPassword} />
      <Route
        path="reset-password-confirmation"
        component={Views.ResetPasswordConfirmation}
      />
    </UnauthorizedRoute>
    <AuthorizedRoute authFunction={isAuthenticated} redirect="/member/login">
      <Route path="status" component={Views.MemberStatus} />
    </AuthorizedRoute>
  </Route>
)

export default Routes
