import { createAction } from 'redux-actions'
import { push } from 'react-router-redux'

export const setMembershipUpgradeModalShown = createAction(
  'SET_MEMBERSHIP_UPGRADE_MODAL_SHOWN'
)
export const clearProducts = createAction('CLEAR_PRODUCTS')
export const clearSelectedTicket = createAction('CLEAR_SELECTED_TICKET')
export const selectTicket = createAction('SELECT_TICKET')
export const addTicket = createAction('ADD_TICKET')
export const removeTicket = createAction('REMOVE_TICKET')
export const setCart = createAction('SET_TICKET_CART')
export const setTicketDate = createAction('SET_TICKET_DATE')
export const clearTickets = createAction('CLEAR_TICKETS')
export const clearAddOnTicketTypes = createAction('CLEAR_ADD_ON_TICKET_TYPES')
export const clearAddOnTicketTimes = createAction('CLEAR_ADD_ON_TICKET_TIMES')
export const clearTimedAddOnTimes = createAction('CLEAR_TIMED_ADD_ON_TIMES')
export const clearTimedAddOnPrices = createAction('CLEAR_TIMED_ADD_ON_PRICES')
export const setCurrentAddOn = createAction('SET_CURRENT_ADD_ON')
export const clearSelectedAddOns = createAction('CLEAR_SELECTED_ADD_ONS')
export const setSelectedAddOns = createAction('SET_SELECTED_ADD_ONS')
export const removeAddOn = createAction('REMOVE_ADD_ON')
export const addAddOn = createAction('ADD_ADD_ON')
export const setTicketConfirmation = createAction('SET_TICKET_CONFIRMATION')
export const replaceTicketingCart = createAction('REPLACE_TICKETING_CART')
export const setSelections = createAction('SET_SELECTIONS')
export const clearTicketTypes = createAction('CLEAR_TICKET_TYPES')
export const emptyCart = createAction('EMPTY_TICKET_CART')
export const setLoadingTicketTimes = createAction('LOADING_TICKET_TIMES')

export function redirectToNextStep(stepType, stepName) {
  const routeNumber = stepName ? parseInt(stepName) + 1 : 2
  return push(`/${stepType}/${routeNumber}`)
}
