import React from 'react'
import PropTypes from 'prop-types'
import { compose, withState } from 'recompose'
import { connect } from 'react-redux'
import * as actions from './actions'
import * as apiActions from 'api-actions'
import { selectors } from './reducer'
import { onMount, waitFor } from 'lp-hoc'
import { Spinner } from 'lp-components'
import { StepNav } from 'components'
import * as Types from 'types'
import { get } from 'lodash'

const propTypes = {
  children: PropTypes.node,
  steps: PropTypes.arrayOf(Types.step).isRequired,
  currentStepIndex: PropTypes.number.isRequired,
}

const defaultProps = {}

function StepLayout({ children, steps, currentStepIndex }) {
  return (
    <div>
      <StepNav steps={steps} currentStepIndex={currentStepIndex} />
      {children}
    </div>
  )
}

StepLayout.propTypes = propTypes
StepLayout.defaultProps = defaultProps

const loadInitialCartData = ({
  fetchCart,
  fetchTicket,
  fetchTicketTimes,
  fetchTicketTypes,
  fetchTimedTicketTypes,
  selectTicket,
  setInitialized,
}) => {
  fetchCart()
    .then((cart) => {
      // Check to see if a ticket has been selected. If so, get more details on it and add to state
      if (!get(cart, 'productId')) return
      return fetchTicket(cart.productId).then((ticket) => {
        selectTicket(ticket)

        // If a ticket is timed, then the times should be fetched as well
        if (ticket['timed?']) {
          // If an offer has been selected, then the ticket types can be fetch concurrently
          return cart.offerId
            ? Promise.all([
                fetchTicketTimes(cart.productId, cart.startDate),
                fetchTimedTicketTypes(
                  cart.productId,
                  cart.startDate,
                  cart.offerId
                ),
              ])
            : fetchTicketTimes(cart.productId, cart.startDate)
        }

        // If an offer has been selected, then fetch the types associated with that offer
        if (cart.offerId) return fetchTicketTypes(cart.productId, cart.offerId)
      })
    })
    .then(() => setInitialized(true))
}

function mapStateToProps(state) {
  return {
    steps: selectors.steps(state),
    currentStepIndex: selectors.currentStepIndex(state),
  }
}

const mapDispatchToProps = {
  fetchCart: apiActions.fetchTicketCart,
  fetchTicket: apiActions.fetchTicket,
  selectTicket: actions.selectTicket,
  fetchTimedTicketTypes: apiActions.fetchTimedTicketTypes,
  fetchTicketTypes: apiActions.fetchTicketTypes,
  fetchTicketTimes: apiActions.fetchTicketTimes,
}

export default compose(
  withState('initialized', 'setInitialized', false),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  onMount(loadInitialCartData),
  waitFor('initialized', Spinner)
)(StepLayout)
