import getMembershipTypeQuantities from './getMembershipTypeQuantities'

// Given an array of members, returns the quantity of members that have the given membership type

function getMembershipTypeQuantity(members, membershipType) {
  if (!members) return 0
  const quantities = getMembershipTypeQuantities(members)
  return quantities[membershipType.id] || 0
}

export default getMembershipTypeQuantity
