import { createAction } from 'redux-actions'

export const setSelectedMembership = createAction('SET_SELECTED_MEMBERSHIP')
export const setSelectedMembershipId = createAction(
  'SET_SELECTED_MEMBERSHIP_ID'
)
export const clearSelectedMembership = createAction('CLEAR_SELECTED_MEMBERSHIP')
export const replaceMembershipCart = createAction('REPLACE_MEMBERSHIP_CART')
export const setMembershipConfirmation = createAction(
  'SET_MEMBERSHIP_CONFIRMATION'
)
export const emptyMembershipCart = createAction('EMPTY_MEMBERSHIP_CART')
