/**
 * Util for converting a date to UTC to facilitate date comparisons that should be agnostic of
 * a user's timezone.
 *
 * @name getUTCDate
 * @param {Object} [date = new Date()] - Optional argument for the date to convert. Defaults to today (now)
 * @returns {Object} - Returns a date object that has been readjusted to UTC (without the user's timezone)
 */

export default function getUTCDate(date = new Date()) {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000) // convert offset from min to ms for compatibility
}
