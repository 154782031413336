import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import { selectors as globalSelectors } from 'global-reducer'
import { displayKeys } from 'config'
import { RenderedHTML } from 'components'
import { Link } from 'react-router'
import { ButtonArea } from 'lp-components'
import { connectQuery } from 'utils'

const propTypes = {
  id: PropTypes.number,
  contactPhone: PropTypes.string,
  displayOrgText: PropTypes.func.isRequired,
  webstore: PropTypes.string.isRequired,
}

const defaultProps = {
  contactPhone: '',
  id: null,
}

function getMembershipRedirect(id, webstore) {
  if (!id) return `/${webstore}/memberships/main`
  return `/${webstore}/memberships/details/${id}`
}

function MembershipTimeout({ id, displayOrgText, webstore, contactPhone }) {
  return (
    <div className="timeout-container">
      <div className="row">
        <div className="col-12">
          <h2>{displayOrgText(displayKeys.MEMBERSHIP_TIMEOUT_HEADING)}</h2>
          <RenderedHTML>
            {displayOrgText(displayKeys.MEMBERSHIP_TIMEOUT_INSTRUCTIONS)}
          </RenderedHTML>

          <ButtonArea>
            <Link
              className="button-primary"
              to={getMembershipRedirect(id, webstore)}
            >
              Check Status
            </Link>
          </ButtonArea>

          <hr />

          <h2>
            {displayOrgText(
              displayKeys.MEMBERSHIP_TIMEOUT_ADDITIONAL_HELP_HEADING
            )}
          </h2>
          <RenderedHTML>
            {contactPhone
              ? displayOrgText(
                  displayKeys.MEMBERSHIP_TIMEOUT_ADDITIONAL_HELP_INSTRUCTIONS_CONTACT,
                  { contactPhone }
                )
              : displayOrgText(
                  displayKeys.MEMBERSHIP_TIMEOUT_ADDITIONAL_HELP_INSTRUCTIONS
                )}
          </RenderedHTML>
        </div>
      </div>
    </div>
  )
}

MembershipTimeout.propTypes = propTypes
MembershipTimeout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    cart: selectors.cart(state),
    contactPhone: globalSelectors.contactPhone(state),
    displayOrgText: globalSelectors.displayOrgText(state),
    webstore: globalSelectors.webstore(state),
  }
}

const mapDispatchToProps = {}

export default compose(
  connectQuery('id'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(MembershipTimeout)
