import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as apiSelectors } from 'lp-redux-api'
import {
  MainHeader,
  StickyContainer,
  Receipt,
  ProductEmptyState,
} from 'components'
import { selectors as giftMembershipSelectors } from '../reducer'
import { selectors as globalSelectors } from 'global-reducer'
import * as apiActions from 'api-actions'
import { onMount, waitFor, modifyProps } from 'lp-hoc'
import { GiftMembershipForm } from '../forms'
import { flashInvalidFormSubmitMessage, getBrandingConfig } from 'utils'
import * as routerActions from 'react-router-redux'
import { get, isEmpty } from 'lodash'
import { clearMessages } from 'redux-flash'

const propTypes = {
  giftMemberships: PropTypes.arrayOf(Types.giftMembership),
  cart: Types.cart.isRequired,
  updateGiftMembershipPriceSelections: PropTypes.func.isRequired,
  updateGiftMembershipCart: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  isCartLoading: PropTypes.bool.isRequired,
  webstore: PropTypes.string.isRequired,
  nextStepPath: PropTypes.string.isRequired,
  receiptImagePath: PropTypes.string.isRequired,
  clearMessages: PropTypes.func.isRequired,
}

const defaultProps = {}

function SelectGiftMembership({
  giftMemberships,
  cart,
  updateGiftMembershipPriceSelections,
  isCartLoading,
  updateGiftMembershipCart,
  webstore,
  nextStepPath,
  push,
  receiptImagePath,
  clearMessages,
}) {
  if (isEmpty(giftMemberships))
    return (
      <ProductEmptyState title="Gift Membership">
        <p>Sorry, there are no gift memberships available at this time.</p>
      </ProductEmptyState>
    )

  return (
    <div className="step-container">
      <MainHeader title="Gift Membership" />
      <div className="row">
        <div className="col-8">
          <GiftMembershipForm
            giftMemberships={giftMemberships}
            initialValues={{
              priceSelections: cart.priceSelections,
            }}
            priceSelections={cart.priceSelections}
            onChangePriceSelections={(priceSelections) =>
              updateGiftMembershipPriceSelections(cart, priceSelections)
            }
            onSubmit={({ priceSelections, ...params }) => {
              clearMessages()
              return updateGiftMembershipCart(cart, {
                priceSelectionsWithAttendeeDetails: priceSelections,
                ...params,
              })
            }}
            onSubmitSuccess={() =>
              push(`/${webstore}/gift-memberships/${nextStepPath}`)
            }
            onSubmitFail={flashInvalidFormSubmitMessage}
          />
        </div>
        <StickyContainer className="col-4">
          <Receipt
            title="Gift Memberships"
            cart={cart}
            isCartLoading={isCartLoading}
            displayTotal
            image={receiptImagePath}
          />
        </StickyContainer>
      </div>
    </div>
  )
}

SelectGiftMembership.propTypes = propTypes
SelectGiftMembership.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    cart: giftMembershipSelectors.cart(state),
    isCartLoading: apiSelectors.isLoading(
      state,
      apiActions.updateGiftMembershipPriceSelections
    ),
    giftMemberships: giftMembershipSelectors.giftMemberships(state),
    nextStepPath: giftMembershipSelectors.nextStepPath(state),
    webstore: globalSelectors.webstore(state),
    orgConfig: globalSelectors.config(state),
  }
}

const mapDispatchToProps = {
  fetchGiftMemberships: apiActions.fetchGiftMemberships,
  updateGiftMembershipPriceSelections:
    apiActions.updateGiftMembershipPriceSelections,
  updateGiftMembershipCart: apiActions.updateGiftMembershipCart,
  push: routerActions.push,
  clearMessages,
}

const modify = ({ webstore, orgConfig }) => {
  const branding = getBrandingConfig(orgConfig, webstore)
  const receiptImagePath = get(branding, 'giftMembershipsImage', '')
  return {
    receiptImagePath,
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  modifyProps(modify),
  onMount('fetchGiftMemberships'),
  waitFor('giftMemberships')
)(SelectGiftMembership)
