import { initialize, plugin, pageview } from 'react-ga'

// Analytics interface

class GoogleAnalytics {
  constructor() {
    this._enabled = false
  }
  initialize(tag) {
    initialize(tag)
    plugin.require('ecommerce')
    this._enabled = true
  }
  sendEcommerceEvent(event) {
    if (!this._enabled) return
    const {
      checkout: { purchaseDetails, totalRevenue: revenue, affiliation },
      order: { transactionId: id },
    } = event
    purchaseDetails.forEach((purchase) =>
      plugin.execute('ecommerce', 'addItem', { id, ...purchase })
    )
    plugin.execute('ecommerce', 'addTransaction', {
      id,
      revenue,
      affiliation,
    })
    plugin.execute('ecommerce', 'send')
    plugin.execute('ecommerce', 'clear')
  }
  sendPageview() {
    if (!this._enabled) return
    return pageview(window.location.pathname)
  }
}

export default new GoogleAnalytics()
