// Takes object of tickets and returns an array of tickets
function serializeSelectedTickets(selectedTickets, allTickets) {
  if (!selectedTickets) return []
  const selected = Object.keys(selectedTickets).map((key) => ({
    centamanId: Number(key.replace('ticket-', '')),
    quantity: selectedTickets[key].quantity,
  }))
  return allTickets.reduce((acc, ticket) => {
    const formTicket = selected.find(
      (selected) => selected.centamanId === ticket.centamanId
    )
    if (!formTicket || formTicket.quantity === 0) return [...acc]

    return [...acc, { ...ticket, quantity: formTicket.quantity }]
  }, [])
}

export default serializeSelectedTickets
