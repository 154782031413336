import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import {
  Input,
  Checkbox,
  SubmitButton,
  ButtonArea,
  Select,
} from 'lp-components'
import { lpForm } from 'lp-form'
import {
  Field,
  formValueSelector,
  propTypes as formPropTypes,
  FormSection,
} from 'redux-form'
import {
  TermsModal,
  PrivacyModal,
  IFrameInput,
  SectionHeader,
  RecaptchaInput,
  MaskedInput,
  CreditCardLogos,
} from 'components'
import { selectors as globalSelectors } from 'global-reducer'
import { selectors as memberSelectors } from '../main/base/member/reducer'
import { isEmpty, noop, startCase } from 'lodash'
import { modifyProps } from 'lp-hoc'
import {
  createDisplayOrgText,
  createShowFormField,
  createFieldValidator,
  debouncePromise,
  findStates,
  getPostcodePattern,
  raceEthnicityOptions,
  regionData,
  validateConnecticutZip,
  validateExpirationDate,
} from 'utils'
import { displayKeys } from 'config'
import * as apiActions from 'api-actions'

/* eslint react/prop-types: [1, { skipUndeclared: true }] */

const propTypes = {
  ...formPropTypes,
  hidePaymentDetails: PropTypes.bool,
  isDonation: PropTypes.bool.isRequired,
  organization: PropTypes.object.isRequired,
  showTermsModal: PropTypes.func,
  showPrivacyModal: PropTypes.func,
  stateOptions: PropTypes.array.isRequired,
  recaptchaEnabled: PropTypes.bool,
  recaptchaSiteKey: PropTypes.string.isRequired,
  displayCardLogos: PropTypes.bool,
  requireConnecticutZip: PropTypes.bool,
  collectRaceEthnicity: PropTypes.bool,
}

const defaultProps = {
  hidePaymentDetails: false,
  showTermsModal: noop,
  showPrivacyModal: noop,
  name: 'billing-details',
  recaptchaEnabled: false,
  displayCardLogos: false,
}

// Custom component with conditional logic that is only used for the Billing Details form
function ConsentLabel({
  termsAndConditions,
  privacyPolicy,
  showTermsModal,
  showPrivacyModal,
}) {
  if (!privacyPolicy)
    return (
      <label className="cb-label" htmlFor="termsAndConditions">
        I have read and agree to the{' '}
        <a onClick={showTermsModal}>Terms and Conditions</a>.
      </label>
    )
  if (!termsAndConditions)
    return (
      <label className="cb-label" htmlFor="termsAndConditions">
        I have read and agree to the{' '}
        <a onClick={showPrivacyModal}>Privacy Policy</a>.
      </label>
    )

  return (
    <label className="cb-label" htmlFor="termsAndConditions">
      I have read and agree to the{' '}
      <a onClick={showTermsModal}>Terms and Conditions</a> and{' '}
      <a onClick={showPrivacyModal}>Privacy Policy</a>.
    </label>
  )
}

/*
 Field level validations (can't be in-line or they won't trigger onSubmit for redux-form)
*/
const validatePaymentAddress = createFieldValidator(
  'paymentDetails.streetAddress1',
  {
    presence: true,
    length: { maximum: 50 },
  }
)
const validatePaymentZip = createFieldValidator('paymentDetails.zip', {
  presence: true,
  length: { maximum: 10 },
})
const validateFullName = createFieldValidator('paymentDetails.fullName', {
  presence: true,
  length: { maximum: 60 },
})
const validateFirstName = createFieldValidator('customerDetails.firstName', {
  presence: true,
  length: { maximum: 30 },
})
const validateLastName = createFieldValidator('customerDetails.lastName', {
  presence: true,
  length: { maximum: 30 },
})
const validateEmail = createFieldValidator('customerDetails.email', {
  presence: true,
  email: true,
  length: { maximum: 60 },
})
const validatePhone = createFieldValidator('customerDetails.phone', {
  presence: true,
  length: { maximum: 20 },
})
const validateAddress = createFieldValidator('customerDetails.streetAddress1', {
  presence: true,
  length: { maximum: 50 },
})
const validateZip = createFieldValidator(
  'customerDetails.zip',
  ({ selectedCountry }) => ({
    presence: true,
    format: {
      pattern: getPostcodePattern(selectedCountry),
      message: 'Please enter a valid format',
    },
    length: { maximum: 10 },
  })
)
const validateCountry = createFieldValidator('customerDetails.country', {
  presence: true,
})
const validateCity = createFieldValidator('customerDetails.city', {
  presence: true,
  length: { maximum: 30 },
})
const validateState = createFieldValidator('customerDetails.state', {
  presence: true,
  length: { maximum: 30 },
})
const validateRaceEthnicity = createFieldValidator(
  'customerDetails.raceEthnicity',
  {
    presence: true,
  }
)

const validateRecaptcha = (value) => {
  // Value will subsequently be validated server-side during order submission
  if (!value) return 'reCAPTCHA must be verified'
}

/*
 Main Component
*/
function BillingDetailsForm({
  change,
  untouch,
  handleSubmit,
  hidePaymentDetails,
  isDonation,
  requireConnecticutZip,
  collectRaceEthnicity,
  submitting,
  organization: {
    termsAndConditions,
    privacyPolicy,
    emailOptInText,
    anonymousDonationText,
  },
  showTermsModal,
  showPrivacyModal,
  showFormField,
  stateOptions,
  displayOrgText,
  recaptchaSiteKey,
  recaptchaEnabled,
  displayCardLogos,
}) {
  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <div className="form-container">
        {showFormField('paymentDetails') && !hidePaymentDetails && (
          <FormSection name="paymentDetails" className="clearfix">
            {!!displayOrgText(displayKeys.BILLING_TITLE) && (
              <SectionHeader
                title={displayOrgText(displayKeys.BILLING_TITLE)}
              />
            )}
            <Field
              name="fullName"
              label={displayOrgText(displayKeys.CREDIT_CARD_NAME)}
              component={Input}
              validate={validateFullName}
            />
            <Field
              className="credit-card-input"
              name="number"
              label="Card Number"
              component={IFrameInput}
              containerId="spreedly-number"
            />
            {displayCardLogos && <CreditCardLogos />}
            <Field
              className="short-input-left"
              name="expirationDate"
              label={displayOrgText(displayKeys.EXPIRATION_DATE)}
              placeholder="MM/YY"
              mask="11/11"
              component={MaskedInput}
              placeholderChar=" "
              validate={validateExpirationDate}
              aria-label="Expiration Date, format 2 digit month and 2 digit year"
            />
            <Field
              className="short-input-right cvv-input"
              name="cvv"
              label="Security Code"
              component={IFrameInput}
              containerId="spreedly-cvv"
            />
            <p className="input-copy">
              3 to 4 digit number typically located on the back of your card.
            </p>
            {showFormField('paymentDetails.streetAddress1') && (
              <Field
                name="streetAddress1"
                component={Input}
                label={displayOrgText(displayKeys.BILLING_STREET_ADDRESS)}
                validate={validatePaymentAddress}
              />
            )}
            {showFormField('paymentDetails.zip') && (
              <Field
                className="short-input-left"
                name="zip"
                component={Input}
                label={displayOrgText(displayKeys.ZIP)}
                validate={validatePaymentZip}
              />
            )}
          </FormSection>
        )}

        {showFormField('customerDetails') && (
          <FormSection name="customerDetails">
            {!!displayOrgText(displayKeys.CONTACT_INFO_TITLE) && (
              <SectionHeader
                title={displayOrgText(displayKeys.CONTACT_INFO_TITLE)}
              />
            )}
            {showFormField('customerDetails.firstAndLastName') && (
              <div className="row">
                <Field
                  name="firstName"
                  label={displayOrgText(displayKeys.FIRST_NAME)}
                  component={Input}
                  validate={validateFirstName}
                  className="col-6"
                />
                <Field
                  name="lastName"
                  label={displayOrgText(displayKeys.LAST_NAME)}
                  component={Input}
                  validate={validateLastName}
                  className="col-6"
                />
              </div>
            )}
            {showFormField('customerDetails.email') && (
              <Field
                name="email"
                component={Input}
                label={displayOrgText(displayKeys.EMAIL_BILLING)}
                validate={validateEmail}
              />
            )}
            {showFormField('customerDetails.phone') && (
              <Field
                name="phone"
                component={Input}
                type="tel"
                label={displayOrgText(displayKeys.PHONE)}
                validate={validatePhone}
              />
            )}
            {showFormField('customerDetails.country') && (
              <Field
                name="country"
                label={displayOrgText(displayKeys.COUNTRY)}
                component={Select}
                placeholder={'Select'}
                options={regionData}
                validate={validateCountry}
                onChange={() => {
                  change('customerDetails.state', '')
                  untouch('customerDetails.state')
                }}
              />
            )}
            {showFormField('customerDetails.streetAddress1') && (
              <Field
                name="streetAddress1"
                component={Input}
                label={displayOrgText(displayKeys.ADDRESS)}
                validate={validateAddress}
              />
            )}
            <div className="short-inputs">
              {showFormField('customerDetails.city') && (
                <Field
                  name="city"
                  component={Input}
                  label={displayOrgText(displayKeys.CITY)}
                  validate={validateCity}
                />
              )}
              {/* Show a dropdown for countries that have state options defined */}
              {showFormField('customerDetails.state') &&
                !isEmpty(stateOptions) && (
                  <Field
                    name="state"
                    component={Select}
                    label={displayOrgText(displayKeys.STATE)}
                    placeholder="Select State"
                    options={stateOptions}
                    normalize={(value) => startCase(value.toLowerCase())}
                    format={(value) => (value ? value.toUpperCase() : '')}
                    validate={validateState}
                  />
                )}
              {showFormField('customerDetails.state') &&
                isEmpty(stateOptions) && (
                  <Field
                    name="state"
                    component={Input}
                    label={displayOrgText(displayKeys.STATE)}
                    validate={validateState}
                  />
                )}
              {showFormField('customerDetails.zip') && (
                <Field
                  name="zip"
                  component={Input}
                  label={displayOrgText(displayKeys.ZIP)}
                  validate={
                    requireConnecticutZip ? validateConnecticutZip : validateZip
                  }
                />
              )}
              {collectRaceEthnicity && (
                <Field
                  name="raceEthnicity"
                  component={Select}
                  label="Race/Ethnicity"
                  placeholder="Select"
                  validate={validateRaceEthnicity}
                  options={raceEthnicityOptions}
                />
              )}
            </div>
          </FormSection>
        )}

        <div className="checkbox-container">
          {(termsAndConditions || privacyPolicy) && (
            <React.Fragment>
              <Field
                name="termsAndConditions"
                component={Checkbox}
                label={false}
                className="checkbox with-consent-label"
              />
              <ConsentLabel
                termsAndConditions={termsAndConditions}
                privacyPolicy={privacyPolicy}
                showTermsModal={(e) => {
                  // Do not register this click as a change to the input (default label behavior)
                  e.preventDefault()
                  return showTermsModal()
                }}
                showPrivacyModal={(e) => {
                  // Do not register this click as a change to the input (default label behavior)
                  e.preventDefault()
                  return showPrivacyModal()
                }}
              />
            </React.Fragment>
          )}
          {emailOptInText && (
            <React.Fragment>
              <Field
                className="checkbox email-opt-in"
                name="emailOptIn"
                component={Checkbox}
                label={false}
              />
              <label className="cb-label" htmlFor="emailOptIn">
                {emailOptInText}
              </label>
            </React.Fragment>
          )}
          {isDonation && anonymousDonationText && (
            <React.Fragment>
              <Field
                className="checkbox email-opt-in"
                name="donationUdfs.anonymousDonation"
                component={Checkbox}
                label={false}
              />
              <label
                className="cb-label"
                htmlFor="donationUdfs.anonymousDonation"
              >
                {anonymousDonationText}
              </label>
            </React.Fragment>
          )}
        </div>

        {recaptchaEnabled && (
          <Field
            name="recaptcha"
            label={false}
            component={RecaptchaInput}
            recaptchaSiteKey={recaptchaSiteKey}
            validate={validateRecaptcha}
          />
        )}

        <ButtonArea className="full-width-button">
          <SubmitButton submitting={submitting}>
            {hidePaymentDetails ? 'Confirm' : 'Submit Payment'}
          </SubmitButton>
        </ButtonArea>
      </div>
      <TermsModal terms={termsAndConditions} />
      <PrivacyModal policy={privacyPolicy} />
    </form>
  )
}

BillingDetailsForm.propTypes = propTypes
BillingDetailsForm.defaultProps = defaultProps

function mapStateToProps(state, { name }) {
  return {
    name,
    organization: globalSelectors.organization(state),
    orgConfig: globalSelectors.config(state),
    selectedCountry: formValueSelector(name)(state, 'customerDetails.country'),
    currentMember: memberSelectors.currentMember(state),
  }
}

const mapDispatchToProps = {
  showTermsModal: () => TermsModal.show(), // wrap to prevent reusing stale synthetic events
  showPrivacyModal: () => PrivacyModal.show(), // wrap to prevent reusing stale synthetic events
  verifyReCaptcha: apiActions.verifyReCaptcha,
  checkOrderIpStatus: apiActions.checkOrderIpStatus,
}

const initializeOrgHelpers = ({ name, orgConfig, initialValues = {} }) => {
  return {
    showFormField: createShowFormField(orgConfig, name),
    displayOrgText: createDisplayOrgText(orgConfig),
    initialValues: {
      ...initialValues,
      customerDetails: {
        country: orgConfig.DEFAULT_COUNTRY,
      },
    },
    recaptchaEnabled: orgConfig.ENABLE_RECAPTCHA,
    recaptchaSiteKey:
      orgConfig.RECAPTCHA_SITE_KEY || process.env.GOOGLE_RECAPTCHA_KEY,
    displayCardLogos: orgConfig.DISPLAY_CARD_LOGOS,
    isDonation: name === Types.billingFormTypes.DONATION,
  }
}

const setInitialValues = ({ currentMember, initialValues = {} }) => {
  const member = currentMember?.primaryMember
  return {
    initialValues: {
      customerDetails: {
        firstName: member?.firstName,
        lastName: member?.lastName,
        email: member?.email,
        phone: member?.phone,
        streetAddress1: member?.address?.streetAddress1,
        streetAddress2: member?.address?.streetAddress2,
        streetAddress3: member?.address?.streetAddress3,
        streetAddress4: member?.address?.streetAddress4,
        city: member?.address?.city,
        state: member?.address?.state,
        zip: member?.address?.zip,
        ...initialValues.customerDetails,
      },
      emailOptIn: true,
    },
  }
}

const initializeIframeValidation = ({ paymentProcessor }) => {
  return {
    // Passes values, dispatch, props, and blurredField
    asyncValidate: debouncePromise(paymentProcessor.validate, 500),
  }
}

const getStateOptions = ({ selectedCountry }) => {
  return {
    stateOptions: findStates(regionData, selectedCountry),
  }
}

const verifyUserBeforeSubmit = ({
  onSubmit,
  checkOrderIpStatus,
  recaptchaEnabled,
  verifyReCaptcha,
}) => {
  // Check IP address is allowed, followed by reCaptcha verification
  return {
    onSubmit: (values, dispatch, props) => {
      return checkOrderIpStatus(values)
        .then(() => {
          if (recaptchaEnabled)
            return verifyReCaptcha(values.recaptcha, props.organization)
        })
        .then(() => onSubmit(values, dispatch, props))
    },
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  modifyProps(initializeOrgHelpers),
  modifyProps(setInitialValues),
  modifyProps(initializeIframeValidation),
  modifyProps(getStateOptions),
  modifyProps(verifyUserBeforeSubmit),
  lpForm({
    name: 'billing-details',
    constraints: {
      termsAndConditions: {
        presence: {
          message: '^You must accept the terms and conditions',
        },
        inclusion: {
          within: [true],
          message: '^You must accept the terms and conditions',
        },
      },
    },
    shouldAsyncValidate: () => true,
  })
)(BillingDetailsForm)
