import React from 'react'
import PropTypes from 'prop-types'
import Tippy from '@tippy.js/react'

// For a full list of props, visit https://atomiks.github.io/tippyjs/#all-options
const propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.string.isRequired,
  allowHTML: PropTypes.bool,
  animateFill: PropTypes.bool,
  animation: PropTypes.string,
  arrow: PropTypes.bool,
  interactive: PropTypes.bool,
  trigger: PropTypes.oneOf(['mouseenter', 'focus', 'click', 'manual']),
  theme: PropTypes.string, // custom themes can be created via https://atomiks.github.io/tippyjs/#creating-custom-themes
  performance: PropTypes.bool,
  popperOptions: PropTypes.object, // Reference here: https://popper.js.org/popper-documentation.html#new_Popper_new
  zIndex: PropTypes.number,
}

const defaultProps = {
  allowHTML: false,
  animateFill: false,
  animation: 'scale',
  arrow: true,
  interactive: true,
  trigger: 'click',
  theme: 'light-border', // Themes are not exported by default and must be individually imported in main/index.js
  performance: true,
  popperOptions: {
    modifiers: {
      flip: {
        padding: {
          top: 70, // This prevents the tooltip from hiding underneath the static navigation header
        },
      },
    },
  },
  zIndex: 9, // This will make sure it does not go above the fixed navigation header
}

function ToggleTip(props) {
  return <Tippy {...props} />
}

ToggleTip.propTypes = propTypes
ToggleTip.defaultProps = defaultProps

export default ToggleTip
