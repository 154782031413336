import React from 'react'
import PropTypes from 'prop-types'
import { modal } from 'lp-hoc'

const propTypes = { handleHide: PropTypes.func.isRequired }
const defaultProps = {}

function HighCapacityModal({ handleHide }) {
  return (
    <div>
      <h1> May 19 & 20 will be high volume days in the Zoo! </h1>{' '}
      <h1> Parking may be limited on these days! </h1>
      <button onClick={handleHide} className="modal-close">
        {' '}
        ×
      </button>
    </div>
  )
}

HighCapacityModal.propTypes = propTypes
HighCapacityModal.defaultProps = defaultProps
export default modal({ name: 'HighCapacityModal', disableOutsideClick: true })(
  HighCapacityModal
)
