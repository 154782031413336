import React from 'react'
import PropTypes from 'prop-types'
import { QuantityInput } from 'components'
import { getMaxQuantity } from 'utils'
import * as Types from 'types'
import { Field } from 'redux-form'
import { curry, noop } from 'lodash'

const propTypes = {
  price: Types.price.isRequired,
  selectedPrices: PropTypes.arrayOf(Types.price).isRequired,
  vacancy: PropTypes.number.isRequired,
  transactionQuantityCap: PropTypes.number,
  warnTransactionCapExceeded: PropTypes.func,
}

const defaultProps = {
  warnTransactionCapExceeded: noop,
  transactionQuantityCap: null,
}

const isSamePrice = curry((a, b) => a.centamanId === b.centamanId)

function getQuantity(price, prices) {
  if (!prices) return 0
  const matchingPrice = prices.find(isSamePrice(price))
  return matchingPrice ? matchingPrice.quantity : 0
}

function updateQuantity(prices, price, quantity) {
  // If it's not in the prices array yet, add it.
  if (!prices.find(isSamePrice(price)))
    return [...prices, { ...price, quantity }]
  // Otherwise, just update the quantity
  return prices.map((p) => (isSamePrice(p, price) ? { ...p, quantity } : p))
}

// Some abstraction around the price quantity input for add-ons.
// Could eventually be ported back to other quantity selectors as well.

// The formatter and normalizer map back and forth between quantities (numbers) and an array of price objects.

function AddOnPriceField({
  price,
  vacancy,
  selectedPrices,
  transactionQuantityCap,
  warnTransactionCapExceeded,
  ...rest
}) {
  return (
    <Field
      itemName={price.displayName}
      component={QuantityInput}
      label={`${price.displayName} Ticket Amount`}
      itemDescription={price.details}
      price={price.price}
      format={(prices) => getQuantity(price, prices)}
      normalize={(quantity) => updateQuantity(selectedPrices, price, quantity)}
      min={price.minQuantity || 0}
      max={getMaxQuantity({
        ticket: price,
        vacancy,
      })}
      transactionQuantityCap={transactionQuantityCap}
      warnTransactionCapExceeded={warnTransactionCapExceeded}
      tooltip={price.infoText}
      {...rest}
    />
  )
}

AddOnPriceField.propTypes = propTypes
AddOnPriceField.defaultProps = defaultProps

export default AddOnPriceField
