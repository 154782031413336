import calculateCalendarRangeEndDate from './calculateCalendarRangeEndDate'
import { startOfMonth } from 'date-fns'

// Given a date, returns the first and last visible dates
// on the calendar widget page that includes the date.

function calculateVisibleCalendarRange(date) {
  const startDate = startOfMonth(date)
  const endDate = calculateCalendarRangeEndDate(date, 2) // go 2 months ahead
  return [startDate, endDate]
}

export default calculateVisibleCalendarRange
