import React from 'react'
import PropTypes from 'prop-types'
import { compose, withState } from 'recompose'
import { connect } from 'react-redux'
import { routerSelectors, stripStepDetails } from 'utils'
import { Link } from 'react-router'
import classnames from 'classnames'
import { noop } from 'lodash'

const propTypes = {
  currentPath: PropTypes.string.isRequired,
  homeUrl: PropTypes.string,
  loggedIn: PropTypes.bool.isRequired,
  logo: PropTypes.string,
  logout: PropTypes.func,
  logoutDisplay: PropTypes.string,
  rightNavLinks: PropTypes.array,
  setShowMenu: PropTypes.func.isRequired,
  showLogin: PropTypes.bool,
  showMenu: PropTypes.bool.isRequired,
  showNav: PropTypes.bool,
  title: PropTypes.string,
}

const defaultProps = {
  homeUrl: '/',
  showLogin: true,
  showNav: false,
  logo: '',
  logout: noop,
  logoutDisplay: 'Logout',
  rightNavLinks: [],
  title: 'Streamlined Checkout',
}

const LogoDisplay = ({ logo, title }) => {
  if (logo) return <img src={logo} alt={title} />
  return title
}

function NavBar({
  currentPath,
  homeUrl,
  loggedIn,
  logo,
  logout,
  logoutDisplay,
  rightNavLinks,
  setShowMenu,
  showLogin,
  showMenu,
  showNav,
  title,
}) {
  return (
    <header className="nav">
      <a href={homeUrl} className="logo">
        <LogoDisplay logo={logo} title={title} />
      </a>
      {showNav && (
        <button
          className={classnames({ 'show-menu': showMenu })}
          aria-hidden="true"
          aria-label="Toggle mobile menu"
          onClick={() => setShowMenu(!showMenu)}
          aria-expanded={showMenu}
        >
          <span />
          <span />
          <span />
        </button>
      )}
      <ul
        className={classnames('menu', { 'show-menu': showMenu })}
        onClick={() => setShowMenu(false)}
      >
        {rightNavLinks.map((navLink, i) => {
          return (
            <li key={i}>
              {navLink.path ? (
                <Link
                  to={navLink.path}
                  className={classnames({
                    selected: currentPath.includes(
                      stripStepDetails(navLink.path, {
                        includesWebstore: true,
                      })
                    ),
                  })}
                >
                  {navLink.name}
                </Link>
              ) : (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={navLink.externalUrl}
                >
                  {navLink.name}
                </a>
              )}
            </li>
          )
        })}
        {showLogin && !loggedIn && (
          <li>
            <Link
              to={{
                pathname: '/member/login',
                state: { redirectUrl: currentPath },
              }}
              className={classnames({
                selected: currentPath.includes('login'),
              })}
            >
              Login
            </Link>
          </li>
        )}
        {loggedIn && (
          <li>
            <Link to={''} onClick={logout}>
              {logoutDisplay}
            </Link>
          </li>
        )}
      </ul>
    </header>
  )
}

NavBar.propTypes = propTypes
NavBar.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentPath: routerSelectors.pathname(state),
  }
}

const mapDispatchToProps = {}

export default compose(
  withState('showMenu', 'setShowMenu', false),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(NavBar)
