// Returns the mapped cart data needed to successfully update a ticketing cart for a General Admission ticket type

export default function mapGeneralAdmissionTicketOrder(
  { productId, offerId },
  ticketingForm
) {
  const priceSelections = ticketingForm.tickets.filter(
    (ticket) => ticket.quantity > 0
  )
  const addOnSelections = ticketingForm.addOns.filter(
    (addOn) => addOn.quantity > 0
  )

  const cart = {
    productId,
    offerId,
    priceSelections,
    addOnSelections,
    startDate: ticketingForm.ticketDate,
    endDate: ticketingForm.ticketDate,
  }

  return cart
}
