import { range } from 'lodash'

// Returns a new array, right padded with an optional value.
export function rightPadArray(array, size, pad = null) {
  const difference = size - array.length
  if (difference <= 0) return array
  return [...array, ...range(difference).fill(pad)]
}

// Given an array and a size, returns a new array trimmed to that size.
// If the size is greater than the length of the array, it's padded with the optional pad value.
function trimArray(array, size, { pad = null } = {}) {
  return size < array.length
    ? array.slice(0, size)
    : rightPadArray(array, size, pad)
}

export default trimArray
