import React from 'react'
import PropTypes from 'prop-types'
import { compose, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import { RenderedHTML, MainHeader, Receipt, StickyContainer } from 'components'
import { selectors } from '../reducer'
import { push } from 'react-router-redux'
import { modifyProps, onMount, waitFor } from 'lp-hoc'
import { connectParams, createDisplayOrgText } from 'utils'
import { Spinner } from 'lp-components'
import { DonationForm } from '../forms'
import * as apiActions from 'api-actions'
import * as flashActions from 'redux-flash'
import { selectors as apiSelectors } from 'lp-redux-api'
import { debounce, first } from 'lodash'
import { selectors as globalSelectors } from 'global-reducer'
import { displayKeys } from 'config'

const propTypes = {
  cart: PropTypes.object.isRequired,
  donationCommentText: PropTypes.string,
  flashErrorMessage: PropTypes.func.isRequired,
  clearMessages: PropTypes.func.isRequired,
  isCartLoading: PropTypes.bool.isRequired,
  moveToNextStep: PropTypes.func.isRequired,
  selectedDonation: PropTypes.object,
  updateDonationCart: PropTypes.func.isRequired,
  stepName: PropTypes.string.isRequired,
  displayOrgText: PropTypes.func.isRequired,
  orgConfig: PropTypes.object.isRequired,
}

const defaultProps = {
  donationCommentText: '',
}

function DonationDetails({
  cart,
  donationCommentText,
  flashErrorMessage,
  clearMessages,
  isCartLoading,
  moveToNextStep,
  selectedDonation: { displayName, prices, image },
  updateDonationCart,
  stepName,
  displayOrgText,
  orgConfig,
}) {
  return (
    <div className="step-container">
      <MainHeader title={stepName} />
      <div className="row">
        <div className="col-8">
          <div className="confirmation-info">
            <RenderedHTML>
              {displayOrgText(displayKeys.DONATION_AMOUNT_DETAILS, {
                displayName,
              })}
            </RenderedHTML>
          </div>
          <DonationForm
            prices={prices}
            commentText={donationCommentText}
            initialValues={{
              price: first(cart.priceSelections), // assumes only 1 donation can be selected per cart
              donationUdfs: {
                receiverCountry: orgConfig.DEFAULT_COUNTRY,
                ...cart.donationUdfs,
              },
            }}
            onSubmit={debounce(({ price, ...fields }) => {
              clearMessages()
              // Clear selection if there is no price (i.e., "Different Amount" is selected)
              if (!price.price)
                return updateDonationCart({ priceSelections: [] })

              return updateDonationCart({
                ...fields,
                priceSelections: [{ ...price, quantity: 1 }],
              })
            }, 500)}
            onSubmitFail={({ message, price }) => {
              const error = price || message
              if (error) flashErrorMessage(error)
            }}
            onSubmitSuccess={moveToNextStep}
            isCartLoading={isCartLoading}
          />
        </div>
        <StickyContainer className="col-4">
          <Receipt
            title={displayName}
            cart={cart}
            displayTotal
            image={image}
            isCartLoading={isCartLoading}
          />
        </StickyContainer>
      </div>
    </div>
  )
}

DonationDetails.propTypes = propTypes
DonationDetails.defaultProps = defaultProps

const setSelectedDonation = ({
  addDonationTypeToCart,
  cart,
  donationId,
  fetchProduct,
  flashErrorMessage,
  push,
  webstore,
}) => {
  fetchProduct(donationId)
    .then((donation) => {
      if (cart.productId === donation.id) return

      return addDonationTypeToCart(donation)
    })
    .catch(() => {
      push(`/${webstore}/donate`)
      flashErrorMessage(
        'Donation type unavailable. Please select a type below',
        { timeout: 6000 }
      )
    })
}

function mapParamsToProps({ id }) {
  return { donationId: id }
}

function mapStateToProps(state) {
  return {
    config: globalSelectors.config(state),
    cart: selectors.cart(state),
    donationCommentText: globalSelectors.donationCommentText(state),
    isCartLoading: apiSelectors.isLoading(state, apiActions.updateDonationCart),
    selectedDonation: selectors.selectedDonation(state),
    webstore: globalSelectors.webstore(state),
    stepName: selectors.currentStepName(state),
    nextStepPath: selectors.nextStepPath(state),
    orgConfig: globalSelectors.config(state),
  }
}

const mapDispatchToProps = {
  addDonationTypeToCart: apiActions.addDonationTypeToCart,
  fetchProduct: apiActions.fetchDonationProduct,
  flashErrorMessage: flashActions.flashErrorMessage,
  clearMessages: flashActions.clearMessages,
  push,
  updateDonationCart: apiActions.updateDonationCart,
}

const modify = ({ push, webstore, nextStepPath }) => {
  return {
    moveToNextStep: () => push(`/${webstore}/donate/${nextStepPath}`),
  }
}

const donationTypeSelected = ({ donationId, selectedDonation }) => {
  return selectedDonation && selectedDonation.id === donationId
}

function initializeOrgHelpers({ config }) {
  return {
    displayOrgText: createDisplayOrgText(config),
  }
}

export default compose(
  connectParams(mapParamsToProps),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  modifyProps(modify),
  onMount(setSelectedDonation),
  withPropsOnChange(['config'], initializeOrgHelpers),
  waitFor(donationTypeSelected, Spinner)
)(DonationDetails)
