import { middleware as configureMiddleware } from 'lp-redux-api'
import * as LS from 'local-storage'
import { selectors as globalSelectors } from 'global-reducer'
import { selectors as memberSelectors } from '../main/base/member/reducer'
import * as memberActions from '../main/base/member/actions'
import { composeMiddleware, http } from 'lp-requests'
import { has, set } from 'lodash'

const addBearerToken = () => {
  const token = LS.getMemberToken() || LS.getCartToken()
  if (token) return { bearerToken: token }
}

const addWebstoreQueryParam = ({ query = {} }) => {
  const webstore = globalSelectors.webstore(window.store.getState())
  if (webstore)
    return {
      query: {
        ...query,
        webstore,
      },
    }
}

const addCostRateIdQueryParam = ({ query = {} }) => {
  const costRateId = memberSelectors.costRateId(window.store.getState())
  if (costRateId)
    return {
      query: {
        ...query,
        costRateId,
      },
    }
}

const addCostRateIdToCart = ({ body = {} }) => {
  // Set costRateId on every cart update
  if (!body || !has(body, 'cart')) return
  const costRateId = memberSelectors.costRateId(window.store.getState())
  if (!costRateId) return
  return set(body, 'cart.costRateId', costRateId)
}

const before = composeMiddleware(
  addBearerToken,
  addWebstoreQueryParam,
  addCostRateIdQueryParam,
  addCostRateIdToCart
)

const onUnauthorized = () => {
  return function(dispatch) {
    dispatch(memberActions.clearMemberSession())
    dispatch(memberActions.clearState())
  }
}

// Config is a function that can take options
const config = (options) => ({
  root: `${process.env.API_URL}/${options.orgSlug}`,
  mode: 'cors',
  successDataPath: 'data.attributes',
  before,
  onUnauthorized,
})

export const createMiddleware = (options) =>
  configureMiddleware(http, config(options))
