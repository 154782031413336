import React, { useState } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import {
  MemberEmailLoginForm,
  MemberNumLoginForm,
  MemberRegistrationForm,
} from '../forms'
import * as apiActions from 'api-actions'
import PropTypes from 'prop-types'
import * as routerActions from 'react-router-redux'
import { selectors as globalSelectors } from 'global-reducer'
import { selectors as memberSelectors } from '../reducer'
import { selectors as membershipSelectors } from 'base/membership/reducer'
import classnames from 'classnames'
import {
  connectQuery,
  createDisplayOrgText,
  routerSelectors,
  findExistingMembership,
} from 'utils'
import { modifyProps } from 'lp-hoc'
import { displayKeys } from 'config'
import { InfoBox, RenderedHTML, ToggleTip } from 'components'
import {
  ExistingMemberModal,
  RenewalPathModal,
  ForgotMemberNumModal,
} from '../components'
import { find, get } from 'lodash'
import { clearMessages } from 'redux-flash'
import * as Types from 'types'

const propTypes = {
  config: PropTypes.object.isRequired,
  loginMethod: PropTypes.string,
  submitLogin: PropTypes.func.isRequired,
  displayOrgText: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  sendToAll: PropTypes.func.isRequired,
  sendToCurrent: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  showExistingMemberModal: PropTypes.func.isRequired,
  showForgotMemberNumModal: PropTypes.func.isRequired,
  contactEmail: PropTypes.string.isRequired,
  clearMessages: PropTypes.func.isRequired,
}

const defaultProps = {}

function MemberLogin({
  config,
  displayOrgText,
  submitLogin,
  redirect,
  sendToAll,
  sendToCurrent,
  register,
  showExistingMemberModal,
  showForgotMemberNumModal,
  contactEmail,
  clearMessages,
  loginMethod,
}) {
  const enableMemberRegistration = config.ENABLE_MEMBER_REGISTRATION

  const [useMemberIdLogin, setUseMemberIdLogin] = useState(
    loginMethod === Types.loginFormMethods.memberNumber
  )
  const loginTooltip = displayOrgText(displayKeys.MEMBER_LOGIN_TOOLTIP)
  const registrationTooltip = displayOrgText(
    displayKeys.MEMBER_REGISTRATION_TOOLTIP
  )
  const loginInstructions = useMemberIdLogin
    ? displayKeys.MEMBER_ID_LOGIN_INSTRUCTIONS
    : displayKeys.MEMBER_EMAIL_LOGIN_INSTRUCTIONS
  const loginErrorMessage = `
   Unable to authenticate at this time. Please try again later.
   If you are still are having problems, contact customer service at ${contactEmail}.
 `
  const registerErrorMessage = `
    Unable to register at this time. Please try again later.
    If you are still are having problems, contact customer service at ${contactEmail}.
  `
  const membershipLabel = displayOrgText(displayKeys.MEMBERSHIP_LABEL)

  const loginMethodSwitchTitle = useMemberIdLogin
    ? displayOrgText(displayKeys.MEMBER_ID_LOGIN_SWITCH_TITLE)
    : displayOrgText(displayKeys.MEMBER_EMAIL_LOGIN_SWITCH_TITLE)

  const loginMethodSwitchLinkLabel = useMemberIdLogin
    ? displayOrgText(displayKeys.MEMBER_ID_LOGIN_SWITCH_LINK_LABEL)
    : displayOrgText(displayKeys.MEMBER_EMAIL_LOGIN_SWITCH_LINK_LABEL)
  return (
    <div>
      <div
        className={classnames('login-container', {
          'hidden-member-signup': !enableMemberRegistration,
        })}
      >
        <div className="row">
          <div
            className={classnames({
              'col-6': enableMemberRegistration,
              'login-container-left': enableMemberRegistration,
            })}
          >
            <div className="login-container-title">
              <h2>{displayOrgText(displayKeys.MEMBER_LOGIN_TITLE)}</h2>
              {loginTooltip && (
                <ToggleTip allowHTML content={loginTooltip}>
                  <button
                    aria-label="tooltip"
                    className="toggletip"
                    type="button"
                  >
                    ?
                  </button>
                </ToggleTip>
              )}
            </div>
            <RenderedHTML>{displayOrgText(loginInstructions)}</RenderedHTML>
            {useMemberIdLogin ? (
              <MemberNumLoginForm
                onSubmit={(params) => {
                  clearMessages()
                  return submitLogin(params, { loginErrorMessage })
                }}
                displayOrgText={displayOrgText}
                onForgot={showForgotMemberNumModal}
              />
            ) : (
              <MemberEmailLoginForm
                onSubmit={(params) => {
                  clearMessages()
                  return submitLogin(params, { loginErrorMessage })
                }}
                displayOrgText={displayOrgText}
              />
            )}
            <div className="switch-login-method">
              <p>{loginMethodSwitchTitle}</p>
              <button
                type="button"
                className="link-primary"
                onClick={() => setUseMemberIdLogin(!useMemberIdLogin)}
              >
                <RenderedHTML>{loginMethodSwitchLinkLabel}</RenderedHTML>
              </button>
            </div>
          </div>
          {enableMemberRegistration && (
            <div className="col-6 login-container-right">
              <div className="login-container-title">
                <h2>{displayOrgText(displayKeys.MEMBER_REGISTRATION_TITLE)}</h2>
                {registrationTooltip && (
                  <ToggleTip allowHTML content={registrationTooltip}>
                    <button
                      aria-label="tooltip"
                      className="toggletip"
                      type="button"
                    >
                      ?
                    </button>
                  </ToggleTip>
                )}
              </div>
              <p>
                {displayOrgText(displayKeys.MEMBER_REGISTRATION_INSTRUCTIONS)}
              </p>
              <MemberRegistrationForm
                onSubmit={({ newMember }) => {
                  clearMessages()
                  return register({ newMember }).catch((e) => {
                    if (e && e.status === 409)
                      // if just e is thrown, onSubmitFail only receives the message without the status code
                      // new object is created to pass the status as well
                      // eslint-disable-next-line
                      throw { errors: e, status: e.status }
                    throw new Error(registerErrorMessage)
                  })
                }}
                onSubmitSuccess={redirect}
                onSubmitFail={(e) => {
                  if (e && e.status === 409) return showExistingMemberModal()
                }}
                displayOrgText={displayOrgText}
              />
            </div>
          )}
        </div>
      </div>
      <div className="information-box-container">
        {config.SHOW_MEMBER_LOGIN_INFO_BOX && (
          <InfoBox
            title={displayOrgText(displayKeys.MEMBER_LOGIN_INFO_BOX_TITLE)}
          >
            <RenderedHTML>
              {displayOrgText(displayKeys.MEMBER_LOGIN_INFO_BOX)}
            </RenderedHTML>
          </InfoBox>
        )}
      </div>
      <ExistingMemberModal>
        <RenderedHTML>
          {displayOrgText(displayKeys.EXISTING_MEMBER_MODAL_CONTENT)}
        </RenderedHTML>
      </ExistingMemberModal>
      <RenewalPathModal onViewAll={sendToAll} onRenew={sendToCurrent}>
        <h2>Would you like to renew?</h2>
        <p>
          It looks like you have an existing {membershipLabel}. You may renew
          your {membershipLabel}, or view all to select a different{' '}
          {membershipLabel}.
        </p>
      </RenewalPathModal>
      <ForgotMemberNumModal>
        <h2>Contact</h2>
        <p>
          If you've forgotten your member number, please contact Customer
          Support at <a href={`mailto:${contactEmail}`}>{contactEmail}</a>.
          Somone will assist you in retreiving your member number.
        </p>
      </ForgotMemberNumModal>
    </div>
  )
}

MemberLogin.propTypes = propTypes
MemberLogin.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    config: globalSelectors.config(state),
    currentPath: routerSelectors.currentPath(state),
    redirectUrl: routerSelectors.redirectUrl(state),
    webstore: globalSelectors.webstore(state),
    contactEmail: globalSelectors.contactEmail(state),
    primaryMember: memberSelectors.primaryMember(state),
    memberships: membershipSelectors.memberships(state),
  }
}

const mapDispatchToProps = {
  login: apiActions.requestMemberLogin,
  push: routerActions.push,
  register: apiActions.registerMember,
  fetchMemberships: apiActions.fetchMemberships,
  showExistingMemberModal: () => ExistingMemberModal.show(),
  showRenewalPathModal: () => RenewalPathModal.show(),
  showForgotMemberNumModal: () => ForgotMemberNumModal.show(),
  clearMessages,
}

const initializeOrgHelpers = ({ config }) => {
  return {
    displayOrgText: createDisplayOrgText(config),
  }
}

const getRedirectUrl = ({
  currentPath,
  loginRedirect,
  redirectUrl,
  basePath,
  config,
}) => {
  if (loginRedirect) return '/' + loginRedirect
  if (config.DEFAULT_LOGIN_REDIRECT_PATH)
    return config.DEFAULT_LOGIN_REDIRECT_PATH
  if (redirectUrl && !redirectUrl.includes(currentPath)) return redirectUrl // Path before failing authentication and getting redirected
  return basePath
}

const existingMembershipProductId = (membershipIds, primaryMember) => {
  const matchedMembershipIds = find(membershipIds, ({ centamanId }) => {
    return !!findExistingMembership(primaryMember, centamanId)
  })
  return get(matchedMembershipIds, 'productId')
}

const membershipIds = (memberships) => {
  return memberships.map((membershipProduct) => {
    return {
      centamanId: membershipProduct.centamanId,
      productId: membershipProduct.id,
    }
  })
}

// will show renewal modal if primary member has an existing membership
// that matches available memberships
const hasExistingMembership = (primaryMember, memberships) => {
  // if there's only one membership available, don't show renewal modal
  // user will be redirected to the renewal flow anyway
  if (memberships.length <= 1) return false
  return existingMembershipProductId(membershipIds(memberships), primaryMember)
}

const modify = ({
  config,
  push,
  currentPath,
  redirectUrl,
  webstore,
  login,
  memberships,
  method,
  primaryMember,
  showRenewalPathModal,
  fetchMemberships,
  loginRedirect,
}) => {
  const membershipPath = webstore
    ? '/' + webstore + '/memberships'
    : '/all/memberships'
  const allMembershipsPath = membershipPath + '/main'
  const redirectPath = getRedirectUrl({
    currentPath,
    loginRedirect,
    redirectUrl,
    basePath: allMembershipsPath,
    config,
  })
  const cameFromMembershipsFlow = redirectPath.includes('memberships')
  const redirect = () => push(redirectPath)
  return {
    loginMethod:
      method || config.MEMBER_LOGIN_METHOD || Types.loginFormMethods.email,
    redirect,
    sendToAll: () => push(allMembershipsPath),
    sendToCurrent: () => {
      const productId = existingMembershipProductId(
        membershipIds(memberships),
        primaryMember
      )
      if (productId) push(`${membershipPath}/details/${productId}`)
    },
    submitLogin: (params, { loginErrorMessage }) => {
      return (
        // need to grab responses from here as selector props take too long to update
        login(params)
          .then((currentMember) => {
            return fetchMemberships().then((memberships) => {
              if (
                !cameFromMembershipsFlow ||
                !currentMember ||
                !memberships ||
                !hasExistingMembership(currentMember.primaryMember, memberships)
              ) {
                return redirect()
              }
              return showRenewalPathModal()
            })
          })
          .catch((e) => {
            if (e && e.status === 401) throw new Error(e.errors.message)
            throw new Error(loginErrorMessage)
          })
      )
    },
  }
}

export default compose(
  connectQuery('method'),
  connectQuery('loginRedirect'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  modifyProps(initializeOrgHelpers),
  modifyProps(modify)
)(MemberLogin)
