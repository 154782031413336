import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Field, formValueSelector } from 'redux-form'
import { lpForm } from 'lp-form'
import { Textarea, SubmitButton } from 'lp-components'
import { RadioGroupPriceSelection } from '../components'
import { modifyProps } from 'lp-hoc'
import { isNil, noop } from 'lodash'
import { displayCurrency } from 'utils'

const propTypes = {
  commentText: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  prices: PropTypes.arrayOf(Types.donationPrice),
}

const defaultProps = {
  prices: [],
  onContinue: noop,
  selection: null,
}

const validateThresholds = ({ price, minimumAmount, maximumAmount }) => {
  if (!price) return

  if (!isNil(maximumAmount) && price > maximumAmount)
    return `Donation amount cannot exceed ${displayCurrency(maximumAmount)}`
  if (!isNil(minimumAmount) && price < minimumAmount)
    return `Donation amount must be at least ${displayCurrency(minimumAmount)}`
}

const validatePrice = (donationPrice) => {
  if (!donationPrice) return 'Donation amount must be selected'
  if (!donationPrice.price) return 'Donation amount must be provided' // If "different amount is selected, make sure a price is provided"

  return validateThresholds(donationPrice)
}

function DonationForm({
  commentText,
  handleSubmit,
  submitting,
  onSubmit,
  prices,
}) {
  return (
    <form onSubmit={handleSubmit} className="donation-selection select-amount">
      <Field
        name="price"
        component={RadioGroupPriceSelection}
        options={prices}
        aria-label="Select donation amount"
        validate={validatePrice}
        onChange={(e, price) => {
          // Do not update the cart when thresholds are exceeded
          if (validateThresholds(price)) return
          onSubmit({ price })
        }}
      />
      {!!commentText && (
        <fieldset>
          <label htmlFor="donationComment" className="donation-note">
            {commentText}
          </label>
          <Field
            name="donationComment"
            component={Textarea}
            maxLength={200}
            hideCharacterCount
            label={false}
            aria-label="Include optional message"
            placeholder="In memory of, dedicated to..."
          />
        </fieldset>
      )}
      <SubmitButton className="full-width-button" submitting={submitting}>
        Continue
      </SubmitButton>
    </form>
  )
}

DonationForm.propTypes = propTypes
DonationForm.defaultProps = defaultProps

const valueSelector = formValueSelector('donation-details')

function mapStateToProps(state) {
  return {
    selection: valueSelector(state, 'price'),
  }
}

const modify = ({ prices }) => {
  const options = prices.map((price) => ({
    ...price,
    key: price.displayName,
    value: price.centamanId,
  }))

  return {
    options,
  }
}

export default compose(
  connect(mapStateToProps),
  modifyProps(modify),
  lpForm({
    name: 'donation-details',
  })
)(DonationForm)
