import React from 'react'
// import PropTypes from 'prop-types'
import * as Types from 'types'
import { HorizontalCard } from 'components'

const propTypes = {
  product: Types.product.isRequired,
}

const defaultProps = {}

const getInternalProductPath = (product) => {
  const { path, webstore, type, id } = product
  if (path) return path
  const baseUrl = '/' + (webstore || 'all')
  if (type === 'MembershipProduct')
    return baseUrl + `/memberships/details/${id}`
  return baseUrl + `/tickets/details/${id}`
}

function ProductCard({ product }) {
  return (
    <div className="horizontal-item">
      <HorizontalCard
        displayName={product.displayName}
        imageUrl={product.image}
        path={getInternalProductPath(product)}
        price={product.startingPrice}
        pricePhrase="Starting from"
        descriptionHtml={product.descriptionHtml}
        buttonText={product.selectButtonText || 'Select'}
      />
    </div>
  )
}

ProductCard.propTypes = propTypes
ProductCard.defaultProps = defaultProps

export default ProductCard
